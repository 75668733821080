import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable, runInAction } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { TradeVolumeReportRecordModel, TradeVolumeReportRequestParams } from '@models/reports/TradeVolume';
import { BillingStatisticsGetRequest } from '@api/fbe/manager/BillingStatisticsGetRequest';
import { Message, MessageType } from '@components/Message/Message';
import { Date as CoreDate } from '@api/fbe/core/Date';
import moment from 'moment';

class TradeVolumeReportStore {
    errorTracker = new ErrorsTracker({ title: 'Trade Volume Report' });

    constructor() {
        makeAutoObservable(this);
    }

    data: TradeVolumeReportRecordModel[] = [];

    isLoading = false;

    requestParams: TradeVolumeReportRequestParams | null = null;

    setData(value: TradeVolumeReportRecordModel[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setRequestParams = (params: TradeVolumeReportRequestParams | null): void => {
        this.requestParams = params;
    };

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new BillingStatisticsGetRequest();
        if (this.requestParams) {
            if (this.requestParams.RequestFrom) {
                request.From = new CoreDate(
                    this.requestParams.RequestFrom.getFullYear(),
                    this.requestParams.RequestFrom.getMonth() + 1,
                    this.requestParams.RequestFrom.getDate(),
                );
            }
            if (this.requestParams.RequestTo) {
                request.To = new CoreDate(
                    this.requestParams.RequestTo.getFullYear(),
                    this.requestParams.RequestTo.getMonth() + 1,
                    this.requestParams.RequestTo.getDate(),
                );
            }
            try {
                const result = await SocketClient.instance.request(request, this.errorTracker);
                runInAction(() => {
                    this.setData(
                        result.Records.map((record, index) => ({
                            id: index.toString(),
                            Date: moment.utc(`${record.Date.Year}-${record.Date.Month}-${record.Date.Day}`).toDate(),
                            TransactionTradeVolumeUSDSum: record.TransactionTradeVolumeUSDSum,
                            TransactionsWithMissedTradeVolumeUSDCount:
                                record.TransactionsWithMissedTradeVolumeUSDCount.toNumber(),
                        })),
                    );
                });
            } catch (error) {
                Message(
                    MessageType.error,
                    "Can't load Trade Volume Report. Please check your request parameters and rights",
                );
            }
            this.setIsLoading(false);
        }
    }

    reset() {
        this.data = [];
        this.isLoading = false;
        this.requestParams = null;
    }
}

export const tradeVolumeReportStore = new TradeVolumeReportStore();
