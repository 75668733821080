import React from 'react';
import AuthLayout from '@App/layouts/AuthLayout';
import { ProtectedRouteWrapper } from '@components/ProtectedRoute';
import { IRouteTree } from '@components/Router';
import { keyMap, t } from '@localization';
import { PagePlaceholder } from '@components/PagePlaceholder/PagePlaceholder';
import { AdminPanelAccessMap, TabAccessLevel } from '@utils/accessMap';
import { adminPages, adminSections } from './utils';
import {
    IconArchive,
    IconBinoculars,
    IconBrain,
    IconCalendarAlt,
    IconChessClock,
    IconCodeMerge,
    IconCoins,
    IconDungeon,
    IconEuroSign,
    IconFileChart,
    IconGears,
    IconHistory,
    IconIdCard,
    IconLaptop,
    IconNetworkWired,
    IconPuzzle,
    IconShield,
    IconTools,
    IconUserTie,
    IconUsers,
} from '@components/Icons/MenuIcon';

const DEFAULT_REDIRECT_ROUTE = adminPages.accounts.path;

export type AdditionalRouteParams = {
    menu?: {
        key?: string;
        title: string;
        icon?: string | JSX.Element;
    };
};

const filterSection = (menuItem: IRouteTree<AdditionalRouteParams>, accessMap: AdminPanelAccessMap): boolean => {
    if (!menuItem.children) {
        return Object.keys(accessMap[menuItem.menu!.key as keyof AdminPanelAccessMap].tabs).some(
            (key) =>
                (accessMap[menuItem.menu!.key as keyof AdminPanelAccessMap].tabs[key] as Record<string, TabAccessLevel>)
                    .accessible,
        );
    }
    return !menuItem!.children?.every((child) => !!child.redirectTo);
};

const filterByReleaseAndRights = ({
    menuItem,
    accessMap,
    isItemSection,
}: {
    menuItem: IRouteTree<AdditionalRouteParams> | undefined;
    accessMap: AdminPanelAccessMap | null;
    isItemSection?: boolean;
}): boolean =>
    menuItem?.menu?.key && accessMap
        ? (!isItemSection
              ? accessMap[menuItem.menu.key as keyof AdminPanelAccessMap].accessible
              : filterSection(menuItem, accessMap))
        : true;

export const getRoutes = (accessMap: AdminPanelAccessMap | null): IRouteTree<AdditionalRouteParams> => ({
    children: [
        {
            path: '/signin',
            exact: true,
            component: AuthLayout,
        },
        {
            component: ProtectedRouteWrapper,
            children: [
                {
                    deferComponent: () => import('@App/layouts/AppLayout').then((x) => x.AppLayout),
                    children: [
                        {
                            menu: {
                                key: adminSections.administration.key,
                                title: t(keyMap.menu.adminisrtation),
                                icon: <IconTools />,
                            },
                            path: adminSections.administration.path,
                            exact: false,
                            children: [
                                {
                                    menu: {
                                        key: adminPages.system.key,
                                        title: t(keyMap.menu.system),
                                        icon: <IconBrain />,
                                    },
                                    path: adminPages.system.path,
                                    deferComponent: () => import('@pages/System/System').then((x) => x.System),
                                    placeholder: PagePlaceholder,
                                    exact: false,
                                },
                                {
                                    menu: {
                                        key: adminPages.modules.key,
                                        title: t(keyMap.menu.modules),
                                        icon: <IconNetworkWired />,
                                    },
                                    path: adminPages.modules.path,
                                    deferComponent: () => import('@pages/Modules/Modules').then((x) => x.Modules),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.platforms.key,
                                        title: t(keyMap.menu.platforms),
                                        icon: <IconLaptop />,
                                    },
                                    path: adminPages.platforms.path,
                                    deferComponent: () => import('@pages/Platforms/Platforms').then((x) => x.Platforms),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.currencies.key,
                                        title: t(keyMap.menu.currencies),
                                        icon: <IconEuroSign />,
                                    },
                                    path: adminPages.currencies.path,
                                    deferComponent: () => import('@pages/Currencies/Currencies'),
                                    placeholder: PagePlaceholder,
                                    exact: false,
                                },
                                {
                                    menu: {
                                        key: adminPages.symbols.key,
                                        title: t(keyMap.menu.symbols),
                                        icon: <IconCoins />,
                                    },
                                    path: adminPages.symbols.path,
                                    deferComponent: () => import('@pages/Symbols/Symbols').then((x) => x.Symbols),
                                    placeholder: PagePlaceholder,
                                    exact: false,
                                },
                                {
                                    menu: {
                                        key: adminPages.accounts.key,
                                        title: t(keyMap.menu.accounts),
                                        icon: <IconUsers />,
                                    },
                                    path: adminPages.accounts.path,
                                    deferComponent: () => import('@pages/Accounts/Accounts').then((x) => x.Accounts),
                                    placeholder: PagePlaceholder,
                                    exact: false,
                                },
                                {
                                    menu: {
                                        key: adminPages.managers.key,
                                        title: t(keyMap.menu.managers),
                                        icon: <IconUserTie />,
                                    },
                                    path: adminPages.managers.path,
                                    deferComponent: () => import('@pages/Managers/Managers').then((x) => x.Managers),
                                    placeholder: PagePlaceholder,
                                    exact: false,
                                },
                                {
                                    menu: {
                                        key: adminPages.calendars.key,
                                        title: t(keyMap.menu.calendars),
                                        icon: <IconCalendarAlt />,
                                    },
                                    path: adminPages.calendars.path,
                                    deferComponent: () => import('@pages/Calendar/Calendar').then((x) => x.Calendar),
                                    placeholder: PagePlaceholder,
                                    exact: false,
                                },
                                {
                                    menu: {
                                        key: adminPages.profiles.key,
                                        title: t(keyMap.menu.profiles),
                                        icon: <IconIdCard />,
                                    },
                                    path: adminPages.profiles.path,
                                    deferComponent: () => import('@pages/Profiles/Profiles').then((x) => x.Profiles),
                                    placeholder: PagePlaceholder,
                                    exact: false,
                                },
                                {
                                    menu: {
                                        key: adminPages.dataFeeds.key,
                                        title: t(keyMap.menu.dataFeed),
                                        icon: <IconArchive />,
                                    },
                                    path: adminPages.dataFeeds.path,
                                    deferComponent: () => import('@pages/DataFeeds/DataFeeds').then((x) => x.DataFeeds),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.tradingRoutes.key,
                                        title: t(keyMap.menu.tradingRoute),
                                        icon: <IconCodeMerge />,
                                    },
                                    path: adminPages.tradingRoutes.path,
                                    deferComponent: () =>
                                        import('@pages/TradingRoute/TradingRoute').then((x) => x.TradingRoutes),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.gateways.key,
                                        title: t(keyMap.menu.gateway),
                                        icon: <IconDungeon />,
                                    },
                                    path: adminPages.gateways.path,
                                    deferComponent: () => import('@pages/Gateways/Gateways').then((x) => x.Gateways),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.quoteHistory.key,
                                        title: t(keyMap.menu.quoteHistory),
                                        icon: <IconHistory />,
                                    },
                                    path: adminPages.quoteHistory.path,
                                    deferComponent: () =>
                                        import('@pages/QuoteHistory/QuoteHistory').then((x) => x.QuoteHistory),
                                    placeholder: PagePlaceholder,
                                    exact: false,
                                },
                                {
                                    menu: {
                                        key: adminPages.sessions.key,
                                        title: t(keyMap.menu.sessions),
                                        icon: <IconChessClock />,
                                    },
                                    path: adminPages.sessions.path,
                                    deferComponent: () => import('@pages/Sessions/Sessions').then((x) => x.Sessions),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    path: adminSections.administration.path,
                                    exact: true,
                                    redirectTo: DEFAULT_REDIRECT_ROUTE,
                                },
                            ].filter((item) => filterByReleaseAndRights({ menuItem: item, accessMap })),
                        },
                        {
                            menu: {
                                key: adminSections.riskManagement.key,
                                title: t(keyMap.menu.riskManagement),
                                icon: <IconShield />,
                            },
                            path: adminSections.riskManagement.path,
                            deferComponent: () =>
                                import('@pages/RiskManagement/RiskManagement').then((x) => x.RiskManagement),
                            placeholder: PagePlaceholder,
                            exact: false,
                        },
                        {
                            menu: {
                                key: adminSections.reports.key,
                                title: t(keyMap.menu.reports),
                                icon: <IconFileChart />,
                            },
                            path: adminSections.reports.path,
                            exact: false,
                            children: [
                                {
                                    menu: {
                                        key: adminPages.balanceTransactions.key,
                                        title: t(keyMap.menu.balanceTransactions),
                                    },
                                    path: adminPages.balanceTransactions.path,
                                    deferComponent: () =>
                                        import('@pages/BalanceTransactions/BalanceTransactions').then(
                                            (x) => x.BalanceTransactions,
                                        ),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.statements.key,
                                        title: t(keyMap.menu.statements),
                                    },
                                    path: adminPages.statements.path,
                                    deferComponent: () =>
                                        import('@pages/Statements/Statements').then((x) => x.Statements),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.dailyTradeReport.key,
                                        title: t(keyMap.menu.dailyTradeReport),
                                    },
                                    path: adminPages.dailyTradeReport.path,
                                    deferComponent: () =>
                                        import('@pages/TradeVolumeReport/TradeVolumeReport').then((x) => x.TradeVolumeReport),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    path: adminSections.reports.path,
                                    exact: true,
                                    redirectTo: DEFAULT_REDIRECT_ROUTE,
                                },
                            ].filter((item) => filterByReleaseAndRights({ menuItem: item, accessMap })),
                        },
                        {
                            menu: {
                                key: adminSections.monitoring.key,
                                title: t(keyMap.menu.monitoring),
                                icon: <IconBinoculars />,
                            },
                            path: adminSections.monitoring.path,
                            exact: false,
                            children: [
                                {
                                    menu: {
                                        key: adminPages.market.key,
                                        title: t(keyMap.menu.market),
                                    },
                                    path: adminPages.market.path,
                                    deferComponent: () => import('@pages/Market/Market').then((x) => x.Market),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.modulesWatch.key,
                                        title: t(keyMap.menu.modules),
                                    },
                                    path: adminPages.modulesWatch.path,
                                    deferComponent: () =>
                                        import('@pages/ModulesWatch/ModulesWatch').then((x) => x.ModulesWatch),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.journal.key,
                                        title: t(keyMap.menu.journal),
                                    },
                                    path: adminPages.journal.path,
                                    deferComponent: () => import('@pages/Journal/Journal').then((x) => x.Journal),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.businessJournal.key,
                                        title: t(keyMap.menu.businessJournal),
                                    },
                                    path: adminPages.businessJournal.path,
                                    deferComponent: () =>
                                        import('@pages/BusinessJournal/BusinessJournal').then((x) => x.BusinessJournal),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    path: adminSections.monitoring.path,
                                    exact: true,
                                    redirectTo: DEFAULT_REDIRECT_ROUTE,
                                },
                            ].filter((item) => filterByReleaseAndRights({ menuItem: item, accessMap })),
                        },
                        {
                            menu: {
                                key: adminSections.hlb.key,
                                title: t(keyMap.menu.hlb),
                                icon: <IconPuzzle />,
                            },
                            path: adminSections.hlb.path,
                            exact: false,
                            children: [
                                {
                                    menu: {
                                        key: adminPages.leverageProfiles.key,
                                        title: t(keyMap.menu.leverage),
                                    },
                                    path: adminPages.leverageProfiles.path,
                                    deferComponent: () =>
                                        import('@pages/HLB/LeverageProfile/LeverageProfiles').then(
                                            (x) => x.LeverageProfiles,
                                        ),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.hlbProfiles.key,
                                        title: t(keyMap.menu.hlbProfiles),
                                    },
                                    path: adminPages.hlbProfiles.path,
                                    deferComponent: () =>
                                        import('@pages/HLB/Profiles/Profiles').then((x) => x.Profiles),
                                    placeholder: PagePlaceholder,
                                    exact: false,
                                },
                                {
                                    path: adminSections.hlb.path,
                                    exact: true,
                                    redirectTo: DEFAULT_REDIRECT_ROUTE,
                                },
                            ].filter((item) => filterByReleaseAndRights({ menuItem: item, accessMap })),
                        },
                        {
                            menu: {
                                key: adminSections.settings.key,
                                title: t(keyMap.menu.settings),
                                icon: <IconGears />,
                            },
                            path: adminSections.settings.path,
                            exact: false,
                            children: [
                                {
                                    menu: {
                                        key: adminPages.mail.key,
                                        title: t(keyMap.menu.mail),
                                    },
                                    path: adminPages.mail.path,
                                    deferComponent: () => import('@pages/Mail/Mail').then((x) => x.Mail),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.emailTemplates.key,
                                        title: t(keyMap.menu.emailTemplates),
                                    },
                                    path: adminPages.emailTemplates.path,
                                    deferComponent: () =>
                                        import('@pages/EmailTemplates/EmailTemplates').then((x) => x.EmailTemplates),
                                    placeholder: PagePlaceholder,
                                    exact: true,
                                },
                                {
                                    menu: {
                                        key: adminPages.pushNotifications.key,
                                        title: t(keyMap.menu.pushNotifications),
                                    },
                                    path: adminPages.pushNotifications.path,
                                    deferComponent: () =>
                                        import('@App/pages/PushNotifications/PushNotifications').then(
                                            (x) => x.PushNotifications,
                                        ),
                                    placeholder: PagePlaceholder,
                                    exact: false,
                                },
                                {
                                    menu: {
                                        key: adminPages.security.key,
                                        title: t(keyMap.menu.security),
                                    },
                                    path: adminPages.security.path,
                                    deferComponent: () => import('@pages/Security/Security').then((x) => x.Security),
                                    placeholder: PagePlaceholder,
                                    exact: false,
                                },
                                {
                                    path: adminSections.settings.path,
                                    exact: true,
                                    redirectTo: DEFAULT_REDIRECT_ROUTE,
                                },
                            ].filter((item) => filterByReleaseAndRights({ menuItem: item, accessMap })),
                        },
                        {
                            path: '/',
                            exact: true,
                            redirectTo: DEFAULT_REDIRECT_ROUTE,
                        },
                    ].filter((item) => filterByReleaseAndRights({ menuItem: item, accessMap, isItemSection: true })),
                },
            ],
        },
    ],
});
