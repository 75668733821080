// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: storage.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'
import * as inner from './inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {SocialTradingMasterUpdate} from './storage/SocialTradingMasterUpdate'
import {SocialTradingMasterUpdateModel} from './storage/SocialTradingMasterUpdate'
import {SocialTradingInvestorUpdate} from './storage/SocialTradingInvestorUpdate'
import {SocialTradingInvestorUpdateModel} from './storage/SocialTradingInvestorUpdate'
import {AccountUpdate} from './storage/AccountUpdate'
import {AccountUpdateModel} from './storage/AccountUpdate'
import {AccountMarginUpdate} from './storage/AccountMarginUpdate'
import {AccountMarginUpdateModel} from './storage/AccountMarginUpdate'
import {AssetUpdate} from './storage/AssetUpdate'
import {AssetUpdateModel} from './storage/AssetUpdate'
import {SocialTradingOrderInvestorUpdate} from './storage/SocialTradingOrderInvestorUpdate'
import {SocialTradingOrderInvestorUpdateModel} from './storage/SocialTradingOrderInvestorUpdate'
import {SocialTradingOrderMasterUpdate} from './storage/SocialTradingOrderMasterUpdate'
import {SocialTradingOrderMasterUpdateModel} from './storage/SocialTradingOrderMasterUpdate'
import {OrderUpdate} from './storage/OrderUpdate'
import {OrderUpdateModel} from './storage/OrderUpdate'
import {PositionUpdate} from './storage/PositionUpdate'
import {PositionUpdateModel} from './storage/PositionUpdate'
import {StorageVersionRequest} from './storage/StorageVersionRequest'
import {StorageVersionRequestModel} from './storage/StorageVersionRequest'
import {StorageVersionResponse} from './storage/StorageVersionResponse'
import {StorageVersionResponseModel} from './storage/StorageVersionResponse'
import {StorageUpdateNotify} from './storage/StorageUpdateNotify'
import {StorageUpdateNotifyModel} from './storage/StorageUpdateNotify'
import {AccountLoginRangeParams} from './storage/AccountLoginRangeParams'
import {AccountLoginRangeParamsModel} from './storage/AccountLoginRangeParams'
import {AccountInsertRequest} from './storage/AccountInsertRequest'
import {AccountInsertRequestModel} from './storage/AccountInsertRequest'
import {AccountInsertResponse} from './storage/AccountInsertResponse'
import {AccountInsertResponseModel} from './storage/AccountInsertResponse'
import {AccountSocialTradingMasterInsertRequest} from './storage/AccountSocialTradingMasterInsertRequest'
import {AccountSocialTradingMasterInsertRequestModel} from './storage/AccountSocialTradingMasterInsertRequest'
import {AccountSocialTradingMasterInsertResponse} from './storage/AccountSocialTradingMasterInsertResponse'
import {AccountSocialTradingMasterInsertResponseModel} from './storage/AccountSocialTradingMasterInsertResponse'
import {AccountSocialTradingInvestorInsertRequest} from './storage/AccountSocialTradingInvestorInsertRequest'
import {AccountSocialTradingInvestorInsertRequestModel} from './storage/AccountSocialTradingInvestorInsertRequest'
import {AccountSocialTradingInvestorInsertResponse} from './storage/AccountSocialTradingInvestorInsertResponse'
import {AccountSocialTradingInvestorInsertResponseModel} from './storage/AccountSocialTradingInvestorInsertResponse'
import {AccountUpdateRequestParams} from './storage/AccountUpdateRequestParams'
import {AccountUpdateRequestParamsModel} from './storage/AccountUpdateRequestParams'
import {AccountUpdateResponseParams} from './storage/AccountUpdateResponseParams'
import {AccountUpdateResponseParamsModel} from './storage/AccountUpdateResponseParams'
import {AccountUpdateRequest} from './storage/AccountUpdateRequest'
import {AccountUpdateRequestModel} from './storage/AccountUpdateRequest'
import {AccountUpdateResponse} from './storage/AccountUpdateResponse'
import {AccountUpdateResponseModel} from './storage/AccountUpdateResponse'
import {AccountDeleteRequest} from './storage/AccountDeleteRequest'
import {AccountDeleteRequestModel} from './storage/AccountDeleteRequest'
import {AccountDeleteResponse} from './storage/AccountDeleteResponse'
import {AccountDeleteResponseModel} from './storage/AccountDeleteResponse'
import {MarginUpdateRequest} from './storage/MarginUpdateRequest'
import {MarginUpdateRequestModel} from './storage/MarginUpdateRequest'
import {MarginUpdateResponse} from './storage/MarginUpdateResponse'
import {MarginUpdateResponseModel} from './storage/MarginUpdateResponse'
import {AssetInsertRequest} from './storage/AssetInsertRequest'
import {AssetInsertRequestModel} from './storage/AssetInsertRequest'
import {AssetInsertResponse} from './storage/AssetInsertResponse'
import {AssetInsertResponseModel} from './storage/AssetInsertResponse'
import {AssetUpdateRequest} from './storage/AssetUpdateRequest'
import {AssetUpdateRequestModel} from './storage/AssetUpdateRequest'
import {AssetUpdateResponse} from './storage/AssetUpdateResponse'
import {AssetUpdateResponseModel} from './storage/AssetUpdateResponse'
import {AssetDeleteRequest} from './storage/AssetDeleteRequest'
import {AssetDeleteRequestModel} from './storage/AssetDeleteRequest'
import {AssetDeleteResponse} from './storage/AssetDeleteResponse'
import {AssetDeleteResponseModel} from './storage/AssetDeleteResponse'
import {OrderInsertRequest} from './storage/OrderInsertRequest'
import {OrderInsertRequestModel} from './storage/OrderInsertRequest'
import {OrderInsertResponse} from './storage/OrderInsertResponse'
import {OrderInsertResponseModel} from './storage/OrderInsertResponse'
import {OrderUpdateRequest} from './storage/OrderUpdateRequest'
import {OrderUpdateRequestModel} from './storage/OrderUpdateRequest'
import {OrderUpdateResponse} from './storage/OrderUpdateResponse'
import {OrderUpdateResponseModel} from './storage/OrderUpdateResponse'
import {OrderDeleteRequest} from './storage/OrderDeleteRequest'
import {OrderDeleteRequestModel} from './storage/OrderDeleteRequest'
import {OrderDeleteResponse} from './storage/OrderDeleteResponse'
import {OrderDeleteResponseModel} from './storage/OrderDeleteResponse'
import {OrderDeleteAllRequest} from './storage/OrderDeleteAllRequest'
import {OrderDeleteAllRequestModel} from './storage/OrderDeleteAllRequest'
import {OrderDeleteAllResponse} from './storage/OrderDeleteAllResponse'
import {OrderDeleteAllResponseModel} from './storage/OrderDeleteAllResponse'
import {PositionInsertRequest} from './storage/PositionInsertRequest'
import {PositionInsertRequestModel} from './storage/PositionInsertRequest'
import {PositionInsertResponse} from './storage/PositionInsertResponse'
import {PositionInsertResponseModel} from './storage/PositionInsertResponse'
import {PositionUpdateRequest} from './storage/PositionUpdateRequest'
import {PositionUpdateRequestModel} from './storage/PositionUpdateRequest'
import {PositionUpdateResponse} from './storage/PositionUpdateResponse'
import {PositionUpdateResponseModel} from './storage/PositionUpdateResponse'
import {PositionDeleteRequest} from './storage/PositionDeleteRequest'
import {PositionDeleteRequestModel} from './storage/PositionDeleteRequest'
import {PositionDeleteResponse} from './storage/PositionDeleteResponse'
import {PositionDeleteResponseModel} from './storage/PositionDeleteResponse'
import {TradeHistoryRequest} from './storage/TradeHistoryRequest'
import {TradeHistoryRequestModel} from './storage/TradeHistoryRequest'
import {TradeHistoryResponse} from './storage/TradeHistoryResponse'
import {TradeHistoryResponseModel} from './storage/TradeHistoryResponse'
import {TradeEventsGetRequest} from './storage/TradeEventsGetRequest'
import {TradeEventsGetRequestModel} from './storage/TradeEventsGetRequest'
import {TradeEventsGetResponse} from './storage/TradeEventsGetResponse'
import {TradeEventsGetResponseModel} from './storage/TradeEventsGetResponse'
import {TradeEventsNotify} from './storage/TradeEventsNotify'
import {TradeEventsNotifyModel} from './storage/TradeEventsNotify'
import {TradeEventsInsertRequest} from './storage/TradeEventsInsertRequest'
import {TradeEventsInsertRequestModel} from './storage/TradeEventsInsertRequest'
import {TradeEventsInsertResponse} from './storage/TradeEventsInsertResponse'
import {TradeEventsInsertResponseModel} from './storage/TradeEventsInsertResponse'
import {ProfitClosedGetRequest} from './storage/ProfitClosedGetRequest'
import {ProfitClosedGetRequestModel} from './storage/ProfitClosedGetRequest'
import {ProfitClosedGetResponse} from './storage/ProfitClosedGetResponse'
import {ProfitClosedGetResponseModel} from './storage/ProfitClosedGetResponse'
import {OrdersClosedSummariesGetRequest} from './storage/OrdersClosedSummariesGetRequest'
import {OrdersClosedSummariesGetRequestModel} from './storage/OrdersClosedSummariesGetRequest'
import {OrdersClosedSummariesGetResponse} from './storage/OrdersClosedSummariesGetResponse'
import {OrdersClosedSummariesGetResponseModel} from './storage/OrdersClosedSummariesGetResponse'
import {OrderInsert2Request} from './storage/OrderInsert2Request'
import {OrderInsert2RequestModel} from './storage/OrderInsert2Request'
import {OrderInsert2Response} from './storage/OrderInsert2Response'
import {OrderInsert2ResponseModel} from './storage/OrderInsert2Response'
import {OrderUpdate2Request} from './storage/OrderUpdate2Request'
import {OrderUpdate2RequestModel} from './storage/OrderUpdate2Request'
import {OrderUpdate2Response} from './storage/OrderUpdate2Response'
import {OrderUpdate2ResponseModel} from './storage/OrderUpdate2Response'
import {OrderDelete2Request} from './storage/OrderDelete2Request'
import {OrderDelete2RequestModel} from './storage/OrderDelete2Request'
import {OrderDelete2Response} from './storage/OrderDelete2Response'
import {OrderDelete2ResponseModel} from './storage/OrderDelete2Response'
import {AccountInsert2Request} from './storage/AccountInsert2Request'
import {AccountInsert2RequestModel} from './storage/AccountInsert2Request'
import {AccountInsert2Response} from './storage/AccountInsert2Response'
import {AccountInsert2ResponseModel} from './storage/AccountInsert2Response'
import {AccountSocialTradingMasterInsert2Request} from './storage/AccountSocialTradingMasterInsert2Request'
import {AccountSocialTradingMasterInsert2RequestModel} from './storage/AccountSocialTradingMasterInsert2Request'
import {AccountSocialTradingMasterInsert2Response} from './storage/AccountSocialTradingMasterInsert2Response'
import {AccountSocialTradingMasterInsert2ResponseModel} from './storage/AccountSocialTradingMasterInsert2Response'
import {AccountSocialTradingInvestorInsert2Request} from './storage/AccountSocialTradingInvestorInsert2Request'
import {AccountSocialTradingInvestorInsert2RequestModel} from './storage/AccountSocialTradingInvestorInsert2Request'
import {AccountSocialTradingInvestorInsert2Response} from './storage/AccountSocialTradingInvestorInsert2Response'
import {AccountSocialTradingInvestorInsert2ResponseModel} from './storage/AccountSocialTradingInvestorInsert2Response'
import {AccountDelete2Request} from './storage/AccountDelete2Request'
import {AccountDelete2RequestModel} from './storage/AccountDelete2Request'
import {AccountDelete2Response} from './storage/AccountDelete2Response'
import {AccountDelete2ResponseModel} from './storage/AccountDelete2Response'
import {AssetInsert2Request} from './storage/AssetInsert2Request'
import {AssetInsert2RequestModel} from './storage/AssetInsert2Request'
import {AssetInsert2Response} from './storage/AssetInsert2Response'
import {AssetInsert2ResponseModel} from './storage/AssetInsert2Response'
import {AssetUpdate2Request} from './storage/AssetUpdate2Request'
import {AssetUpdate2RequestModel} from './storage/AssetUpdate2Request'
import {AssetUpdate2Response} from './storage/AssetUpdate2Response'
import {AssetUpdate2ResponseModel} from './storage/AssetUpdate2Response'
import {AssetDelete2Request} from './storage/AssetDelete2Request'
import {AssetDelete2RequestModel} from './storage/AssetDelete2Request'
import {AssetDelete2Response} from './storage/AssetDelete2Response'
import {AssetDelete2ResponseModel} from './storage/AssetDelete2Response'
import {PositionInsert2Request} from './storage/PositionInsert2Request'
import {PositionInsert2RequestModel} from './storage/PositionInsert2Request'
import {PositionInsert2Response} from './storage/PositionInsert2Response'
import {PositionInsert2ResponseModel} from './storage/PositionInsert2Response'
import {PositionUpdate2Request} from './storage/PositionUpdate2Request'
import {PositionUpdate2RequestModel} from './storage/PositionUpdate2Request'
import {PositionUpdate2Response} from './storage/PositionUpdate2Response'
import {PositionUpdate2ResponseModel} from './storage/PositionUpdate2Response'
import {PositionDelete2Request} from './storage/PositionDelete2Request'
import {PositionDelete2RequestModel} from './storage/PositionDelete2Request'
import {PositionDelete2Response} from './storage/PositionDelete2Response'
import {PositionDelete2ResponseModel} from './storage/PositionDelete2Response'
import {Deprecated001} from './storage/Deprecated001'
import {Deprecated001Model} from './storage/Deprecated001'
import {Deprecated002} from './storage/Deprecated002'
import {Deprecated002Model} from './storage/Deprecated002'
import {Deprecated003} from './storage/Deprecated003'
import {Deprecated003Model} from './storage/Deprecated003'
import {Deprecated004} from './storage/Deprecated004'
import {Deprecated004Model} from './storage/Deprecated004'
import {Deprecated005} from './storage/Deprecated005'
import {Deprecated005Model} from './storage/Deprecated005'
import {Deprecated006} from './storage/Deprecated006'
import {Deprecated006Model} from './storage/Deprecated006'
import {TradeEventsInsert2Request} from './storage/TradeEventsInsert2Request'
import {TradeEventsInsert2RequestModel} from './storage/TradeEventsInsert2Request'
import {TradeEventsInsert2Response} from './storage/TradeEventsInsert2Response'
import {TradeEventsInsert2ResponseModel} from './storage/TradeEventsInsert2Response'
import {MarginUpdate2Request} from './storage/MarginUpdate2Request'
import {MarginUpdate2RequestModel} from './storage/MarginUpdate2Request'
import {MarginUpdate2Response} from './storage/MarginUpdate2Response'
import {MarginUpdate2ResponseModel} from './storage/MarginUpdate2Response'
import {Account2ResponseParams} from './storage/Account2ResponseParams'
import {Account2ResponseParamsModel} from './storage/Account2ResponseParams'
import {AccountUpdate2Request} from './storage/AccountUpdate2Request'
import {AccountUpdate2RequestModel} from './storage/AccountUpdate2Request'
import {AccountUpdate2Response} from './storage/AccountUpdate2Response'
import {AccountUpdate2ResponseModel} from './storage/AccountUpdate2Response'
import {DepositMarginRequest} from './storage/DepositMarginRequest'
import {DepositMarginRequestModel} from './storage/DepositMarginRequest'
import {DepositMarginResponse} from './storage/DepositMarginResponse'
import {DepositMarginResponseModel} from './storage/DepositMarginResponse'
import {DepositMargin2Request} from './storage/DepositMargin2Request'
import {DepositMargin2RequestModel} from './storage/DepositMargin2Request'
import {DepositMargin2Response} from './storage/DepositMargin2Response'
import {DepositMargin2ResponseModel} from './storage/DepositMargin2Response'
import {StoreClientLoginSuccessRequest} from './storage/StoreClientLoginSuccessRequest'
import {StoreClientLoginSuccessRequestModel} from './storage/StoreClientLoginSuccessRequest'
import {StoreClientLoginSuccessResponse} from './storage/StoreClientLoginSuccessResponse'
import {StoreClientLoginSuccessResponseModel} from './storage/StoreClientLoginSuccessResponse'
import {ClientLoginInfoGetRequest} from './storage/ClientLoginInfoGetRequest'
import {ClientLoginInfoGetRequestModel} from './storage/ClientLoginInfoGetRequest'
import {ClientLoginInfoGetResponse} from './storage/ClientLoginInfoGetResponse'
import {ClientLoginInfoGetResponseModel} from './storage/ClientLoginInfoGetResponse'
import {ClientLoginInfoNotify} from './storage/ClientLoginInfoNotify'
import {ClientLoginInfoNotifyModel} from './storage/ClientLoginInfoNotify'
import {StoreClientLoginSuccess2Request} from './storage/StoreClientLoginSuccess2Request'
import {StoreClientLoginSuccess2RequestModel} from './storage/StoreClientLoginSuccess2Request'
import {StoreClientLoginSuccess2Response} from './storage/StoreClientLoginSuccess2Response'
import {StoreClientLoginSuccess2ResponseModel} from './storage/StoreClientLoginSuccess2Response'
import {OrderUpdateWithTypeRequest} from './storage/OrderUpdateWithTypeRequest'
import {OrderUpdateWithTypeRequestModel} from './storage/OrderUpdateWithTypeRequest'
import {OrderUpdateWithTypeResponse} from './storage/OrderUpdateWithTypeResponse'
import {OrderUpdateWithTypeResponseModel} from './storage/OrderUpdateWithTypeResponse'
import {OrderUpdateWithType2Request} from './storage/OrderUpdateWithType2Request'
import {OrderUpdateWithType2RequestModel} from './storage/OrderUpdateWithType2Request'
import {OrderUpdateWithType2Response} from './storage/OrderUpdateWithType2Response'
import {OrderUpdateWithType2ResponseModel} from './storage/OrderUpdateWithType2Response'
import {TradeHistoryTimeRangeGetRequest} from './storage/TradeHistoryTimeRangeGetRequest'
import {TradeHistoryTimeRangeGetRequestModel} from './storage/TradeHistoryTimeRangeGetRequest'
import {TradeHistoryTimeRangeGetResponse} from './storage/TradeHistoryTimeRangeGetResponse'
import {TradeHistoryTimeRangeGetResponseModel} from './storage/TradeHistoryTimeRangeGetResponse'
import {TradeEventsTimeRangeGetRequest} from './storage/TradeEventsTimeRangeGetRequest'
import {TradeEventsTimeRangeGetRequestModel} from './storage/TradeEventsTimeRangeGetRequest'
import {TradeEventsTimeRangeGetResponse} from './storage/TradeEventsTimeRangeGetResponse'
import {TradeEventsTimeRangeGetResponseModel} from './storage/TradeEventsTimeRangeGetResponse'
import {TradeHistoryByIdRequest} from './storage/TradeHistoryByIdRequest'
import {TradeHistoryByIdRequestModel} from './storage/TradeHistoryByIdRequest'
import {TradeHistoryByIdResponse} from './storage/TradeHistoryByIdResponse'
import {TradeHistoryByIdResponseModel} from './storage/TradeHistoryByIdResponse'
import {OrdersGetAllRequest} from './storage/OrdersGetAllRequest'
import {OrdersGetAllRequestModel} from './storage/OrdersGetAllRequest'
import {OrdersGetAllResponse} from './storage/OrdersGetAllResponse'
import {OrdersGetAllResponseModel} from './storage/OrdersGetAllResponse'
import {PositionsGetAllRequest} from './storage/PositionsGetAllRequest'
import {PositionsGetAllRequestModel} from './storage/PositionsGetAllRequest'
import {PositionsGetAllResponse} from './storage/PositionsGetAllResponse'
import {PositionsGetAllResponseModel} from './storage/PositionsGetAllResponse'
import {AssetsGetAllRequest} from './storage/AssetsGetAllRequest'
import {AssetsGetAllRequestModel} from './storage/AssetsGetAllRequest'
import {AssetsGetAllResponse} from './storage/AssetsGetAllResponse'
import {AssetsGetAllResponseModel} from './storage/AssetsGetAllResponse'
import {MarginsGetAllRequest} from './storage/MarginsGetAllRequest'
import {MarginsGetAllRequestModel} from './storage/MarginsGetAllRequest'
import {MarginsGetAllResponse} from './storage/MarginsGetAllResponse'
import {MarginsGetAllResponseModel} from './storage/MarginsGetAllResponse'
import {AccountsGetAllRequest} from './storage/AccountsGetAllRequest'
import {AccountsGetAllRequestModel} from './storage/AccountsGetAllRequest'
import {AccountsGetAllResponse} from './storage/AccountsGetAllResponse'
import {AccountsGetAllResponseModel} from './storage/AccountsGetAllResponse'
import {OrderInsertRequestParams} from './storage/OrderInsertRequestParams'
import {OrderInsertRequestParamsModel} from './storage/OrderInsertRequestParams'
import {OrderInsert2ResponseParams} from './storage/OrderInsert2ResponseParams'
import {OrderInsert2ResponseParamsModel} from './storage/OrderInsert2ResponseParams'
import {OrderUpdateRequestParams} from './storage/OrderUpdateRequestParams'
import {OrderUpdateRequestParamsModel} from './storage/OrderUpdateRequestParams'
import {OrderDeleteRequestParams} from './storage/OrderDeleteRequestParams'
import {OrderDeleteRequestParamsModel} from './storage/OrderDeleteRequestParams'
import {OrderUpdate2ResponseParams} from './storage/OrderUpdate2ResponseParams'
import {OrderUpdate2ResponseParamsModel} from './storage/OrderUpdate2ResponseParams'
import {MassRequestParams} from './storage/MassRequestParams'
import {MassRequestParamsModel} from './storage/MassRequestParams'
import {MassResponseParams} from './storage/MassResponseParams'
import {MassResponseParamsModel} from './storage/MassResponseParams'
import {MassRequest} from './storage/MassRequest'
import {MassRequestModel} from './storage/MassRequest'
import {MassResponse} from './storage/MassResponse'
import {MassResponseModel} from './storage/MassResponse'
import {Order2ResponseParams} from './storage/Order2ResponseParams'
import {Order2ResponseParamsModel} from './storage/Order2ResponseParams'
import {Mass2Request} from './storage/Mass2Request'
import {Mass2RequestModel} from './storage/Mass2Request'
import {SocialTradingMasterCommission2ResponseParams} from './storage/SocialTradingMasterCommission2ResponseParams'
import {SocialTradingMasterCommission2ResponseParamsModel} from './storage/SocialTradingMasterCommission2ResponseParams'
import {Mass2ResponseParams} from './storage/Mass2ResponseParams'
import {Mass2ResponseParamsModel} from './storage/Mass2ResponseParams'
import {Mass2Response} from './storage/Mass2Response'
import {Mass2ResponseModel} from './storage/Mass2Response'
import {MarginUpdateNotify} from './storage/MarginUpdateNotify'
import {MarginUpdateNotifyModel} from './storage/MarginUpdateNotify'
import {PersonsGetAllRequest} from './storage/PersonsGetAllRequest'
import {PersonsGetAllRequestModel} from './storage/PersonsGetAllRequest'
import {PersonsGetAllResponse} from './storage/PersonsGetAllResponse'
import {PersonsGetAllResponseModel} from './storage/PersonsGetAllResponse'
import {PersonsInsertParams} from './storage/PersonsInsertParams'
import {PersonsInsertParamsModel} from './storage/PersonsInsertParams'
import {PersonsInsertRequest} from './storage/PersonsInsertRequest'
import {PersonsInsertRequestModel} from './storage/PersonsInsertRequest'
import {PersonsInsertResponse} from './storage/PersonsInsertResponse'
import {PersonsInsertResponseModel} from './storage/PersonsInsertResponse'
import {PersonUpdateRequest} from './storage/PersonUpdateRequest'
import {PersonUpdateRequestModel} from './storage/PersonUpdateRequest'
import {PersonUpdateResponse} from './storage/PersonUpdateResponse'
import {PersonUpdateResponseModel} from './storage/PersonUpdateResponse'
import {PersonsInsert2Request} from './storage/PersonsInsert2Request'
import {PersonsInsert2RequestModel} from './storage/PersonsInsert2Request'
import {PersonsInsert2Response} from './storage/PersonsInsert2Response'
import {PersonsInsert2ResponseModel} from './storage/PersonsInsert2Response'
import {PersonUpdate2Request} from './storage/PersonUpdate2Request'
import {PersonUpdate2RequestModel} from './storage/PersonUpdate2Request'
import {PersonUpdate2Response} from './storage/PersonUpdate2Response'
import {PersonUpdate2ResponseModel} from './storage/PersonUpdate2Response'
import {LastIdsGetRequest} from './storage/LastIdsGetRequest'
import {LastIdsGetRequestModel} from './storage/LastIdsGetRequest'
import {LastIdsGetResponse} from './storage/LastIdsGetResponse'
import {LastIdsGetResponseModel} from './storage/LastIdsGetResponse'
import {DailySnapshotRequest} from './storage/DailySnapshotRequest'
import {DailySnapshotRequestModel} from './storage/DailySnapshotRequest'
import {DailySnapshotResponse} from './storage/DailySnapshotResponse'
import {DailySnapshotResponseModel} from './storage/DailySnapshotResponse'
import {BillingStatisticsGetRequest} from './storage/BillingStatisticsGetRequest'
import {BillingStatisticsGetRequestModel} from './storage/BillingStatisticsGetRequest'
import {BillingStatisticsGetResponse} from './storage/BillingStatisticsGetResponse'
import {BillingStatisticsGetResponseModel} from './storage/BillingStatisticsGetResponse'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding storage sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize storage sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._innerSender = new inner.Sender(this.buffer)
    this._StorageVersionRequestModel = new StorageVersionRequestModel(this.buffer)
    this._StorageVersionResponseModel = new StorageVersionResponseModel(this.buffer)
    this._StorageUpdateNotifyModel = new StorageUpdateNotifyModel(this.buffer)
    this._AccountInsertRequestModel = new AccountInsertRequestModel(this.buffer)
    this._AccountInsertResponseModel = new AccountInsertResponseModel(this.buffer)
    this._AccountSocialTradingMasterInsertRequestModel = new AccountSocialTradingMasterInsertRequestModel(this.buffer)
    this._AccountSocialTradingMasterInsertResponseModel = new AccountSocialTradingMasterInsertResponseModel(this.buffer)
    this._AccountSocialTradingInvestorInsertRequestModel = new AccountSocialTradingInvestorInsertRequestModel(this.buffer)
    this._AccountSocialTradingInvestorInsertResponseModel = new AccountSocialTradingInvestorInsertResponseModel(this.buffer)
    this._AccountUpdateRequestModel = new AccountUpdateRequestModel(this.buffer)
    this._AccountUpdateResponseModel = new AccountUpdateResponseModel(this.buffer)
    this._AccountDeleteRequestModel = new AccountDeleteRequestModel(this.buffer)
    this._AccountDeleteResponseModel = new AccountDeleteResponseModel(this.buffer)
    this._MarginUpdateRequestModel = new MarginUpdateRequestModel(this.buffer)
    this._MarginUpdateResponseModel = new MarginUpdateResponseModel(this.buffer)
    this._AssetInsertRequestModel = new AssetInsertRequestModel(this.buffer)
    this._AssetInsertResponseModel = new AssetInsertResponseModel(this.buffer)
    this._AssetUpdateRequestModel = new AssetUpdateRequestModel(this.buffer)
    this._AssetUpdateResponseModel = new AssetUpdateResponseModel(this.buffer)
    this._AssetDeleteRequestModel = new AssetDeleteRequestModel(this.buffer)
    this._AssetDeleteResponseModel = new AssetDeleteResponseModel(this.buffer)
    this._OrderInsertRequestModel = new OrderInsertRequestModel(this.buffer)
    this._OrderInsertResponseModel = new OrderInsertResponseModel(this.buffer)
    this._OrderUpdateRequestModel = new OrderUpdateRequestModel(this.buffer)
    this._OrderUpdateResponseModel = new OrderUpdateResponseModel(this.buffer)
    this._OrderDeleteRequestModel = new OrderDeleteRequestModel(this.buffer)
    this._OrderDeleteResponseModel = new OrderDeleteResponseModel(this.buffer)
    this._OrderDeleteAllRequestModel = new OrderDeleteAllRequestModel(this.buffer)
    this._OrderDeleteAllResponseModel = new OrderDeleteAllResponseModel(this.buffer)
    this._PositionInsertRequestModel = new PositionInsertRequestModel(this.buffer)
    this._PositionInsertResponseModel = new PositionInsertResponseModel(this.buffer)
    this._PositionUpdateRequestModel = new PositionUpdateRequestModel(this.buffer)
    this._PositionUpdateResponseModel = new PositionUpdateResponseModel(this.buffer)
    this._PositionDeleteRequestModel = new PositionDeleteRequestModel(this.buffer)
    this._PositionDeleteResponseModel = new PositionDeleteResponseModel(this.buffer)
    this._TradeHistoryRequestModel = new TradeHistoryRequestModel(this.buffer)
    this._TradeHistoryResponseModel = new TradeHistoryResponseModel(this.buffer)
    this._TradeEventsGetRequestModel = new TradeEventsGetRequestModel(this.buffer)
    this._TradeEventsGetResponseModel = new TradeEventsGetResponseModel(this.buffer)
    this._TradeEventsNotifyModel = new TradeEventsNotifyModel(this.buffer)
    this._TradeEventsInsertRequestModel = new TradeEventsInsertRequestModel(this.buffer)
    this._TradeEventsInsertResponseModel = new TradeEventsInsertResponseModel(this.buffer)
    this._ProfitClosedGetRequestModel = new ProfitClosedGetRequestModel(this.buffer)
    this._ProfitClosedGetResponseModel = new ProfitClosedGetResponseModel(this.buffer)
    this._OrdersClosedSummariesGetRequestModel = new OrdersClosedSummariesGetRequestModel(this.buffer)
    this._OrdersClosedSummariesGetResponseModel = new OrdersClosedSummariesGetResponseModel(this.buffer)
    this._OrderInsert2RequestModel = new OrderInsert2RequestModel(this.buffer)
    this._OrderInsert2ResponseModel = new OrderInsert2ResponseModel(this.buffer)
    this._OrderUpdate2RequestModel = new OrderUpdate2RequestModel(this.buffer)
    this._OrderUpdate2ResponseModel = new OrderUpdate2ResponseModel(this.buffer)
    this._OrderDelete2RequestModel = new OrderDelete2RequestModel(this.buffer)
    this._OrderDelete2ResponseModel = new OrderDelete2ResponseModel(this.buffer)
    this._AccountInsert2RequestModel = new AccountInsert2RequestModel(this.buffer)
    this._AccountInsert2ResponseModel = new AccountInsert2ResponseModel(this.buffer)
    this._AccountSocialTradingMasterInsert2RequestModel = new AccountSocialTradingMasterInsert2RequestModel(this.buffer)
    this._AccountSocialTradingMasterInsert2ResponseModel = new AccountSocialTradingMasterInsert2ResponseModel(this.buffer)
    this._AccountSocialTradingInvestorInsert2RequestModel = new AccountSocialTradingInvestorInsert2RequestModel(this.buffer)
    this._AccountSocialTradingInvestorInsert2ResponseModel = new AccountSocialTradingInvestorInsert2ResponseModel(this.buffer)
    this._AccountDelete2RequestModel = new AccountDelete2RequestModel(this.buffer)
    this._AccountDelete2ResponseModel = new AccountDelete2ResponseModel(this.buffer)
    this._AssetInsert2RequestModel = new AssetInsert2RequestModel(this.buffer)
    this._AssetInsert2ResponseModel = new AssetInsert2ResponseModel(this.buffer)
    this._AssetUpdate2RequestModel = new AssetUpdate2RequestModel(this.buffer)
    this._AssetUpdate2ResponseModel = new AssetUpdate2ResponseModel(this.buffer)
    this._AssetDelete2RequestModel = new AssetDelete2RequestModel(this.buffer)
    this._AssetDelete2ResponseModel = new AssetDelete2ResponseModel(this.buffer)
    this._PositionInsert2RequestModel = new PositionInsert2RequestModel(this.buffer)
    this._PositionInsert2ResponseModel = new PositionInsert2ResponseModel(this.buffer)
    this._PositionUpdate2RequestModel = new PositionUpdate2RequestModel(this.buffer)
    this._PositionUpdate2ResponseModel = new PositionUpdate2ResponseModel(this.buffer)
    this._PositionDelete2RequestModel = new PositionDelete2RequestModel(this.buffer)
    this._PositionDelete2ResponseModel = new PositionDelete2ResponseModel(this.buffer)
    this._Deprecated001Model = new Deprecated001Model(this.buffer)
    this._Deprecated002Model = new Deprecated002Model(this.buffer)
    this._Deprecated003Model = new Deprecated003Model(this.buffer)
    this._Deprecated004Model = new Deprecated004Model(this.buffer)
    this._Deprecated005Model = new Deprecated005Model(this.buffer)
    this._Deprecated006Model = new Deprecated006Model(this.buffer)
    this._TradeEventsInsert2RequestModel = new TradeEventsInsert2RequestModel(this.buffer)
    this._TradeEventsInsert2ResponseModel = new TradeEventsInsert2ResponseModel(this.buffer)
    this._MarginUpdate2RequestModel = new MarginUpdate2RequestModel(this.buffer)
    this._MarginUpdate2ResponseModel = new MarginUpdate2ResponseModel(this.buffer)
    this._AccountUpdate2RequestModel = new AccountUpdate2RequestModel(this.buffer)
    this._AccountUpdate2ResponseModel = new AccountUpdate2ResponseModel(this.buffer)
    this._DepositMarginRequestModel = new DepositMarginRequestModel(this.buffer)
    this._DepositMarginResponseModel = new DepositMarginResponseModel(this.buffer)
    this._DepositMargin2RequestModel = new DepositMargin2RequestModel(this.buffer)
    this._DepositMargin2ResponseModel = new DepositMargin2ResponseModel(this.buffer)
    this._StoreClientLoginSuccessRequestModel = new StoreClientLoginSuccessRequestModel(this.buffer)
    this._StoreClientLoginSuccessResponseModel = new StoreClientLoginSuccessResponseModel(this.buffer)
    this._ClientLoginInfoGetRequestModel = new ClientLoginInfoGetRequestModel(this.buffer)
    this._ClientLoginInfoGetResponseModel = new ClientLoginInfoGetResponseModel(this.buffer)
    this._ClientLoginInfoNotifyModel = new ClientLoginInfoNotifyModel(this.buffer)
    this._StoreClientLoginSuccess2RequestModel = new StoreClientLoginSuccess2RequestModel(this.buffer)
    this._StoreClientLoginSuccess2ResponseModel = new StoreClientLoginSuccess2ResponseModel(this.buffer)
    this._OrderUpdateWithTypeRequestModel = new OrderUpdateWithTypeRequestModel(this.buffer)
    this._OrderUpdateWithTypeResponseModel = new OrderUpdateWithTypeResponseModel(this.buffer)
    this._OrderUpdateWithType2RequestModel = new OrderUpdateWithType2RequestModel(this.buffer)
    this._OrderUpdateWithType2ResponseModel = new OrderUpdateWithType2ResponseModel(this.buffer)
    this._TradeHistoryTimeRangeGetRequestModel = new TradeHistoryTimeRangeGetRequestModel(this.buffer)
    this._TradeHistoryTimeRangeGetResponseModel = new TradeHistoryTimeRangeGetResponseModel(this.buffer)
    this._TradeEventsTimeRangeGetRequestModel = new TradeEventsTimeRangeGetRequestModel(this.buffer)
    this._TradeEventsTimeRangeGetResponseModel = new TradeEventsTimeRangeGetResponseModel(this.buffer)
    this._TradeHistoryByIdRequestModel = new TradeHistoryByIdRequestModel(this.buffer)
    this._TradeHistoryByIdResponseModel = new TradeHistoryByIdResponseModel(this.buffer)
    this._OrdersGetAllRequestModel = new OrdersGetAllRequestModel(this.buffer)
    this._OrdersGetAllResponseModel = new OrdersGetAllResponseModel(this.buffer)
    this._PositionsGetAllRequestModel = new PositionsGetAllRequestModel(this.buffer)
    this._PositionsGetAllResponseModel = new PositionsGetAllResponseModel(this.buffer)
    this._AssetsGetAllRequestModel = new AssetsGetAllRequestModel(this.buffer)
    this._AssetsGetAllResponseModel = new AssetsGetAllResponseModel(this.buffer)
    this._MarginsGetAllRequestModel = new MarginsGetAllRequestModel(this.buffer)
    this._MarginsGetAllResponseModel = new MarginsGetAllResponseModel(this.buffer)
    this._AccountsGetAllRequestModel = new AccountsGetAllRequestModel(this.buffer)
    this._AccountsGetAllResponseModel = new AccountsGetAllResponseModel(this.buffer)
    this._MassRequestModel = new MassRequestModel(this.buffer)
    this._MassResponseModel = new MassResponseModel(this.buffer)
    this._Mass2RequestModel = new Mass2RequestModel(this.buffer)
    this._Mass2ResponseParamsModel = new Mass2ResponseParamsModel(this.buffer)
    this._Mass2ResponseModel = new Mass2ResponseModel(this.buffer)
    this._MarginUpdateNotifyModel = new MarginUpdateNotifyModel(this.buffer)
    this._PersonsGetAllRequestModel = new PersonsGetAllRequestModel(this.buffer)
    this._PersonsGetAllResponseModel = new PersonsGetAllResponseModel(this.buffer)
    this._PersonsInsertRequestModel = new PersonsInsertRequestModel(this.buffer)
    this._PersonsInsertResponseModel = new PersonsInsertResponseModel(this.buffer)
    this._PersonUpdateRequestModel = new PersonUpdateRequestModel(this.buffer)
    this._PersonUpdateResponseModel = new PersonUpdateResponseModel(this.buffer)
    this._PersonsInsert2RequestModel = new PersonsInsert2RequestModel(this.buffer)
    this._PersonsInsert2ResponseModel = new PersonsInsert2ResponseModel(this.buffer)
    this._PersonUpdate2RequestModel = new PersonUpdate2RequestModel(this.buffer)
    this._PersonUpdate2ResponseModel = new PersonUpdate2ResponseModel(this.buffer)
    this._LastIdsGetRequestModel = new LastIdsGetRequestModel(this.buffer)
    this._LastIdsGetResponseModel = new LastIdsGetResponseModel(this.buffer)
    this._DailySnapshotRequestModel = new DailySnapshotRequestModel(this.buffer)
    this._DailySnapshotResponseModel = new DailySnapshotResponseModel(this.buffer)
    this._BillingStatisticsGetRequestModel = new BillingStatisticsGetRequestModel(this.buffer)
    this._BillingStatisticsGetResponseModel = new BillingStatisticsGetResponseModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  /**
   * Get imported inner sender
   * @this {!Sender}
   * @returns {!inner.Sender} inner sender
   */
  get innerSender () {
    return this._innerSender
  }

  // Sender models accessors

  /**
   * Get StorageVersionRequest model
   * @this {!Sender}
   * @returns {!StorageVersionRequestModel} StorageVersionRequest model
   */
  get StorageVersionRequestModel () {
    return this._StorageVersionRequestModel
  }

  /**
   * Get StorageVersionResponse model
   * @this {!Sender}
   * @returns {!StorageVersionResponseModel} StorageVersionResponse model
   */
  get StorageVersionResponseModel () {
    return this._StorageVersionResponseModel
  }

  /**
   * Get StorageUpdateNotify model
   * @this {!Sender}
   * @returns {!StorageUpdateNotifyModel} StorageUpdateNotify model
   */
  get StorageUpdateNotifyModel () {
    return this._StorageUpdateNotifyModel
  }

  /**
   * Get AccountInsertRequest model
   * @this {!Sender}
   * @returns {!AccountInsertRequestModel} AccountInsertRequest model
   */
  get AccountInsertRequestModel () {
    return this._AccountInsertRequestModel
  }

  /**
   * Get AccountInsertResponse model
   * @this {!Sender}
   * @returns {!AccountInsertResponseModel} AccountInsertResponse model
   */
  get AccountInsertResponseModel () {
    return this._AccountInsertResponseModel
  }

  /**
   * Get AccountSocialTradingMasterInsertRequest model
   * @this {!Sender}
   * @returns {!AccountSocialTradingMasterInsertRequestModel} AccountSocialTradingMasterInsertRequest model
   */
  get AccountSocialTradingMasterInsertRequestModel () {
    return this._AccountSocialTradingMasterInsertRequestModel
  }

  /**
   * Get AccountSocialTradingMasterInsertResponse model
   * @this {!Sender}
   * @returns {!AccountSocialTradingMasterInsertResponseModel} AccountSocialTradingMasterInsertResponse model
   */
  get AccountSocialTradingMasterInsertResponseModel () {
    return this._AccountSocialTradingMasterInsertResponseModel
  }

  /**
   * Get AccountSocialTradingInvestorInsertRequest model
   * @this {!Sender}
   * @returns {!AccountSocialTradingInvestorInsertRequestModel} AccountSocialTradingInvestorInsertRequest model
   */
  get AccountSocialTradingInvestorInsertRequestModel () {
    return this._AccountSocialTradingInvestorInsertRequestModel
  }

  /**
   * Get AccountSocialTradingInvestorInsertResponse model
   * @this {!Sender}
   * @returns {!AccountSocialTradingInvestorInsertResponseModel} AccountSocialTradingInvestorInsertResponse model
   */
  get AccountSocialTradingInvestorInsertResponseModel () {
    return this._AccountSocialTradingInvestorInsertResponseModel
  }

  /**
   * Get AccountUpdateRequest model
   * @this {!Sender}
   * @returns {!AccountUpdateRequestModel} AccountUpdateRequest model
   */
  get AccountUpdateRequestModel () {
    return this._AccountUpdateRequestModel
  }

  /**
   * Get AccountUpdateResponse model
   * @this {!Sender}
   * @returns {!AccountUpdateResponseModel} AccountUpdateResponse model
   */
  get AccountUpdateResponseModel () {
    return this._AccountUpdateResponseModel
  }

  /**
   * Get AccountDeleteRequest model
   * @this {!Sender}
   * @returns {!AccountDeleteRequestModel} AccountDeleteRequest model
   */
  get AccountDeleteRequestModel () {
    return this._AccountDeleteRequestModel
  }

  /**
   * Get AccountDeleteResponse model
   * @this {!Sender}
   * @returns {!AccountDeleteResponseModel} AccountDeleteResponse model
   */
  get AccountDeleteResponseModel () {
    return this._AccountDeleteResponseModel
  }

  /**
   * Get MarginUpdateRequest model
   * @this {!Sender}
   * @returns {!MarginUpdateRequestModel} MarginUpdateRequest model
   */
  get MarginUpdateRequestModel () {
    return this._MarginUpdateRequestModel
  }

  /**
   * Get MarginUpdateResponse model
   * @this {!Sender}
   * @returns {!MarginUpdateResponseModel} MarginUpdateResponse model
   */
  get MarginUpdateResponseModel () {
    return this._MarginUpdateResponseModel
  }

  /**
   * Get AssetInsertRequest model
   * @this {!Sender}
   * @returns {!AssetInsertRequestModel} AssetInsertRequest model
   */
  get AssetInsertRequestModel () {
    return this._AssetInsertRequestModel
  }

  /**
   * Get AssetInsertResponse model
   * @this {!Sender}
   * @returns {!AssetInsertResponseModel} AssetInsertResponse model
   */
  get AssetInsertResponseModel () {
    return this._AssetInsertResponseModel
  }

  /**
   * Get AssetUpdateRequest model
   * @this {!Sender}
   * @returns {!AssetUpdateRequestModel} AssetUpdateRequest model
   */
  get AssetUpdateRequestModel () {
    return this._AssetUpdateRequestModel
  }

  /**
   * Get AssetUpdateResponse model
   * @this {!Sender}
   * @returns {!AssetUpdateResponseModel} AssetUpdateResponse model
   */
  get AssetUpdateResponseModel () {
    return this._AssetUpdateResponseModel
  }

  /**
   * Get AssetDeleteRequest model
   * @this {!Sender}
   * @returns {!AssetDeleteRequestModel} AssetDeleteRequest model
   */
  get AssetDeleteRequestModel () {
    return this._AssetDeleteRequestModel
  }

  /**
   * Get AssetDeleteResponse model
   * @this {!Sender}
   * @returns {!AssetDeleteResponseModel} AssetDeleteResponse model
   */
  get AssetDeleteResponseModel () {
    return this._AssetDeleteResponseModel
  }

  /**
   * Get OrderInsertRequest model
   * @this {!Sender}
   * @returns {!OrderInsertRequestModel} OrderInsertRequest model
   */
  get OrderInsertRequestModel () {
    return this._OrderInsertRequestModel
  }

  /**
   * Get OrderInsertResponse model
   * @this {!Sender}
   * @returns {!OrderInsertResponseModel} OrderInsertResponse model
   */
  get OrderInsertResponseModel () {
    return this._OrderInsertResponseModel
  }

  /**
   * Get OrderUpdateRequest model
   * @this {!Sender}
   * @returns {!OrderUpdateRequestModel} OrderUpdateRequest model
   */
  get OrderUpdateRequestModel () {
    return this._OrderUpdateRequestModel
  }

  /**
   * Get OrderUpdateResponse model
   * @this {!Sender}
   * @returns {!OrderUpdateResponseModel} OrderUpdateResponse model
   */
  get OrderUpdateResponseModel () {
    return this._OrderUpdateResponseModel
  }

  /**
   * Get OrderDeleteRequest model
   * @this {!Sender}
   * @returns {!OrderDeleteRequestModel} OrderDeleteRequest model
   */
  get OrderDeleteRequestModel () {
    return this._OrderDeleteRequestModel
  }

  /**
   * Get OrderDeleteResponse model
   * @this {!Sender}
   * @returns {!OrderDeleteResponseModel} OrderDeleteResponse model
   */
  get OrderDeleteResponseModel () {
    return this._OrderDeleteResponseModel
  }

  /**
   * Get OrderDeleteAllRequest model
   * @this {!Sender}
   * @returns {!OrderDeleteAllRequestModel} OrderDeleteAllRequest model
   */
  get OrderDeleteAllRequestModel () {
    return this._OrderDeleteAllRequestModel
  }

  /**
   * Get OrderDeleteAllResponse model
   * @this {!Sender}
   * @returns {!OrderDeleteAllResponseModel} OrderDeleteAllResponse model
   */
  get OrderDeleteAllResponseModel () {
    return this._OrderDeleteAllResponseModel
  }

  /**
   * Get PositionInsertRequest model
   * @this {!Sender}
   * @returns {!PositionInsertRequestModel} PositionInsertRequest model
   */
  get PositionInsertRequestModel () {
    return this._PositionInsertRequestModel
  }

  /**
   * Get PositionInsertResponse model
   * @this {!Sender}
   * @returns {!PositionInsertResponseModel} PositionInsertResponse model
   */
  get PositionInsertResponseModel () {
    return this._PositionInsertResponseModel
  }

  /**
   * Get PositionUpdateRequest model
   * @this {!Sender}
   * @returns {!PositionUpdateRequestModel} PositionUpdateRequest model
   */
  get PositionUpdateRequestModel () {
    return this._PositionUpdateRequestModel
  }

  /**
   * Get PositionUpdateResponse model
   * @this {!Sender}
   * @returns {!PositionUpdateResponseModel} PositionUpdateResponse model
   */
  get PositionUpdateResponseModel () {
    return this._PositionUpdateResponseModel
  }

  /**
   * Get PositionDeleteRequest model
   * @this {!Sender}
   * @returns {!PositionDeleteRequestModel} PositionDeleteRequest model
   */
  get PositionDeleteRequestModel () {
    return this._PositionDeleteRequestModel
  }

  /**
   * Get PositionDeleteResponse model
   * @this {!Sender}
   * @returns {!PositionDeleteResponseModel} PositionDeleteResponse model
   */
  get PositionDeleteResponseModel () {
    return this._PositionDeleteResponseModel
  }

  /**
   * Get TradeHistoryRequest model
   * @this {!Sender}
   * @returns {!TradeHistoryRequestModel} TradeHistoryRequest model
   */
  get TradeHistoryRequestModel () {
    return this._TradeHistoryRequestModel
  }

  /**
   * Get TradeHistoryResponse model
   * @this {!Sender}
   * @returns {!TradeHistoryResponseModel} TradeHistoryResponse model
   */
  get TradeHistoryResponseModel () {
    return this._TradeHistoryResponseModel
  }

  /**
   * Get TradeEventsGetRequest model
   * @this {!Sender}
   * @returns {!TradeEventsGetRequestModel} TradeEventsGetRequest model
   */
  get TradeEventsGetRequestModel () {
    return this._TradeEventsGetRequestModel
  }

  /**
   * Get TradeEventsGetResponse model
   * @this {!Sender}
   * @returns {!TradeEventsGetResponseModel} TradeEventsGetResponse model
   */
  get TradeEventsGetResponseModel () {
    return this._TradeEventsGetResponseModel
  }

  /**
   * Get TradeEventsNotify model
   * @this {!Sender}
   * @returns {!TradeEventsNotifyModel} TradeEventsNotify model
   */
  get TradeEventsNotifyModel () {
    return this._TradeEventsNotifyModel
  }

  /**
   * Get TradeEventsInsertRequest model
   * @this {!Sender}
   * @returns {!TradeEventsInsertRequestModel} TradeEventsInsertRequest model
   */
  get TradeEventsInsertRequestModel () {
    return this._TradeEventsInsertRequestModel
  }

  /**
   * Get TradeEventsInsertResponse model
   * @this {!Sender}
   * @returns {!TradeEventsInsertResponseModel} TradeEventsInsertResponse model
   */
  get TradeEventsInsertResponseModel () {
    return this._TradeEventsInsertResponseModel
  }

  /**
   * Get ProfitClosedGetRequest model
   * @this {!Sender}
   * @returns {!ProfitClosedGetRequestModel} ProfitClosedGetRequest model
   */
  get ProfitClosedGetRequestModel () {
    return this._ProfitClosedGetRequestModel
  }

  /**
   * Get ProfitClosedGetResponse model
   * @this {!Sender}
   * @returns {!ProfitClosedGetResponseModel} ProfitClosedGetResponse model
   */
  get ProfitClosedGetResponseModel () {
    return this._ProfitClosedGetResponseModel
  }

  /**
   * Get OrdersClosedSummariesGetRequest model
   * @this {!Sender}
   * @returns {!OrdersClosedSummariesGetRequestModel} OrdersClosedSummariesGetRequest model
   */
  get OrdersClosedSummariesGetRequestModel () {
    return this._OrdersClosedSummariesGetRequestModel
  }

  /**
   * Get OrdersClosedSummariesGetResponse model
   * @this {!Sender}
   * @returns {!OrdersClosedSummariesGetResponseModel} OrdersClosedSummariesGetResponse model
   */
  get OrdersClosedSummariesGetResponseModel () {
    return this._OrdersClosedSummariesGetResponseModel
  }

  /**
   * Get OrderInsert2Request model
   * @this {!Sender}
   * @returns {!OrderInsert2RequestModel} OrderInsert2Request model
   */
  get OrderInsert2RequestModel () {
    return this._OrderInsert2RequestModel
  }

  /**
   * Get OrderInsert2Response model
   * @this {!Sender}
   * @returns {!OrderInsert2ResponseModel} OrderInsert2Response model
   */
  get OrderInsert2ResponseModel () {
    return this._OrderInsert2ResponseModel
  }

  /**
   * Get OrderUpdate2Request model
   * @this {!Sender}
   * @returns {!OrderUpdate2RequestModel} OrderUpdate2Request model
   */
  get OrderUpdate2RequestModel () {
    return this._OrderUpdate2RequestModel
  }

  /**
   * Get OrderUpdate2Response model
   * @this {!Sender}
   * @returns {!OrderUpdate2ResponseModel} OrderUpdate2Response model
   */
  get OrderUpdate2ResponseModel () {
    return this._OrderUpdate2ResponseModel
  }

  /**
   * Get OrderDelete2Request model
   * @this {!Sender}
   * @returns {!OrderDelete2RequestModel} OrderDelete2Request model
   */
  get OrderDelete2RequestModel () {
    return this._OrderDelete2RequestModel
  }

  /**
   * Get OrderDelete2Response model
   * @this {!Sender}
   * @returns {!OrderDelete2ResponseModel} OrderDelete2Response model
   */
  get OrderDelete2ResponseModel () {
    return this._OrderDelete2ResponseModel
  }

  /**
   * Get AccountInsert2Request model
   * @this {!Sender}
   * @returns {!AccountInsert2RequestModel} AccountInsert2Request model
   */
  get AccountInsert2RequestModel () {
    return this._AccountInsert2RequestModel
  }

  /**
   * Get AccountInsert2Response model
   * @this {!Sender}
   * @returns {!AccountInsert2ResponseModel} AccountInsert2Response model
   */
  get AccountInsert2ResponseModel () {
    return this._AccountInsert2ResponseModel
  }

  /**
   * Get AccountSocialTradingMasterInsert2Request model
   * @this {!Sender}
   * @returns {!AccountSocialTradingMasterInsert2RequestModel} AccountSocialTradingMasterInsert2Request model
   */
  get AccountSocialTradingMasterInsert2RequestModel () {
    return this._AccountSocialTradingMasterInsert2RequestModel
  }

  /**
   * Get AccountSocialTradingMasterInsert2Response model
   * @this {!Sender}
   * @returns {!AccountSocialTradingMasterInsert2ResponseModel} AccountSocialTradingMasterInsert2Response model
   */
  get AccountSocialTradingMasterInsert2ResponseModel () {
    return this._AccountSocialTradingMasterInsert2ResponseModel
  }

  /**
   * Get AccountSocialTradingInvestorInsert2Request model
   * @this {!Sender}
   * @returns {!AccountSocialTradingInvestorInsert2RequestModel} AccountSocialTradingInvestorInsert2Request model
   */
  get AccountSocialTradingInvestorInsert2RequestModel () {
    return this._AccountSocialTradingInvestorInsert2RequestModel
  }

  /**
   * Get AccountSocialTradingInvestorInsert2Response model
   * @this {!Sender}
   * @returns {!AccountSocialTradingInvestorInsert2ResponseModel} AccountSocialTradingInvestorInsert2Response model
   */
  get AccountSocialTradingInvestorInsert2ResponseModel () {
    return this._AccountSocialTradingInvestorInsert2ResponseModel
  }

  /**
   * Get AccountDelete2Request model
   * @this {!Sender}
   * @returns {!AccountDelete2RequestModel} AccountDelete2Request model
   */
  get AccountDelete2RequestModel () {
    return this._AccountDelete2RequestModel
  }

  /**
   * Get AccountDelete2Response model
   * @this {!Sender}
   * @returns {!AccountDelete2ResponseModel} AccountDelete2Response model
   */
  get AccountDelete2ResponseModel () {
    return this._AccountDelete2ResponseModel
  }

  /**
   * Get AssetInsert2Request model
   * @this {!Sender}
   * @returns {!AssetInsert2RequestModel} AssetInsert2Request model
   */
  get AssetInsert2RequestModel () {
    return this._AssetInsert2RequestModel
  }

  /**
   * Get AssetInsert2Response model
   * @this {!Sender}
   * @returns {!AssetInsert2ResponseModel} AssetInsert2Response model
   */
  get AssetInsert2ResponseModel () {
    return this._AssetInsert2ResponseModel
  }

  /**
   * Get AssetUpdate2Request model
   * @this {!Sender}
   * @returns {!AssetUpdate2RequestModel} AssetUpdate2Request model
   */
  get AssetUpdate2RequestModel () {
    return this._AssetUpdate2RequestModel
  }

  /**
   * Get AssetUpdate2Response model
   * @this {!Sender}
   * @returns {!AssetUpdate2ResponseModel} AssetUpdate2Response model
   */
  get AssetUpdate2ResponseModel () {
    return this._AssetUpdate2ResponseModel
  }

  /**
   * Get AssetDelete2Request model
   * @this {!Sender}
   * @returns {!AssetDelete2RequestModel} AssetDelete2Request model
   */
  get AssetDelete2RequestModel () {
    return this._AssetDelete2RequestModel
  }

  /**
   * Get AssetDelete2Response model
   * @this {!Sender}
   * @returns {!AssetDelete2ResponseModel} AssetDelete2Response model
   */
  get AssetDelete2ResponseModel () {
    return this._AssetDelete2ResponseModel
  }

  /**
   * Get PositionInsert2Request model
   * @this {!Sender}
   * @returns {!PositionInsert2RequestModel} PositionInsert2Request model
   */
  get PositionInsert2RequestModel () {
    return this._PositionInsert2RequestModel
  }

  /**
   * Get PositionInsert2Response model
   * @this {!Sender}
   * @returns {!PositionInsert2ResponseModel} PositionInsert2Response model
   */
  get PositionInsert2ResponseModel () {
    return this._PositionInsert2ResponseModel
  }

  /**
   * Get PositionUpdate2Request model
   * @this {!Sender}
   * @returns {!PositionUpdate2RequestModel} PositionUpdate2Request model
   */
  get PositionUpdate2RequestModel () {
    return this._PositionUpdate2RequestModel
  }

  /**
   * Get PositionUpdate2Response model
   * @this {!Sender}
   * @returns {!PositionUpdate2ResponseModel} PositionUpdate2Response model
   */
  get PositionUpdate2ResponseModel () {
    return this._PositionUpdate2ResponseModel
  }

  /**
   * Get PositionDelete2Request model
   * @this {!Sender}
   * @returns {!PositionDelete2RequestModel} PositionDelete2Request model
   */
  get PositionDelete2RequestModel () {
    return this._PositionDelete2RequestModel
  }

  /**
   * Get PositionDelete2Response model
   * @this {!Sender}
   * @returns {!PositionDelete2ResponseModel} PositionDelete2Response model
   */
  get PositionDelete2ResponseModel () {
    return this._PositionDelete2ResponseModel
  }

  /**
   * Get Deprecated001 model
   * @this {!Sender}
   * @returns {!Deprecated001Model} Deprecated001 model
   */
  get Deprecated001Model () {
    return this._Deprecated001Model
  }

  /**
   * Get Deprecated002 model
   * @this {!Sender}
   * @returns {!Deprecated002Model} Deprecated002 model
   */
  get Deprecated002Model () {
    return this._Deprecated002Model
  }

  /**
   * Get Deprecated003 model
   * @this {!Sender}
   * @returns {!Deprecated003Model} Deprecated003 model
   */
  get Deprecated003Model () {
    return this._Deprecated003Model
  }

  /**
   * Get Deprecated004 model
   * @this {!Sender}
   * @returns {!Deprecated004Model} Deprecated004 model
   */
  get Deprecated004Model () {
    return this._Deprecated004Model
  }

  /**
   * Get Deprecated005 model
   * @this {!Sender}
   * @returns {!Deprecated005Model} Deprecated005 model
   */
  get Deprecated005Model () {
    return this._Deprecated005Model
  }

  /**
   * Get Deprecated006 model
   * @this {!Sender}
   * @returns {!Deprecated006Model} Deprecated006 model
   */
  get Deprecated006Model () {
    return this._Deprecated006Model
  }

  /**
   * Get TradeEventsInsert2Request model
   * @this {!Sender}
   * @returns {!TradeEventsInsert2RequestModel} TradeEventsInsert2Request model
   */
  get TradeEventsInsert2RequestModel () {
    return this._TradeEventsInsert2RequestModel
  }

  /**
   * Get TradeEventsInsert2Response model
   * @this {!Sender}
   * @returns {!TradeEventsInsert2ResponseModel} TradeEventsInsert2Response model
   */
  get TradeEventsInsert2ResponseModel () {
    return this._TradeEventsInsert2ResponseModel
  }

  /**
   * Get MarginUpdate2Request model
   * @this {!Sender}
   * @returns {!MarginUpdate2RequestModel} MarginUpdate2Request model
   */
  get MarginUpdate2RequestModel () {
    return this._MarginUpdate2RequestModel
  }

  /**
   * Get MarginUpdate2Response model
   * @this {!Sender}
   * @returns {!MarginUpdate2ResponseModel} MarginUpdate2Response model
   */
  get MarginUpdate2ResponseModel () {
    return this._MarginUpdate2ResponseModel
  }

  /**
   * Get AccountUpdate2Request model
   * @this {!Sender}
   * @returns {!AccountUpdate2RequestModel} AccountUpdate2Request model
   */
  get AccountUpdate2RequestModel () {
    return this._AccountUpdate2RequestModel
  }

  /**
   * Get AccountUpdate2Response model
   * @this {!Sender}
   * @returns {!AccountUpdate2ResponseModel} AccountUpdate2Response model
   */
  get AccountUpdate2ResponseModel () {
    return this._AccountUpdate2ResponseModel
  }

  /**
   * Get DepositMarginRequest model
   * @this {!Sender}
   * @returns {!DepositMarginRequestModel} DepositMarginRequest model
   */
  get DepositMarginRequestModel () {
    return this._DepositMarginRequestModel
  }

  /**
   * Get DepositMarginResponse model
   * @this {!Sender}
   * @returns {!DepositMarginResponseModel} DepositMarginResponse model
   */
  get DepositMarginResponseModel () {
    return this._DepositMarginResponseModel
  }

  /**
   * Get DepositMargin2Request model
   * @this {!Sender}
   * @returns {!DepositMargin2RequestModel} DepositMargin2Request model
   */
  get DepositMargin2RequestModel () {
    return this._DepositMargin2RequestModel
  }

  /**
   * Get DepositMargin2Response model
   * @this {!Sender}
   * @returns {!DepositMargin2ResponseModel} DepositMargin2Response model
   */
  get DepositMargin2ResponseModel () {
    return this._DepositMargin2ResponseModel
  }

  /**
   * Get StoreClientLoginSuccessRequest model
   * @this {!Sender}
   * @returns {!StoreClientLoginSuccessRequestModel} StoreClientLoginSuccessRequest model
   */
  get StoreClientLoginSuccessRequestModel () {
    return this._StoreClientLoginSuccessRequestModel
  }

  /**
   * Get StoreClientLoginSuccessResponse model
   * @this {!Sender}
   * @returns {!StoreClientLoginSuccessResponseModel} StoreClientLoginSuccessResponse model
   */
  get StoreClientLoginSuccessResponseModel () {
    return this._StoreClientLoginSuccessResponseModel
  }

  /**
   * Get ClientLoginInfoGetRequest model
   * @this {!Sender}
   * @returns {!ClientLoginInfoGetRequestModel} ClientLoginInfoGetRequest model
   */
  get ClientLoginInfoGetRequestModel () {
    return this._ClientLoginInfoGetRequestModel
  }

  /**
   * Get ClientLoginInfoGetResponse model
   * @this {!Sender}
   * @returns {!ClientLoginInfoGetResponseModel} ClientLoginInfoGetResponse model
   */
  get ClientLoginInfoGetResponseModel () {
    return this._ClientLoginInfoGetResponseModel
  }

  /**
   * Get ClientLoginInfoNotify model
   * @this {!Sender}
   * @returns {!ClientLoginInfoNotifyModel} ClientLoginInfoNotify model
   */
  get ClientLoginInfoNotifyModel () {
    return this._ClientLoginInfoNotifyModel
  }

  /**
   * Get StoreClientLoginSuccess2Request model
   * @this {!Sender}
   * @returns {!StoreClientLoginSuccess2RequestModel} StoreClientLoginSuccess2Request model
   */
  get StoreClientLoginSuccess2RequestModel () {
    return this._StoreClientLoginSuccess2RequestModel
  }

  /**
   * Get StoreClientLoginSuccess2Response model
   * @this {!Sender}
   * @returns {!StoreClientLoginSuccess2ResponseModel} StoreClientLoginSuccess2Response model
   */
  get StoreClientLoginSuccess2ResponseModel () {
    return this._StoreClientLoginSuccess2ResponseModel
  }

  /**
   * Get OrderUpdateWithTypeRequest model
   * @this {!Sender}
   * @returns {!OrderUpdateWithTypeRequestModel} OrderUpdateWithTypeRequest model
   */
  get OrderUpdateWithTypeRequestModel () {
    return this._OrderUpdateWithTypeRequestModel
  }

  /**
   * Get OrderUpdateWithTypeResponse model
   * @this {!Sender}
   * @returns {!OrderUpdateWithTypeResponseModel} OrderUpdateWithTypeResponse model
   */
  get OrderUpdateWithTypeResponseModel () {
    return this._OrderUpdateWithTypeResponseModel
  }

  /**
   * Get OrderUpdateWithType2Request model
   * @this {!Sender}
   * @returns {!OrderUpdateWithType2RequestModel} OrderUpdateWithType2Request model
   */
  get OrderUpdateWithType2RequestModel () {
    return this._OrderUpdateWithType2RequestModel
  }

  /**
   * Get OrderUpdateWithType2Response model
   * @this {!Sender}
   * @returns {!OrderUpdateWithType2ResponseModel} OrderUpdateWithType2Response model
   */
  get OrderUpdateWithType2ResponseModel () {
    return this._OrderUpdateWithType2ResponseModel
  }

  /**
   * Get TradeHistoryTimeRangeGetRequest model
   * @this {!Sender}
   * @returns {!TradeHistoryTimeRangeGetRequestModel} TradeHistoryTimeRangeGetRequest model
   */
  get TradeHistoryTimeRangeGetRequestModel () {
    return this._TradeHistoryTimeRangeGetRequestModel
  }

  /**
   * Get TradeHistoryTimeRangeGetResponse model
   * @this {!Sender}
   * @returns {!TradeHistoryTimeRangeGetResponseModel} TradeHistoryTimeRangeGetResponse model
   */
  get TradeHistoryTimeRangeGetResponseModel () {
    return this._TradeHistoryTimeRangeGetResponseModel
  }

  /**
   * Get TradeEventsTimeRangeGetRequest model
   * @this {!Sender}
   * @returns {!TradeEventsTimeRangeGetRequestModel} TradeEventsTimeRangeGetRequest model
   */
  get TradeEventsTimeRangeGetRequestModel () {
    return this._TradeEventsTimeRangeGetRequestModel
  }

  /**
   * Get TradeEventsTimeRangeGetResponse model
   * @this {!Sender}
   * @returns {!TradeEventsTimeRangeGetResponseModel} TradeEventsTimeRangeGetResponse model
   */
  get TradeEventsTimeRangeGetResponseModel () {
    return this._TradeEventsTimeRangeGetResponseModel
  }

  /**
   * Get TradeHistoryByIdRequest model
   * @this {!Sender}
   * @returns {!TradeHistoryByIdRequestModel} TradeHistoryByIdRequest model
   */
  get TradeHistoryByIdRequestModel () {
    return this._TradeHistoryByIdRequestModel
  }

  /**
   * Get TradeHistoryByIdResponse model
   * @this {!Sender}
   * @returns {!TradeHistoryByIdResponseModel} TradeHistoryByIdResponse model
   */
  get TradeHistoryByIdResponseModel () {
    return this._TradeHistoryByIdResponseModel
  }

  /**
   * Get OrdersGetAllRequest model
   * @this {!Sender}
   * @returns {!OrdersGetAllRequestModel} OrdersGetAllRequest model
   */
  get OrdersGetAllRequestModel () {
    return this._OrdersGetAllRequestModel
  }

  /**
   * Get OrdersGetAllResponse model
   * @this {!Sender}
   * @returns {!OrdersGetAllResponseModel} OrdersGetAllResponse model
   */
  get OrdersGetAllResponseModel () {
    return this._OrdersGetAllResponseModel
  }

  /**
   * Get PositionsGetAllRequest model
   * @this {!Sender}
   * @returns {!PositionsGetAllRequestModel} PositionsGetAllRequest model
   */
  get PositionsGetAllRequestModel () {
    return this._PositionsGetAllRequestModel
  }

  /**
   * Get PositionsGetAllResponse model
   * @this {!Sender}
   * @returns {!PositionsGetAllResponseModel} PositionsGetAllResponse model
   */
  get PositionsGetAllResponseModel () {
    return this._PositionsGetAllResponseModel
  }

  /**
   * Get AssetsGetAllRequest model
   * @this {!Sender}
   * @returns {!AssetsGetAllRequestModel} AssetsGetAllRequest model
   */
  get AssetsGetAllRequestModel () {
    return this._AssetsGetAllRequestModel
  }

  /**
   * Get AssetsGetAllResponse model
   * @this {!Sender}
   * @returns {!AssetsGetAllResponseModel} AssetsGetAllResponse model
   */
  get AssetsGetAllResponseModel () {
    return this._AssetsGetAllResponseModel
  }

  /**
   * Get MarginsGetAllRequest model
   * @this {!Sender}
   * @returns {!MarginsGetAllRequestModel} MarginsGetAllRequest model
   */
  get MarginsGetAllRequestModel () {
    return this._MarginsGetAllRequestModel
  }

  /**
   * Get MarginsGetAllResponse model
   * @this {!Sender}
   * @returns {!MarginsGetAllResponseModel} MarginsGetAllResponse model
   */
  get MarginsGetAllResponseModel () {
    return this._MarginsGetAllResponseModel
  }

  /**
   * Get AccountsGetAllRequest model
   * @this {!Sender}
   * @returns {!AccountsGetAllRequestModel} AccountsGetAllRequest model
   */
  get AccountsGetAllRequestModel () {
    return this._AccountsGetAllRequestModel
  }

  /**
   * Get AccountsGetAllResponse model
   * @this {!Sender}
   * @returns {!AccountsGetAllResponseModel} AccountsGetAllResponse model
   */
  get AccountsGetAllResponseModel () {
    return this._AccountsGetAllResponseModel
  }

  /**
   * Get MassRequest model
   * @this {!Sender}
   * @returns {!MassRequestModel} MassRequest model
   */
  get MassRequestModel () {
    return this._MassRequestModel
  }

  /**
   * Get MassResponse model
   * @this {!Sender}
   * @returns {!MassResponseModel} MassResponse model
   */
  get MassResponseModel () {
    return this._MassResponseModel
  }

  /**
   * Get Mass2Request model
   * @this {!Sender}
   * @returns {!Mass2RequestModel} Mass2Request model
   */
  get Mass2RequestModel () {
    return this._Mass2RequestModel
  }

  /**
   * Get Mass2ResponseParams model
   * @this {!Sender}
   * @returns {!Mass2ResponseParamsModel} Mass2ResponseParams model
   */
  get Mass2ResponseParamsModel () {
    return this._Mass2ResponseParamsModel
  }

  /**
   * Get Mass2Response model
   * @this {!Sender}
   * @returns {!Mass2ResponseModel} Mass2Response model
   */
  get Mass2ResponseModel () {
    return this._Mass2ResponseModel
  }

  /**
   * Get MarginUpdateNotify model
   * @this {!Sender}
   * @returns {!MarginUpdateNotifyModel} MarginUpdateNotify model
   */
  get MarginUpdateNotifyModel () {
    return this._MarginUpdateNotifyModel
  }

  /**
   * Get PersonsGetAllRequest model
   * @this {!Sender}
   * @returns {!PersonsGetAllRequestModel} PersonsGetAllRequest model
   */
  get PersonsGetAllRequestModel () {
    return this._PersonsGetAllRequestModel
  }

  /**
   * Get PersonsGetAllResponse model
   * @this {!Sender}
   * @returns {!PersonsGetAllResponseModel} PersonsGetAllResponse model
   */
  get PersonsGetAllResponseModel () {
    return this._PersonsGetAllResponseModel
  }

  /**
   * Get PersonsInsertRequest model
   * @this {!Sender}
   * @returns {!PersonsInsertRequestModel} PersonsInsertRequest model
   */
  get PersonsInsertRequestModel () {
    return this._PersonsInsertRequestModel
  }

  /**
   * Get PersonsInsertResponse model
   * @this {!Sender}
   * @returns {!PersonsInsertResponseModel} PersonsInsertResponse model
   */
  get PersonsInsertResponseModel () {
    return this._PersonsInsertResponseModel
  }

  /**
   * Get PersonUpdateRequest model
   * @this {!Sender}
   * @returns {!PersonUpdateRequestModel} PersonUpdateRequest model
   */
  get PersonUpdateRequestModel () {
    return this._PersonUpdateRequestModel
  }

  /**
   * Get PersonUpdateResponse model
   * @this {!Sender}
   * @returns {!PersonUpdateResponseModel} PersonUpdateResponse model
   */
  get PersonUpdateResponseModel () {
    return this._PersonUpdateResponseModel
  }

  /**
   * Get PersonsInsert2Request model
   * @this {!Sender}
   * @returns {!PersonsInsert2RequestModel} PersonsInsert2Request model
   */
  get PersonsInsert2RequestModel () {
    return this._PersonsInsert2RequestModel
  }

  /**
   * Get PersonsInsert2Response model
   * @this {!Sender}
   * @returns {!PersonsInsert2ResponseModel} PersonsInsert2Response model
   */
  get PersonsInsert2ResponseModel () {
    return this._PersonsInsert2ResponseModel
  }

  /**
   * Get PersonUpdate2Request model
   * @this {!Sender}
   * @returns {!PersonUpdate2RequestModel} PersonUpdate2Request model
   */
  get PersonUpdate2RequestModel () {
    return this._PersonUpdate2RequestModel
  }

  /**
   * Get PersonUpdate2Response model
   * @this {!Sender}
   * @returns {!PersonUpdate2ResponseModel} PersonUpdate2Response model
   */
  get PersonUpdate2ResponseModel () {
    return this._PersonUpdate2ResponseModel
  }

  /**
   * Get LastIdsGetRequest model
   * @this {!Sender}
   * @returns {!LastIdsGetRequestModel} LastIdsGetRequest model
   */
  get LastIdsGetRequestModel () {
    return this._LastIdsGetRequestModel
  }

  /**
   * Get LastIdsGetResponse model
   * @this {!Sender}
   * @returns {!LastIdsGetResponseModel} LastIdsGetResponse model
   */
  get LastIdsGetResponseModel () {
    return this._LastIdsGetResponseModel
  }

  /**
   * Get DailySnapshotRequest model
   * @this {!Sender}
   * @returns {!DailySnapshotRequestModel} DailySnapshotRequest model
   */
  get DailySnapshotRequestModel () {
    return this._DailySnapshotRequestModel
  }

  /**
   * Get DailySnapshotResponse model
   * @this {!Sender}
   * @returns {!DailySnapshotResponseModel} DailySnapshotResponse model
   */
  get DailySnapshotResponseModel () {
    return this._DailySnapshotResponseModel
  }

  /**
   * Get BillingStatisticsGetRequest model
   * @this {!Sender}
   * @returns {!BillingStatisticsGetRequestModel} BillingStatisticsGetRequest model
   */
  get BillingStatisticsGetRequestModel () {
    return this._BillingStatisticsGetRequestModel
  }

  /**
   * Get BillingStatisticsGetResponse model
   * @this {!Sender}
   * @returns {!BillingStatisticsGetResponseModel} BillingStatisticsGetResponse model
   */
  get BillingStatisticsGetResponseModel () {
    return this._BillingStatisticsGetResponseModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof StorageVersionRequest) && (value.fbeType === this.StorageVersionRequestModel.fbeType)) {
      return this.send_StorageVersionRequest(value)
    }
    if ((value instanceof StorageVersionResponse) && (value.fbeType === this.StorageVersionResponseModel.fbeType)) {
      return this.send_StorageVersionResponse(value)
    }
    if ((value instanceof StorageUpdateNotify) && (value.fbeType === this.StorageUpdateNotifyModel.fbeType)) {
      return this.send_StorageUpdateNotify(value)
    }
    if ((value instanceof AccountInsertRequest) && (value.fbeType === this.AccountInsertRequestModel.fbeType)) {
      return this.send_AccountInsertRequest(value)
    }
    if ((value instanceof AccountInsertResponse) && (value.fbeType === this.AccountInsertResponseModel.fbeType)) {
      return this.send_AccountInsertResponse(value)
    }
    if ((value instanceof AccountSocialTradingMasterInsertRequest) && (value.fbeType === this.AccountSocialTradingMasterInsertRequestModel.fbeType)) {
      return this.send_AccountSocialTradingMasterInsertRequest(value)
    }
    if ((value instanceof AccountSocialTradingMasterInsertResponse) && (value.fbeType === this.AccountSocialTradingMasterInsertResponseModel.fbeType)) {
      return this.send_AccountSocialTradingMasterInsertResponse(value)
    }
    if ((value instanceof AccountSocialTradingInvestorInsertRequest) && (value.fbeType === this.AccountSocialTradingInvestorInsertRequestModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorInsertRequest(value)
    }
    if ((value instanceof AccountSocialTradingInvestorInsertResponse) && (value.fbeType === this.AccountSocialTradingInvestorInsertResponseModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorInsertResponse(value)
    }
    if ((value instanceof AccountUpdateRequest) && (value.fbeType === this.AccountUpdateRequestModel.fbeType)) {
      return this.send_AccountUpdateRequest(value)
    }
    if ((value instanceof AccountUpdateResponse) && (value.fbeType === this.AccountUpdateResponseModel.fbeType)) {
      return this.send_AccountUpdateResponse(value)
    }
    if ((value instanceof AccountDeleteRequest) && (value.fbeType === this.AccountDeleteRequestModel.fbeType)) {
      return this.send_AccountDeleteRequest(value)
    }
    if ((value instanceof AccountDeleteResponse) && (value.fbeType === this.AccountDeleteResponseModel.fbeType)) {
      return this.send_AccountDeleteResponse(value)
    }
    if ((value instanceof MarginUpdateRequest) && (value.fbeType === this.MarginUpdateRequestModel.fbeType)) {
      return this.send_MarginUpdateRequest(value)
    }
    if ((value instanceof MarginUpdateResponse) && (value.fbeType === this.MarginUpdateResponseModel.fbeType)) {
      return this.send_MarginUpdateResponse(value)
    }
    if ((value instanceof AssetInsertRequest) && (value.fbeType === this.AssetInsertRequestModel.fbeType)) {
      return this.send_AssetInsertRequest(value)
    }
    if ((value instanceof AssetInsertResponse) && (value.fbeType === this.AssetInsertResponseModel.fbeType)) {
      return this.send_AssetInsertResponse(value)
    }
    if ((value instanceof AssetUpdateRequest) && (value.fbeType === this.AssetUpdateRequestModel.fbeType)) {
      return this.send_AssetUpdateRequest(value)
    }
    if ((value instanceof AssetUpdateResponse) && (value.fbeType === this.AssetUpdateResponseModel.fbeType)) {
      return this.send_AssetUpdateResponse(value)
    }
    if ((value instanceof AssetDeleteRequest) && (value.fbeType === this.AssetDeleteRequestModel.fbeType)) {
      return this.send_AssetDeleteRequest(value)
    }
    if ((value instanceof AssetDeleteResponse) && (value.fbeType === this.AssetDeleteResponseModel.fbeType)) {
      return this.send_AssetDeleteResponse(value)
    }
    if ((value instanceof OrderInsertRequest) && (value.fbeType === this.OrderInsertRequestModel.fbeType)) {
      return this.send_OrderInsertRequest(value)
    }
    if ((value instanceof OrderInsertResponse) && (value.fbeType === this.OrderInsertResponseModel.fbeType)) {
      return this.send_OrderInsertResponse(value)
    }
    if ((value instanceof OrderUpdateRequest) && (value.fbeType === this.OrderUpdateRequestModel.fbeType)) {
      return this.send_OrderUpdateRequest(value)
    }
    if ((value instanceof OrderUpdateResponse) && (value.fbeType === this.OrderUpdateResponseModel.fbeType)) {
      return this.send_OrderUpdateResponse(value)
    }
    if ((value instanceof OrderDeleteRequest) && (value.fbeType === this.OrderDeleteRequestModel.fbeType)) {
      return this.send_OrderDeleteRequest(value)
    }
    if ((value instanceof OrderDeleteResponse) && (value.fbeType === this.OrderDeleteResponseModel.fbeType)) {
      return this.send_OrderDeleteResponse(value)
    }
    if ((value instanceof OrderDeleteAllRequest) && (value.fbeType === this.OrderDeleteAllRequestModel.fbeType)) {
      return this.send_OrderDeleteAllRequest(value)
    }
    if ((value instanceof OrderDeleteAllResponse) && (value.fbeType === this.OrderDeleteAllResponseModel.fbeType)) {
      return this.send_OrderDeleteAllResponse(value)
    }
    if ((value instanceof PositionInsertRequest) && (value.fbeType === this.PositionInsertRequestModel.fbeType)) {
      return this.send_PositionInsertRequest(value)
    }
    if ((value instanceof PositionInsertResponse) && (value.fbeType === this.PositionInsertResponseModel.fbeType)) {
      return this.send_PositionInsertResponse(value)
    }
    if ((value instanceof PositionUpdateRequest) && (value.fbeType === this.PositionUpdateRequestModel.fbeType)) {
      return this.send_PositionUpdateRequest(value)
    }
    if ((value instanceof PositionUpdateResponse) && (value.fbeType === this.PositionUpdateResponseModel.fbeType)) {
      return this.send_PositionUpdateResponse(value)
    }
    if ((value instanceof PositionDeleteRequest) && (value.fbeType === this.PositionDeleteRequestModel.fbeType)) {
      return this.send_PositionDeleteRequest(value)
    }
    if ((value instanceof PositionDeleteResponse) && (value.fbeType === this.PositionDeleteResponseModel.fbeType)) {
      return this.send_PositionDeleteResponse(value)
    }
    if ((value instanceof TradeHistoryRequest) && (value.fbeType === this.TradeHistoryRequestModel.fbeType)) {
      return this.send_TradeHistoryRequest(value)
    }
    if ((value instanceof TradeHistoryResponse) && (value.fbeType === this.TradeHistoryResponseModel.fbeType)) {
      return this.send_TradeHistoryResponse(value)
    }
    if ((value instanceof TradeEventsGetRequest) && (value.fbeType === this.TradeEventsGetRequestModel.fbeType)) {
      return this.send_TradeEventsGetRequest(value)
    }
    if ((value instanceof TradeEventsGetResponse) && (value.fbeType === this.TradeEventsGetResponseModel.fbeType)) {
      return this.send_TradeEventsGetResponse(value)
    }
    if ((value instanceof TradeEventsNotify) && (value.fbeType === this.TradeEventsNotifyModel.fbeType)) {
      return this.send_TradeEventsNotify(value)
    }
    if ((value instanceof TradeEventsInsertRequest) && (value.fbeType === this.TradeEventsInsertRequestModel.fbeType)) {
      return this.send_TradeEventsInsertRequest(value)
    }
    if ((value instanceof TradeEventsInsertResponse) && (value.fbeType === this.TradeEventsInsertResponseModel.fbeType)) {
      return this.send_TradeEventsInsertResponse(value)
    }
    if ((value instanceof ProfitClosedGetRequest) && (value.fbeType === this.ProfitClosedGetRequestModel.fbeType)) {
      return this.send_ProfitClosedGetRequest(value)
    }
    if ((value instanceof ProfitClosedGetResponse) && (value.fbeType === this.ProfitClosedGetResponseModel.fbeType)) {
      return this.send_ProfitClosedGetResponse(value)
    }
    if ((value instanceof OrdersClosedSummariesGetRequest) && (value.fbeType === this.OrdersClosedSummariesGetRequestModel.fbeType)) {
      return this.send_OrdersClosedSummariesGetRequest(value)
    }
    if ((value instanceof OrdersClosedSummariesGetResponse) && (value.fbeType === this.OrdersClosedSummariesGetResponseModel.fbeType)) {
      return this.send_OrdersClosedSummariesGetResponse(value)
    }
    if ((value instanceof OrderInsert2Request) && (value.fbeType === this.OrderInsert2RequestModel.fbeType)) {
      return this.send_OrderInsert2Request(value)
    }
    if ((value instanceof OrderInsert2Response) && (value.fbeType === this.OrderInsert2ResponseModel.fbeType)) {
      return this.send_OrderInsert2Response(value)
    }
    if ((value instanceof OrderUpdate2Request) && (value.fbeType === this.OrderUpdate2RequestModel.fbeType)) {
      return this.send_OrderUpdate2Request(value)
    }
    if ((value instanceof OrderUpdate2Response) && (value.fbeType === this.OrderUpdate2ResponseModel.fbeType)) {
      return this.send_OrderUpdate2Response(value)
    }
    if ((value instanceof OrderDelete2Request) && (value.fbeType === this.OrderDelete2RequestModel.fbeType)) {
      return this.send_OrderDelete2Request(value)
    }
    if ((value instanceof OrderDelete2Response) && (value.fbeType === this.OrderDelete2ResponseModel.fbeType)) {
      return this.send_OrderDelete2Response(value)
    }
    if ((value instanceof AccountInsert2Request) && (value.fbeType === this.AccountInsert2RequestModel.fbeType)) {
      return this.send_AccountInsert2Request(value)
    }
    if ((value instanceof AccountInsert2Response) && (value.fbeType === this.AccountInsert2ResponseModel.fbeType)) {
      return this.send_AccountInsert2Response(value)
    }
    if ((value instanceof AccountSocialTradingMasterInsert2Request) && (value.fbeType === this.AccountSocialTradingMasterInsert2RequestModel.fbeType)) {
      return this.send_AccountSocialTradingMasterInsert2Request(value)
    }
    if ((value instanceof AccountSocialTradingMasterInsert2Response) && (value.fbeType === this.AccountSocialTradingMasterInsert2ResponseModel.fbeType)) {
      return this.send_AccountSocialTradingMasterInsert2Response(value)
    }
    if ((value instanceof AccountSocialTradingInvestorInsert2Request) && (value.fbeType === this.AccountSocialTradingInvestorInsert2RequestModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorInsert2Request(value)
    }
    if ((value instanceof AccountSocialTradingInvestorInsert2Response) && (value.fbeType === this.AccountSocialTradingInvestorInsert2ResponseModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorInsert2Response(value)
    }
    if ((value instanceof AccountDelete2Request) && (value.fbeType === this.AccountDelete2RequestModel.fbeType)) {
      return this.send_AccountDelete2Request(value)
    }
    if ((value instanceof AccountDelete2Response) && (value.fbeType === this.AccountDelete2ResponseModel.fbeType)) {
      return this.send_AccountDelete2Response(value)
    }
    if ((value instanceof AssetInsert2Request) && (value.fbeType === this.AssetInsert2RequestModel.fbeType)) {
      return this.send_AssetInsert2Request(value)
    }
    if ((value instanceof AssetInsert2Response) && (value.fbeType === this.AssetInsert2ResponseModel.fbeType)) {
      return this.send_AssetInsert2Response(value)
    }
    if ((value instanceof AssetUpdate2Request) && (value.fbeType === this.AssetUpdate2RequestModel.fbeType)) {
      return this.send_AssetUpdate2Request(value)
    }
    if ((value instanceof AssetUpdate2Response) && (value.fbeType === this.AssetUpdate2ResponseModel.fbeType)) {
      return this.send_AssetUpdate2Response(value)
    }
    if ((value instanceof AssetDelete2Request) && (value.fbeType === this.AssetDelete2RequestModel.fbeType)) {
      return this.send_AssetDelete2Request(value)
    }
    if ((value instanceof AssetDelete2Response) && (value.fbeType === this.AssetDelete2ResponseModel.fbeType)) {
      return this.send_AssetDelete2Response(value)
    }
    if ((value instanceof PositionInsert2Request) && (value.fbeType === this.PositionInsert2RequestModel.fbeType)) {
      return this.send_PositionInsert2Request(value)
    }
    if ((value instanceof PositionInsert2Response) && (value.fbeType === this.PositionInsert2ResponseModel.fbeType)) {
      return this.send_PositionInsert2Response(value)
    }
    if ((value instanceof PositionUpdate2Request) && (value.fbeType === this.PositionUpdate2RequestModel.fbeType)) {
      return this.send_PositionUpdate2Request(value)
    }
    if ((value instanceof PositionUpdate2Response) && (value.fbeType === this.PositionUpdate2ResponseModel.fbeType)) {
      return this.send_PositionUpdate2Response(value)
    }
    if ((value instanceof PositionDelete2Request) && (value.fbeType === this.PositionDelete2RequestModel.fbeType)) {
      return this.send_PositionDelete2Request(value)
    }
    if ((value instanceof PositionDelete2Response) && (value.fbeType === this.PositionDelete2ResponseModel.fbeType)) {
      return this.send_PositionDelete2Response(value)
    }
    if ((value instanceof Deprecated001) && (value.fbeType === this.Deprecated001Model.fbeType)) {
      return this.send_Deprecated001(value)
    }
    if ((value instanceof Deprecated002) && (value.fbeType === this.Deprecated002Model.fbeType)) {
      return this.send_Deprecated002(value)
    }
    if ((value instanceof Deprecated003) && (value.fbeType === this.Deprecated003Model.fbeType)) {
      return this.send_Deprecated003(value)
    }
    if ((value instanceof Deprecated004) && (value.fbeType === this.Deprecated004Model.fbeType)) {
      return this.send_Deprecated004(value)
    }
    if ((value instanceof Deprecated005) && (value.fbeType === this.Deprecated005Model.fbeType)) {
      return this.send_Deprecated005(value)
    }
    if ((value instanceof Deprecated006) && (value.fbeType === this.Deprecated006Model.fbeType)) {
      return this.send_Deprecated006(value)
    }
    if ((value instanceof TradeEventsInsert2Request) && (value.fbeType === this.TradeEventsInsert2RequestModel.fbeType)) {
      return this.send_TradeEventsInsert2Request(value)
    }
    if ((value instanceof TradeEventsInsert2Response) && (value.fbeType === this.TradeEventsInsert2ResponseModel.fbeType)) {
      return this.send_TradeEventsInsert2Response(value)
    }
    if ((value instanceof MarginUpdate2Request) && (value.fbeType === this.MarginUpdate2RequestModel.fbeType)) {
      return this.send_MarginUpdate2Request(value)
    }
    if ((value instanceof MarginUpdate2Response) && (value.fbeType === this.MarginUpdate2ResponseModel.fbeType)) {
      return this.send_MarginUpdate2Response(value)
    }
    if ((value instanceof AccountUpdate2Request) && (value.fbeType === this.AccountUpdate2RequestModel.fbeType)) {
      return this.send_AccountUpdate2Request(value)
    }
    if ((value instanceof AccountUpdate2Response) && (value.fbeType === this.AccountUpdate2ResponseModel.fbeType)) {
      return this.send_AccountUpdate2Response(value)
    }
    if ((value instanceof DepositMarginRequest) && (value.fbeType === this.DepositMarginRequestModel.fbeType)) {
      return this.send_DepositMarginRequest(value)
    }
    if ((value instanceof DepositMarginResponse) && (value.fbeType === this.DepositMarginResponseModel.fbeType)) {
      return this.send_DepositMarginResponse(value)
    }
    if ((value instanceof DepositMargin2Request) && (value.fbeType === this.DepositMargin2RequestModel.fbeType)) {
      return this.send_DepositMargin2Request(value)
    }
    if ((value instanceof DepositMargin2Response) && (value.fbeType === this.DepositMargin2ResponseModel.fbeType)) {
      return this.send_DepositMargin2Response(value)
    }
    if ((value instanceof StoreClientLoginSuccessRequest) && (value.fbeType === this.StoreClientLoginSuccessRequestModel.fbeType)) {
      return this.send_StoreClientLoginSuccessRequest(value)
    }
    if ((value instanceof StoreClientLoginSuccessResponse) && (value.fbeType === this.StoreClientLoginSuccessResponseModel.fbeType)) {
      return this.send_StoreClientLoginSuccessResponse(value)
    }
    if ((value instanceof ClientLoginInfoGetRequest) && (value.fbeType === this.ClientLoginInfoGetRequestModel.fbeType)) {
      return this.send_ClientLoginInfoGetRequest(value)
    }
    if ((value instanceof ClientLoginInfoGetResponse) && (value.fbeType === this.ClientLoginInfoGetResponseModel.fbeType)) {
      return this.send_ClientLoginInfoGetResponse(value)
    }
    if ((value instanceof ClientLoginInfoNotify) && (value.fbeType === this.ClientLoginInfoNotifyModel.fbeType)) {
      return this.send_ClientLoginInfoNotify(value)
    }
    if ((value instanceof StoreClientLoginSuccess2Request) && (value.fbeType === this.StoreClientLoginSuccess2RequestModel.fbeType)) {
      return this.send_StoreClientLoginSuccess2Request(value)
    }
    if ((value instanceof StoreClientLoginSuccess2Response) && (value.fbeType === this.StoreClientLoginSuccess2ResponseModel.fbeType)) {
      return this.send_StoreClientLoginSuccess2Response(value)
    }
    if ((value instanceof OrderUpdateWithTypeRequest) && (value.fbeType === this.OrderUpdateWithTypeRequestModel.fbeType)) {
      return this.send_OrderUpdateWithTypeRequest(value)
    }
    if ((value instanceof OrderUpdateWithTypeResponse) && (value.fbeType === this.OrderUpdateWithTypeResponseModel.fbeType)) {
      return this.send_OrderUpdateWithTypeResponse(value)
    }
    if ((value instanceof OrderUpdateWithType2Request) && (value.fbeType === this.OrderUpdateWithType2RequestModel.fbeType)) {
      return this.send_OrderUpdateWithType2Request(value)
    }
    if ((value instanceof OrderUpdateWithType2Response) && (value.fbeType === this.OrderUpdateWithType2ResponseModel.fbeType)) {
      return this.send_OrderUpdateWithType2Response(value)
    }
    if ((value instanceof TradeHistoryTimeRangeGetRequest) && (value.fbeType === this.TradeHistoryTimeRangeGetRequestModel.fbeType)) {
      return this.send_TradeHistoryTimeRangeGetRequest(value)
    }
    if ((value instanceof TradeHistoryTimeRangeGetResponse) && (value.fbeType === this.TradeHistoryTimeRangeGetResponseModel.fbeType)) {
      return this.send_TradeHistoryTimeRangeGetResponse(value)
    }
    if ((value instanceof TradeEventsTimeRangeGetRequest) && (value.fbeType === this.TradeEventsTimeRangeGetRequestModel.fbeType)) {
      return this.send_TradeEventsTimeRangeGetRequest(value)
    }
    if ((value instanceof TradeEventsTimeRangeGetResponse) && (value.fbeType === this.TradeEventsTimeRangeGetResponseModel.fbeType)) {
      return this.send_TradeEventsTimeRangeGetResponse(value)
    }
    if ((value instanceof TradeHistoryByIdRequest) && (value.fbeType === this.TradeHistoryByIdRequestModel.fbeType)) {
      return this.send_TradeHistoryByIdRequest(value)
    }
    if ((value instanceof TradeHistoryByIdResponse) && (value.fbeType === this.TradeHistoryByIdResponseModel.fbeType)) {
      return this.send_TradeHistoryByIdResponse(value)
    }
    if ((value instanceof OrdersGetAllRequest) && (value.fbeType === this.OrdersGetAllRequestModel.fbeType)) {
      return this.send_OrdersGetAllRequest(value)
    }
    if ((value instanceof OrdersGetAllResponse) && (value.fbeType === this.OrdersGetAllResponseModel.fbeType)) {
      return this.send_OrdersGetAllResponse(value)
    }
    if ((value instanceof PositionsGetAllRequest) && (value.fbeType === this.PositionsGetAllRequestModel.fbeType)) {
      return this.send_PositionsGetAllRequest(value)
    }
    if ((value instanceof PositionsGetAllResponse) && (value.fbeType === this.PositionsGetAllResponseModel.fbeType)) {
      return this.send_PositionsGetAllResponse(value)
    }
    if ((value instanceof AssetsGetAllRequest) && (value.fbeType === this.AssetsGetAllRequestModel.fbeType)) {
      return this.send_AssetsGetAllRequest(value)
    }
    if ((value instanceof AssetsGetAllResponse) && (value.fbeType === this.AssetsGetAllResponseModel.fbeType)) {
      return this.send_AssetsGetAllResponse(value)
    }
    if ((value instanceof MarginsGetAllRequest) && (value.fbeType === this.MarginsGetAllRequestModel.fbeType)) {
      return this.send_MarginsGetAllRequest(value)
    }
    if ((value instanceof MarginsGetAllResponse) && (value.fbeType === this.MarginsGetAllResponseModel.fbeType)) {
      return this.send_MarginsGetAllResponse(value)
    }
    if ((value instanceof AccountsGetAllRequest) && (value.fbeType === this.AccountsGetAllRequestModel.fbeType)) {
      return this.send_AccountsGetAllRequest(value)
    }
    if ((value instanceof AccountsGetAllResponse) && (value.fbeType === this.AccountsGetAllResponseModel.fbeType)) {
      return this.send_AccountsGetAllResponse(value)
    }
    if ((value instanceof MassRequest) && (value.fbeType === this.MassRequestModel.fbeType)) {
      return this.send_MassRequest(value)
    }
    if ((value instanceof MassResponse) && (value.fbeType === this.MassResponseModel.fbeType)) {
      return this.send_MassResponse(value)
    }
    if ((value instanceof Mass2Request) && (value.fbeType === this.Mass2RequestModel.fbeType)) {
      return this.send_Mass2Request(value)
    }
    if ((value instanceof Mass2ResponseParams) && (value.fbeType === this.Mass2ResponseParamsModel.fbeType)) {
      return this.send_Mass2ResponseParams(value)
    }
    if ((value instanceof Mass2Response) && (value.fbeType === this.Mass2ResponseModel.fbeType)) {
      return this.send_Mass2Response(value)
    }
    if ((value instanceof MarginUpdateNotify) && (value.fbeType === this.MarginUpdateNotifyModel.fbeType)) {
      return this.send_MarginUpdateNotify(value)
    }
    if ((value instanceof PersonsGetAllRequest) && (value.fbeType === this.PersonsGetAllRequestModel.fbeType)) {
      return this.send_PersonsGetAllRequest(value)
    }
    if ((value instanceof PersonsGetAllResponse) && (value.fbeType === this.PersonsGetAllResponseModel.fbeType)) {
      return this.send_PersonsGetAllResponse(value)
    }
    if ((value instanceof PersonsInsertRequest) && (value.fbeType === this.PersonsInsertRequestModel.fbeType)) {
      return this.send_PersonsInsertRequest(value)
    }
    if ((value instanceof PersonsInsertResponse) && (value.fbeType === this.PersonsInsertResponseModel.fbeType)) {
      return this.send_PersonsInsertResponse(value)
    }
    if ((value instanceof PersonUpdateRequest) && (value.fbeType === this.PersonUpdateRequestModel.fbeType)) {
      return this.send_PersonUpdateRequest(value)
    }
    if ((value instanceof PersonUpdateResponse) && (value.fbeType === this.PersonUpdateResponseModel.fbeType)) {
      return this.send_PersonUpdateResponse(value)
    }
    if ((value instanceof PersonsInsert2Request) && (value.fbeType === this.PersonsInsert2RequestModel.fbeType)) {
      return this.send_PersonsInsert2Request(value)
    }
    if ((value instanceof PersonsInsert2Response) && (value.fbeType === this.PersonsInsert2ResponseModel.fbeType)) {
      return this.send_PersonsInsert2Response(value)
    }
    if ((value instanceof PersonUpdate2Request) && (value.fbeType === this.PersonUpdate2RequestModel.fbeType)) {
      return this.send_PersonUpdate2Request(value)
    }
    if ((value instanceof PersonUpdate2Response) && (value.fbeType === this.PersonUpdate2ResponseModel.fbeType)) {
      return this.send_PersonUpdate2Response(value)
    }
    if ((value instanceof LastIdsGetRequest) && (value.fbeType === this.LastIdsGetRequestModel.fbeType)) {
      return this.send_LastIdsGetRequest(value)
    }
    if ((value instanceof LastIdsGetResponse) && (value.fbeType === this.LastIdsGetResponseModel.fbeType)) {
      return this.send_LastIdsGetResponse(value)
    }
    if ((value instanceof DailySnapshotRequest) && (value.fbeType === this.DailySnapshotRequestModel.fbeType)) {
      return this.send_DailySnapshotRequest(value)
    }
    if ((value instanceof DailySnapshotResponse) && (value.fbeType === this.DailySnapshotResponseModel.fbeType)) {
      return this.send_DailySnapshotResponse(value)
    }
    if ((value instanceof BillingStatisticsGetRequest) && (value.fbeType === this.BillingStatisticsGetRequestModel.fbeType)) {
      return this.send_BillingStatisticsGetRequest(value)
    }
    if ((value instanceof BillingStatisticsGetResponse) && (value.fbeType === this.BillingStatisticsGetResponseModel.fbeType)) {
      return this.send_BillingStatisticsGetResponse(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._innerSender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send StorageVersionRequest value
   * @this {!Sender}
   * @param {!StorageVersionRequest} value StorageVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_StorageVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StorageVersionRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.StorageVersionRequest serialization failed!')
    console.assert(this.StorageVersionRequestModel.verify(), 'storage.StorageVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StorageVersionResponse value
   * @this {!Sender}
   * @param {!StorageVersionResponse} value StorageVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_StorageVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StorageVersionResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.StorageVersionResponse serialization failed!')
    console.assert(this.StorageVersionResponseModel.verify(), 'storage.StorageVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StorageUpdateNotify value
   * @this {!Sender}
   * @param {!StorageUpdateNotify} value StorageUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_StorageUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StorageUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'storage.StorageUpdateNotify serialization failed!')
    console.assert(this.StorageUpdateNotifyModel.verify(), 'storage.StorageUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInsertRequest value
   * @this {!Sender}
   * @param {!AccountInsertRequest} value AccountInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInsertRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountInsertRequest serialization failed!')
    console.assert(this.AccountInsertRequestModel.verify(), 'storage.AccountInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInsertResponse value
   * @this {!Sender}
   * @param {!AccountInsertResponse} value AccountInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInsertResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountInsertResponse serialization failed!')
    console.assert(this.AccountInsertResponseModel.verify(), 'storage.AccountInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterInsertRequest value
   * @this {!Sender}
   * @param {!AccountSocialTradingMasterInsertRequest} value AccountSocialTradingMasterInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterInsertRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingMasterInsertRequest serialization failed!')
    console.assert(this.AccountSocialTradingMasterInsertRequestModel.verify(), 'storage.AccountSocialTradingMasterInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterInsertResponse value
   * @this {!Sender}
   * @param {!AccountSocialTradingMasterInsertResponse} value AccountSocialTradingMasterInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterInsertResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingMasterInsertResponse serialization failed!')
    console.assert(this.AccountSocialTradingMasterInsertResponseModel.verify(), 'storage.AccountSocialTradingMasterInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorInsertRequest value
   * @this {!Sender}
   * @param {!AccountSocialTradingInvestorInsertRequest} value AccountSocialTradingInvestorInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorInsertRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingInvestorInsertRequest serialization failed!')
    console.assert(this.AccountSocialTradingInvestorInsertRequestModel.verify(), 'storage.AccountSocialTradingInvestorInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorInsertResponse value
   * @this {!Sender}
   * @param {!AccountSocialTradingInvestorInsertResponse} value AccountSocialTradingInvestorInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorInsertResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingInvestorInsertResponse serialization failed!')
    console.assert(this.AccountSocialTradingInvestorInsertResponseModel.verify(), 'storage.AccountSocialTradingInvestorInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdateRequest value
   * @this {!Sender}
   * @param {!AccountUpdateRequest} value AccountUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountUpdateRequest serialization failed!')
    console.assert(this.AccountUpdateRequestModel.verify(), 'storage.AccountUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdateResponse value
   * @this {!Sender}
   * @param {!AccountUpdateResponse} value AccountUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountUpdateResponse serialization failed!')
    console.assert(this.AccountUpdateResponseModel.verify(), 'storage.AccountUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDeleteRequest value
   * @this {!Sender}
   * @param {!AccountDeleteRequest} value AccountDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountDeleteRequest serialization failed!')
    console.assert(this.AccountDeleteRequestModel.verify(), 'storage.AccountDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDeleteResponse value
   * @this {!Sender}
   * @param {!AccountDeleteResponse} value AccountDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountDeleteResponse serialization failed!')
    console.assert(this.AccountDeleteResponseModel.verify(), 'storage.AccountDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdateRequest value
   * @this {!Sender}
   * @param {!MarginUpdateRequest} value MarginUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginUpdateRequest serialization failed!')
    console.assert(this.MarginUpdateRequestModel.verify(), 'storage.MarginUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdateResponse value
   * @this {!Sender}
   * @param {!MarginUpdateResponse} value MarginUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginUpdateResponse serialization failed!')
    console.assert(this.MarginUpdateResponseModel.verify(), 'storage.MarginUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInsertRequest value
   * @this {!Sender}
   * @param {!AssetInsertRequest} value AssetInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInsertRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetInsertRequest serialization failed!')
    console.assert(this.AssetInsertRequestModel.verify(), 'storage.AssetInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInsertResponse value
   * @this {!Sender}
   * @param {!AssetInsertResponse} value AssetInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInsertResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetInsertResponse serialization failed!')
    console.assert(this.AssetInsertResponseModel.verify(), 'storage.AssetInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetUpdateRequest value
   * @this {!Sender}
   * @param {!AssetUpdateRequest} value AssetUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetUpdateRequest serialization failed!')
    console.assert(this.AssetUpdateRequestModel.verify(), 'storage.AssetUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetUpdateResponse value
   * @this {!Sender}
   * @param {!AssetUpdateResponse} value AssetUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetUpdateResponse serialization failed!')
    console.assert(this.AssetUpdateResponseModel.verify(), 'storage.AssetUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetDeleteRequest value
   * @this {!Sender}
   * @param {!AssetDeleteRequest} value AssetDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetDeleteRequest serialization failed!')
    console.assert(this.AssetDeleteRequestModel.verify(), 'storage.AssetDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetDeleteResponse value
   * @this {!Sender}
   * @param {!AssetDeleteResponse} value AssetDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetDeleteResponse serialization failed!')
    console.assert(this.AssetDeleteResponseModel.verify(), 'storage.AssetDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInsertRequest value
   * @this {!Sender}
   * @param {!OrderInsertRequest} value OrderInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInsertRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderInsertRequest serialization failed!')
    console.assert(this.OrderInsertRequestModel.verify(), 'storage.OrderInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInsertResponse value
   * @this {!Sender}
   * @param {!OrderInsertResponse} value OrderInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInsertResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderInsertResponse serialization failed!')
    console.assert(this.OrderInsertResponseModel.verify(), 'storage.OrderInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateRequest value
   * @this {!Sender}
   * @param {!OrderUpdateRequest} value OrderUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateRequest serialization failed!')
    console.assert(this.OrderUpdateRequestModel.verify(), 'storage.OrderUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateResponse value
   * @this {!Sender}
   * @param {!OrderUpdateResponse} value OrderUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateResponse serialization failed!')
    console.assert(this.OrderUpdateResponseModel.verify(), 'storage.OrderUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDeleteRequest value
   * @this {!Sender}
   * @param {!OrderDeleteRequest} value OrderDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDeleteRequest serialization failed!')
    console.assert(this.OrderDeleteRequestModel.verify(), 'storage.OrderDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDeleteResponse value
   * @this {!Sender}
   * @param {!OrderDeleteResponse} value OrderDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDeleteResponse serialization failed!')
    console.assert(this.OrderDeleteResponseModel.verify(), 'storage.OrderDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDeleteAllRequest value
   * @this {!Sender}
   * @param {!OrderDeleteAllRequest} value OrderDeleteAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDeleteAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDeleteAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDeleteAllRequest serialization failed!')
    console.assert(this.OrderDeleteAllRequestModel.verify(), 'storage.OrderDeleteAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDeleteAllResponse value
   * @this {!Sender}
   * @param {!OrderDeleteAllResponse} value OrderDeleteAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDeleteAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDeleteAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDeleteAllResponse serialization failed!')
    console.assert(this.OrderDeleteAllResponseModel.verify(), 'storage.OrderDeleteAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInsertRequest value
   * @this {!Sender}
   * @param {!PositionInsertRequest} value PositionInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInsertRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionInsertRequest serialization failed!')
    console.assert(this.PositionInsertRequestModel.verify(), 'storage.PositionInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInsertResponse value
   * @this {!Sender}
   * @param {!PositionInsertResponse} value PositionInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInsertResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionInsertResponse serialization failed!')
    console.assert(this.PositionInsertResponseModel.verify(), 'storage.PositionInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionUpdateRequest value
   * @this {!Sender}
   * @param {!PositionUpdateRequest} value PositionUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionUpdateRequest serialization failed!')
    console.assert(this.PositionUpdateRequestModel.verify(), 'storage.PositionUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionUpdateResponse value
   * @this {!Sender}
   * @param {!PositionUpdateResponse} value PositionUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionUpdateResponse serialization failed!')
    console.assert(this.PositionUpdateResponseModel.verify(), 'storage.PositionUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionDeleteRequest value
   * @this {!Sender}
   * @param {!PositionDeleteRequest} value PositionDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionDeleteRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionDeleteRequest serialization failed!')
    console.assert(this.PositionDeleteRequestModel.verify(), 'storage.PositionDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionDeleteResponse value
   * @this {!Sender}
   * @param {!PositionDeleteResponse} value PositionDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionDeleteResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionDeleteResponse serialization failed!')
    console.assert(this.PositionDeleteResponseModel.verify(), 'storage.PositionDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryRequest value
   * @this {!Sender}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryRequest serialization failed!')
    console.assert(this.TradeHistoryRequestModel.verify(), 'storage.TradeHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryResponse value
   * @this {!Sender}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryResponse serialization failed!')
    console.assert(this.TradeHistoryResponseModel.verify(), 'storage.TradeHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetRequest value
   * @this {!Sender}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsGetRequest serialization failed!')
    console.assert(this.TradeEventsGetRequestModel.verify(), 'storage.TradeEventsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetResponse value
   * @this {!Sender}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsGetResponse serialization failed!')
    console.assert(this.TradeEventsGetResponseModel.verify(), 'storage.TradeEventsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsNotify value
   * @this {!Sender}
   * @param {!TradeEventsNotify} value TradeEventsNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsNotifyModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsNotify serialization failed!')
    console.assert(this.TradeEventsNotifyModel.verify(), 'storage.TradeEventsNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsInsertRequest value
   * @this {!Sender}
   * @param {!TradeEventsInsertRequest} value TradeEventsInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsInsertRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsInsertRequest serialization failed!')
    console.assert(this.TradeEventsInsertRequestModel.verify(), 'storage.TradeEventsInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsInsertResponse value
   * @this {!Sender}
   * @param {!TradeEventsInsertResponse} value TradeEventsInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsInsertResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsInsertResponse serialization failed!')
    console.assert(this.TradeEventsInsertResponseModel.verify(), 'storage.TradeEventsInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetRequest value
   * @this {!Sender}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.ProfitClosedGetRequest serialization failed!')
    console.assert(this.ProfitClosedGetRequestModel.verify(), 'storage.ProfitClosedGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetResponse value
   * @this {!Sender}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.ProfitClosedGetResponse serialization failed!')
    console.assert(this.ProfitClosedGetResponseModel.verify(), 'storage.ProfitClosedGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersClosedSummariesGetRequest value
   * @this {!Sender}
   * @param {!OrdersClosedSummariesGetRequest} value OrdersClosedSummariesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersClosedSummariesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersClosedSummariesGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrdersClosedSummariesGetRequest serialization failed!')
    console.assert(this.OrdersClosedSummariesGetRequestModel.verify(), 'storage.OrdersClosedSummariesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersClosedSummariesGetResponse value
   * @this {!Sender}
   * @param {!OrdersClosedSummariesGetResponse} value OrdersClosedSummariesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersClosedSummariesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersClosedSummariesGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrdersClosedSummariesGetResponse serialization failed!')
    console.assert(this.OrdersClosedSummariesGetResponseModel.verify(), 'storage.OrdersClosedSummariesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInsert2Request value
   * @this {!Sender}
   * @param {!OrderInsert2Request} value OrderInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInsert2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderInsert2Request serialization failed!')
    console.assert(this.OrderInsert2RequestModel.verify(), 'storage.OrderInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInsert2Response value
   * @this {!Sender}
   * @param {!OrderInsert2Response} value OrderInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInsert2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderInsert2Response serialization failed!')
    console.assert(this.OrderInsert2ResponseModel.verify(), 'storage.OrderInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdate2Request value
   * @this {!Sender}
   * @param {!OrderUpdate2Request} value OrderUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdate2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdate2Request serialization failed!')
    console.assert(this.OrderUpdate2RequestModel.verify(), 'storage.OrderUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdate2Response value
   * @this {!Sender}
   * @param {!OrderUpdate2Response} value OrderUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdate2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdate2Response serialization failed!')
    console.assert(this.OrderUpdate2ResponseModel.verify(), 'storage.OrderUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDelete2Request value
   * @this {!Sender}
   * @param {!OrderDelete2Request} value OrderDelete2Request value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDelete2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDelete2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDelete2Request serialization failed!')
    console.assert(this.OrderDelete2RequestModel.verify(), 'storage.OrderDelete2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDelete2Response value
   * @this {!Sender}
   * @param {!OrderDelete2Response} value OrderDelete2Response value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDelete2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDelete2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDelete2Response serialization failed!')
    console.assert(this.OrderDelete2ResponseModel.verify(), 'storage.OrderDelete2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInsert2Request value
   * @this {!Sender}
   * @param {!AccountInsert2Request} value AccountInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInsert2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountInsert2Request serialization failed!')
    console.assert(this.AccountInsert2RequestModel.verify(), 'storage.AccountInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInsert2Response value
   * @this {!Sender}
   * @param {!AccountInsert2Response} value AccountInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInsert2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountInsert2Response serialization failed!')
    console.assert(this.AccountInsert2ResponseModel.verify(), 'storage.AccountInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterInsert2Request value
   * @this {!Sender}
   * @param {!AccountSocialTradingMasterInsert2Request} value AccountSocialTradingMasterInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterInsert2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingMasterInsert2Request serialization failed!')
    console.assert(this.AccountSocialTradingMasterInsert2RequestModel.verify(), 'storage.AccountSocialTradingMasterInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterInsert2Response value
   * @this {!Sender}
   * @param {!AccountSocialTradingMasterInsert2Response} value AccountSocialTradingMasterInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterInsert2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingMasterInsert2Response serialization failed!')
    console.assert(this.AccountSocialTradingMasterInsert2ResponseModel.verify(), 'storage.AccountSocialTradingMasterInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorInsert2Request value
   * @this {!Sender}
   * @param {!AccountSocialTradingInvestorInsert2Request} value AccountSocialTradingInvestorInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorInsert2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingInvestorInsert2Request serialization failed!')
    console.assert(this.AccountSocialTradingInvestorInsert2RequestModel.verify(), 'storage.AccountSocialTradingInvestorInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorInsert2Response value
   * @this {!Sender}
   * @param {!AccountSocialTradingInvestorInsert2Response} value AccountSocialTradingInvestorInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorInsert2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingInvestorInsert2Response serialization failed!')
    console.assert(this.AccountSocialTradingInvestorInsert2ResponseModel.verify(), 'storage.AccountSocialTradingInvestorInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDelete2Request value
   * @this {!Sender}
   * @param {!AccountDelete2Request} value AccountDelete2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDelete2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDelete2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountDelete2Request serialization failed!')
    console.assert(this.AccountDelete2RequestModel.verify(), 'storage.AccountDelete2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDelete2Response value
   * @this {!Sender}
   * @param {!AccountDelete2Response} value AccountDelete2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDelete2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDelete2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountDelete2Response serialization failed!')
    console.assert(this.AccountDelete2ResponseModel.verify(), 'storage.AccountDelete2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInsert2Request value
   * @this {!Sender}
   * @param {!AssetInsert2Request} value AssetInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInsert2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetInsert2Request serialization failed!')
    console.assert(this.AssetInsert2RequestModel.verify(), 'storage.AssetInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInsert2Response value
   * @this {!Sender}
   * @param {!AssetInsert2Response} value AssetInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInsert2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetInsert2Response serialization failed!')
    console.assert(this.AssetInsert2ResponseModel.verify(), 'storage.AssetInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetUpdate2Request value
   * @this {!Sender}
   * @param {!AssetUpdate2Request} value AssetUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AssetUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetUpdate2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetUpdate2Request serialization failed!')
    console.assert(this.AssetUpdate2RequestModel.verify(), 'storage.AssetUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetUpdate2Response value
   * @this {!Sender}
   * @param {!AssetUpdate2Response} value AssetUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AssetUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetUpdate2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetUpdate2Response serialization failed!')
    console.assert(this.AssetUpdate2ResponseModel.verify(), 'storage.AssetUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetDelete2Request value
   * @this {!Sender}
   * @param {!AssetDelete2Request} value AssetDelete2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AssetDelete2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetDelete2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetDelete2Request serialization failed!')
    console.assert(this.AssetDelete2RequestModel.verify(), 'storage.AssetDelete2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetDelete2Response value
   * @this {!Sender}
   * @param {!AssetDelete2Response} value AssetDelete2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AssetDelete2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetDelete2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetDelete2Response serialization failed!')
    console.assert(this.AssetDelete2ResponseModel.verify(), 'storage.AssetDelete2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInsert2Request value
   * @this {!Sender}
   * @param {!PositionInsert2Request} value PositionInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInsert2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionInsert2Request serialization failed!')
    console.assert(this.PositionInsert2RequestModel.verify(), 'storage.PositionInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInsert2Response value
   * @this {!Sender}
   * @param {!PositionInsert2Response} value PositionInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInsert2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionInsert2Response serialization failed!')
    console.assert(this.PositionInsert2ResponseModel.verify(), 'storage.PositionInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionUpdate2Request value
   * @this {!Sender}
   * @param {!PositionUpdate2Request} value PositionUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_PositionUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionUpdate2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionUpdate2Request serialization failed!')
    console.assert(this.PositionUpdate2RequestModel.verify(), 'storage.PositionUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionUpdate2Response value
   * @this {!Sender}
   * @param {!PositionUpdate2Response} value PositionUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_PositionUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionUpdate2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionUpdate2Response serialization failed!')
    console.assert(this.PositionUpdate2ResponseModel.verify(), 'storage.PositionUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionDelete2Request value
   * @this {!Sender}
   * @param {!PositionDelete2Request} value PositionDelete2Request value to send
   * @returns {!number} Sent bytes
   */
  send_PositionDelete2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionDelete2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionDelete2Request serialization failed!')
    console.assert(this.PositionDelete2RequestModel.verify(), 'storage.PositionDelete2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionDelete2Response value
   * @this {!Sender}
   * @param {!PositionDelete2Response} value PositionDelete2Response value to send
   * @returns {!number} Sent bytes
   */
  send_PositionDelete2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionDelete2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionDelete2Response serialization failed!')
    console.assert(this.PositionDelete2ResponseModel.verify(), 'storage.PositionDelete2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated001 value
   * @this {!Sender}
   * @param {!Deprecated001} value Deprecated001 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated001 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated001Model.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated001 serialization failed!')
    console.assert(this.Deprecated001Model.verify(), 'storage.Deprecated001 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated002 value
   * @this {!Sender}
   * @param {!Deprecated002} value Deprecated002 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated002 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated002Model.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated002 serialization failed!')
    console.assert(this.Deprecated002Model.verify(), 'storage.Deprecated002 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated003 value
   * @this {!Sender}
   * @param {!Deprecated003} value Deprecated003 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated003 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated003Model.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated003 serialization failed!')
    console.assert(this.Deprecated003Model.verify(), 'storage.Deprecated003 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated004 value
   * @this {!Sender}
   * @param {!Deprecated004} value Deprecated004 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated004 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated004Model.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated004 serialization failed!')
    console.assert(this.Deprecated004Model.verify(), 'storage.Deprecated004 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated005 value
   * @this {!Sender}
   * @param {!Deprecated005} value Deprecated005 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated005 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated005Model.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated005 serialization failed!')
    console.assert(this.Deprecated005Model.verify(), 'storage.Deprecated005 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated006 value
   * @this {!Sender}
   * @param {!Deprecated006} value Deprecated006 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated006 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated006Model.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated006 serialization failed!')
    console.assert(this.Deprecated006Model.verify(), 'storage.Deprecated006 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsInsert2Request value
   * @this {!Sender}
   * @param {!TradeEventsInsert2Request} value TradeEventsInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsInsert2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsInsert2Request serialization failed!')
    console.assert(this.TradeEventsInsert2RequestModel.verify(), 'storage.TradeEventsInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsInsert2Response value
   * @this {!Sender}
   * @param {!TradeEventsInsert2Response} value TradeEventsInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsInsert2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsInsert2Response serialization failed!')
    console.assert(this.TradeEventsInsert2ResponseModel.verify(), 'storage.TradeEventsInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdate2Request value
   * @this {!Sender}
   * @param {!MarginUpdate2Request} value MarginUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdate2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginUpdate2Request serialization failed!')
    console.assert(this.MarginUpdate2RequestModel.verify(), 'storage.MarginUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdate2Response value
   * @this {!Sender}
   * @param {!MarginUpdate2Response} value MarginUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdate2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginUpdate2Response serialization failed!')
    console.assert(this.MarginUpdate2ResponseModel.verify(), 'storage.MarginUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdate2Request value
   * @this {!Sender}
   * @param {!AccountUpdate2Request} value AccountUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdate2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountUpdate2Request serialization failed!')
    console.assert(this.AccountUpdate2RequestModel.verify(), 'storage.AccountUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdate2Response value
   * @this {!Sender}
   * @param {!AccountUpdate2Response} value AccountUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdate2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountUpdate2Response serialization failed!')
    console.assert(this.AccountUpdate2ResponseModel.verify(), 'storage.AccountUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositMarginRequest value
   * @this {!Sender}
   * @param {!DepositMarginRequest} value DepositMarginRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DepositMarginRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositMarginRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.DepositMarginRequest serialization failed!')
    console.assert(this.DepositMarginRequestModel.verify(), 'storage.DepositMarginRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositMarginResponse value
   * @this {!Sender}
   * @param {!DepositMarginResponse} value DepositMarginResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DepositMarginResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositMarginResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.DepositMarginResponse serialization failed!')
    console.assert(this.DepositMarginResponseModel.verify(), 'storage.DepositMarginResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositMargin2Request value
   * @this {!Sender}
   * @param {!DepositMargin2Request} value DepositMargin2Request value to send
   * @returns {!number} Sent bytes
   */
  send_DepositMargin2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositMargin2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.DepositMargin2Request serialization failed!')
    console.assert(this.DepositMargin2RequestModel.verify(), 'storage.DepositMargin2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositMargin2Response value
   * @this {!Sender}
   * @param {!DepositMargin2Response} value DepositMargin2Response value to send
   * @returns {!number} Sent bytes
   */
  send_DepositMargin2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositMargin2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.DepositMargin2Response serialization failed!')
    console.assert(this.DepositMargin2ResponseModel.verify(), 'storage.DepositMargin2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StoreClientLoginSuccessRequest value
   * @this {!Sender}
   * @param {!StoreClientLoginSuccessRequest} value StoreClientLoginSuccessRequest value to send
   * @returns {!number} Sent bytes
   */
  send_StoreClientLoginSuccessRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StoreClientLoginSuccessRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.StoreClientLoginSuccessRequest serialization failed!')
    console.assert(this.StoreClientLoginSuccessRequestModel.verify(), 'storage.StoreClientLoginSuccessRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StoreClientLoginSuccessResponse value
   * @this {!Sender}
   * @param {!StoreClientLoginSuccessResponse} value StoreClientLoginSuccessResponse value to send
   * @returns {!number} Sent bytes
   */
  send_StoreClientLoginSuccessResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StoreClientLoginSuccessResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.StoreClientLoginSuccessResponse serialization failed!')
    console.assert(this.StoreClientLoginSuccessResponseModel.verify(), 'storage.StoreClientLoginSuccessResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientLoginInfoGetRequest value
   * @this {!Sender}
   * @param {!ClientLoginInfoGetRequest} value ClientLoginInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientLoginInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientLoginInfoGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.ClientLoginInfoGetRequest serialization failed!')
    console.assert(this.ClientLoginInfoGetRequestModel.verify(), 'storage.ClientLoginInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientLoginInfoGetResponse value
   * @this {!Sender}
   * @param {!ClientLoginInfoGetResponse} value ClientLoginInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientLoginInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientLoginInfoGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.ClientLoginInfoGetResponse serialization failed!')
    console.assert(this.ClientLoginInfoGetResponseModel.verify(), 'storage.ClientLoginInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientLoginInfoNotify value
   * @this {!Sender}
   * @param {!ClientLoginInfoNotify} value ClientLoginInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ClientLoginInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientLoginInfoNotifyModel.serialize(value)
    console.assert((serialized > 0), 'storage.ClientLoginInfoNotify serialization failed!')
    console.assert(this.ClientLoginInfoNotifyModel.verify(), 'storage.ClientLoginInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StoreClientLoginSuccess2Request value
   * @this {!Sender}
   * @param {!StoreClientLoginSuccess2Request} value StoreClientLoginSuccess2Request value to send
   * @returns {!number} Sent bytes
   */
  send_StoreClientLoginSuccess2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StoreClientLoginSuccess2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.StoreClientLoginSuccess2Request serialization failed!')
    console.assert(this.StoreClientLoginSuccess2RequestModel.verify(), 'storage.StoreClientLoginSuccess2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StoreClientLoginSuccess2Response value
   * @this {!Sender}
   * @param {!StoreClientLoginSuccess2Response} value StoreClientLoginSuccess2Response value to send
   * @returns {!number} Sent bytes
   */
  send_StoreClientLoginSuccess2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StoreClientLoginSuccess2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.StoreClientLoginSuccess2Response serialization failed!')
    console.assert(this.StoreClientLoginSuccess2ResponseModel.verify(), 'storage.StoreClientLoginSuccess2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithTypeRequest value
   * @this {!Sender}
   * @param {!OrderUpdateWithTypeRequest} value OrderUpdateWithTypeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithTypeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithTypeRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateWithTypeRequest serialization failed!')
    console.assert(this.OrderUpdateWithTypeRequestModel.verify(), 'storage.OrderUpdateWithTypeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithTypeResponse value
   * @this {!Sender}
   * @param {!OrderUpdateWithTypeResponse} value OrderUpdateWithTypeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithTypeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithTypeResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateWithTypeResponse serialization failed!')
    console.assert(this.OrderUpdateWithTypeResponseModel.verify(), 'storage.OrderUpdateWithTypeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithType2Request value
   * @this {!Sender}
   * @param {!OrderUpdateWithType2Request} value OrderUpdateWithType2Request value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithType2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithType2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateWithType2Request serialization failed!')
    console.assert(this.OrderUpdateWithType2RequestModel.verify(), 'storage.OrderUpdateWithType2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithType2Response value
   * @this {!Sender}
   * @param {!OrderUpdateWithType2Response} value OrderUpdateWithType2Response value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithType2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithType2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateWithType2Response serialization failed!')
    console.assert(this.OrderUpdateWithType2ResponseModel.verify(), 'storage.OrderUpdateWithType2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryTimeRangeGetRequest value
   * @this {!Sender}
   * @param {!TradeHistoryTimeRangeGetRequest} value TradeHistoryTimeRangeGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryTimeRangeGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryTimeRangeGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryTimeRangeGetRequest serialization failed!')
    console.assert(this.TradeHistoryTimeRangeGetRequestModel.verify(), 'storage.TradeHistoryTimeRangeGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryTimeRangeGetResponse value
   * @this {!Sender}
   * @param {!TradeHistoryTimeRangeGetResponse} value TradeHistoryTimeRangeGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryTimeRangeGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryTimeRangeGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryTimeRangeGetResponse serialization failed!')
    console.assert(this.TradeHistoryTimeRangeGetResponseModel.verify(), 'storage.TradeHistoryTimeRangeGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsTimeRangeGetRequest value
   * @this {!Sender}
   * @param {!TradeEventsTimeRangeGetRequest} value TradeEventsTimeRangeGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsTimeRangeGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsTimeRangeGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsTimeRangeGetRequest serialization failed!')
    console.assert(this.TradeEventsTimeRangeGetRequestModel.verify(), 'storage.TradeEventsTimeRangeGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsTimeRangeGetResponse value
   * @this {!Sender}
   * @param {!TradeEventsTimeRangeGetResponse} value TradeEventsTimeRangeGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsTimeRangeGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsTimeRangeGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsTimeRangeGetResponse serialization failed!')
    console.assert(this.TradeEventsTimeRangeGetResponseModel.verify(), 'storage.TradeEventsTimeRangeGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdRequest value
   * @this {!Sender}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryByIdRequest serialization failed!')
    console.assert(this.TradeHistoryByIdRequestModel.verify(), 'storage.TradeHistoryByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdResponse value
   * @this {!Sender}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryByIdResponse serialization failed!')
    console.assert(this.TradeHistoryByIdResponseModel.verify(), 'storage.TradeHistoryByIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllRequest value
   * @this {!Sender}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrdersGetAllRequest serialization failed!')
    console.assert(this.OrdersGetAllRequestModel.verify(), 'storage.OrdersGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllResponse value
   * @this {!Sender}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrdersGetAllResponse serialization failed!')
    console.assert(this.OrdersGetAllResponseModel.verify(), 'storage.OrdersGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllRequest value
   * @this {!Sender}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionsGetAllRequest serialization failed!')
    console.assert(this.PositionsGetAllRequestModel.verify(), 'storage.PositionsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllResponse value
   * @this {!Sender}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionsGetAllResponse serialization failed!')
    console.assert(this.PositionsGetAllResponseModel.verify(), 'storage.PositionsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllRequest value
   * @this {!Sender}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetsGetAllRequest serialization failed!')
    console.assert(this.AssetsGetAllRequestModel.verify(), 'storage.AssetsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllResponse value
   * @this {!Sender}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetsGetAllResponse serialization failed!')
    console.assert(this.AssetsGetAllResponseModel.verify(), 'storage.AssetsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllRequest value
   * @this {!Sender}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginsGetAllRequest serialization failed!')
    console.assert(this.MarginsGetAllRequestModel.verify(), 'storage.MarginsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllResponse value
   * @this {!Sender}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginsGetAllResponse serialization failed!')
    console.assert(this.MarginsGetAllResponseModel.verify(), 'storage.MarginsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetAllRequest value
   * @this {!Sender}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountsGetAllRequest serialization failed!')
    console.assert(this.AccountsGetAllRequestModel.verify(), 'storage.AccountsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetAllResponse value
   * @this {!Sender}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountsGetAllResponse serialization failed!')
    console.assert(this.AccountsGetAllResponseModel.verify(), 'storage.AccountsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MassRequest value
   * @this {!Sender}
   * @param {!MassRequest} value MassRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MassRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MassRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.MassRequest serialization failed!')
    console.assert(this.MassRequestModel.verify(), 'storage.MassRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MassResponse value
   * @this {!Sender}
   * @param {!MassResponse} value MassResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MassResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MassResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.MassResponse serialization failed!')
    console.assert(this.MassResponseModel.verify(), 'storage.MassResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Mass2Request value
   * @this {!Sender}
   * @param {!Mass2Request} value Mass2Request value to send
   * @returns {!number} Sent bytes
   */
  send_Mass2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Mass2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.Mass2Request serialization failed!')
    console.assert(this.Mass2RequestModel.verify(), 'storage.Mass2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Mass2ResponseParams value
   * @this {!Sender}
   * @param {!Mass2ResponseParams} value Mass2ResponseParams value to send
   * @returns {!number} Sent bytes
   */
  send_Mass2ResponseParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Mass2ResponseParamsModel.serialize(value)
    console.assert((serialized > 0), 'storage.Mass2ResponseParams serialization failed!')
    console.assert(this.Mass2ResponseParamsModel.verify(), 'storage.Mass2ResponseParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Mass2Response value
   * @this {!Sender}
   * @param {!Mass2Response} value Mass2Response value to send
   * @returns {!number} Sent bytes
   */
  send_Mass2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Mass2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.Mass2Response serialization failed!')
    console.assert(this.Mass2ResponseModel.verify(), 'storage.Mass2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdateNotify value
   * @this {!Sender}
   * @param {!MarginUpdateNotify} value MarginUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginUpdateNotify serialization failed!')
    console.assert(this.MarginUpdateNotifyModel.verify(), 'storage.MarginUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllRequest value
   * @this {!Sender}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsGetAllRequest serialization failed!')
    console.assert(this.PersonsGetAllRequestModel.verify(), 'storage.PersonsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllResponse value
   * @this {!Sender}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsGetAllResponse serialization failed!')
    console.assert(this.PersonsGetAllResponseModel.verify(), 'storage.PersonsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsertRequest value
   * @this {!Sender}
   * @param {!PersonsInsertRequest} value PersonsInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsertRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsInsertRequest serialization failed!')
    console.assert(this.PersonsInsertRequestModel.verify(), 'storage.PersonsInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsertResponse value
   * @this {!Sender}
   * @param {!PersonsInsertResponse} value PersonsInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsertResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsInsertResponse serialization failed!')
    console.assert(this.PersonsInsertResponseModel.verify(), 'storage.PersonsInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdateRequest value
   * @this {!Sender}
   * @param {!PersonUpdateRequest} value PersonUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonUpdateRequest serialization failed!')
    console.assert(this.PersonUpdateRequestModel.verify(), 'storage.PersonUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdateResponse value
   * @this {!Sender}
   * @param {!PersonUpdateResponse} value PersonUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonUpdateResponse serialization failed!')
    console.assert(this.PersonUpdateResponseModel.verify(), 'storage.PersonUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsert2Request value
   * @this {!Sender}
   * @param {!PersonsInsert2Request} value PersonsInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsert2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsInsert2Request serialization failed!')
    console.assert(this.PersonsInsert2RequestModel.verify(), 'storage.PersonsInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsert2Response value
   * @this {!Sender}
   * @param {!PersonsInsert2Response} value PersonsInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsert2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsInsert2Response serialization failed!')
    console.assert(this.PersonsInsert2ResponseModel.verify(), 'storage.PersonsInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdate2Request value
   * @this {!Sender}
   * @param {!PersonUpdate2Request} value PersonUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdate2RequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonUpdate2Request serialization failed!')
    console.assert(this.PersonUpdate2RequestModel.verify(), 'storage.PersonUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdate2Response value
   * @this {!Sender}
   * @param {!PersonUpdate2Response} value PersonUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdate2ResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonUpdate2Response serialization failed!')
    console.assert(this.PersonUpdate2ResponseModel.verify(), 'storage.PersonUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LastIdsGetRequest value
   * @this {!Sender}
   * @param {!LastIdsGetRequest} value LastIdsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LastIdsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LastIdsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.LastIdsGetRequest serialization failed!')
    console.assert(this.LastIdsGetRequestModel.verify(), 'storage.LastIdsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LastIdsGetResponse value
   * @this {!Sender}
   * @param {!LastIdsGetResponse} value LastIdsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LastIdsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LastIdsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.LastIdsGetResponse serialization failed!')
    console.assert(this.LastIdsGetResponseModel.verify(), 'storage.LastIdsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DailySnapshotRequest value
   * @this {!Sender}
   * @param {!DailySnapshotRequest} value DailySnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DailySnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DailySnapshotRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.DailySnapshotRequest serialization failed!')
    console.assert(this.DailySnapshotRequestModel.verify(), 'storage.DailySnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DailySnapshotResponse value
   * @this {!Sender}
   * @param {!DailySnapshotResponse} value DailySnapshotResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DailySnapshotResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DailySnapshotResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.DailySnapshotResponse serialization failed!')
    console.assert(this.DailySnapshotResponseModel.verify(), 'storage.DailySnapshotResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BillingStatisticsGetRequest value
   * @this {!Sender}
   * @param {!BillingStatisticsGetRequest} value BillingStatisticsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BillingStatisticsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BillingStatisticsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'storage.BillingStatisticsGetRequest serialization failed!')
    console.assert(this.BillingStatisticsGetRequestModel.verify(), 'storage.BillingStatisticsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BillingStatisticsGetResponse value
   * @this {!Sender}
   * @param {!BillingStatisticsGetResponse} value BillingStatisticsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BillingStatisticsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BillingStatisticsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'storage.BillingStatisticsGetResponse serialization failed!')
    console.assert(this.BillingStatisticsGetResponseModel.verify(), 'storage.BillingStatisticsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'storage.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
    this._innerSender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
    this._innerSender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding storage receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize storage receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._innerReceiver = new inner.Receiver(this.buffer)
    this._StorageVersionRequestValue = new StorageVersionRequest()
    this._StorageVersionRequestModel = new StorageVersionRequestModel()
    this._StorageVersionResponseValue = new StorageVersionResponse()
    this._StorageVersionResponseModel = new StorageVersionResponseModel()
    this._StorageUpdateNotifyValue = new StorageUpdateNotify()
    this._StorageUpdateNotifyModel = new StorageUpdateNotifyModel()
    this._AccountInsertRequestValue = new AccountInsertRequest()
    this._AccountInsertRequestModel = new AccountInsertRequestModel()
    this._AccountInsertResponseValue = new AccountInsertResponse()
    this._AccountInsertResponseModel = new AccountInsertResponseModel()
    this._AccountSocialTradingMasterInsertRequestValue = new AccountSocialTradingMasterInsertRequest()
    this._AccountSocialTradingMasterInsertRequestModel = new AccountSocialTradingMasterInsertRequestModel()
    this._AccountSocialTradingMasterInsertResponseValue = new AccountSocialTradingMasterInsertResponse()
    this._AccountSocialTradingMasterInsertResponseModel = new AccountSocialTradingMasterInsertResponseModel()
    this._AccountSocialTradingInvestorInsertRequestValue = new AccountSocialTradingInvestorInsertRequest()
    this._AccountSocialTradingInvestorInsertRequestModel = new AccountSocialTradingInvestorInsertRequestModel()
    this._AccountSocialTradingInvestorInsertResponseValue = new AccountSocialTradingInvestorInsertResponse()
    this._AccountSocialTradingInvestorInsertResponseModel = new AccountSocialTradingInvestorInsertResponseModel()
    this._AccountUpdateRequestValue = new AccountUpdateRequest()
    this._AccountUpdateRequestModel = new AccountUpdateRequestModel()
    this._AccountUpdateResponseValue = new AccountUpdateResponse()
    this._AccountUpdateResponseModel = new AccountUpdateResponseModel()
    this._AccountDeleteRequestValue = new AccountDeleteRequest()
    this._AccountDeleteRequestModel = new AccountDeleteRequestModel()
    this._AccountDeleteResponseValue = new AccountDeleteResponse()
    this._AccountDeleteResponseModel = new AccountDeleteResponseModel()
    this._MarginUpdateRequestValue = new MarginUpdateRequest()
    this._MarginUpdateRequestModel = new MarginUpdateRequestModel()
    this._MarginUpdateResponseValue = new MarginUpdateResponse()
    this._MarginUpdateResponseModel = new MarginUpdateResponseModel()
    this._AssetInsertRequestValue = new AssetInsertRequest()
    this._AssetInsertRequestModel = new AssetInsertRequestModel()
    this._AssetInsertResponseValue = new AssetInsertResponse()
    this._AssetInsertResponseModel = new AssetInsertResponseModel()
    this._AssetUpdateRequestValue = new AssetUpdateRequest()
    this._AssetUpdateRequestModel = new AssetUpdateRequestModel()
    this._AssetUpdateResponseValue = new AssetUpdateResponse()
    this._AssetUpdateResponseModel = new AssetUpdateResponseModel()
    this._AssetDeleteRequestValue = new AssetDeleteRequest()
    this._AssetDeleteRequestModel = new AssetDeleteRequestModel()
    this._AssetDeleteResponseValue = new AssetDeleteResponse()
    this._AssetDeleteResponseModel = new AssetDeleteResponseModel()
    this._OrderInsertRequestValue = new OrderInsertRequest()
    this._OrderInsertRequestModel = new OrderInsertRequestModel()
    this._OrderInsertResponseValue = new OrderInsertResponse()
    this._OrderInsertResponseModel = new OrderInsertResponseModel()
    this._OrderUpdateRequestValue = new OrderUpdateRequest()
    this._OrderUpdateRequestModel = new OrderUpdateRequestModel()
    this._OrderUpdateResponseValue = new OrderUpdateResponse()
    this._OrderUpdateResponseModel = new OrderUpdateResponseModel()
    this._OrderDeleteRequestValue = new OrderDeleteRequest()
    this._OrderDeleteRequestModel = new OrderDeleteRequestModel()
    this._OrderDeleteResponseValue = new OrderDeleteResponse()
    this._OrderDeleteResponseModel = new OrderDeleteResponseModel()
    this._OrderDeleteAllRequestValue = new OrderDeleteAllRequest()
    this._OrderDeleteAllRequestModel = new OrderDeleteAllRequestModel()
    this._OrderDeleteAllResponseValue = new OrderDeleteAllResponse()
    this._OrderDeleteAllResponseModel = new OrderDeleteAllResponseModel()
    this._PositionInsertRequestValue = new PositionInsertRequest()
    this._PositionInsertRequestModel = new PositionInsertRequestModel()
    this._PositionInsertResponseValue = new PositionInsertResponse()
    this._PositionInsertResponseModel = new PositionInsertResponseModel()
    this._PositionUpdateRequestValue = new PositionUpdateRequest()
    this._PositionUpdateRequestModel = new PositionUpdateRequestModel()
    this._PositionUpdateResponseValue = new PositionUpdateResponse()
    this._PositionUpdateResponseModel = new PositionUpdateResponseModel()
    this._PositionDeleteRequestValue = new PositionDeleteRequest()
    this._PositionDeleteRequestModel = new PositionDeleteRequestModel()
    this._PositionDeleteResponseValue = new PositionDeleteResponse()
    this._PositionDeleteResponseModel = new PositionDeleteResponseModel()
    this._TradeHistoryRequestValue = new TradeHistoryRequest()
    this._TradeHistoryRequestModel = new TradeHistoryRequestModel()
    this._TradeHistoryResponseValue = new TradeHistoryResponse()
    this._TradeHistoryResponseModel = new TradeHistoryResponseModel()
    this._TradeEventsGetRequestValue = new TradeEventsGetRequest()
    this._TradeEventsGetRequestModel = new TradeEventsGetRequestModel()
    this._TradeEventsGetResponseValue = new TradeEventsGetResponse()
    this._TradeEventsGetResponseModel = new TradeEventsGetResponseModel()
    this._TradeEventsNotifyValue = new TradeEventsNotify()
    this._TradeEventsNotifyModel = new TradeEventsNotifyModel()
    this._TradeEventsInsertRequestValue = new TradeEventsInsertRequest()
    this._TradeEventsInsertRequestModel = new TradeEventsInsertRequestModel()
    this._TradeEventsInsertResponseValue = new TradeEventsInsertResponse()
    this._TradeEventsInsertResponseModel = new TradeEventsInsertResponseModel()
    this._ProfitClosedGetRequestValue = new ProfitClosedGetRequest()
    this._ProfitClosedGetRequestModel = new ProfitClosedGetRequestModel()
    this._ProfitClosedGetResponseValue = new ProfitClosedGetResponse()
    this._ProfitClosedGetResponseModel = new ProfitClosedGetResponseModel()
    this._OrdersClosedSummariesGetRequestValue = new OrdersClosedSummariesGetRequest()
    this._OrdersClosedSummariesGetRequestModel = new OrdersClosedSummariesGetRequestModel()
    this._OrdersClosedSummariesGetResponseValue = new OrdersClosedSummariesGetResponse()
    this._OrdersClosedSummariesGetResponseModel = new OrdersClosedSummariesGetResponseModel()
    this._OrderInsert2RequestValue = new OrderInsert2Request()
    this._OrderInsert2RequestModel = new OrderInsert2RequestModel()
    this._OrderInsert2ResponseValue = new OrderInsert2Response()
    this._OrderInsert2ResponseModel = new OrderInsert2ResponseModel()
    this._OrderUpdate2RequestValue = new OrderUpdate2Request()
    this._OrderUpdate2RequestModel = new OrderUpdate2RequestModel()
    this._OrderUpdate2ResponseValue = new OrderUpdate2Response()
    this._OrderUpdate2ResponseModel = new OrderUpdate2ResponseModel()
    this._OrderDelete2RequestValue = new OrderDelete2Request()
    this._OrderDelete2RequestModel = new OrderDelete2RequestModel()
    this._OrderDelete2ResponseValue = new OrderDelete2Response()
    this._OrderDelete2ResponseModel = new OrderDelete2ResponseModel()
    this._AccountInsert2RequestValue = new AccountInsert2Request()
    this._AccountInsert2RequestModel = new AccountInsert2RequestModel()
    this._AccountInsert2ResponseValue = new AccountInsert2Response()
    this._AccountInsert2ResponseModel = new AccountInsert2ResponseModel()
    this._AccountSocialTradingMasterInsert2RequestValue = new AccountSocialTradingMasterInsert2Request()
    this._AccountSocialTradingMasterInsert2RequestModel = new AccountSocialTradingMasterInsert2RequestModel()
    this._AccountSocialTradingMasterInsert2ResponseValue = new AccountSocialTradingMasterInsert2Response()
    this._AccountSocialTradingMasterInsert2ResponseModel = new AccountSocialTradingMasterInsert2ResponseModel()
    this._AccountSocialTradingInvestorInsert2RequestValue = new AccountSocialTradingInvestorInsert2Request()
    this._AccountSocialTradingInvestorInsert2RequestModel = new AccountSocialTradingInvestorInsert2RequestModel()
    this._AccountSocialTradingInvestorInsert2ResponseValue = new AccountSocialTradingInvestorInsert2Response()
    this._AccountSocialTradingInvestorInsert2ResponseModel = new AccountSocialTradingInvestorInsert2ResponseModel()
    this._AccountDelete2RequestValue = new AccountDelete2Request()
    this._AccountDelete2RequestModel = new AccountDelete2RequestModel()
    this._AccountDelete2ResponseValue = new AccountDelete2Response()
    this._AccountDelete2ResponseModel = new AccountDelete2ResponseModel()
    this._AssetInsert2RequestValue = new AssetInsert2Request()
    this._AssetInsert2RequestModel = new AssetInsert2RequestModel()
    this._AssetInsert2ResponseValue = new AssetInsert2Response()
    this._AssetInsert2ResponseModel = new AssetInsert2ResponseModel()
    this._AssetUpdate2RequestValue = new AssetUpdate2Request()
    this._AssetUpdate2RequestModel = new AssetUpdate2RequestModel()
    this._AssetUpdate2ResponseValue = new AssetUpdate2Response()
    this._AssetUpdate2ResponseModel = new AssetUpdate2ResponseModel()
    this._AssetDelete2RequestValue = new AssetDelete2Request()
    this._AssetDelete2RequestModel = new AssetDelete2RequestModel()
    this._AssetDelete2ResponseValue = new AssetDelete2Response()
    this._AssetDelete2ResponseModel = new AssetDelete2ResponseModel()
    this._PositionInsert2RequestValue = new PositionInsert2Request()
    this._PositionInsert2RequestModel = new PositionInsert2RequestModel()
    this._PositionInsert2ResponseValue = new PositionInsert2Response()
    this._PositionInsert2ResponseModel = new PositionInsert2ResponseModel()
    this._PositionUpdate2RequestValue = new PositionUpdate2Request()
    this._PositionUpdate2RequestModel = new PositionUpdate2RequestModel()
    this._PositionUpdate2ResponseValue = new PositionUpdate2Response()
    this._PositionUpdate2ResponseModel = new PositionUpdate2ResponseModel()
    this._PositionDelete2RequestValue = new PositionDelete2Request()
    this._PositionDelete2RequestModel = new PositionDelete2RequestModel()
    this._PositionDelete2ResponseValue = new PositionDelete2Response()
    this._PositionDelete2ResponseModel = new PositionDelete2ResponseModel()
    this._Deprecated001Value = new Deprecated001()
    this._Deprecated001Model = new Deprecated001Model()
    this._Deprecated002Value = new Deprecated002()
    this._Deprecated002Model = new Deprecated002Model()
    this._Deprecated003Value = new Deprecated003()
    this._Deprecated003Model = new Deprecated003Model()
    this._Deprecated004Value = new Deprecated004()
    this._Deprecated004Model = new Deprecated004Model()
    this._Deprecated005Value = new Deprecated005()
    this._Deprecated005Model = new Deprecated005Model()
    this._Deprecated006Value = new Deprecated006()
    this._Deprecated006Model = new Deprecated006Model()
    this._TradeEventsInsert2RequestValue = new TradeEventsInsert2Request()
    this._TradeEventsInsert2RequestModel = new TradeEventsInsert2RequestModel()
    this._TradeEventsInsert2ResponseValue = new TradeEventsInsert2Response()
    this._TradeEventsInsert2ResponseModel = new TradeEventsInsert2ResponseModel()
    this._MarginUpdate2RequestValue = new MarginUpdate2Request()
    this._MarginUpdate2RequestModel = new MarginUpdate2RequestModel()
    this._MarginUpdate2ResponseValue = new MarginUpdate2Response()
    this._MarginUpdate2ResponseModel = new MarginUpdate2ResponseModel()
    this._AccountUpdate2RequestValue = new AccountUpdate2Request()
    this._AccountUpdate2RequestModel = new AccountUpdate2RequestModel()
    this._AccountUpdate2ResponseValue = new AccountUpdate2Response()
    this._AccountUpdate2ResponseModel = new AccountUpdate2ResponseModel()
    this._DepositMarginRequestValue = new DepositMarginRequest()
    this._DepositMarginRequestModel = new DepositMarginRequestModel()
    this._DepositMarginResponseValue = new DepositMarginResponse()
    this._DepositMarginResponseModel = new DepositMarginResponseModel()
    this._DepositMargin2RequestValue = new DepositMargin2Request()
    this._DepositMargin2RequestModel = new DepositMargin2RequestModel()
    this._DepositMargin2ResponseValue = new DepositMargin2Response()
    this._DepositMargin2ResponseModel = new DepositMargin2ResponseModel()
    this._StoreClientLoginSuccessRequestValue = new StoreClientLoginSuccessRequest()
    this._StoreClientLoginSuccessRequestModel = new StoreClientLoginSuccessRequestModel()
    this._StoreClientLoginSuccessResponseValue = new StoreClientLoginSuccessResponse()
    this._StoreClientLoginSuccessResponseModel = new StoreClientLoginSuccessResponseModel()
    this._ClientLoginInfoGetRequestValue = new ClientLoginInfoGetRequest()
    this._ClientLoginInfoGetRequestModel = new ClientLoginInfoGetRequestModel()
    this._ClientLoginInfoGetResponseValue = new ClientLoginInfoGetResponse()
    this._ClientLoginInfoGetResponseModel = new ClientLoginInfoGetResponseModel()
    this._ClientLoginInfoNotifyValue = new ClientLoginInfoNotify()
    this._ClientLoginInfoNotifyModel = new ClientLoginInfoNotifyModel()
    this._StoreClientLoginSuccess2RequestValue = new StoreClientLoginSuccess2Request()
    this._StoreClientLoginSuccess2RequestModel = new StoreClientLoginSuccess2RequestModel()
    this._StoreClientLoginSuccess2ResponseValue = new StoreClientLoginSuccess2Response()
    this._StoreClientLoginSuccess2ResponseModel = new StoreClientLoginSuccess2ResponseModel()
    this._OrderUpdateWithTypeRequestValue = new OrderUpdateWithTypeRequest()
    this._OrderUpdateWithTypeRequestModel = new OrderUpdateWithTypeRequestModel()
    this._OrderUpdateWithTypeResponseValue = new OrderUpdateWithTypeResponse()
    this._OrderUpdateWithTypeResponseModel = new OrderUpdateWithTypeResponseModel()
    this._OrderUpdateWithType2RequestValue = new OrderUpdateWithType2Request()
    this._OrderUpdateWithType2RequestModel = new OrderUpdateWithType2RequestModel()
    this._OrderUpdateWithType2ResponseValue = new OrderUpdateWithType2Response()
    this._OrderUpdateWithType2ResponseModel = new OrderUpdateWithType2ResponseModel()
    this._TradeHistoryTimeRangeGetRequestValue = new TradeHistoryTimeRangeGetRequest()
    this._TradeHistoryTimeRangeGetRequestModel = new TradeHistoryTimeRangeGetRequestModel()
    this._TradeHistoryTimeRangeGetResponseValue = new TradeHistoryTimeRangeGetResponse()
    this._TradeHistoryTimeRangeGetResponseModel = new TradeHistoryTimeRangeGetResponseModel()
    this._TradeEventsTimeRangeGetRequestValue = new TradeEventsTimeRangeGetRequest()
    this._TradeEventsTimeRangeGetRequestModel = new TradeEventsTimeRangeGetRequestModel()
    this._TradeEventsTimeRangeGetResponseValue = new TradeEventsTimeRangeGetResponse()
    this._TradeEventsTimeRangeGetResponseModel = new TradeEventsTimeRangeGetResponseModel()
    this._TradeHistoryByIdRequestValue = new TradeHistoryByIdRequest()
    this._TradeHistoryByIdRequestModel = new TradeHistoryByIdRequestModel()
    this._TradeHistoryByIdResponseValue = new TradeHistoryByIdResponse()
    this._TradeHistoryByIdResponseModel = new TradeHistoryByIdResponseModel()
    this._OrdersGetAllRequestValue = new OrdersGetAllRequest()
    this._OrdersGetAllRequestModel = new OrdersGetAllRequestModel()
    this._OrdersGetAllResponseValue = new OrdersGetAllResponse()
    this._OrdersGetAllResponseModel = new OrdersGetAllResponseModel()
    this._PositionsGetAllRequestValue = new PositionsGetAllRequest()
    this._PositionsGetAllRequestModel = new PositionsGetAllRequestModel()
    this._PositionsGetAllResponseValue = new PositionsGetAllResponse()
    this._PositionsGetAllResponseModel = new PositionsGetAllResponseModel()
    this._AssetsGetAllRequestValue = new AssetsGetAllRequest()
    this._AssetsGetAllRequestModel = new AssetsGetAllRequestModel()
    this._AssetsGetAllResponseValue = new AssetsGetAllResponse()
    this._AssetsGetAllResponseModel = new AssetsGetAllResponseModel()
    this._MarginsGetAllRequestValue = new MarginsGetAllRequest()
    this._MarginsGetAllRequestModel = new MarginsGetAllRequestModel()
    this._MarginsGetAllResponseValue = new MarginsGetAllResponse()
    this._MarginsGetAllResponseModel = new MarginsGetAllResponseModel()
    this._AccountsGetAllRequestValue = new AccountsGetAllRequest()
    this._AccountsGetAllRequestModel = new AccountsGetAllRequestModel()
    this._AccountsGetAllResponseValue = new AccountsGetAllResponse()
    this._AccountsGetAllResponseModel = new AccountsGetAllResponseModel()
    this._MassRequestValue = new MassRequest()
    this._MassRequestModel = new MassRequestModel()
    this._MassResponseValue = new MassResponse()
    this._MassResponseModel = new MassResponseModel()
    this._Mass2RequestValue = new Mass2Request()
    this._Mass2RequestModel = new Mass2RequestModel()
    this._Mass2ResponseParamsValue = new Mass2ResponseParams()
    this._Mass2ResponseParamsModel = new Mass2ResponseParamsModel()
    this._Mass2ResponseValue = new Mass2Response()
    this._Mass2ResponseModel = new Mass2ResponseModel()
    this._MarginUpdateNotifyValue = new MarginUpdateNotify()
    this._MarginUpdateNotifyModel = new MarginUpdateNotifyModel()
    this._PersonsGetAllRequestValue = new PersonsGetAllRequest()
    this._PersonsGetAllRequestModel = new PersonsGetAllRequestModel()
    this._PersonsGetAllResponseValue = new PersonsGetAllResponse()
    this._PersonsGetAllResponseModel = new PersonsGetAllResponseModel()
    this._PersonsInsertRequestValue = new PersonsInsertRequest()
    this._PersonsInsertRequestModel = new PersonsInsertRequestModel()
    this._PersonsInsertResponseValue = new PersonsInsertResponse()
    this._PersonsInsertResponseModel = new PersonsInsertResponseModel()
    this._PersonUpdateRequestValue = new PersonUpdateRequest()
    this._PersonUpdateRequestModel = new PersonUpdateRequestModel()
    this._PersonUpdateResponseValue = new PersonUpdateResponse()
    this._PersonUpdateResponseModel = new PersonUpdateResponseModel()
    this._PersonsInsert2RequestValue = new PersonsInsert2Request()
    this._PersonsInsert2RequestModel = new PersonsInsert2RequestModel()
    this._PersonsInsert2ResponseValue = new PersonsInsert2Response()
    this._PersonsInsert2ResponseModel = new PersonsInsert2ResponseModel()
    this._PersonUpdate2RequestValue = new PersonUpdate2Request()
    this._PersonUpdate2RequestModel = new PersonUpdate2RequestModel()
    this._PersonUpdate2ResponseValue = new PersonUpdate2Response()
    this._PersonUpdate2ResponseModel = new PersonUpdate2ResponseModel()
    this._LastIdsGetRequestValue = new LastIdsGetRequest()
    this._LastIdsGetRequestModel = new LastIdsGetRequestModel()
    this._LastIdsGetResponseValue = new LastIdsGetResponse()
    this._LastIdsGetResponseModel = new LastIdsGetResponseModel()
    this._DailySnapshotRequestValue = new DailySnapshotRequest()
    this._DailySnapshotRequestModel = new DailySnapshotRequestModel()
    this._DailySnapshotResponseValue = new DailySnapshotResponse()
    this._DailySnapshotResponseModel = new DailySnapshotResponseModel()
    this._BillingStatisticsGetRequestValue = new BillingStatisticsGetRequest()
    this._BillingStatisticsGetRequestModel = new BillingStatisticsGetRequestModel()
    this._BillingStatisticsGetResponseValue = new BillingStatisticsGetResponse()
    this._BillingStatisticsGetResponseModel = new BillingStatisticsGetResponseModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  /**
   * Get imported inner receiver
   * @this {!Receiver}
   * @returns {Receiver} inner receiver
   */
  get innerReceiver () {
    return this._innerReceiver
  }

  /**
   * Set imported inner receiver
   * @this {!Receiver}
   * @param {Receiver} receiver inner receiver
   */
  set innerReceiver (receiver) {
    this._innerReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * StorageVersionRequest receive handler
   * @this {!Receiver}
   * @param {!StorageVersionRequest} value StorageVersionRequest received value
   */
  onReceive_StorageVersionRequest (value) {}  // eslint-disable-line

  /**
   * StorageVersionResponse receive handler
   * @this {!Receiver}
   * @param {!StorageVersionResponse} value StorageVersionResponse received value
   */
  onReceive_StorageVersionResponse (value) {}  // eslint-disable-line

  /**
   * StorageUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!StorageUpdateNotify} value StorageUpdateNotify received value
   */
  onReceive_StorageUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountInsertRequest receive handler
   * @this {!Receiver}
   * @param {!AccountInsertRequest} value AccountInsertRequest received value
   */
  onReceive_AccountInsertRequest (value) {}  // eslint-disable-line

  /**
   * AccountInsertResponse receive handler
   * @this {!Receiver}
   * @param {!AccountInsertResponse} value AccountInsertResponse received value
   */
  onReceive_AccountInsertResponse (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsertRequest receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingMasterInsertRequest} value AccountSocialTradingMasterInsertRequest received value
   */
  onReceive_AccountSocialTradingMasterInsertRequest (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsertResponse receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingMasterInsertResponse} value AccountSocialTradingMasterInsertResponse received value
   */
  onReceive_AccountSocialTradingMasterInsertResponse (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsertRequest receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingInvestorInsertRequest} value AccountSocialTradingInvestorInsertRequest received value
   */
  onReceive_AccountSocialTradingInvestorInsertRequest (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsertResponse receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingInvestorInsertResponse} value AccountSocialTradingInvestorInsertResponse received value
   */
  onReceive_AccountSocialTradingInvestorInsertResponse (value) {}  // eslint-disable-line

  /**
   * AccountUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!AccountUpdateRequest} value AccountUpdateRequest received value
   */
  onReceive_AccountUpdateRequest (value) {}  // eslint-disable-line

  /**
   * AccountUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!AccountUpdateResponse} value AccountUpdateResponse received value
   */
  onReceive_AccountUpdateResponse (value) {}  // eslint-disable-line

  /**
   * AccountDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!AccountDeleteRequest} value AccountDeleteRequest received value
   */
  onReceive_AccountDeleteRequest (value) {}  // eslint-disable-line

  /**
   * AccountDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!AccountDeleteResponse} value AccountDeleteResponse received value
   */
  onReceive_AccountDeleteResponse (value) {}  // eslint-disable-line

  /**
   * MarginUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!MarginUpdateRequest} value MarginUpdateRequest received value
   */
  onReceive_MarginUpdateRequest (value) {}  // eslint-disable-line

  /**
   * MarginUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!MarginUpdateResponse} value MarginUpdateResponse received value
   */
  onReceive_MarginUpdateResponse (value) {}  // eslint-disable-line

  /**
   * AssetInsertRequest receive handler
   * @this {!Receiver}
   * @param {!AssetInsertRequest} value AssetInsertRequest received value
   */
  onReceive_AssetInsertRequest (value) {}  // eslint-disable-line

  /**
   * AssetInsertResponse receive handler
   * @this {!Receiver}
   * @param {!AssetInsertResponse} value AssetInsertResponse received value
   */
  onReceive_AssetInsertResponse (value) {}  // eslint-disable-line

  /**
   * AssetUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!AssetUpdateRequest} value AssetUpdateRequest received value
   */
  onReceive_AssetUpdateRequest (value) {}  // eslint-disable-line

  /**
   * AssetUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!AssetUpdateResponse} value AssetUpdateResponse received value
   */
  onReceive_AssetUpdateResponse (value) {}  // eslint-disable-line

  /**
   * AssetDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!AssetDeleteRequest} value AssetDeleteRequest received value
   */
  onReceive_AssetDeleteRequest (value) {}  // eslint-disable-line

  /**
   * AssetDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!AssetDeleteResponse} value AssetDeleteResponse received value
   */
  onReceive_AssetDeleteResponse (value) {}  // eslint-disable-line

  /**
   * OrderInsertRequest receive handler
   * @this {!Receiver}
   * @param {!OrderInsertRequest} value OrderInsertRequest received value
   */
  onReceive_OrderInsertRequest (value) {}  // eslint-disable-line

  /**
   * OrderInsertResponse receive handler
   * @this {!Receiver}
   * @param {!OrderInsertResponse} value OrderInsertResponse received value
   */
  onReceive_OrderInsertResponse (value) {}  // eslint-disable-line

  /**
   * OrderUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!OrderUpdateRequest} value OrderUpdateRequest received value
   */
  onReceive_OrderUpdateRequest (value) {}  // eslint-disable-line

  /**
   * OrderUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!OrderUpdateResponse} value OrderUpdateResponse received value
   */
  onReceive_OrderUpdateResponse (value) {}  // eslint-disable-line

  /**
   * OrderDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!OrderDeleteRequest} value OrderDeleteRequest received value
   */
  onReceive_OrderDeleteRequest (value) {}  // eslint-disable-line

  /**
   * OrderDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!OrderDeleteResponse} value OrderDeleteResponse received value
   */
  onReceive_OrderDeleteResponse (value) {}  // eslint-disable-line

  /**
   * OrderDeleteAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrderDeleteAllRequest} value OrderDeleteAllRequest received value
   */
  onReceive_OrderDeleteAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderDeleteAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrderDeleteAllResponse} value OrderDeleteAllResponse received value
   */
  onReceive_OrderDeleteAllResponse (value) {}  // eslint-disable-line

  /**
   * PositionInsertRequest receive handler
   * @this {!Receiver}
   * @param {!PositionInsertRequest} value PositionInsertRequest received value
   */
  onReceive_PositionInsertRequest (value) {}  // eslint-disable-line

  /**
   * PositionInsertResponse receive handler
   * @this {!Receiver}
   * @param {!PositionInsertResponse} value PositionInsertResponse received value
   */
  onReceive_PositionInsertResponse (value) {}  // eslint-disable-line

  /**
   * PositionUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!PositionUpdateRequest} value PositionUpdateRequest received value
   */
  onReceive_PositionUpdateRequest (value) {}  // eslint-disable-line

  /**
   * PositionUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!PositionUpdateResponse} value PositionUpdateResponse received value
   */
  onReceive_PositionUpdateResponse (value) {}  // eslint-disable-line

  /**
   * PositionDeleteRequest receive handler
   * @this {!Receiver}
   * @param {!PositionDeleteRequest} value PositionDeleteRequest received value
   */
  onReceive_PositionDeleteRequest (value) {}  // eslint-disable-line

  /**
   * PositionDeleteResponse receive handler
   * @this {!Receiver}
   * @param {!PositionDeleteResponse} value PositionDeleteResponse received value
   */
  onReceive_PositionDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryRequest receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest received value
   */
  onReceive_TradeHistoryRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryResponse receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse received value
   */
  onReceive_TradeHistoryResponse (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetRequest receive handler
   * @this {!Receiver}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest received value
   */
  onReceive_TradeEventsGetRequest (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetResponse receive handler
   * @this {!Receiver}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse received value
   */
  onReceive_TradeEventsGetResponse (value) {}  // eslint-disable-line

  /**
   * TradeEventsNotify receive handler
   * @this {!Receiver}
   * @param {!TradeEventsNotify} value TradeEventsNotify received value
   */
  onReceive_TradeEventsNotify (value) {}  // eslint-disable-line

  /**
   * TradeEventsInsertRequest receive handler
   * @this {!Receiver}
   * @param {!TradeEventsInsertRequest} value TradeEventsInsertRequest received value
   */
  onReceive_TradeEventsInsertRequest (value) {}  // eslint-disable-line

  /**
   * TradeEventsInsertResponse receive handler
   * @this {!Receiver}
   * @param {!TradeEventsInsertResponse} value TradeEventsInsertResponse received value
   */
  onReceive_TradeEventsInsertResponse (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetRequest receive handler
   * @this {!Receiver}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest received value
   */
  onReceive_ProfitClosedGetRequest (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetResponse receive handler
   * @this {!Receiver}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse received value
   */
  onReceive_ProfitClosedGetResponse (value) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetRequest receive handler
   * @this {!Receiver}
   * @param {!OrdersClosedSummariesGetRequest} value OrdersClosedSummariesGetRequest received value
   */
  onReceive_OrdersClosedSummariesGetRequest (value) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetResponse receive handler
   * @this {!Receiver}
   * @param {!OrdersClosedSummariesGetResponse} value OrdersClosedSummariesGetResponse received value
   */
  onReceive_OrdersClosedSummariesGetResponse (value) {}  // eslint-disable-line

  /**
   * OrderInsert2Request receive handler
   * @this {!Receiver}
   * @param {!OrderInsert2Request} value OrderInsert2Request received value
   */
  onReceive_OrderInsert2Request (value) {}  // eslint-disable-line

  /**
   * OrderInsert2Response receive handler
   * @this {!Receiver}
   * @param {!OrderInsert2Response} value OrderInsert2Response received value
   */
  onReceive_OrderInsert2Response (value) {}  // eslint-disable-line

  /**
   * OrderUpdate2Request receive handler
   * @this {!Receiver}
   * @param {!OrderUpdate2Request} value OrderUpdate2Request received value
   */
  onReceive_OrderUpdate2Request (value) {}  // eslint-disable-line

  /**
   * OrderUpdate2Response receive handler
   * @this {!Receiver}
   * @param {!OrderUpdate2Response} value OrderUpdate2Response received value
   */
  onReceive_OrderUpdate2Response (value) {}  // eslint-disable-line

  /**
   * OrderDelete2Request receive handler
   * @this {!Receiver}
   * @param {!OrderDelete2Request} value OrderDelete2Request received value
   */
  onReceive_OrderDelete2Request (value) {}  // eslint-disable-line

  /**
   * OrderDelete2Response receive handler
   * @this {!Receiver}
   * @param {!OrderDelete2Response} value OrderDelete2Response received value
   */
  onReceive_OrderDelete2Response (value) {}  // eslint-disable-line

  /**
   * AccountInsert2Request receive handler
   * @this {!Receiver}
   * @param {!AccountInsert2Request} value AccountInsert2Request received value
   */
  onReceive_AccountInsert2Request (value) {}  // eslint-disable-line

  /**
   * AccountInsert2Response receive handler
   * @this {!Receiver}
   * @param {!AccountInsert2Response} value AccountInsert2Response received value
   */
  onReceive_AccountInsert2Response (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsert2Request receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingMasterInsert2Request} value AccountSocialTradingMasterInsert2Request received value
   */
  onReceive_AccountSocialTradingMasterInsert2Request (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsert2Response receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingMasterInsert2Response} value AccountSocialTradingMasterInsert2Response received value
   */
  onReceive_AccountSocialTradingMasterInsert2Response (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsert2Request receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingInvestorInsert2Request} value AccountSocialTradingInvestorInsert2Request received value
   */
  onReceive_AccountSocialTradingInvestorInsert2Request (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsert2Response receive handler
   * @this {!Receiver}
   * @param {!AccountSocialTradingInvestorInsert2Response} value AccountSocialTradingInvestorInsert2Response received value
   */
  onReceive_AccountSocialTradingInvestorInsert2Response (value) {}  // eslint-disable-line

  /**
   * AccountDelete2Request receive handler
   * @this {!Receiver}
   * @param {!AccountDelete2Request} value AccountDelete2Request received value
   */
  onReceive_AccountDelete2Request (value) {}  // eslint-disable-line

  /**
   * AccountDelete2Response receive handler
   * @this {!Receiver}
   * @param {!AccountDelete2Response} value AccountDelete2Response received value
   */
  onReceive_AccountDelete2Response (value) {}  // eslint-disable-line

  /**
   * AssetInsert2Request receive handler
   * @this {!Receiver}
   * @param {!AssetInsert2Request} value AssetInsert2Request received value
   */
  onReceive_AssetInsert2Request (value) {}  // eslint-disable-line

  /**
   * AssetInsert2Response receive handler
   * @this {!Receiver}
   * @param {!AssetInsert2Response} value AssetInsert2Response received value
   */
  onReceive_AssetInsert2Response (value) {}  // eslint-disable-line

  /**
   * AssetUpdate2Request receive handler
   * @this {!Receiver}
   * @param {!AssetUpdate2Request} value AssetUpdate2Request received value
   */
  onReceive_AssetUpdate2Request (value) {}  // eslint-disable-line

  /**
   * AssetUpdate2Response receive handler
   * @this {!Receiver}
   * @param {!AssetUpdate2Response} value AssetUpdate2Response received value
   */
  onReceive_AssetUpdate2Response (value) {}  // eslint-disable-line

  /**
   * AssetDelete2Request receive handler
   * @this {!Receiver}
   * @param {!AssetDelete2Request} value AssetDelete2Request received value
   */
  onReceive_AssetDelete2Request (value) {}  // eslint-disable-line

  /**
   * AssetDelete2Response receive handler
   * @this {!Receiver}
   * @param {!AssetDelete2Response} value AssetDelete2Response received value
   */
  onReceive_AssetDelete2Response (value) {}  // eslint-disable-line

  /**
   * PositionInsert2Request receive handler
   * @this {!Receiver}
   * @param {!PositionInsert2Request} value PositionInsert2Request received value
   */
  onReceive_PositionInsert2Request (value) {}  // eslint-disable-line

  /**
   * PositionInsert2Response receive handler
   * @this {!Receiver}
   * @param {!PositionInsert2Response} value PositionInsert2Response received value
   */
  onReceive_PositionInsert2Response (value) {}  // eslint-disable-line

  /**
   * PositionUpdate2Request receive handler
   * @this {!Receiver}
   * @param {!PositionUpdate2Request} value PositionUpdate2Request received value
   */
  onReceive_PositionUpdate2Request (value) {}  // eslint-disable-line

  /**
   * PositionUpdate2Response receive handler
   * @this {!Receiver}
   * @param {!PositionUpdate2Response} value PositionUpdate2Response received value
   */
  onReceive_PositionUpdate2Response (value) {}  // eslint-disable-line

  /**
   * PositionDelete2Request receive handler
   * @this {!Receiver}
   * @param {!PositionDelete2Request} value PositionDelete2Request received value
   */
  onReceive_PositionDelete2Request (value) {}  // eslint-disable-line

  /**
   * PositionDelete2Response receive handler
   * @this {!Receiver}
   * @param {!PositionDelete2Response} value PositionDelete2Response received value
   */
  onReceive_PositionDelete2Response (value) {}  // eslint-disable-line

  /**
   * Deprecated001 receive handler
   * @this {!Receiver}
   * @param {!Deprecated001} value Deprecated001 received value
   */
  onReceive_Deprecated001 (value) {}  // eslint-disable-line

  /**
   * Deprecated002 receive handler
   * @this {!Receiver}
   * @param {!Deprecated002} value Deprecated002 received value
   */
  onReceive_Deprecated002 (value) {}  // eslint-disable-line

  /**
   * Deprecated003 receive handler
   * @this {!Receiver}
   * @param {!Deprecated003} value Deprecated003 received value
   */
  onReceive_Deprecated003 (value) {}  // eslint-disable-line

  /**
   * Deprecated004 receive handler
   * @this {!Receiver}
   * @param {!Deprecated004} value Deprecated004 received value
   */
  onReceive_Deprecated004 (value) {}  // eslint-disable-line

  /**
   * Deprecated005 receive handler
   * @this {!Receiver}
   * @param {!Deprecated005} value Deprecated005 received value
   */
  onReceive_Deprecated005 (value) {}  // eslint-disable-line

  /**
   * Deprecated006 receive handler
   * @this {!Receiver}
   * @param {!Deprecated006} value Deprecated006 received value
   */
  onReceive_Deprecated006 (value) {}  // eslint-disable-line

  /**
   * TradeEventsInsert2Request receive handler
   * @this {!Receiver}
   * @param {!TradeEventsInsert2Request} value TradeEventsInsert2Request received value
   */
  onReceive_TradeEventsInsert2Request (value) {}  // eslint-disable-line

  /**
   * TradeEventsInsert2Response receive handler
   * @this {!Receiver}
   * @param {!TradeEventsInsert2Response} value TradeEventsInsert2Response received value
   */
  onReceive_TradeEventsInsert2Response (value) {}  // eslint-disable-line

  /**
   * MarginUpdate2Request receive handler
   * @this {!Receiver}
   * @param {!MarginUpdate2Request} value MarginUpdate2Request received value
   */
  onReceive_MarginUpdate2Request (value) {}  // eslint-disable-line

  /**
   * MarginUpdate2Response receive handler
   * @this {!Receiver}
   * @param {!MarginUpdate2Response} value MarginUpdate2Response received value
   */
  onReceive_MarginUpdate2Response (value) {}  // eslint-disable-line

  /**
   * AccountUpdate2Request receive handler
   * @this {!Receiver}
   * @param {!AccountUpdate2Request} value AccountUpdate2Request received value
   */
  onReceive_AccountUpdate2Request (value) {}  // eslint-disable-line

  /**
   * AccountUpdate2Response receive handler
   * @this {!Receiver}
   * @param {!AccountUpdate2Response} value AccountUpdate2Response received value
   */
  onReceive_AccountUpdate2Response (value) {}  // eslint-disable-line

  /**
   * DepositMarginRequest receive handler
   * @this {!Receiver}
   * @param {!DepositMarginRequest} value DepositMarginRequest received value
   */
  onReceive_DepositMarginRequest (value) {}  // eslint-disable-line

  /**
   * DepositMarginResponse receive handler
   * @this {!Receiver}
   * @param {!DepositMarginResponse} value DepositMarginResponse received value
   */
  onReceive_DepositMarginResponse (value) {}  // eslint-disable-line

  /**
   * DepositMargin2Request receive handler
   * @this {!Receiver}
   * @param {!DepositMargin2Request} value DepositMargin2Request received value
   */
  onReceive_DepositMargin2Request (value) {}  // eslint-disable-line

  /**
   * DepositMargin2Response receive handler
   * @this {!Receiver}
   * @param {!DepositMargin2Response} value DepositMargin2Response received value
   */
  onReceive_DepositMargin2Response (value) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccessRequest receive handler
   * @this {!Receiver}
   * @param {!StoreClientLoginSuccessRequest} value StoreClientLoginSuccessRequest received value
   */
  onReceive_StoreClientLoginSuccessRequest (value) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccessResponse receive handler
   * @this {!Receiver}
   * @param {!StoreClientLoginSuccessResponse} value StoreClientLoginSuccessResponse received value
   */
  onReceive_StoreClientLoginSuccessResponse (value) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetRequest receive handler
   * @this {!Receiver}
   * @param {!ClientLoginInfoGetRequest} value ClientLoginInfoGetRequest received value
   */
  onReceive_ClientLoginInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetResponse receive handler
   * @this {!Receiver}
   * @param {!ClientLoginInfoGetResponse} value ClientLoginInfoGetResponse received value
   */
  onReceive_ClientLoginInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientLoginInfoNotify receive handler
   * @this {!Receiver}
   * @param {!ClientLoginInfoNotify} value ClientLoginInfoNotify received value
   */
  onReceive_ClientLoginInfoNotify (value) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccess2Request receive handler
   * @this {!Receiver}
   * @param {!StoreClientLoginSuccess2Request} value StoreClientLoginSuccess2Request received value
   */
  onReceive_StoreClientLoginSuccess2Request (value) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccess2Response receive handler
   * @this {!Receiver}
   * @param {!StoreClientLoginSuccess2Response} value StoreClientLoginSuccess2Response received value
   */
  onReceive_StoreClientLoginSuccess2Response (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeRequest receive handler
   * @this {!Receiver}
   * @param {!OrderUpdateWithTypeRequest} value OrderUpdateWithTypeRequest received value
   */
  onReceive_OrderUpdateWithTypeRequest (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeResponse receive handler
   * @this {!Receiver}
   * @param {!OrderUpdateWithTypeResponse} value OrderUpdateWithTypeResponse received value
   */
  onReceive_OrderUpdateWithTypeResponse (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithType2Request receive handler
   * @this {!Receiver}
   * @param {!OrderUpdateWithType2Request} value OrderUpdateWithType2Request received value
   */
  onReceive_OrderUpdateWithType2Request (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithType2Response receive handler
   * @this {!Receiver}
   * @param {!OrderUpdateWithType2Response} value OrderUpdateWithType2Response received value
   */
  onReceive_OrderUpdateWithType2Response (value) {}  // eslint-disable-line

  /**
   * TradeHistoryTimeRangeGetRequest receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryTimeRangeGetRequest} value TradeHistoryTimeRangeGetRequest received value
   */
  onReceive_TradeHistoryTimeRangeGetRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryTimeRangeGetResponse receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryTimeRangeGetResponse} value TradeHistoryTimeRangeGetResponse received value
   */
  onReceive_TradeHistoryTimeRangeGetResponse (value) {}  // eslint-disable-line

  /**
   * TradeEventsTimeRangeGetRequest receive handler
   * @this {!Receiver}
   * @param {!TradeEventsTimeRangeGetRequest} value TradeEventsTimeRangeGetRequest received value
   */
  onReceive_TradeEventsTimeRangeGetRequest (value) {}  // eslint-disable-line

  /**
   * TradeEventsTimeRangeGetResponse receive handler
   * @this {!Receiver}
   * @param {!TradeEventsTimeRangeGetResponse} value TradeEventsTimeRangeGetResponse received value
   */
  onReceive_TradeEventsTimeRangeGetResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdRequest receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest received value
   */
  onReceive_TradeHistoryByIdRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdResponse receive handler
   * @this {!Receiver}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse received value
   */
  onReceive_TradeHistoryByIdResponse (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest received value
   */
  onReceive_OrdersGetAllRequest (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse received value
   */
  onReceive_OrdersGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest received value
   */
  onReceive_PositionsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse received value
   */
  onReceive_PositionsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest received value
   */
  onReceive_AssetsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse received value
   */
  onReceive_AssetsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest received value
   */
  onReceive_MarginsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse received value
   */
  onReceive_MarginsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest received value
   */
  onReceive_AccountsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse received value
   */
  onReceive_AccountsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MassRequest receive handler
   * @this {!Receiver}
   * @param {!MassRequest} value MassRequest received value
   */
  onReceive_MassRequest (value) {}  // eslint-disable-line

  /**
   * MassResponse receive handler
   * @this {!Receiver}
   * @param {!MassResponse} value MassResponse received value
   */
  onReceive_MassResponse (value) {}  // eslint-disable-line

  /**
   * Mass2Request receive handler
   * @this {!Receiver}
   * @param {!Mass2Request} value Mass2Request received value
   */
  onReceive_Mass2Request (value) {}  // eslint-disable-line

  /**
   * Mass2ResponseParams receive handler
   * @this {!Receiver}
   * @param {!Mass2ResponseParams} value Mass2ResponseParams received value
   */
  onReceive_Mass2ResponseParams (value) {}  // eslint-disable-line

  /**
   * Mass2Response receive handler
   * @this {!Receiver}
   * @param {!Mass2Response} value Mass2Response received value
   */
  onReceive_Mass2Response (value) {}  // eslint-disable-line

  /**
   * MarginUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!MarginUpdateNotify} value MarginUpdateNotify received value
   */
  onReceive_MarginUpdateNotify (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest received value
   */
  onReceive_PersonsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse received value
   */
  onReceive_PersonsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PersonsInsertRequest receive handler
   * @this {!Receiver}
   * @param {!PersonsInsertRequest} value PersonsInsertRequest received value
   */
  onReceive_PersonsInsertRequest (value) {}  // eslint-disable-line

  /**
   * PersonsInsertResponse receive handler
   * @this {!Receiver}
   * @param {!PersonsInsertResponse} value PersonsInsertResponse received value
   */
  onReceive_PersonsInsertResponse (value) {}  // eslint-disable-line

  /**
   * PersonUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!PersonUpdateRequest} value PersonUpdateRequest received value
   */
  onReceive_PersonUpdateRequest (value) {}  // eslint-disable-line

  /**
   * PersonUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!PersonUpdateResponse} value PersonUpdateResponse received value
   */
  onReceive_PersonUpdateResponse (value) {}  // eslint-disable-line

  /**
   * PersonsInsert2Request receive handler
   * @this {!Receiver}
   * @param {!PersonsInsert2Request} value PersonsInsert2Request received value
   */
  onReceive_PersonsInsert2Request (value) {}  // eslint-disable-line

  /**
   * PersonsInsert2Response receive handler
   * @this {!Receiver}
   * @param {!PersonsInsert2Response} value PersonsInsert2Response received value
   */
  onReceive_PersonsInsert2Response (value) {}  // eslint-disable-line

  /**
   * PersonUpdate2Request receive handler
   * @this {!Receiver}
   * @param {!PersonUpdate2Request} value PersonUpdate2Request received value
   */
  onReceive_PersonUpdate2Request (value) {}  // eslint-disable-line

  /**
   * PersonUpdate2Response receive handler
   * @this {!Receiver}
   * @param {!PersonUpdate2Response} value PersonUpdate2Response received value
   */
  onReceive_PersonUpdate2Response (value) {}  // eslint-disable-line

  /**
   * LastIdsGetRequest receive handler
   * @this {!Receiver}
   * @param {!LastIdsGetRequest} value LastIdsGetRequest received value
   */
  onReceive_LastIdsGetRequest (value) {}  // eslint-disable-line

  /**
   * LastIdsGetResponse receive handler
   * @this {!Receiver}
   * @param {!LastIdsGetResponse} value LastIdsGetResponse received value
   */
  onReceive_LastIdsGetResponse (value) {}  // eslint-disable-line

  /**
   * DailySnapshotRequest receive handler
   * @this {!Receiver}
   * @param {!DailySnapshotRequest} value DailySnapshotRequest received value
   */
  onReceive_DailySnapshotRequest (value) {}  // eslint-disable-line

  /**
   * DailySnapshotResponse receive handler
   * @this {!Receiver}
   * @param {!DailySnapshotResponse} value DailySnapshotResponse received value
   */
  onReceive_DailySnapshotResponse (value) {}  // eslint-disable-line

  /**
   * BillingStatisticsGetRequest receive handler
   * @this {!Receiver}
   * @param {!BillingStatisticsGetRequest} value BillingStatisticsGetRequest received value
   */
  onReceive_BillingStatisticsGetRequest (value) {}  // eslint-disable-line

  /**
   * BillingStatisticsGetResponse receive handler
   * @this {!Receiver}
   * @param {!BillingStatisticsGetResponse} value BillingStatisticsGetResponse received value
   */
  onReceive_BillingStatisticsGetResponse (value) {}  // eslint-disable-line

  /**
   * storage receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case StorageVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StorageVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._StorageVersionRequestModel.verify(), 'storage.StorageVersionRequest validation failed!')
        let deserialized = this._StorageVersionRequestModel.deserialize(this._StorageVersionRequestValue)
        console.assert((deserialized.size > 0), 'storage.StorageVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StorageVersionRequestValue)
        this.onReceive_StorageVersionRequest(this._StorageVersionRequestValue)
        return true
      }
      case StorageVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StorageVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._StorageVersionResponseModel.verify(), 'storage.StorageVersionResponse validation failed!')
        let deserialized = this._StorageVersionResponseModel.deserialize(this._StorageVersionResponseValue)
        console.assert((deserialized.size > 0), 'storage.StorageVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StorageVersionResponseValue)
        this.onReceive_StorageVersionResponse(this._StorageVersionResponseValue)
        return true
      }
      case StorageUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StorageUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._StorageUpdateNotifyModel.verify(), 'storage.StorageUpdateNotify validation failed!')
        let deserialized = this._StorageUpdateNotifyModel.deserialize(this._StorageUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'storage.StorageUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StorageUpdateNotifyValue)
        this.onReceive_StorageUpdateNotify(this._StorageUpdateNotifyValue)
        return true
      }
      case AccountInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsertRequestModel.verify(), 'storage.AccountInsertRequest validation failed!')
        let deserialized = this._AccountInsertRequestModel.deserialize(this._AccountInsertRequestValue)
        console.assert((deserialized.size > 0), 'storage.AccountInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInsertRequestValue)
        this.onReceive_AccountInsertRequest(this._AccountInsertRequestValue)
        return true
      }
      case AccountInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsertResponseModel.verify(), 'storage.AccountInsertResponse validation failed!')
        let deserialized = this._AccountInsertResponseModel.deserialize(this._AccountInsertResponseValue)
        console.assert((deserialized.size > 0), 'storage.AccountInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInsertResponseValue)
        this.onReceive_AccountInsertResponse(this._AccountInsertResponseValue)
        return true
      }
      case AccountSocialTradingMasterInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsertRequestModel.verify(), 'storage.AccountSocialTradingMasterInsertRequest validation failed!')
        let deserialized = this._AccountSocialTradingMasterInsertRequestModel.deserialize(this._AccountSocialTradingMasterInsertRequestValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingMasterInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterInsertRequestValue)
        this.onReceive_AccountSocialTradingMasterInsertRequest(this._AccountSocialTradingMasterInsertRequestValue)
        return true
      }
      case AccountSocialTradingMasterInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsertResponseModel.verify(), 'storage.AccountSocialTradingMasterInsertResponse validation failed!')
        let deserialized = this._AccountSocialTradingMasterInsertResponseModel.deserialize(this._AccountSocialTradingMasterInsertResponseValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingMasterInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterInsertResponseValue)
        this.onReceive_AccountSocialTradingMasterInsertResponse(this._AccountSocialTradingMasterInsertResponseValue)
        return true
      }
      case AccountSocialTradingInvestorInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsertRequestModel.verify(), 'storage.AccountSocialTradingInvestorInsertRequest validation failed!')
        let deserialized = this._AccountSocialTradingInvestorInsertRequestModel.deserialize(this._AccountSocialTradingInvestorInsertRequestValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingInvestorInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorInsertRequestValue)
        this.onReceive_AccountSocialTradingInvestorInsertRequest(this._AccountSocialTradingInvestorInsertRequestValue)
        return true
      }
      case AccountSocialTradingInvestorInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsertResponseModel.verify(), 'storage.AccountSocialTradingInvestorInsertResponse validation failed!')
        let deserialized = this._AccountSocialTradingInvestorInsertResponseModel.deserialize(this._AccountSocialTradingInvestorInsertResponseValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingInvestorInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorInsertResponseValue)
        this.onReceive_AccountSocialTradingInvestorInsertResponse(this._AccountSocialTradingInvestorInsertResponseValue)
        return true
      }
      case AccountUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateRequestModel.verify(), 'storage.AccountUpdateRequest validation failed!')
        let deserialized = this._AccountUpdateRequestModel.deserialize(this._AccountUpdateRequestValue)
        console.assert((deserialized.size > 0), 'storage.AccountUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdateRequestValue)
        this.onReceive_AccountUpdateRequest(this._AccountUpdateRequestValue)
        return true
      }
      case AccountUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateResponseModel.verify(), 'storage.AccountUpdateResponse validation failed!')
        let deserialized = this._AccountUpdateResponseModel.deserialize(this._AccountUpdateResponseValue)
        console.assert((deserialized.size > 0), 'storage.AccountUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdateResponseValue)
        this.onReceive_AccountUpdateResponse(this._AccountUpdateResponseValue)
        return true
      }
      case AccountDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteRequestModel.verify(), 'storage.AccountDeleteRequest validation failed!')
        let deserialized = this._AccountDeleteRequestModel.deserialize(this._AccountDeleteRequestValue)
        console.assert((deserialized.size > 0), 'storage.AccountDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDeleteRequestValue)
        this.onReceive_AccountDeleteRequest(this._AccountDeleteRequestValue)
        return true
      }
      case AccountDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteResponseModel.verify(), 'storage.AccountDeleteResponse validation failed!')
        let deserialized = this._AccountDeleteResponseModel.deserialize(this._AccountDeleteResponseValue)
        console.assert((deserialized.size > 0), 'storage.AccountDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDeleteResponseValue)
        this.onReceive_AccountDeleteResponse(this._AccountDeleteResponseValue)
        return true
      }
      case MarginUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateRequestModel.verify(), 'storage.MarginUpdateRequest validation failed!')
        let deserialized = this._MarginUpdateRequestModel.deserialize(this._MarginUpdateRequestValue)
        console.assert((deserialized.size > 0), 'storage.MarginUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdateRequestValue)
        this.onReceive_MarginUpdateRequest(this._MarginUpdateRequestValue)
        return true
      }
      case MarginUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateResponseModel.verify(), 'storage.MarginUpdateResponse validation failed!')
        let deserialized = this._MarginUpdateResponseModel.deserialize(this._MarginUpdateResponseValue)
        console.assert((deserialized.size > 0), 'storage.MarginUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdateResponseValue)
        this.onReceive_MarginUpdateResponse(this._MarginUpdateResponseValue)
        return true
      }
      case AssetInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsertRequestModel.verify(), 'storage.AssetInsertRequest validation failed!')
        let deserialized = this._AssetInsertRequestModel.deserialize(this._AssetInsertRequestValue)
        console.assert((deserialized.size > 0), 'storage.AssetInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInsertRequestValue)
        this.onReceive_AssetInsertRequest(this._AssetInsertRequestValue)
        return true
      }
      case AssetInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsertResponseModel.verify(), 'storage.AssetInsertResponse validation failed!')
        let deserialized = this._AssetInsertResponseModel.deserialize(this._AssetInsertResponseValue)
        console.assert((deserialized.size > 0), 'storage.AssetInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInsertResponseValue)
        this.onReceive_AssetInsertResponse(this._AssetInsertResponseValue)
        return true
      }
      case AssetUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdateRequestModel.verify(), 'storage.AssetUpdateRequest validation failed!')
        let deserialized = this._AssetUpdateRequestModel.deserialize(this._AssetUpdateRequestValue)
        console.assert((deserialized.size > 0), 'storage.AssetUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetUpdateRequestValue)
        this.onReceive_AssetUpdateRequest(this._AssetUpdateRequestValue)
        return true
      }
      case AssetUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdateResponseModel.verify(), 'storage.AssetUpdateResponse validation failed!')
        let deserialized = this._AssetUpdateResponseModel.deserialize(this._AssetUpdateResponseValue)
        console.assert((deserialized.size > 0), 'storage.AssetUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetUpdateResponseValue)
        this.onReceive_AssetUpdateResponse(this._AssetUpdateResponseValue)
        return true
      }
      case AssetDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDeleteRequestModel.verify(), 'storage.AssetDeleteRequest validation failed!')
        let deserialized = this._AssetDeleteRequestModel.deserialize(this._AssetDeleteRequestValue)
        console.assert((deserialized.size > 0), 'storage.AssetDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetDeleteRequestValue)
        this.onReceive_AssetDeleteRequest(this._AssetDeleteRequestValue)
        return true
      }
      case AssetDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDeleteResponseModel.verify(), 'storage.AssetDeleteResponse validation failed!')
        let deserialized = this._AssetDeleteResponseModel.deserialize(this._AssetDeleteResponseValue)
        console.assert((deserialized.size > 0), 'storage.AssetDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetDeleteResponseValue)
        this.onReceive_AssetDeleteResponse(this._AssetDeleteResponseValue)
        return true
      }
      case OrderInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsertRequestModel.verify(), 'storage.OrderInsertRequest validation failed!')
        let deserialized = this._OrderInsertRequestModel.deserialize(this._OrderInsertRequestValue)
        console.assert((deserialized.size > 0), 'storage.OrderInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInsertRequestValue)
        this.onReceive_OrderInsertRequest(this._OrderInsertRequestValue)
        return true
      }
      case OrderInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsertResponseModel.verify(), 'storage.OrderInsertResponse validation failed!')
        let deserialized = this._OrderInsertResponseModel.deserialize(this._OrderInsertResponseValue)
        console.assert((deserialized.size > 0), 'storage.OrderInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInsertResponseValue)
        this.onReceive_OrderInsertResponse(this._OrderInsertResponseValue)
        return true
      }
      case OrderUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateRequestModel.verify(), 'storage.OrderUpdateRequest validation failed!')
        let deserialized = this._OrderUpdateRequestModel.deserialize(this._OrderUpdateRequestValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateRequestValue)
        this.onReceive_OrderUpdateRequest(this._OrderUpdateRequestValue)
        return true
      }
      case OrderUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateResponseModel.verify(), 'storage.OrderUpdateResponse validation failed!')
        let deserialized = this._OrderUpdateResponseModel.deserialize(this._OrderUpdateResponseValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateResponseValue)
        this.onReceive_OrderUpdateResponse(this._OrderUpdateResponseValue)
        return true
      }
      case OrderDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteRequestModel.verify(), 'storage.OrderDeleteRequest validation failed!')
        let deserialized = this._OrderDeleteRequestModel.deserialize(this._OrderDeleteRequestValue)
        console.assert((deserialized.size > 0), 'storage.OrderDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDeleteRequestValue)
        this.onReceive_OrderDeleteRequest(this._OrderDeleteRequestValue)
        return true
      }
      case OrderDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteResponseModel.verify(), 'storage.OrderDeleteResponse validation failed!')
        let deserialized = this._OrderDeleteResponseModel.deserialize(this._OrderDeleteResponseValue)
        console.assert((deserialized.size > 0), 'storage.OrderDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDeleteResponseValue)
        this.onReceive_OrderDeleteResponse(this._OrderDeleteResponseValue)
        return true
      }
      case OrderDeleteAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDeleteAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteAllRequestModel.verify(), 'storage.OrderDeleteAllRequest validation failed!')
        let deserialized = this._OrderDeleteAllRequestModel.deserialize(this._OrderDeleteAllRequestValue)
        console.assert((deserialized.size > 0), 'storage.OrderDeleteAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDeleteAllRequestValue)
        this.onReceive_OrderDeleteAllRequest(this._OrderDeleteAllRequestValue)
        return true
      }
      case OrderDeleteAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDeleteAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteAllResponseModel.verify(), 'storage.OrderDeleteAllResponse validation failed!')
        let deserialized = this._OrderDeleteAllResponseModel.deserialize(this._OrderDeleteAllResponseValue)
        console.assert((deserialized.size > 0), 'storage.OrderDeleteAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDeleteAllResponseValue)
        this.onReceive_OrderDeleteAllResponse(this._OrderDeleteAllResponseValue)
        return true
      }
      case PositionInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsertRequestModel.verify(), 'storage.PositionInsertRequest validation failed!')
        let deserialized = this._PositionInsertRequestModel.deserialize(this._PositionInsertRequestValue)
        console.assert((deserialized.size > 0), 'storage.PositionInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInsertRequestValue)
        this.onReceive_PositionInsertRequest(this._PositionInsertRequestValue)
        return true
      }
      case PositionInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsertResponseModel.verify(), 'storage.PositionInsertResponse validation failed!')
        let deserialized = this._PositionInsertResponseModel.deserialize(this._PositionInsertResponseValue)
        console.assert((deserialized.size > 0), 'storage.PositionInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInsertResponseValue)
        this.onReceive_PositionInsertResponse(this._PositionInsertResponseValue)
        return true
      }
      case PositionUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdateRequestModel.verify(), 'storage.PositionUpdateRequest validation failed!')
        let deserialized = this._PositionUpdateRequestModel.deserialize(this._PositionUpdateRequestValue)
        console.assert((deserialized.size > 0), 'storage.PositionUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionUpdateRequestValue)
        this.onReceive_PositionUpdateRequest(this._PositionUpdateRequestValue)
        return true
      }
      case PositionUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdateResponseModel.verify(), 'storage.PositionUpdateResponse validation failed!')
        let deserialized = this._PositionUpdateResponseModel.deserialize(this._PositionUpdateResponseValue)
        console.assert((deserialized.size > 0), 'storage.PositionUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionUpdateResponseValue)
        this.onReceive_PositionUpdateResponse(this._PositionUpdateResponseValue)
        return true
      }
      case PositionDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDeleteRequestModel.verify(), 'storage.PositionDeleteRequest validation failed!')
        let deserialized = this._PositionDeleteRequestModel.deserialize(this._PositionDeleteRequestValue)
        console.assert((deserialized.size > 0), 'storage.PositionDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionDeleteRequestValue)
        this.onReceive_PositionDeleteRequest(this._PositionDeleteRequestValue)
        return true
      }
      case PositionDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDeleteResponseModel.verify(), 'storage.PositionDeleteResponse validation failed!')
        let deserialized = this._PositionDeleteResponseModel.deserialize(this._PositionDeleteResponseValue)
        console.assert((deserialized.size > 0), 'storage.PositionDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionDeleteResponseValue)
        this.onReceive_PositionDeleteResponse(this._PositionDeleteResponseValue)
        return true
      }
      case TradeHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryRequestModel.verify(), 'storage.TradeHistoryRequest validation failed!')
        let deserialized = this._TradeHistoryRequestModel.deserialize(this._TradeHistoryRequestValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryRequestValue)
        this.onReceive_TradeHistoryRequest(this._TradeHistoryRequestValue)
        return true
      }
      case TradeHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryResponseModel.verify(), 'storage.TradeHistoryResponse validation failed!')
        let deserialized = this._TradeHistoryResponseModel.deserialize(this._TradeHistoryResponseValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryResponseValue)
        this.onReceive_TradeHistoryResponse(this._TradeHistoryResponseValue)
        return true
      }
      case TradeEventsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetRequestModel.verify(), 'storage.TradeEventsGetRequest validation failed!')
        let deserialized = this._TradeEventsGetRequestModel.deserialize(this._TradeEventsGetRequestValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetRequestValue)
        this.onReceive_TradeEventsGetRequest(this._TradeEventsGetRequestValue)
        return true
      }
      case TradeEventsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetResponseModel.verify(), 'storage.TradeEventsGetResponse validation failed!')
        let deserialized = this._TradeEventsGetResponseModel.deserialize(this._TradeEventsGetResponseValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetResponseValue)
        this.onReceive_TradeEventsGetResponse(this._TradeEventsGetResponseValue)
        return true
      }
      case TradeEventsNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsNotifyModel.verify(), 'storage.TradeEventsNotify validation failed!')
        let deserialized = this._TradeEventsNotifyModel.deserialize(this._TradeEventsNotifyValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsNotifyValue)
        this.onReceive_TradeEventsNotify(this._TradeEventsNotifyValue)
        return true
      }
      case TradeEventsInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsertRequestModel.verify(), 'storage.TradeEventsInsertRequest validation failed!')
        let deserialized = this._TradeEventsInsertRequestModel.deserialize(this._TradeEventsInsertRequestValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsInsertRequestValue)
        this.onReceive_TradeEventsInsertRequest(this._TradeEventsInsertRequestValue)
        return true
      }
      case TradeEventsInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsertResponseModel.verify(), 'storage.TradeEventsInsertResponse validation failed!')
        let deserialized = this._TradeEventsInsertResponseModel.deserialize(this._TradeEventsInsertResponseValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsInsertResponseValue)
        this.onReceive_TradeEventsInsertResponse(this._TradeEventsInsertResponseValue)
        return true
      }
      case ProfitClosedGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetRequestModel.verify(), 'storage.ProfitClosedGetRequest validation failed!')
        let deserialized = this._ProfitClosedGetRequestModel.deserialize(this._ProfitClosedGetRequestValue)
        console.assert((deserialized.size > 0), 'storage.ProfitClosedGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetRequestValue)
        this.onReceive_ProfitClosedGetRequest(this._ProfitClosedGetRequestValue)
        return true
      }
      case ProfitClosedGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetResponseModel.verify(), 'storage.ProfitClosedGetResponse validation failed!')
        let deserialized = this._ProfitClosedGetResponseModel.deserialize(this._ProfitClosedGetResponseValue)
        console.assert((deserialized.size > 0), 'storage.ProfitClosedGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetResponseValue)
        this.onReceive_ProfitClosedGetResponse(this._ProfitClosedGetResponseValue)
        return true
      }
      case OrdersClosedSummariesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersClosedSummariesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetRequestModel.verify(), 'storage.OrdersClosedSummariesGetRequest validation failed!')
        let deserialized = this._OrdersClosedSummariesGetRequestModel.deserialize(this._OrdersClosedSummariesGetRequestValue)
        console.assert((deserialized.size > 0), 'storage.OrdersClosedSummariesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersClosedSummariesGetRequestValue)
        this.onReceive_OrdersClosedSummariesGetRequest(this._OrdersClosedSummariesGetRequestValue)
        return true
      }
      case OrdersClosedSummariesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersClosedSummariesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetResponseModel.verify(), 'storage.OrdersClosedSummariesGetResponse validation failed!')
        let deserialized = this._OrdersClosedSummariesGetResponseModel.deserialize(this._OrdersClosedSummariesGetResponseValue)
        console.assert((deserialized.size > 0), 'storage.OrdersClosedSummariesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersClosedSummariesGetResponseValue)
        this.onReceive_OrdersClosedSummariesGetResponse(this._OrdersClosedSummariesGetResponseValue)
        return true
      }
      case OrderInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsert2RequestModel.verify(), 'storage.OrderInsert2Request validation failed!')
        let deserialized = this._OrderInsert2RequestModel.deserialize(this._OrderInsert2RequestValue)
        console.assert((deserialized.size > 0), 'storage.OrderInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInsert2RequestValue)
        this.onReceive_OrderInsert2Request(this._OrderInsert2RequestValue)
        return true
      }
      case OrderInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsert2ResponseModel.verify(), 'storage.OrderInsert2Response validation failed!')
        let deserialized = this._OrderInsert2ResponseModel.deserialize(this._OrderInsert2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.OrderInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInsert2ResponseValue)
        this.onReceive_OrderInsert2Response(this._OrderInsert2ResponseValue)
        return true
      }
      case OrderUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdate2RequestModel.verify(), 'storage.OrderUpdate2Request validation failed!')
        let deserialized = this._OrderUpdate2RequestModel.deserialize(this._OrderUpdate2RequestValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdate2RequestValue)
        this.onReceive_OrderUpdate2Request(this._OrderUpdate2RequestValue)
        return true
      }
      case OrderUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdate2ResponseModel.verify(), 'storage.OrderUpdate2Response validation failed!')
        let deserialized = this._OrderUpdate2ResponseModel.deserialize(this._OrderUpdate2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdate2ResponseValue)
        this.onReceive_OrderUpdate2Response(this._OrderUpdate2ResponseValue)
        return true
      }
      case OrderDelete2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDelete2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDelete2RequestModel.verify(), 'storage.OrderDelete2Request validation failed!')
        let deserialized = this._OrderDelete2RequestModel.deserialize(this._OrderDelete2RequestValue)
        console.assert((deserialized.size > 0), 'storage.OrderDelete2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDelete2RequestValue)
        this.onReceive_OrderDelete2Request(this._OrderDelete2RequestValue)
        return true
      }
      case OrderDelete2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDelete2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDelete2ResponseModel.verify(), 'storage.OrderDelete2Response validation failed!')
        let deserialized = this._OrderDelete2ResponseModel.deserialize(this._OrderDelete2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.OrderDelete2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDelete2ResponseValue)
        this.onReceive_OrderDelete2Response(this._OrderDelete2ResponseValue)
        return true
      }
      case AccountInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsert2RequestModel.verify(), 'storage.AccountInsert2Request validation failed!')
        let deserialized = this._AccountInsert2RequestModel.deserialize(this._AccountInsert2RequestValue)
        console.assert((deserialized.size > 0), 'storage.AccountInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInsert2RequestValue)
        this.onReceive_AccountInsert2Request(this._AccountInsert2RequestValue)
        return true
      }
      case AccountInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsert2ResponseModel.verify(), 'storage.AccountInsert2Response validation failed!')
        let deserialized = this._AccountInsert2ResponseModel.deserialize(this._AccountInsert2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.AccountInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInsert2ResponseValue)
        this.onReceive_AccountInsert2Response(this._AccountInsert2ResponseValue)
        return true
      }
      case AccountSocialTradingMasterInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsert2RequestModel.verify(), 'storage.AccountSocialTradingMasterInsert2Request validation failed!')
        let deserialized = this._AccountSocialTradingMasterInsert2RequestModel.deserialize(this._AccountSocialTradingMasterInsert2RequestValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingMasterInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterInsert2RequestValue)
        this.onReceive_AccountSocialTradingMasterInsert2Request(this._AccountSocialTradingMasterInsert2RequestValue)
        return true
      }
      case AccountSocialTradingMasterInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsert2ResponseModel.verify(), 'storage.AccountSocialTradingMasterInsert2Response validation failed!')
        let deserialized = this._AccountSocialTradingMasterInsert2ResponseModel.deserialize(this._AccountSocialTradingMasterInsert2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingMasterInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterInsert2ResponseValue)
        this.onReceive_AccountSocialTradingMasterInsert2Response(this._AccountSocialTradingMasterInsert2ResponseValue)
        return true
      }
      case AccountSocialTradingInvestorInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsert2RequestModel.verify(), 'storage.AccountSocialTradingInvestorInsert2Request validation failed!')
        let deserialized = this._AccountSocialTradingInvestorInsert2RequestModel.deserialize(this._AccountSocialTradingInvestorInsert2RequestValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingInvestorInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorInsert2RequestValue)
        this.onReceive_AccountSocialTradingInvestorInsert2Request(this._AccountSocialTradingInvestorInsert2RequestValue)
        return true
      }
      case AccountSocialTradingInvestorInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsert2ResponseModel.verify(), 'storage.AccountSocialTradingInvestorInsert2Response validation failed!')
        let deserialized = this._AccountSocialTradingInvestorInsert2ResponseModel.deserialize(this._AccountSocialTradingInvestorInsert2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingInvestorInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorInsert2ResponseValue)
        this.onReceive_AccountSocialTradingInvestorInsert2Response(this._AccountSocialTradingInvestorInsert2ResponseValue)
        return true
      }
      case AccountDelete2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDelete2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDelete2RequestModel.verify(), 'storage.AccountDelete2Request validation failed!')
        let deserialized = this._AccountDelete2RequestModel.deserialize(this._AccountDelete2RequestValue)
        console.assert((deserialized.size > 0), 'storage.AccountDelete2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDelete2RequestValue)
        this.onReceive_AccountDelete2Request(this._AccountDelete2RequestValue)
        return true
      }
      case AccountDelete2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDelete2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDelete2ResponseModel.verify(), 'storage.AccountDelete2Response validation failed!')
        let deserialized = this._AccountDelete2ResponseModel.deserialize(this._AccountDelete2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.AccountDelete2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDelete2ResponseValue)
        this.onReceive_AccountDelete2Response(this._AccountDelete2ResponseValue)
        return true
      }
      case AssetInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsert2RequestModel.verify(), 'storage.AssetInsert2Request validation failed!')
        let deserialized = this._AssetInsert2RequestModel.deserialize(this._AssetInsert2RequestValue)
        console.assert((deserialized.size > 0), 'storage.AssetInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInsert2RequestValue)
        this.onReceive_AssetInsert2Request(this._AssetInsert2RequestValue)
        return true
      }
      case AssetInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsert2ResponseModel.verify(), 'storage.AssetInsert2Response validation failed!')
        let deserialized = this._AssetInsert2ResponseModel.deserialize(this._AssetInsert2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.AssetInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInsert2ResponseValue)
        this.onReceive_AssetInsert2Response(this._AssetInsert2ResponseValue)
        return true
      }
      case AssetUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdate2RequestModel.verify(), 'storage.AssetUpdate2Request validation failed!')
        let deserialized = this._AssetUpdate2RequestModel.deserialize(this._AssetUpdate2RequestValue)
        console.assert((deserialized.size > 0), 'storage.AssetUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetUpdate2RequestValue)
        this.onReceive_AssetUpdate2Request(this._AssetUpdate2RequestValue)
        return true
      }
      case AssetUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdate2ResponseModel.verify(), 'storage.AssetUpdate2Response validation failed!')
        let deserialized = this._AssetUpdate2ResponseModel.deserialize(this._AssetUpdate2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.AssetUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetUpdate2ResponseValue)
        this.onReceive_AssetUpdate2Response(this._AssetUpdate2ResponseValue)
        return true
      }
      case AssetDelete2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetDelete2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDelete2RequestModel.verify(), 'storage.AssetDelete2Request validation failed!')
        let deserialized = this._AssetDelete2RequestModel.deserialize(this._AssetDelete2RequestValue)
        console.assert((deserialized.size > 0), 'storage.AssetDelete2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetDelete2RequestValue)
        this.onReceive_AssetDelete2Request(this._AssetDelete2RequestValue)
        return true
      }
      case AssetDelete2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetDelete2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDelete2ResponseModel.verify(), 'storage.AssetDelete2Response validation failed!')
        let deserialized = this._AssetDelete2ResponseModel.deserialize(this._AssetDelete2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.AssetDelete2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetDelete2ResponseValue)
        this.onReceive_AssetDelete2Response(this._AssetDelete2ResponseValue)
        return true
      }
      case PositionInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsert2RequestModel.verify(), 'storage.PositionInsert2Request validation failed!')
        let deserialized = this._PositionInsert2RequestModel.deserialize(this._PositionInsert2RequestValue)
        console.assert((deserialized.size > 0), 'storage.PositionInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInsert2RequestValue)
        this.onReceive_PositionInsert2Request(this._PositionInsert2RequestValue)
        return true
      }
      case PositionInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsert2ResponseModel.verify(), 'storage.PositionInsert2Response validation failed!')
        let deserialized = this._PositionInsert2ResponseModel.deserialize(this._PositionInsert2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.PositionInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInsert2ResponseValue)
        this.onReceive_PositionInsert2Response(this._PositionInsert2ResponseValue)
        return true
      }
      case PositionUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdate2RequestModel.verify(), 'storage.PositionUpdate2Request validation failed!')
        let deserialized = this._PositionUpdate2RequestModel.deserialize(this._PositionUpdate2RequestValue)
        console.assert((deserialized.size > 0), 'storage.PositionUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionUpdate2RequestValue)
        this.onReceive_PositionUpdate2Request(this._PositionUpdate2RequestValue)
        return true
      }
      case PositionUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdate2ResponseModel.verify(), 'storage.PositionUpdate2Response validation failed!')
        let deserialized = this._PositionUpdate2ResponseModel.deserialize(this._PositionUpdate2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.PositionUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionUpdate2ResponseValue)
        this.onReceive_PositionUpdate2Response(this._PositionUpdate2ResponseValue)
        return true
      }
      case PositionDelete2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionDelete2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDelete2RequestModel.verify(), 'storage.PositionDelete2Request validation failed!')
        let deserialized = this._PositionDelete2RequestModel.deserialize(this._PositionDelete2RequestValue)
        console.assert((deserialized.size > 0), 'storage.PositionDelete2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionDelete2RequestValue)
        this.onReceive_PositionDelete2Request(this._PositionDelete2RequestValue)
        return true
      }
      case PositionDelete2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionDelete2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDelete2ResponseModel.verify(), 'storage.PositionDelete2Response validation failed!')
        let deserialized = this._PositionDelete2ResponseModel.deserialize(this._PositionDelete2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.PositionDelete2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionDelete2ResponseValue)
        this.onReceive_PositionDelete2Response(this._PositionDelete2ResponseValue)
        return true
      }
      case Deprecated001Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated001Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated001Model.verify(), 'storage.Deprecated001 validation failed!')
        let deserialized = this._Deprecated001Model.deserialize(this._Deprecated001Value)
        console.assert((deserialized.size > 0), 'storage.Deprecated001 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated001Value)
        this.onReceive_Deprecated001(this._Deprecated001Value)
        return true
      }
      case Deprecated002Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated002Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated002Model.verify(), 'storage.Deprecated002 validation failed!')
        let deserialized = this._Deprecated002Model.deserialize(this._Deprecated002Value)
        console.assert((deserialized.size > 0), 'storage.Deprecated002 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated002Value)
        this.onReceive_Deprecated002(this._Deprecated002Value)
        return true
      }
      case Deprecated003Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated003Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated003Model.verify(), 'storage.Deprecated003 validation failed!')
        let deserialized = this._Deprecated003Model.deserialize(this._Deprecated003Value)
        console.assert((deserialized.size > 0), 'storage.Deprecated003 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated003Value)
        this.onReceive_Deprecated003(this._Deprecated003Value)
        return true
      }
      case Deprecated004Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated004Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated004Model.verify(), 'storage.Deprecated004 validation failed!')
        let deserialized = this._Deprecated004Model.deserialize(this._Deprecated004Value)
        console.assert((deserialized.size > 0), 'storage.Deprecated004 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated004Value)
        this.onReceive_Deprecated004(this._Deprecated004Value)
        return true
      }
      case Deprecated005Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated005Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated005Model.verify(), 'storage.Deprecated005 validation failed!')
        let deserialized = this._Deprecated005Model.deserialize(this._Deprecated005Value)
        console.assert((deserialized.size > 0), 'storage.Deprecated005 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated005Value)
        this.onReceive_Deprecated005(this._Deprecated005Value)
        return true
      }
      case Deprecated006Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated006Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated006Model.verify(), 'storage.Deprecated006 validation failed!')
        let deserialized = this._Deprecated006Model.deserialize(this._Deprecated006Value)
        console.assert((deserialized.size > 0), 'storage.Deprecated006 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated006Value)
        this.onReceive_Deprecated006(this._Deprecated006Value)
        return true
      }
      case TradeEventsInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsert2RequestModel.verify(), 'storage.TradeEventsInsert2Request validation failed!')
        let deserialized = this._TradeEventsInsert2RequestModel.deserialize(this._TradeEventsInsert2RequestValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsInsert2RequestValue)
        this.onReceive_TradeEventsInsert2Request(this._TradeEventsInsert2RequestValue)
        return true
      }
      case TradeEventsInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsert2ResponseModel.verify(), 'storage.TradeEventsInsert2Response validation failed!')
        let deserialized = this._TradeEventsInsert2ResponseModel.deserialize(this._TradeEventsInsert2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsInsert2ResponseValue)
        this.onReceive_TradeEventsInsert2Response(this._TradeEventsInsert2ResponseValue)
        return true
      }
      case MarginUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdate2RequestModel.verify(), 'storage.MarginUpdate2Request validation failed!')
        let deserialized = this._MarginUpdate2RequestModel.deserialize(this._MarginUpdate2RequestValue)
        console.assert((deserialized.size > 0), 'storage.MarginUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdate2RequestValue)
        this.onReceive_MarginUpdate2Request(this._MarginUpdate2RequestValue)
        return true
      }
      case MarginUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdate2ResponseModel.verify(), 'storage.MarginUpdate2Response validation failed!')
        let deserialized = this._MarginUpdate2ResponseModel.deserialize(this._MarginUpdate2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.MarginUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdate2ResponseValue)
        this.onReceive_MarginUpdate2Response(this._MarginUpdate2ResponseValue)
        return true
      }
      case AccountUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdate2RequestModel.verify(), 'storage.AccountUpdate2Request validation failed!')
        let deserialized = this._AccountUpdate2RequestModel.deserialize(this._AccountUpdate2RequestValue)
        console.assert((deserialized.size > 0), 'storage.AccountUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdate2RequestValue)
        this.onReceive_AccountUpdate2Request(this._AccountUpdate2RequestValue)
        return true
      }
      case AccountUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdate2ResponseModel.verify(), 'storage.AccountUpdate2Response validation failed!')
        let deserialized = this._AccountUpdate2ResponseModel.deserialize(this._AccountUpdate2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.AccountUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdate2ResponseValue)
        this.onReceive_AccountUpdate2Response(this._AccountUpdate2ResponseValue)
        return true
      }
      case DepositMarginRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositMarginRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMarginRequestModel.verify(), 'storage.DepositMarginRequest validation failed!')
        let deserialized = this._DepositMarginRequestModel.deserialize(this._DepositMarginRequestValue)
        console.assert((deserialized.size > 0), 'storage.DepositMarginRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositMarginRequestValue)
        this.onReceive_DepositMarginRequest(this._DepositMarginRequestValue)
        return true
      }
      case DepositMarginResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositMarginResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMarginResponseModel.verify(), 'storage.DepositMarginResponse validation failed!')
        let deserialized = this._DepositMarginResponseModel.deserialize(this._DepositMarginResponseValue)
        console.assert((deserialized.size > 0), 'storage.DepositMarginResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositMarginResponseValue)
        this.onReceive_DepositMarginResponse(this._DepositMarginResponseValue)
        return true
      }
      case DepositMargin2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositMargin2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMargin2RequestModel.verify(), 'storage.DepositMargin2Request validation failed!')
        let deserialized = this._DepositMargin2RequestModel.deserialize(this._DepositMargin2RequestValue)
        console.assert((deserialized.size > 0), 'storage.DepositMargin2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositMargin2RequestValue)
        this.onReceive_DepositMargin2Request(this._DepositMargin2RequestValue)
        return true
      }
      case DepositMargin2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositMargin2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMargin2ResponseModel.verify(), 'storage.DepositMargin2Response validation failed!')
        let deserialized = this._DepositMargin2ResponseModel.deserialize(this._DepositMargin2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.DepositMargin2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositMargin2ResponseValue)
        this.onReceive_DepositMargin2Response(this._DepositMargin2ResponseValue)
        return true
      }
      case StoreClientLoginSuccessRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StoreClientLoginSuccessRequestModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccessRequestModel.verify(), 'storage.StoreClientLoginSuccessRequest validation failed!')
        let deserialized = this._StoreClientLoginSuccessRequestModel.deserialize(this._StoreClientLoginSuccessRequestValue)
        console.assert((deserialized.size > 0), 'storage.StoreClientLoginSuccessRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StoreClientLoginSuccessRequestValue)
        this.onReceive_StoreClientLoginSuccessRequest(this._StoreClientLoginSuccessRequestValue)
        return true
      }
      case StoreClientLoginSuccessResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StoreClientLoginSuccessResponseModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccessResponseModel.verify(), 'storage.StoreClientLoginSuccessResponse validation failed!')
        let deserialized = this._StoreClientLoginSuccessResponseModel.deserialize(this._StoreClientLoginSuccessResponseValue)
        console.assert((deserialized.size > 0), 'storage.StoreClientLoginSuccessResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StoreClientLoginSuccessResponseValue)
        this.onReceive_StoreClientLoginSuccessResponse(this._StoreClientLoginSuccessResponseValue)
        return true
      }
      case ClientLoginInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientLoginInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetRequestModel.verify(), 'storage.ClientLoginInfoGetRequest validation failed!')
        let deserialized = this._ClientLoginInfoGetRequestModel.deserialize(this._ClientLoginInfoGetRequestValue)
        console.assert((deserialized.size > 0), 'storage.ClientLoginInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientLoginInfoGetRequestValue)
        this.onReceive_ClientLoginInfoGetRequest(this._ClientLoginInfoGetRequestValue)
        return true
      }
      case ClientLoginInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientLoginInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetResponseModel.verify(), 'storage.ClientLoginInfoGetResponse validation failed!')
        let deserialized = this._ClientLoginInfoGetResponseModel.deserialize(this._ClientLoginInfoGetResponseValue)
        console.assert((deserialized.size > 0), 'storage.ClientLoginInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientLoginInfoGetResponseValue)
        this.onReceive_ClientLoginInfoGetResponse(this._ClientLoginInfoGetResponseValue)
        return true
      }
      case ClientLoginInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientLoginInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoNotifyModel.verify(), 'storage.ClientLoginInfoNotify validation failed!')
        let deserialized = this._ClientLoginInfoNotifyModel.deserialize(this._ClientLoginInfoNotifyValue)
        console.assert((deserialized.size > 0), 'storage.ClientLoginInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientLoginInfoNotifyValue)
        this.onReceive_ClientLoginInfoNotify(this._ClientLoginInfoNotifyValue)
        return true
      }
      case StoreClientLoginSuccess2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StoreClientLoginSuccess2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccess2RequestModel.verify(), 'storage.StoreClientLoginSuccess2Request validation failed!')
        let deserialized = this._StoreClientLoginSuccess2RequestModel.deserialize(this._StoreClientLoginSuccess2RequestValue)
        console.assert((deserialized.size > 0), 'storage.StoreClientLoginSuccess2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StoreClientLoginSuccess2RequestValue)
        this.onReceive_StoreClientLoginSuccess2Request(this._StoreClientLoginSuccess2RequestValue)
        return true
      }
      case StoreClientLoginSuccess2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StoreClientLoginSuccess2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccess2ResponseModel.verify(), 'storage.StoreClientLoginSuccess2Response validation failed!')
        let deserialized = this._StoreClientLoginSuccess2ResponseModel.deserialize(this._StoreClientLoginSuccess2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.StoreClientLoginSuccess2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StoreClientLoginSuccess2ResponseValue)
        this.onReceive_StoreClientLoginSuccess2Response(this._StoreClientLoginSuccess2ResponseValue)
        return true
      }
      case OrderUpdateWithTypeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithTypeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeRequestModel.verify(), 'storage.OrderUpdateWithTypeRequest validation failed!')
        let deserialized = this._OrderUpdateWithTypeRequestModel.deserialize(this._OrderUpdateWithTypeRequestValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateWithTypeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithTypeRequestValue)
        this.onReceive_OrderUpdateWithTypeRequest(this._OrderUpdateWithTypeRequestValue)
        return true
      }
      case OrderUpdateWithTypeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithTypeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeResponseModel.verify(), 'storage.OrderUpdateWithTypeResponse validation failed!')
        let deserialized = this._OrderUpdateWithTypeResponseModel.deserialize(this._OrderUpdateWithTypeResponseValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateWithTypeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithTypeResponseValue)
        this.onReceive_OrderUpdateWithTypeResponse(this._OrderUpdateWithTypeResponseValue)
        return true
      }
      case OrderUpdateWithType2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithType2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithType2RequestModel.verify(), 'storage.OrderUpdateWithType2Request validation failed!')
        let deserialized = this._OrderUpdateWithType2RequestModel.deserialize(this._OrderUpdateWithType2RequestValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateWithType2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithType2RequestValue)
        this.onReceive_OrderUpdateWithType2Request(this._OrderUpdateWithType2RequestValue)
        return true
      }
      case OrderUpdateWithType2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithType2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithType2ResponseModel.verify(), 'storage.OrderUpdateWithType2Response validation failed!')
        let deserialized = this._OrderUpdateWithType2ResponseModel.deserialize(this._OrderUpdateWithType2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateWithType2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithType2ResponseValue)
        this.onReceive_OrderUpdateWithType2Response(this._OrderUpdateWithType2ResponseValue)
        return true
      }
      case TradeHistoryTimeRangeGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryTimeRangeGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryTimeRangeGetRequestModel.verify(), 'storage.TradeHistoryTimeRangeGetRequest validation failed!')
        let deserialized = this._TradeHistoryTimeRangeGetRequestModel.deserialize(this._TradeHistoryTimeRangeGetRequestValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryTimeRangeGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryTimeRangeGetRequestValue)
        this.onReceive_TradeHistoryTimeRangeGetRequest(this._TradeHistoryTimeRangeGetRequestValue)
        return true
      }
      case TradeHistoryTimeRangeGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryTimeRangeGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryTimeRangeGetResponseModel.verify(), 'storage.TradeHistoryTimeRangeGetResponse validation failed!')
        let deserialized = this._TradeHistoryTimeRangeGetResponseModel.deserialize(this._TradeHistoryTimeRangeGetResponseValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryTimeRangeGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryTimeRangeGetResponseValue)
        this.onReceive_TradeHistoryTimeRangeGetResponse(this._TradeHistoryTimeRangeGetResponseValue)
        return true
      }
      case TradeEventsTimeRangeGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsTimeRangeGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsTimeRangeGetRequestModel.verify(), 'storage.TradeEventsTimeRangeGetRequest validation failed!')
        let deserialized = this._TradeEventsTimeRangeGetRequestModel.deserialize(this._TradeEventsTimeRangeGetRequestValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsTimeRangeGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsTimeRangeGetRequestValue)
        this.onReceive_TradeEventsTimeRangeGetRequest(this._TradeEventsTimeRangeGetRequestValue)
        return true
      }
      case TradeEventsTimeRangeGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsTimeRangeGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsTimeRangeGetResponseModel.verify(), 'storage.TradeEventsTimeRangeGetResponse validation failed!')
        let deserialized = this._TradeEventsTimeRangeGetResponseModel.deserialize(this._TradeEventsTimeRangeGetResponseValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsTimeRangeGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsTimeRangeGetResponseValue)
        this.onReceive_TradeEventsTimeRangeGetResponse(this._TradeEventsTimeRangeGetResponseValue)
        return true
      }
      case TradeHistoryByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdRequestModel.verify(), 'storage.TradeHistoryByIdRequest validation failed!')
        let deserialized = this._TradeHistoryByIdRequestModel.deserialize(this._TradeHistoryByIdRequestValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdRequestValue)
        this.onReceive_TradeHistoryByIdRequest(this._TradeHistoryByIdRequestValue)
        return true
      }
      case TradeHistoryByIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdResponseModel.verify(), 'storage.TradeHistoryByIdResponse validation failed!')
        let deserialized = this._TradeHistoryByIdResponseModel.deserialize(this._TradeHistoryByIdResponseValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryByIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdResponseValue)
        this.onReceive_TradeHistoryByIdResponse(this._TradeHistoryByIdResponseValue)
        return true
      }
      case OrdersGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllRequestModel.verify(), 'storage.OrdersGetAllRequest validation failed!')
        let deserialized = this._OrdersGetAllRequestModel.deserialize(this._OrdersGetAllRequestValue)
        console.assert((deserialized.size > 0), 'storage.OrdersGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllRequestValue)
        this.onReceive_OrdersGetAllRequest(this._OrdersGetAllRequestValue)
        return true
      }
      case OrdersGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllResponseModel.verify(), 'storage.OrdersGetAllResponse validation failed!')
        let deserialized = this._OrdersGetAllResponseModel.deserialize(this._OrdersGetAllResponseValue)
        console.assert((deserialized.size > 0), 'storage.OrdersGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllResponseValue)
        this.onReceive_OrdersGetAllResponse(this._OrdersGetAllResponseValue)
        return true
      }
      case PositionsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllRequestModel.verify(), 'storage.PositionsGetAllRequest validation failed!')
        let deserialized = this._PositionsGetAllRequestModel.deserialize(this._PositionsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'storage.PositionsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllRequestValue)
        this.onReceive_PositionsGetAllRequest(this._PositionsGetAllRequestValue)
        return true
      }
      case PositionsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllResponseModel.verify(), 'storage.PositionsGetAllResponse validation failed!')
        let deserialized = this._PositionsGetAllResponseModel.deserialize(this._PositionsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'storage.PositionsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllResponseValue)
        this.onReceive_PositionsGetAllResponse(this._PositionsGetAllResponseValue)
        return true
      }
      case AssetsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllRequestModel.verify(), 'storage.AssetsGetAllRequest validation failed!')
        let deserialized = this._AssetsGetAllRequestModel.deserialize(this._AssetsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'storage.AssetsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllRequestValue)
        this.onReceive_AssetsGetAllRequest(this._AssetsGetAllRequestValue)
        return true
      }
      case AssetsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllResponseModel.verify(), 'storage.AssetsGetAllResponse validation failed!')
        let deserialized = this._AssetsGetAllResponseModel.deserialize(this._AssetsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'storage.AssetsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllResponseValue)
        this.onReceive_AssetsGetAllResponse(this._AssetsGetAllResponseValue)
        return true
      }
      case MarginsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllRequestModel.verify(), 'storage.MarginsGetAllRequest validation failed!')
        let deserialized = this._MarginsGetAllRequestModel.deserialize(this._MarginsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'storage.MarginsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllRequestValue)
        this.onReceive_MarginsGetAllRequest(this._MarginsGetAllRequestValue)
        return true
      }
      case MarginsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllResponseModel.verify(), 'storage.MarginsGetAllResponse validation failed!')
        let deserialized = this._MarginsGetAllResponseModel.deserialize(this._MarginsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'storage.MarginsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllResponseValue)
        this.onReceive_MarginsGetAllResponse(this._MarginsGetAllResponseValue)
        return true
      }
      case AccountsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllRequestModel.verify(), 'storage.AccountsGetAllRequest validation failed!')
        let deserialized = this._AccountsGetAllRequestModel.deserialize(this._AccountsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'storage.AccountsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllRequestValue)
        this.onReceive_AccountsGetAllRequest(this._AccountsGetAllRequestValue)
        return true
      }
      case AccountsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllResponseModel.verify(), 'storage.AccountsGetAllResponse validation failed!')
        let deserialized = this._AccountsGetAllResponseModel.deserialize(this._AccountsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'storage.AccountsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllResponseValue)
        this.onReceive_AccountsGetAllResponse(this._AccountsGetAllResponseValue)
        return true
      }
      case MassRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MassRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MassRequestModel.verify(), 'storage.MassRequest validation failed!')
        let deserialized = this._MassRequestModel.deserialize(this._MassRequestValue)
        console.assert((deserialized.size > 0), 'storage.MassRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MassRequestValue)
        this.onReceive_MassRequest(this._MassRequestValue)
        return true
      }
      case MassResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MassResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MassResponseModel.verify(), 'storage.MassResponse validation failed!')
        let deserialized = this._MassResponseModel.deserialize(this._MassResponseValue)
        console.assert((deserialized.size > 0), 'storage.MassResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MassResponseValue)
        this.onReceive_MassResponse(this._MassResponseValue)
        return true
      }
      case Mass2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Mass2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._Mass2RequestModel.verify(), 'storage.Mass2Request validation failed!')
        let deserialized = this._Mass2RequestModel.deserialize(this._Mass2RequestValue)
        console.assert((deserialized.size > 0), 'storage.Mass2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Mass2RequestValue)
        this.onReceive_Mass2Request(this._Mass2RequestValue)
        return true
      }
      case Mass2ResponseParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Mass2ResponseParamsModel.attachBuffer(buffer, offset)
        console.assert(this._Mass2ResponseParamsModel.verify(), 'storage.Mass2ResponseParams validation failed!')
        let deserialized = this._Mass2ResponseParamsModel.deserialize(this._Mass2ResponseParamsValue)
        console.assert((deserialized.size > 0), 'storage.Mass2ResponseParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Mass2ResponseParamsValue)
        this.onReceive_Mass2ResponseParams(this._Mass2ResponseParamsValue)
        return true
      }
      case Mass2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Mass2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Mass2ResponseModel.verify(), 'storage.Mass2Response validation failed!')
        let deserialized = this._Mass2ResponseModel.deserialize(this._Mass2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.Mass2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Mass2ResponseValue)
        this.onReceive_Mass2Response(this._Mass2ResponseValue)
        return true
      }
      case MarginUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateNotifyModel.verify(), 'storage.MarginUpdateNotify validation failed!')
        let deserialized = this._MarginUpdateNotifyModel.deserialize(this._MarginUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'storage.MarginUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdateNotifyValue)
        this.onReceive_MarginUpdateNotify(this._MarginUpdateNotifyValue)
        return true
      }
      case PersonsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllRequestModel.verify(), 'storage.PersonsGetAllRequest validation failed!')
        let deserialized = this._PersonsGetAllRequestModel.deserialize(this._PersonsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'storage.PersonsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllRequestValue)
        this.onReceive_PersonsGetAllRequest(this._PersonsGetAllRequestValue)
        return true
      }
      case PersonsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllResponseModel.verify(), 'storage.PersonsGetAllResponse validation failed!')
        let deserialized = this._PersonsGetAllResponseModel.deserialize(this._PersonsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'storage.PersonsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllResponseValue)
        this.onReceive_PersonsGetAllResponse(this._PersonsGetAllResponseValue)
        return true
      }
      case PersonsInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertRequestModel.verify(), 'storage.PersonsInsertRequest validation failed!')
        let deserialized = this._PersonsInsertRequestModel.deserialize(this._PersonsInsertRequestValue)
        console.assert((deserialized.size > 0), 'storage.PersonsInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsertRequestValue)
        this.onReceive_PersonsInsertRequest(this._PersonsInsertRequestValue)
        return true
      }
      case PersonsInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertResponseModel.verify(), 'storage.PersonsInsertResponse validation failed!')
        let deserialized = this._PersonsInsertResponseModel.deserialize(this._PersonsInsertResponseValue)
        console.assert((deserialized.size > 0), 'storage.PersonsInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsertResponseValue)
        this.onReceive_PersonsInsertResponse(this._PersonsInsertResponseValue)
        return true
      }
      case PersonUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateRequestModel.verify(), 'storage.PersonUpdateRequest validation failed!')
        let deserialized = this._PersonUpdateRequestModel.deserialize(this._PersonUpdateRequestValue)
        console.assert((deserialized.size > 0), 'storage.PersonUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdateRequestValue)
        this.onReceive_PersonUpdateRequest(this._PersonUpdateRequestValue)
        return true
      }
      case PersonUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateResponseModel.verify(), 'storage.PersonUpdateResponse validation failed!')
        let deserialized = this._PersonUpdateResponseModel.deserialize(this._PersonUpdateResponseValue)
        console.assert((deserialized.size > 0), 'storage.PersonUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdateResponseValue)
        this.onReceive_PersonUpdateResponse(this._PersonUpdateResponseValue)
        return true
      }
      case PersonsInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsert2RequestModel.verify(), 'storage.PersonsInsert2Request validation failed!')
        let deserialized = this._PersonsInsert2RequestModel.deserialize(this._PersonsInsert2RequestValue)
        console.assert((deserialized.size > 0), 'storage.PersonsInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsert2RequestValue)
        this.onReceive_PersonsInsert2Request(this._PersonsInsert2RequestValue)
        return true
      }
      case PersonsInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsert2ResponseModel.verify(), 'storage.PersonsInsert2Response validation failed!')
        let deserialized = this._PersonsInsert2ResponseModel.deserialize(this._PersonsInsert2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.PersonsInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsert2ResponseValue)
        this.onReceive_PersonsInsert2Response(this._PersonsInsert2ResponseValue)
        return true
      }
      case PersonUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdate2RequestModel.verify(), 'storage.PersonUpdate2Request validation failed!')
        let deserialized = this._PersonUpdate2RequestModel.deserialize(this._PersonUpdate2RequestValue)
        console.assert((deserialized.size > 0), 'storage.PersonUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdate2RequestValue)
        this.onReceive_PersonUpdate2Request(this._PersonUpdate2RequestValue)
        return true
      }
      case PersonUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdate2ResponseModel.verify(), 'storage.PersonUpdate2Response validation failed!')
        let deserialized = this._PersonUpdate2ResponseModel.deserialize(this._PersonUpdate2ResponseValue)
        console.assert((deserialized.size > 0), 'storage.PersonUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdate2ResponseValue)
        this.onReceive_PersonUpdate2Response(this._PersonUpdate2ResponseValue)
        return true
      }
      case LastIdsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LastIdsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LastIdsGetRequestModel.verify(), 'storage.LastIdsGetRequest validation failed!')
        let deserialized = this._LastIdsGetRequestModel.deserialize(this._LastIdsGetRequestValue)
        console.assert((deserialized.size > 0), 'storage.LastIdsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LastIdsGetRequestValue)
        this.onReceive_LastIdsGetRequest(this._LastIdsGetRequestValue)
        return true
      }
      case LastIdsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LastIdsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LastIdsGetResponseModel.verify(), 'storage.LastIdsGetResponse validation failed!')
        let deserialized = this._LastIdsGetResponseModel.deserialize(this._LastIdsGetResponseValue)
        console.assert((deserialized.size > 0), 'storage.LastIdsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LastIdsGetResponseValue)
        this.onReceive_LastIdsGetResponse(this._LastIdsGetResponseValue)
        return true
      }
      case DailySnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DailySnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotRequestModel.verify(), 'storage.DailySnapshotRequest validation failed!')
        let deserialized = this._DailySnapshotRequestModel.deserialize(this._DailySnapshotRequestValue)
        console.assert((deserialized.size > 0), 'storage.DailySnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DailySnapshotRequestValue)
        this.onReceive_DailySnapshotRequest(this._DailySnapshotRequestValue)
        return true
      }
      case DailySnapshotResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DailySnapshotResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotResponseModel.verify(), 'storage.DailySnapshotResponse validation failed!')
        let deserialized = this._DailySnapshotResponseModel.deserialize(this._DailySnapshotResponseValue)
        console.assert((deserialized.size > 0), 'storage.DailySnapshotResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DailySnapshotResponseValue)
        this.onReceive_DailySnapshotResponse(this._DailySnapshotResponseValue)
        return true
      }
      case BillingStatisticsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BillingStatisticsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BillingStatisticsGetRequestModel.verify(), 'storage.BillingStatisticsGetRequest validation failed!')
        let deserialized = this._BillingStatisticsGetRequestModel.deserialize(this._BillingStatisticsGetRequestValue)
        console.assert((deserialized.size > 0), 'storage.BillingStatisticsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BillingStatisticsGetRequestValue)
        this.onReceive_BillingStatisticsGetRequest(this._BillingStatisticsGetRequestValue)
        return true
      }
      case BillingStatisticsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BillingStatisticsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BillingStatisticsGetResponseModel.verify(), 'storage.BillingStatisticsGetResponse validation failed!')
        let deserialized = this._BillingStatisticsGetResponseModel.deserialize(this._BillingStatisticsGetResponseValue)
        console.assert((deserialized.size > 0), 'storage.BillingStatisticsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BillingStatisticsGetResponseValue)
        this.onReceive_BillingStatisticsGetResponse(this._BillingStatisticsGetResponseValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.innerReceiver != null) && this.innerReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
    this._innerReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding storage proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize storage proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._innerProxy = new inner.Proxy(this.buffer)
    this._StorageVersionRequestModel = new StorageVersionRequestModel()
    this._StorageVersionResponseModel = new StorageVersionResponseModel()
    this._StorageUpdateNotifyModel = new StorageUpdateNotifyModel()
    this._AccountInsertRequestModel = new AccountInsertRequestModel()
    this._AccountInsertResponseModel = new AccountInsertResponseModel()
    this._AccountSocialTradingMasterInsertRequestModel = new AccountSocialTradingMasterInsertRequestModel()
    this._AccountSocialTradingMasterInsertResponseModel = new AccountSocialTradingMasterInsertResponseModel()
    this._AccountSocialTradingInvestorInsertRequestModel = new AccountSocialTradingInvestorInsertRequestModel()
    this._AccountSocialTradingInvestorInsertResponseModel = new AccountSocialTradingInvestorInsertResponseModel()
    this._AccountUpdateRequestModel = new AccountUpdateRequestModel()
    this._AccountUpdateResponseModel = new AccountUpdateResponseModel()
    this._AccountDeleteRequestModel = new AccountDeleteRequestModel()
    this._AccountDeleteResponseModel = new AccountDeleteResponseModel()
    this._MarginUpdateRequestModel = new MarginUpdateRequestModel()
    this._MarginUpdateResponseModel = new MarginUpdateResponseModel()
    this._AssetInsertRequestModel = new AssetInsertRequestModel()
    this._AssetInsertResponseModel = new AssetInsertResponseModel()
    this._AssetUpdateRequestModel = new AssetUpdateRequestModel()
    this._AssetUpdateResponseModel = new AssetUpdateResponseModel()
    this._AssetDeleteRequestModel = new AssetDeleteRequestModel()
    this._AssetDeleteResponseModel = new AssetDeleteResponseModel()
    this._OrderInsertRequestModel = new OrderInsertRequestModel()
    this._OrderInsertResponseModel = new OrderInsertResponseModel()
    this._OrderUpdateRequestModel = new OrderUpdateRequestModel()
    this._OrderUpdateResponseModel = new OrderUpdateResponseModel()
    this._OrderDeleteRequestModel = new OrderDeleteRequestModel()
    this._OrderDeleteResponseModel = new OrderDeleteResponseModel()
    this._OrderDeleteAllRequestModel = new OrderDeleteAllRequestModel()
    this._OrderDeleteAllResponseModel = new OrderDeleteAllResponseModel()
    this._PositionInsertRequestModel = new PositionInsertRequestModel()
    this._PositionInsertResponseModel = new PositionInsertResponseModel()
    this._PositionUpdateRequestModel = new PositionUpdateRequestModel()
    this._PositionUpdateResponseModel = new PositionUpdateResponseModel()
    this._PositionDeleteRequestModel = new PositionDeleteRequestModel()
    this._PositionDeleteResponseModel = new PositionDeleteResponseModel()
    this._TradeHistoryRequestModel = new TradeHistoryRequestModel()
    this._TradeHistoryResponseModel = new TradeHistoryResponseModel()
    this._TradeEventsGetRequestModel = new TradeEventsGetRequestModel()
    this._TradeEventsGetResponseModel = new TradeEventsGetResponseModel()
    this._TradeEventsNotifyModel = new TradeEventsNotifyModel()
    this._TradeEventsInsertRequestModel = new TradeEventsInsertRequestModel()
    this._TradeEventsInsertResponseModel = new TradeEventsInsertResponseModel()
    this._ProfitClosedGetRequestModel = new ProfitClosedGetRequestModel()
    this._ProfitClosedGetResponseModel = new ProfitClosedGetResponseModel()
    this._OrdersClosedSummariesGetRequestModel = new OrdersClosedSummariesGetRequestModel()
    this._OrdersClosedSummariesGetResponseModel = new OrdersClosedSummariesGetResponseModel()
    this._OrderInsert2RequestModel = new OrderInsert2RequestModel()
    this._OrderInsert2ResponseModel = new OrderInsert2ResponseModel()
    this._OrderUpdate2RequestModel = new OrderUpdate2RequestModel()
    this._OrderUpdate2ResponseModel = new OrderUpdate2ResponseModel()
    this._OrderDelete2RequestModel = new OrderDelete2RequestModel()
    this._OrderDelete2ResponseModel = new OrderDelete2ResponseModel()
    this._AccountInsert2RequestModel = new AccountInsert2RequestModel()
    this._AccountInsert2ResponseModel = new AccountInsert2ResponseModel()
    this._AccountSocialTradingMasterInsert2RequestModel = new AccountSocialTradingMasterInsert2RequestModel()
    this._AccountSocialTradingMasterInsert2ResponseModel = new AccountSocialTradingMasterInsert2ResponseModel()
    this._AccountSocialTradingInvestorInsert2RequestModel = new AccountSocialTradingInvestorInsert2RequestModel()
    this._AccountSocialTradingInvestorInsert2ResponseModel = new AccountSocialTradingInvestorInsert2ResponseModel()
    this._AccountDelete2RequestModel = new AccountDelete2RequestModel()
    this._AccountDelete2ResponseModel = new AccountDelete2ResponseModel()
    this._AssetInsert2RequestModel = new AssetInsert2RequestModel()
    this._AssetInsert2ResponseModel = new AssetInsert2ResponseModel()
    this._AssetUpdate2RequestModel = new AssetUpdate2RequestModel()
    this._AssetUpdate2ResponseModel = new AssetUpdate2ResponseModel()
    this._AssetDelete2RequestModel = new AssetDelete2RequestModel()
    this._AssetDelete2ResponseModel = new AssetDelete2ResponseModel()
    this._PositionInsert2RequestModel = new PositionInsert2RequestModel()
    this._PositionInsert2ResponseModel = new PositionInsert2ResponseModel()
    this._PositionUpdate2RequestModel = new PositionUpdate2RequestModel()
    this._PositionUpdate2ResponseModel = new PositionUpdate2ResponseModel()
    this._PositionDelete2RequestModel = new PositionDelete2RequestModel()
    this._PositionDelete2ResponseModel = new PositionDelete2ResponseModel()
    this._Deprecated001Model = new Deprecated001Model()
    this._Deprecated002Model = new Deprecated002Model()
    this._Deprecated003Model = new Deprecated003Model()
    this._Deprecated004Model = new Deprecated004Model()
    this._Deprecated005Model = new Deprecated005Model()
    this._Deprecated006Model = new Deprecated006Model()
    this._TradeEventsInsert2RequestModel = new TradeEventsInsert2RequestModel()
    this._TradeEventsInsert2ResponseModel = new TradeEventsInsert2ResponseModel()
    this._MarginUpdate2RequestModel = new MarginUpdate2RequestModel()
    this._MarginUpdate2ResponseModel = new MarginUpdate2ResponseModel()
    this._AccountUpdate2RequestModel = new AccountUpdate2RequestModel()
    this._AccountUpdate2ResponseModel = new AccountUpdate2ResponseModel()
    this._DepositMarginRequestModel = new DepositMarginRequestModel()
    this._DepositMarginResponseModel = new DepositMarginResponseModel()
    this._DepositMargin2RequestModel = new DepositMargin2RequestModel()
    this._DepositMargin2ResponseModel = new DepositMargin2ResponseModel()
    this._StoreClientLoginSuccessRequestModel = new StoreClientLoginSuccessRequestModel()
    this._StoreClientLoginSuccessResponseModel = new StoreClientLoginSuccessResponseModel()
    this._ClientLoginInfoGetRequestModel = new ClientLoginInfoGetRequestModel()
    this._ClientLoginInfoGetResponseModel = new ClientLoginInfoGetResponseModel()
    this._ClientLoginInfoNotifyModel = new ClientLoginInfoNotifyModel()
    this._StoreClientLoginSuccess2RequestModel = new StoreClientLoginSuccess2RequestModel()
    this._StoreClientLoginSuccess2ResponseModel = new StoreClientLoginSuccess2ResponseModel()
    this._OrderUpdateWithTypeRequestModel = new OrderUpdateWithTypeRequestModel()
    this._OrderUpdateWithTypeResponseModel = new OrderUpdateWithTypeResponseModel()
    this._OrderUpdateWithType2RequestModel = new OrderUpdateWithType2RequestModel()
    this._OrderUpdateWithType2ResponseModel = new OrderUpdateWithType2ResponseModel()
    this._TradeHistoryTimeRangeGetRequestModel = new TradeHistoryTimeRangeGetRequestModel()
    this._TradeHistoryTimeRangeGetResponseModel = new TradeHistoryTimeRangeGetResponseModel()
    this._TradeEventsTimeRangeGetRequestModel = new TradeEventsTimeRangeGetRequestModel()
    this._TradeEventsTimeRangeGetResponseModel = new TradeEventsTimeRangeGetResponseModel()
    this._TradeHistoryByIdRequestModel = new TradeHistoryByIdRequestModel()
    this._TradeHistoryByIdResponseModel = new TradeHistoryByIdResponseModel()
    this._OrdersGetAllRequestModel = new OrdersGetAllRequestModel()
    this._OrdersGetAllResponseModel = new OrdersGetAllResponseModel()
    this._PositionsGetAllRequestModel = new PositionsGetAllRequestModel()
    this._PositionsGetAllResponseModel = new PositionsGetAllResponseModel()
    this._AssetsGetAllRequestModel = new AssetsGetAllRequestModel()
    this._AssetsGetAllResponseModel = new AssetsGetAllResponseModel()
    this._MarginsGetAllRequestModel = new MarginsGetAllRequestModel()
    this._MarginsGetAllResponseModel = new MarginsGetAllResponseModel()
    this._AccountsGetAllRequestModel = new AccountsGetAllRequestModel()
    this._AccountsGetAllResponseModel = new AccountsGetAllResponseModel()
    this._MassRequestModel = new MassRequestModel()
    this._MassResponseModel = new MassResponseModel()
    this._Mass2RequestModel = new Mass2RequestModel()
    this._Mass2ResponseParamsModel = new Mass2ResponseParamsModel()
    this._Mass2ResponseModel = new Mass2ResponseModel()
    this._MarginUpdateNotifyModel = new MarginUpdateNotifyModel()
    this._PersonsGetAllRequestModel = new PersonsGetAllRequestModel()
    this._PersonsGetAllResponseModel = new PersonsGetAllResponseModel()
    this._PersonsInsertRequestModel = new PersonsInsertRequestModel()
    this._PersonsInsertResponseModel = new PersonsInsertResponseModel()
    this._PersonUpdateRequestModel = new PersonUpdateRequestModel()
    this._PersonUpdateResponseModel = new PersonUpdateResponseModel()
    this._PersonsInsert2RequestModel = new PersonsInsert2RequestModel()
    this._PersonsInsert2ResponseModel = new PersonsInsert2ResponseModel()
    this._PersonUpdate2RequestModel = new PersonUpdate2RequestModel()
    this._PersonUpdate2ResponseModel = new PersonUpdate2ResponseModel()
    this._LastIdsGetRequestModel = new LastIdsGetRequestModel()
    this._LastIdsGetResponseModel = new LastIdsGetResponseModel()
    this._DailySnapshotRequestModel = new DailySnapshotRequestModel()
    this._DailySnapshotResponseModel = new DailySnapshotResponseModel()
    this._BillingStatisticsGetRequestModel = new BillingStatisticsGetRequestModel()
    this._BillingStatisticsGetResponseModel = new BillingStatisticsGetResponseModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  /**
   * Get imported inner proxy
   * @this {!Proxy}
   * @returns {Proxy} inner proxy
   */
  get innerProxy () {
    return this._innerProxy
  }

  /**
   * Set imported inner proxy
   * @this {!Proxy}
   * @param {Proxy} proxy inner proxy
   */
  set innerProxy (proxy) {
    this._innerProxy = proxy
  }

  // Proxy handlers

  /**
   * StorageVersionRequest proxy handler
   * @this {!Proxy}
   * @param {!StorageVersionRequest} model StorageVersionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_StorageVersionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * StorageVersionResponse proxy handler
   * @this {!Proxy}
   * @param {!StorageVersionResponse} model StorageVersionResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_StorageVersionResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * StorageUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!StorageUpdateNotify} model StorageUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_StorageUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountInsertRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountInsertRequest} model AccountInsertRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountInsertRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountInsertResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountInsertResponse} model AccountInsertResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountInsertResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsertRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingMasterInsertRequest} model AccountSocialTradingMasterInsertRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingMasterInsertRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsertResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingMasterInsertResponse} model AccountSocialTradingMasterInsertResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingMasterInsertResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsertRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingInvestorInsertRequest} model AccountSocialTradingInvestorInsertRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingInvestorInsertRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsertResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingInvestorInsertResponse} model AccountSocialTradingInvestorInsertResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingInvestorInsertResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountUpdateRequest} model AccountUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountUpdateResponse} model AccountUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountDeleteRequest} model AccountDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountDeleteResponse} model AccountDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!MarginUpdateRequest} model MarginUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!MarginUpdateResponse} model MarginUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetInsertRequest proxy handler
   * @this {!Proxy}
   * @param {!AssetInsertRequest} model AssetInsertRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetInsertRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetInsertResponse proxy handler
   * @this {!Proxy}
   * @param {!AssetInsertResponse} model AssetInsertResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetInsertResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!AssetUpdateRequest} model AssetUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!AssetUpdateResponse} model AssetUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!AssetDeleteRequest} model AssetDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!AssetDeleteResponse} model AssetDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderInsertRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderInsertRequest} model OrderInsertRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderInsertRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderInsertResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderInsertResponse} model OrderInsertResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderInsertResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdateRequest} model OrderUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdateResponse} model OrderUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderDeleteRequest} model OrderDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderDeleteResponse} model OrderDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderDeleteAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderDeleteAllRequest} model OrderDeleteAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderDeleteAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderDeleteAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderDeleteAllResponse} model OrderDeleteAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderDeleteAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionInsertRequest proxy handler
   * @this {!Proxy}
   * @param {!PositionInsertRequest} model PositionInsertRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionInsertRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionInsertResponse proxy handler
   * @this {!Proxy}
   * @param {!PositionInsertResponse} model PositionInsertResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionInsertResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!PositionUpdateRequest} model PositionUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!PositionUpdateResponse} model PositionUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionDeleteRequest proxy handler
   * @this {!Proxy}
   * @param {!PositionDeleteRequest} model PositionDeleteRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionDeleteRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionDeleteResponse proxy handler
   * @this {!Proxy}
   * @param {!PositionDeleteResponse} model PositionDeleteResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionDeleteResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryRequest} model TradeHistoryRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryResponse} model TradeHistoryResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsGetRequest} model TradeEventsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsGetResponse} model TradeEventsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsNotify proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsNotify} model TradeEventsNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsInsertRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsInsertRequest} model TradeEventsInsertRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsInsertRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsInsertResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsInsertResponse} model TradeEventsInsertResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsInsertResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ProfitClosedGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ProfitClosedGetRequest} model ProfitClosedGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ProfitClosedGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ProfitClosedGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ProfitClosedGetResponse} model ProfitClosedGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ProfitClosedGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetRequest proxy handler
   * @this {!Proxy}
   * @param {!OrdersClosedSummariesGetRequest} model OrdersClosedSummariesGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersClosedSummariesGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetResponse proxy handler
   * @this {!Proxy}
   * @param {!OrdersClosedSummariesGetResponse} model OrdersClosedSummariesGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersClosedSummariesGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderInsert2Request proxy handler
   * @this {!Proxy}
   * @param {!OrderInsert2Request} model OrderInsert2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderInsert2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderInsert2Response proxy handler
   * @this {!Proxy}
   * @param {!OrderInsert2Response} model OrderInsert2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderInsert2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdate2Request proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdate2Request} model OrderUpdate2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdate2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdate2Response proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdate2Response} model OrderUpdate2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdate2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderDelete2Request proxy handler
   * @this {!Proxy}
   * @param {!OrderDelete2Request} model OrderDelete2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderDelete2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderDelete2Response proxy handler
   * @this {!Proxy}
   * @param {!OrderDelete2Response} model OrderDelete2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderDelete2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountInsert2Request proxy handler
   * @this {!Proxy}
   * @param {!AccountInsert2Request} model AccountInsert2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountInsert2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountInsert2Response proxy handler
   * @this {!Proxy}
   * @param {!AccountInsert2Response} model AccountInsert2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountInsert2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsert2Request proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingMasterInsert2Request} model AccountSocialTradingMasterInsert2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingMasterInsert2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsert2Response proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingMasterInsert2Response} model AccountSocialTradingMasterInsert2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingMasterInsert2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsert2Request proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingInvestorInsert2Request} model AccountSocialTradingInvestorInsert2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingInvestorInsert2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsert2Response proxy handler
   * @this {!Proxy}
   * @param {!AccountSocialTradingInvestorInsert2Response} model AccountSocialTradingInvestorInsert2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountSocialTradingInvestorInsert2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountDelete2Request proxy handler
   * @this {!Proxy}
   * @param {!AccountDelete2Request} model AccountDelete2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountDelete2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountDelete2Response proxy handler
   * @this {!Proxy}
   * @param {!AccountDelete2Response} model AccountDelete2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountDelete2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetInsert2Request proxy handler
   * @this {!Proxy}
   * @param {!AssetInsert2Request} model AssetInsert2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetInsert2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetInsert2Response proxy handler
   * @this {!Proxy}
   * @param {!AssetInsert2Response} model AssetInsert2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetInsert2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetUpdate2Request proxy handler
   * @this {!Proxy}
   * @param {!AssetUpdate2Request} model AssetUpdate2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetUpdate2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetUpdate2Response proxy handler
   * @this {!Proxy}
   * @param {!AssetUpdate2Response} model AssetUpdate2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetUpdate2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetDelete2Request proxy handler
   * @this {!Proxy}
   * @param {!AssetDelete2Request} model AssetDelete2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetDelete2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetDelete2Response proxy handler
   * @this {!Proxy}
   * @param {!AssetDelete2Response} model AssetDelete2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetDelete2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionInsert2Request proxy handler
   * @this {!Proxy}
   * @param {!PositionInsert2Request} model PositionInsert2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionInsert2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionInsert2Response proxy handler
   * @this {!Proxy}
   * @param {!PositionInsert2Response} model PositionInsert2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionInsert2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionUpdate2Request proxy handler
   * @this {!Proxy}
   * @param {!PositionUpdate2Request} model PositionUpdate2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionUpdate2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionUpdate2Response proxy handler
   * @this {!Proxy}
   * @param {!PositionUpdate2Response} model PositionUpdate2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionUpdate2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionDelete2Request proxy handler
   * @this {!Proxy}
   * @param {!PositionDelete2Request} model PositionDelete2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionDelete2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionDelete2Response proxy handler
   * @this {!Proxy}
   * @param {!PositionDelete2Response} model PositionDelete2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionDelete2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated001 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated001} model Deprecated001 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated001 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated002 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated002} model Deprecated002 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated002 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated003 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated003} model Deprecated003 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated003 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated004 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated004} model Deprecated004 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated004 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated005 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated005} model Deprecated005 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated005 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Deprecated006 proxy handler
   * @this {!Proxy}
   * @param {!Deprecated006} model Deprecated006 model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Deprecated006 (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsInsert2Request proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsInsert2Request} model TradeEventsInsert2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsInsert2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsInsert2Response proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsInsert2Response} model TradeEventsInsert2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsInsert2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginUpdate2Request proxy handler
   * @this {!Proxy}
   * @param {!MarginUpdate2Request} model MarginUpdate2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginUpdate2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginUpdate2Response proxy handler
   * @this {!Proxy}
   * @param {!MarginUpdate2Response} model MarginUpdate2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginUpdate2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountUpdate2Request proxy handler
   * @this {!Proxy}
   * @param {!AccountUpdate2Request} model AccountUpdate2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountUpdate2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountUpdate2Response proxy handler
   * @this {!Proxy}
   * @param {!AccountUpdate2Response} model AccountUpdate2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountUpdate2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DepositMarginRequest proxy handler
   * @this {!Proxy}
   * @param {!DepositMarginRequest} model DepositMarginRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DepositMarginRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DepositMarginResponse proxy handler
   * @this {!Proxy}
   * @param {!DepositMarginResponse} model DepositMarginResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DepositMarginResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DepositMargin2Request proxy handler
   * @this {!Proxy}
   * @param {!DepositMargin2Request} model DepositMargin2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DepositMargin2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DepositMargin2Response proxy handler
   * @this {!Proxy}
   * @param {!DepositMargin2Response} model DepositMargin2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DepositMargin2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccessRequest proxy handler
   * @this {!Proxy}
   * @param {!StoreClientLoginSuccessRequest} model StoreClientLoginSuccessRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_StoreClientLoginSuccessRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccessResponse proxy handler
   * @this {!Proxy}
   * @param {!StoreClientLoginSuccessResponse} model StoreClientLoginSuccessResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_StoreClientLoginSuccessResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientLoginInfoGetRequest} model ClientLoginInfoGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientLoginInfoGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientLoginInfoGetResponse} model ClientLoginInfoGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientLoginInfoGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientLoginInfoNotify proxy handler
   * @this {!Proxy}
   * @param {!ClientLoginInfoNotify} model ClientLoginInfoNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientLoginInfoNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccess2Request proxy handler
   * @this {!Proxy}
   * @param {!StoreClientLoginSuccess2Request} model StoreClientLoginSuccess2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_StoreClientLoginSuccess2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccess2Response proxy handler
   * @this {!Proxy}
   * @param {!StoreClientLoginSuccess2Response} model StoreClientLoginSuccess2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_StoreClientLoginSuccess2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeRequest proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdateWithTypeRequest} model OrderUpdateWithTypeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdateWithTypeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeResponse proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdateWithTypeResponse} model OrderUpdateWithTypeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdateWithTypeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdateWithType2Request proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdateWithType2Request} model OrderUpdateWithType2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdateWithType2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrderUpdateWithType2Response proxy handler
   * @this {!Proxy}
   * @param {!OrderUpdateWithType2Response} model OrderUpdateWithType2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrderUpdateWithType2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryTimeRangeGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryTimeRangeGetRequest} model TradeHistoryTimeRangeGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryTimeRangeGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryTimeRangeGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryTimeRangeGetResponse} model TradeHistoryTimeRangeGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryTimeRangeGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsTimeRangeGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsTimeRangeGetRequest} model TradeEventsTimeRangeGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsTimeRangeGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeEventsTimeRangeGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeEventsTimeRangeGetResponse} model TradeEventsTimeRangeGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeEventsTimeRangeGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdRequest proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryByIdRequest} model TradeHistoryByIdRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryByIdRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdResponse proxy handler
   * @this {!Proxy}
   * @param {!TradeHistoryByIdResponse} model TradeHistoryByIdResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TradeHistoryByIdResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!OrdersGetAllRequest} model OrdersGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * OrdersGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!OrdersGetAllResponse} model OrdersGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_OrdersGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!PositionsGetAllRequest} model PositionsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PositionsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!PositionsGetAllResponse} model PositionsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PositionsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!AssetsGetAllRequest} model AssetsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AssetsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!AssetsGetAllResponse} model AssetsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AssetsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!MarginsGetAllRequest} model MarginsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!MarginsGetAllResponse} model MarginsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!AccountsGetAllRequest} model AccountsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * AccountsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!AccountsGetAllResponse} model AccountsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_AccountsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MassRequest proxy handler
   * @this {!Proxy}
   * @param {!MassRequest} model MassRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MassRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MassResponse proxy handler
   * @this {!Proxy}
   * @param {!MassResponse} model MassResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MassResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Mass2Request proxy handler
   * @this {!Proxy}
   * @param {!Mass2Request} model Mass2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Mass2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Mass2ResponseParams proxy handler
   * @this {!Proxy}
   * @param {!Mass2ResponseParams} model Mass2ResponseParams model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Mass2ResponseParams (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Mass2Response proxy handler
   * @this {!Proxy}
   * @param {!Mass2Response} model Mass2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Mass2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * MarginUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!MarginUpdateNotify} model MarginUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_MarginUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!PersonsGetAllRequest} model PersonsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!PersonsGetAllResponse} model PersonsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsInsertRequest proxy handler
   * @this {!Proxy}
   * @param {!PersonsInsertRequest} model PersonsInsertRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsInsertRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsInsertResponse proxy handler
   * @this {!Proxy}
   * @param {!PersonsInsertResponse} model PersonsInsertResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsInsertResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!PersonUpdateRequest} model PersonUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!PersonUpdateResponse} model PersonUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsInsert2Request proxy handler
   * @this {!Proxy}
   * @param {!PersonsInsert2Request} model PersonsInsert2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsInsert2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonsInsert2Response proxy handler
   * @this {!Proxy}
   * @param {!PersonsInsert2Response} model PersonsInsert2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonsInsert2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonUpdate2Request proxy handler
   * @this {!Proxy}
   * @param {!PersonUpdate2Request} model PersonUpdate2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonUpdate2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * PersonUpdate2Response proxy handler
   * @this {!Proxy}
   * @param {!PersonUpdate2Response} model PersonUpdate2Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_PersonUpdate2Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LastIdsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!LastIdsGetRequest} model LastIdsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LastIdsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LastIdsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!LastIdsGetResponse} model LastIdsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LastIdsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DailySnapshotRequest proxy handler
   * @this {!Proxy}
   * @param {!DailySnapshotRequest} model DailySnapshotRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DailySnapshotRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DailySnapshotResponse proxy handler
   * @this {!Proxy}
   * @param {!DailySnapshotResponse} model DailySnapshotResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DailySnapshotResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BillingStatisticsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!BillingStatisticsGetRequest} model BillingStatisticsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BillingStatisticsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BillingStatisticsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!BillingStatisticsGetResponse} model BillingStatisticsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BillingStatisticsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * storage receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case StorageVersionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._StorageVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._StorageVersionRequestModel.verify(), 'storage.StorageVersionRequest validation failed!')

        let fbeBegin = this._StorageVersionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_StorageVersionRequest(this._StorageVersionRequestModel, type, buffer, offset, size)
        this._StorageVersionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case StorageVersionResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._StorageVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._StorageVersionResponseModel.verify(), 'storage.StorageVersionResponse validation failed!')

        let fbeBegin = this._StorageVersionResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_StorageVersionResponse(this._StorageVersionResponseModel, type, buffer, offset, size)
        this._StorageVersionResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case StorageUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._StorageUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._StorageUpdateNotifyModel.verify(), 'storage.StorageUpdateNotify validation failed!')

        let fbeBegin = this._StorageUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_StorageUpdateNotify(this._StorageUpdateNotifyModel, type, buffer, offset, size)
        this._StorageUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountInsertRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsertRequestModel.verify(), 'storage.AccountInsertRequest validation failed!')

        let fbeBegin = this._AccountInsertRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountInsertRequest(this._AccountInsertRequestModel, type, buffer, offset, size)
        this._AccountInsertRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountInsertResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsertResponseModel.verify(), 'storage.AccountInsertResponse validation failed!')

        let fbeBegin = this._AccountInsertResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountInsertResponse(this._AccountInsertResponseModel, type, buffer, offset, size)
        this._AccountInsertResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingMasterInsertRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingMasterInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsertRequestModel.verify(), 'storage.AccountSocialTradingMasterInsertRequest validation failed!')

        let fbeBegin = this._AccountSocialTradingMasterInsertRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingMasterInsertRequest(this._AccountSocialTradingMasterInsertRequestModel, type, buffer, offset, size)
        this._AccountSocialTradingMasterInsertRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingMasterInsertResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingMasterInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsertResponseModel.verify(), 'storage.AccountSocialTradingMasterInsertResponse validation failed!')

        let fbeBegin = this._AccountSocialTradingMasterInsertResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingMasterInsertResponse(this._AccountSocialTradingMasterInsertResponseModel, type, buffer, offset, size)
        this._AccountSocialTradingMasterInsertResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingInvestorInsertRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingInvestorInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsertRequestModel.verify(), 'storage.AccountSocialTradingInvestorInsertRequest validation failed!')

        let fbeBegin = this._AccountSocialTradingInvestorInsertRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingInvestorInsertRequest(this._AccountSocialTradingInvestorInsertRequestModel, type, buffer, offset, size)
        this._AccountSocialTradingInvestorInsertRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingInvestorInsertResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingInvestorInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsertResponseModel.verify(), 'storage.AccountSocialTradingInvestorInsertResponse validation failed!')

        let fbeBegin = this._AccountSocialTradingInvestorInsertResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingInvestorInsertResponse(this._AccountSocialTradingInvestorInsertResponseModel, type, buffer, offset, size)
        this._AccountSocialTradingInvestorInsertResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateRequestModel.verify(), 'storage.AccountUpdateRequest validation failed!')

        let fbeBegin = this._AccountUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountUpdateRequest(this._AccountUpdateRequestModel, type, buffer, offset, size)
        this._AccountUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateResponseModel.verify(), 'storage.AccountUpdateResponse validation failed!')

        let fbeBegin = this._AccountUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountUpdateResponse(this._AccountUpdateResponseModel, type, buffer, offset, size)
        this._AccountUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteRequestModel.verify(), 'storage.AccountDeleteRequest validation failed!')

        let fbeBegin = this._AccountDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountDeleteRequest(this._AccountDeleteRequestModel, type, buffer, offset, size)
        this._AccountDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteResponseModel.verify(), 'storage.AccountDeleteResponse validation failed!')

        let fbeBegin = this._AccountDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountDeleteResponse(this._AccountDeleteResponseModel, type, buffer, offset, size)
        this._AccountDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateRequestModel.verify(), 'storage.MarginUpdateRequest validation failed!')

        let fbeBegin = this._MarginUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginUpdateRequest(this._MarginUpdateRequestModel, type, buffer, offset, size)
        this._MarginUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateResponseModel.verify(), 'storage.MarginUpdateResponse validation failed!')

        let fbeBegin = this._MarginUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginUpdateResponse(this._MarginUpdateResponseModel, type, buffer, offset, size)
        this._MarginUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetInsertRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsertRequestModel.verify(), 'storage.AssetInsertRequest validation failed!')

        let fbeBegin = this._AssetInsertRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetInsertRequest(this._AssetInsertRequestModel, type, buffer, offset, size)
        this._AssetInsertRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetInsertResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsertResponseModel.verify(), 'storage.AssetInsertResponse validation failed!')

        let fbeBegin = this._AssetInsertResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetInsertResponse(this._AssetInsertResponseModel, type, buffer, offset, size)
        this._AssetInsertResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdateRequestModel.verify(), 'storage.AssetUpdateRequest validation failed!')

        let fbeBegin = this._AssetUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetUpdateRequest(this._AssetUpdateRequestModel, type, buffer, offset, size)
        this._AssetUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdateResponseModel.verify(), 'storage.AssetUpdateResponse validation failed!')

        let fbeBegin = this._AssetUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetUpdateResponse(this._AssetUpdateResponseModel, type, buffer, offset, size)
        this._AssetUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDeleteRequestModel.verify(), 'storage.AssetDeleteRequest validation failed!')

        let fbeBegin = this._AssetDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetDeleteRequest(this._AssetDeleteRequestModel, type, buffer, offset, size)
        this._AssetDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDeleteResponseModel.verify(), 'storage.AssetDeleteResponse validation failed!')

        let fbeBegin = this._AssetDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetDeleteResponse(this._AssetDeleteResponseModel, type, buffer, offset, size)
        this._AssetDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderInsertRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsertRequestModel.verify(), 'storage.OrderInsertRequest validation failed!')

        let fbeBegin = this._OrderInsertRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderInsertRequest(this._OrderInsertRequestModel, type, buffer, offset, size)
        this._OrderInsertRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderInsertResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsertResponseModel.verify(), 'storage.OrderInsertResponse validation failed!')

        let fbeBegin = this._OrderInsertResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderInsertResponse(this._OrderInsertResponseModel, type, buffer, offset, size)
        this._OrderInsertResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateRequestModel.verify(), 'storage.OrderUpdateRequest validation failed!')

        let fbeBegin = this._OrderUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdateRequest(this._OrderUpdateRequestModel, type, buffer, offset, size)
        this._OrderUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateResponseModel.verify(), 'storage.OrderUpdateResponse validation failed!')

        let fbeBegin = this._OrderUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdateResponse(this._OrderUpdateResponseModel, type, buffer, offset, size)
        this._OrderUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteRequestModel.verify(), 'storage.OrderDeleteRequest validation failed!')

        let fbeBegin = this._OrderDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderDeleteRequest(this._OrderDeleteRequestModel, type, buffer, offset, size)
        this._OrderDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteResponseModel.verify(), 'storage.OrderDeleteResponse validation failed!')

        let fbeBegin = this._OrderDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderDeleteResponse(this._OrderDeleteResponseModel, type, buffer, offset, size)
        this._OrderDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderDeleteAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderDeleteAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteAllRequestModel.verify(), 'storage.OrderDeleteAllRequest validation failed!')

        let fbeBegin = this._OrderDeleteAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderDeleteAllRequest(this._OrderDeleteAllRequestModel, type, buffer, offset, size)
        this._OrderDeleteAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderDeleteAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderDeleteAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteAllResponseModel.verify(), 'storage.OrderDeleteAllResponse validation failed!')

        let fbeBegin = this._OrderDeleteAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderDeleteAllResponse(this._OrderDeleteAllResponseModel, type, buffer, offset, size)
        this._OrderDeleteAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionInsertRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsertRequestModel.verify(), 'storage.PositionInsertRequest validation failed!')

        let fbeBegin = this._PositionInsertRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionInsertRequest(this._PositionInsertRequestModel, type, buffer, offset, size)
        this._PositionInsertRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionInsertResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsertResponseModel.verify(), 'storage.PositionInsertResponse validation failed!')

        let fbeBegin = this._PositionInsertResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionInsertResponse(this._PositionInsertResponseModel, type, buffer, offset, size)
        this._PositionInsertResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdateRequestModel.verify(), 'storage.PositionUpdateRequest validation failed!')

        let fbeBegin = this._PositionUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionUpdateRequest(this._PositionUpdateRequestModel, type, buffer, offset, size)
        this._PositionUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdateResponseModel.verify(), 'storage.PositionUpdateResponse validation failed!')

        let fbeBegin = this._PositionUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionUpdateResponse(this._PositionUpdateResponseModel, type, buffer, offset, size)
        this._PositionUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionDeleteRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionDeleteRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDeleteRequestModel.verify(), 'storage.PositionDeleteRequest validation failed!')

        let fbeBegin = this._PositionDeleteRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionDeleteRequest(this._PositionDeleteRequestModel, type, buffer, offset, size)
        this._PositionDeleteRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionDeleteResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionDeleteResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDeleteResponseModel.verify(), 'storage.PositionDeleteResponse validation failed!')

        let fbeBegin = this._PositionDeleteResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionDeleteResponse(this._PositionDeleteResponseModel, type, buffer, offset, size)
        this._PositionDeleteResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryRequestModel.verify(), 'storage.TradeHistoryRequest validation failed!')

        let fbeBegin = this._TradeHistoryRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryRequest(this._TradeHistoryRequestModel, type, buffer, offset, size)
        this._TradeHistoryRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryResponseModel.verify(), 'storage.TradeHistoryResponse validation failed!')

        let fbeBegin = this._TradeHistoryResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryResponse(this._TradeHistoryResponseModel, type, buffer, offset, size)
        this._TradeHistoryResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetRequestModel.verify(), 'storage.TradeEventsGetRequest validation failed!')

        let fbeBegin = this._TradeEventsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsGetRequest(this._TradeEventsGetRequestModel, type, buffer, offset, size)
        this._TradeEventsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetResponseModel.verify(), 'storage.TradeEventsGetResponse validation failed!')

        let fbeBegin = this._TradeEventsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsGetResponse(this._TradeEventsGetResponseModel, type, buffer, offset, size)
        this._TradeEventsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsNotifyModel.verify(), 'storage.TradeEventsNotify validation failed!')

        let fbeBegin = this._TradeEventsNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsNotify(this._TradeEventsNotifyModel, type, buffer, offset, size)
        this._TradeEventsNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsInsertRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsertRequestModel.verify(), 'storage.TradeEventsInsertRequest validation failed!')

        let fbeBegin = this._TradeEventsInsertRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsInsertRequest(this._TradeEventsInsertRequestModel, type, buffer, offset, size)
        this._TradeEventsInsertRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsInsertResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsertResponseModel.verify(), 'storage.TradeEventsInsertResponse validation failed!')

        let fbeBegin = this._TradeEventsInsertResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsInsertResponse(this._TradeEventsInsertResponseModel, type, buffer, offset, size)
        this._TradeEventsInsertResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ProfitClosedGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ProfitClosedGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetRequestModel.verify(), 'storage.ProfitClosedGetRequest validation failed!')

        let fbeBegin = this._ProfitClosedGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ProfitClosedGetRequest(this._ProfitClosedGetRequestModel, type, buffer, offset, size)
        this._ProfitClosedGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ProfitClosedGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ProfitClosedGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetResponseModel.verify(), 'storage.ProfitClosedGetResponse validation failed!')

        let fbeBegin = this._ProfitClosedGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ProfitClosedGetResponse(this._ProfitClosedGetResponseModel, type, buffer, offset, size)
        this._ProfitClosedGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersClosedSummariesGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersClosedSummariesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetRequestModel.verify(), 'storage.OrdersClosedSummariesGetRequest validation failed!')

        let fbeBegin = this._OrdersClosedSummariesGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersClosedSummariesGetRequest(this._OrdersClosedSummariesGetRequestModel, type, buffer, offset, size)
        this._OrdersClosedSummariesGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersClosedSummariesGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersClosedSummariesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetResponseModel.verify(), 'storage.OrdersClosedSummariesGetResponse validation failed!')

        let fbeBegin = this._OrdersClosedSummariesGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersClosedSummariesGetResponse(this._OrdersClosedSummariesGetResponseModel, type, buffer, offset, size)
        this._OrdersClosedSummariesGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderInsert2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsert2RequestModel.verify(), 'storage.OrderInsert2Request validation failed!')

        let fbeBegin = this._OrderInsert2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderInsert2Request(this._OrderInsert2RequestModel, type, buffer, offset, size)
        this._OrderInsert2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderInsert2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsert2ResponseModel.verify(), 'storage.OrderInsert2Response validation failed!')

        let fbeBegin = this._OrderInsert2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderInsert2Response(this._OrderInsert2ResponseModel, type, buffer, offset, size)
        this._OrderInsert2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdate2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdate2RequestModel.verify(), 'storage.OrderUpdate2Request validation failed!')

        let fbeBegin = this._OrderUpdate2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdate2Request(this._OrderUpdate2RequestModel, type, buffer, offset, size)
        this._OrderUpdate2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdate2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdate2ResponseModel.verify(), 'storage.OrderUpdate2Response validation failed!')

        let fbeBegin = this._OrderUpdate2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdate2Response(this._OrderUpdate2ResponseModel, type, buffer, offset, size)
        this._OrderUpdate2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderDelete2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderDelete2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDelete2RequestModel.verify(), 'storage.OrderDelete2Request validation failed!')

        let fbeBegin = this._OrderDelete2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderDelete2Request(this._OrderDelete2RequestModel, type, buffer, offset, size)
        this._OrderDelete2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderDelete2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderDelete2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDelete2ResponseModel.verify(), 'storage.OrderDelete2Response validation failed!')

        let fbeBegin = this._OrderDelete2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderDelete2Response(this._OrderDelete2ResponseModel, type, buffer, offset, size)
        this._OrderDelete2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountInsert2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsert2RequestModel.verify(), 'storage.AccountInsert2Request validation failed!')

        let fbeBegin = this._AccountInsert2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountInsert2Request(this._AccountInsert2RequestModel, type, buffer, offset, size)
        this._AccountInsert2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountInsert2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsert2ResponseModel.verify(), 'storage.AccountInsert2Response validation failed!')

        let fbeBegin = this._AccountInsert2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountInsert2Response(this._AccountInsert2ResponseModel, type, buffer, offset, size)
        this._AccountInsert2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingMasterInsert2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingMasterInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsert2RequestModel.verify(), 'storage.AccountSocialTradingMasterInsert2Request validation failed!')

        let fbeBegin = this._AccountSocialTradingMasterInsert2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingMasterInsert2Request(this._AccountSocialTradingMasterInsert2RequestModel, type, buffer, offset, size)
        this._AccountSocialTradingMasterInsert2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingMasterInsert2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingMasterInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsert2ResponseModel.verify(), 'storage.AccountSocialTradingMasterInsert2Response validation failed!')

        let fbeBegin = this._AccountSocialTradingMasterInsert2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingMasterInsert2Response(this._AccountSocialTradingMasterInsert2ResponseModel, type, buffer, offset, size)
        this._AccountSocialTradingMasterInsert2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingInvestorInsert2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingInvestorInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsert2RequestModel.verify(), 'storage.AccountSocialTradingInvestorInsert2Request validation failed!')

        let fbeBegin = this._AccountSocialTradingInvestorInsert2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingInvestorInsert2Request(this._AccountSocialTradingInvestorInsert2RequestModel, type, buffer, offset, size)
        this._AccountSocialTradingInvestorInsert2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountSocialTradingInvestorInsert2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountSocialTradingInvestorInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsert2ResponseModel.verify(), 'storage.AccountSocialTradingInvestorInsert2Response validation failed!')

        let fbeBegin = this._AccountSocialTradingInvestorInsert2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountSocialTradingInvestorInsert2Response(this._AccountSocialTradingInvestorInsert2ResponseModel, type, buffer, offset, size)
        this._AccountSocialTradingInvestorInsert2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountDelete2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountDelete2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDelete2RequestModel.verify(), 'storage.AccountDelete2Request validation failed!')

        let fbeBegin = this._AccountDelete2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountDelete2Request(this._AccountDelete2RequestModel, type, buffer, offset, size)
        this._AccountDelete2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountDelete2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountDelete2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDelete2ResponseModel.verify(), 'storage.AccountDelete2Response validation failed!')

        let fbeBegin = this._AccountDelete2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountDelete2Response(this._AccountDelete2ResponseModel, type, buffer, offset, size)
        this._AccountDelete2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetInsert2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsert2RequestModel.verify(), 'storage.AssetInsert2Request validation failed!')

        let fbeBegin = this._AssetInsert2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetInsert2Request(this._AssetInsert2RequestModel, type, buffer, offset, size)
        this._AssetInsert2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetInsert2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsert2ResponseModel.verify(), 'storage.AssetInsert2Response validation failed!')

        let fbeBegin = this._AssetInsert2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetInsert2Response(this._AssetInsert2ResponseModel, type, buffer, offset, size)
        this._AssetInsert2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetUpdate2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdate2RequestModel.verify(), 'storage.AssetUpdate2Request validation failed!')

        let fbeBegin = this._AssetUpdate2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetUpdate2Request(this._AssetUpdate2RequestModel, type, buffer, offset, size)
        this._AssetUpdate2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetUpdate2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdate2ResponseModel.verify(), 'storage.AssetUpdate2Response validation failed!')

        let fbeBegin = this._AssetUpdate2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetUpdate2Response(this._AssetUpdate2ResponseModel, type, buffer, offset, size)
        this._AssetUpdate2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetDelete2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetDelete2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDelete2RequestModel.verify(), 'storage.AssetDelete2Request validation failed!')

        let fbeBegin = this._AssetDelete2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetDelete2Request(this._AssetDelete2RequestModel, type, buffer, offset, size)
        this._AssetDelete2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetDelete2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetDelete2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDelete2ResponseModel.verify(), 'storage.AssetDelete2Response validation failed!')

        let fbeBegin = this._AssetDelete2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetDelete2Response(this._AssetDelete2ResponseModel, type, buffer, offset, size)
        this._AssetDelete2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionInsert2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsert2RequestModel.verify(), 'storage.PositionInsert2Request validation failed!')

        let fbeBegin = this._PositionInsert2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionInsert2Request(this._PositionInsert2RequestModel, type, buffer, offset, size)
        this._PositionInsert2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionInsert2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsert2ResponseModel.verify(), 'storage.PositionInsert2Response validation failed!')

        let fbeBegin = this._PositionInsert2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionInsert2Response(this._PositionInsert2ResponseModel, type, buffer, offset, size)
        this._PositionInsert2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionUpdate2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdate2RequestModel.verify(), 'storage.PositionUpdate2Request validation failed!')

        let fbeBegin = this._PositionUpdate2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionUpdate2Request(this._PositionUpdate2RequestModel, type, buffer, offset, size)
        this._PositionUpdate2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionUpdate2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdate2ResponseModel.verify(), 'storage.PositionUpdate2Response validation failed!')

        let fbeBegin = this._PositionUpdate2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionUpdate2Response(this._PositionUpdate2ResponseModel, type, buffer, offset, size)
        this._PositionUpdate2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionDelete2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionDelete2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDelete2RequestModel.verify(), 'storage.PositionDelete2Request validation failed!')

        let fbeBegin = this._PositionDelete2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionDelete2Request(this._PositionDelete2RequestModel, type, buffer, offset, size)
        this._PositionDelete2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionDelete2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionDelete2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDelete2ResponseModel.verify(), 'storage.PositionDelete2Response validation failed!')

        let fbeBegin = this._PositionDelete2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionDelete2Response(this._PositionDelete2ResponseModel, type, buffer, offset, size)
        this._PositionDelete2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated001Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated001Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated001Model.verify(), 'storage.Deprecated001 validation failed!')

        let fbeBegin = this._Deprecated001Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated001(this._Deprecated001Model, type, buffer, offset, size)
        this._Deprecated001Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated002Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated002Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated002Model.verify(), 'storage.Deprecated002 validation failed!')

        let fbeBegin = this._Deprecated002Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated002(this._Deprecated002Model, type, buffer, offset, size)
        this._Deprecated002Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated003Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated003Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated003Model.verify(), 'storage.Deprecated003 validation failed!')

        let fbeBegin = this._Deprecated003Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated003(this._Deprecated003Model, type, buffer, offset, size)
        this._Deprecated003Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated004Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated004Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated004Model.verify(), 'storage.Deprecated004 validation failed!')

        let fbeBegin = this._Deprecated004Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated004(this._Deprecated004Model, type, buffer, offset, size)
        this._Deprecated004Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated005Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated005Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated005Model.verify(), 'storage.Deprecated005 validation failed!')

        let fbeBegin = this._Deprecated005Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated005(this._Deprecated005Model, type, buffer, offset, size)
        this._Deprecated005Model.model.getEnd(fbeBegin)
        return true
      }
      case Deprecated006Model.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Deprecated006Model.attachBuffer(buffer, offset)
        console.assert(this._Deprecated006Model.verify(), 'storage.Deprecated006 validation failed!')

        let fbeBegin = this._Deprecated006Model.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Deprecated006(this._Deprecated006Model, type, buffer, offset, size)
        this._Deprecated006Model.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsInsert2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsert2RequestModel.verify(), 'storage.TradeEventsInsert2Request validation failed!')

        let fbeBegin = this._TradeEventsInsert2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsInsert2Request(this._TradeEventsInsert2RequestModel, type, buffer, offset, size)
        this._TradeEventsInsert2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsInsert2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsert2ResponseModel.verify(), 'storage.TradeEventsInsert2Response validation failed!')

        let fbeBegin = this._TradeEventsInsert2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsInsert2Response(this._TradeEventsInsert2ResponseModel, type, buffer, offset, size)
        this._TradeEventsInsert2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginUpdate2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdate2RequestModel.verify(), 'storage.MarginUpdate2Request validation failed!')

        let fbeBegin = this._MarginUpdate2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginUpdate2Request(this._MarginUpdate2RequestModel, type, buffer, offset, size)
        this._MarginUpdate2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginUpdate2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdate2ResponseModel.verify(), 'storage.MarginUpdate2Response validation failed!')

        let fbeBegin = this._MarginUpdate2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginUpdate2Response(this._MarginUpdate2ResponseModel, type, buffer, offset, size)
        this._MarginUpdate2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountUpdate2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdate2RequestModel.verify(), 'storage.AccountUpdate2Request validation failed!')

        let fbeBegin = this._AccountUpdate2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountUpdate2Request(this._AccountUpdate2RequestModel, type, buffer, offset, size)
        this._AccountUpdate2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountUpdate2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdate2ResponseModel.verify(), 'storage.AccountUpdate2Response validation failed!')

        let fbeBegin = this._AccountUpdate2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountUpdate2Response(this._AccountUpdate2ResponseModel, type, buffer, offset, size)
        this._AccountUpdate2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DepositMarginRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DepositMarginRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMarginRequestModel.verify(), 'storage.DepositMarginRequest validation failed!')

        let fbeBegin = this._DepositMarginRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DepositMarginRequest(this._DepositMarginRequestModel, type, buffer, offset, size)
        this._DepositMarginRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DepositMarginResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DepositMarginResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMarginResponseModel.verify(), 'storage.DepositMarginResponse validation failed!')

        let fbeBegin = this._DepositMarginResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DepositMarginResponse(this._DepositMarginResponseModel, type, buffer, offset, size)
        this._DepositMarginResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DepositMargin2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DepositMargin2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMargin2RequestModel.verify(), 'storage.DepositMargin2Request validation failed!')

        let fbeBegin = this._DepositMargin2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DepositMargin2Request(this._DepositMargin2RequestModel, type, buffer, offset, size)
        this._DepositMargin2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DepositMargin2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DepositMargin2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMargin2ResponseModel.verify(), 'storage.DepositMargin2Response validation failed!')

        let fbeBegin = this._DepositMargin2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DepositMargin2Response(this._DepositMargin2ResponseModel, type, buffer, offset, size)
        this._DepositMargin2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case StoreClientLoginSuccessRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._StoreClientLoginSuccessRequestModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccessRequestModel.verify(), 'storage.StoreClientLoginSuccessRequest validation failed!')

        let fbeBegin = this._StoreClientLoginSuccessRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_StoreClientLoginSuccessRequest(this._StoreClientLoginSuccessRequestModel, type, buffer, offset, size)
        this._StoreClientLoginSuccessRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case StoreClientLoginSuccessResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._StoreClientLoginSuccessResponseModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccessResponseModel.verify(), 'storage.StoreClientLoginSuccessResponse validation failed!')

        let fbeBegin = this._StoreClientLoginSuccessResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_StoreClientLoginSuccessResponse(this._StoreClientLoginSuccessResponseModel, type, buffer, offset, size)
        this._StoreClientLoginSuccessResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientLoginInfoGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientLoginInfoGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetRequestModel.verify(), 'storage.ClientLoginInfoGetRequest validation failed!')

        let fbeBegin = this._ClientLoginInfoGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientLoginInfoGetRequest(this._ClientLoginInfoGetRequestModel, type, buffer, offset, size)
        this._ClientLoginInfoGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientLoginInfoGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientLoginInfoGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetResponseModel.verify(), 'storage.ClientLoginInfoGetResponse validation failed!')

        let fbeBegin = this._ClientLoginInfoGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientLoginInfoGetResponse(this._ClientLoginInfoGetResponseModel, type, buffer, offset, size)
        this._ClientLoginInfoGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientLoginInfoNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientLoginInfoNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoNotifyModel.verify(), 'storage.ClientLoginInfoNotify validation failed!')

        let fbeBegin = this._ClientLoginInfoNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientLoginInfoNotify(this._ClientLoginInfoNotifyModel, type, buffer, offset, size)
        this._ClientLoginInfoNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case StoreClientLoginSuccess2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._StoreClientLoginSuccess2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccess2RequestModel.verify(), 'storage.StoreClientLoginSuccess2Request validation failed!')

        let fbeBegin = this._StoreClientLoginSuccess2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_StoreClientLoginSuccess2Request(this._StoreClientLoginSuccess2RequestModel, type, buffer, offset, size)
        this._StoreClientLoginSuccess2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case StoreClientLoginSuccess2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._StoreClientLoginSuccess2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccess2ResponseModel.verify(), 'storage.StoreClientLoginSuccess2Response validation failed!')

        let fbeBegin = this._StoreClientLoginSuccess2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_StoreClientLoginSuccess2Response(this._StoreClientLoginSuccess2ResponseModel, type, buffer, offset, size)
        this._StoreClientLoginSuccess2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdateWithTypeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdateWithTypeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeRequestModel.verify(), 'storage.OrderUpdateWithTypeRequest validation failed!')

        let fbeBegin = this._OrderUpdateWithTypeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdateWithTypeRequest(this._OrderUpdateWithTypeRequestModel, type, buffer, offset, size)
        this._OrderUpdateWithTypeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdateWithTypeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdateWithTypeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeResponseModel.verify(), 'storage.OrderUpdateWithTypeResponse validation failed!')

        let fbeBegin = this._OrderUpdateWithTypeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdateWithTypeResponse(this._OrderUpdateWithTypeResponseModel, type, buffer, offset, size)
        this._OrderUpdateWithTypeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdateWithType2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdateWithType2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithType2RequestModel.verify(), 'storage.OrderUpdateWithType2Request validation failed!')

        let fbeBegin = this._OrderUpdateWithType2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdateWithType2Request(this._OrderUpdateWithType2RequestModel, type, buffer, offset, size)
        this._OrderUpdateWithType2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrderUpdateWithType2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrderUpdateWithType2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithType2ResponseModel.verify(), 'storage.OrderUpdateWithType2Response validation failed!')

        let fbeBegin = this._OrderUpdateWithType2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrderUpdateWithType2Response(this._OrderUpdateWithType2ResponseModel, type, buffer, offset, size)
        this._OrderUpdateWithType2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryTimeRangeGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryTimeRangeGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryTimeRangeGetRequestModel.verify(), 'storage.TradeHistoryTimeRangeGetRequest validation failed!')

        let fbeBegin = this._TradeHistoryTimeRangeGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryTimeRangeGetRequest(this._TradeHistoryTimeRangeGetRequestModel, type, buffer, offset, size)
        this._TradeHistoryTimeRangeGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryTimeRangeGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryTimeRangeGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryTimeRangeGetResponseModel.verify(), 'storage.TradeHistoryTimeRangeGetResponse validation failed!')

        let fbeBegin = this._TradeHistoryTimeRangeGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryTimeRangeGetResponse(this._TradeHistoryTimeRangeGetResponseModel, type, buffer, offset, size)
        this._TradeHistoryTimeRangeGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsTimeRangeGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsTimeRangeGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsTimeRangeGetRequestModel.verify(), 'storage.TradeEventsTimeRangeGetRequest validation failed!')

        let fbeBegin = this._TradeEventsTimeRangeGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsTimeRangeGetRequest(this._TradeEventsTimeRangeGetRequestModel, type, buffer, offset, size)
        this._TradeEventsTimeRangeGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeEventsTimeRangeGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeEventsTimeRangeGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsTimeRangeGetResponseModel.verify(), 'storage.TradeEventsTimeRangeGetResponse validation failed!')

        let fbeBegin = this._TradeEventsTimeRangeGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeEventsTimeRangeGetResponse(this._TradeEventsTimeRangeGetResponseModel, type, buffer, offset, size)
        this._TradeEventsTimeRangeGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryByIdRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryByIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdRequestModel.verify(), 'storage.TradeHistoryByIdRequest validation failed!')

        let fbeBegin = this._TradeHistoryByIdRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryByIdRequest(this._TradeHistoryByIdRequestModel, type, buffer, offset, size)
        this._TradeHistoryByIdRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TradeHistoryByIdResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TradeHistoryByIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdResponseModel.verify(), 'storage.TradeHistoryByIdResponse validation failed!')

        let fbeBegin = this._TradeHistoryByIdResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TradeHistoryByIdResponse(this._TradeHistoryByIdResponseModel, type, buffer, offset, size)
        this._TradeHistoryByIdResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllRequestModel.verify(), 'storage.OrdersGetAllRequest validation failed!')

        let fbeBegin = this._OrdersGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersGetAllRequest(this._OrdersGetAllRequestModel, type, buffer, offset, size)
        this._OrdersGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case OrdersGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._OrdersGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllResponseModel.verify(), 'storage.OrdersGetAllResponse validation failed!')

        let fbeBegin = this._OrdersGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_OrdersGetAllResponse(this._OrdersGetAllResponseModel, type, buffer, offset, size)
        this._OrdersGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllRequestModel.verify(), 'storage.PositionsGetAllRequest validation failed!')

        let fbeBegin = this._PositionsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionsGetAllRequest(this._PositionsGetAllRequestModel, type, buffer, offset, size)
        this._PositionsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PositionsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PositionsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllResponseModel.verify(), 'storage.PositionsGetAllResponse validation failed!')

        let fbeBegin = this._PositionsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PositionsGetAllResponse(this._PositionsGetAllResponseModel, type, buffer, offset, size)
        this._PositionsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllRequestModel.verify(), 'storage.AssetsGetAllRequest validation failed!')

        let fbeBegin = this._AssetsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetsGetAllRequest(this._AssetsGetAllRequestModel, type, buffer, offset, size)
        this._AssetsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AssetsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AssetsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllResponseModel.verify(), 'storage.AssetsGetAllResponse validation failed!')

        let fbeBegin = this._AssetsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AssetsGetAllResponse(this._AssetsGetAllResponseModel, type, buffer, offset, size)
        this._AssetsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllRequestModel.verify(), 'storage.MarginsGetAllRequest validation failed!')

        let fbeBegin = this._MarginsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginsGetAllRequest(this._MarginsGetAllRequestModel, type, buffer, offset, size)
        this._MarginsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllResponseModel.verify(), 'storage.MarginsGetAllResponse validation failed!')

        let fbeBegin = this._MarginsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginsGetAllResponse(this._MarginsGetAllResponseModel, type, buffer, offset, size)
        this._MarginsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllRequestModel.verify(), 'storage.AccountsGetAllRequest validation failed!')

        let fbeBegin = this._AccountsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountsGetAllRequest(this._AccountsGetAllRequestModel, type, buffer, offset, size)
        this._AccountsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case AccountsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._AccountsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllResponseModel.verify(), 'storage.AccountsGetAllResponse validation failed!')

        let fbeBegin = this._AccountsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_AccountsGetAllResponse(this._AccountsGetAllResponseModel, type, buffer, offset, size)
        this._AccountsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MassRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MassRequestModel.attachBuffer(buffer, offset)
        console.assert(this._MassRequestModel.verify(), 'storage.MassRequest validation failed!')

        let fbeBegin = this._MassRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MassRequest(this._MassRequestModel, type, buffer, offset, size)
        this._MassRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case MassResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MassResponseModel.attachBuffer(buffer, offset)
        console.assert(this._MassResponseModel.verify(), 'storage.MassResponse validation failed!')

        let fbeBegin = this._MassResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MassResponse(this._MassResponseModel, type, buffer, offset, size)
        this._MassResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case Mass2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Mass2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._Mass2RequestModel.verify(), 'storage.Mass2Request validation failed!')

        let fbeBegin = this._Mass2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Mass2Request(this._Mass2RequestModel, type, buffer, offset, size)
        this._Mass2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case Mass2ResponseParamsModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Mass2ResponseParamsModel.attachBuffer(buffer, offset)
        console.assert(this._Mass2ResponseParamsModel.verify(), 'storage.Mass2ResponseParams validation failed!')

        let fbeBegin = this._Mass2ResponseParamsModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Mass2ResponseParams(this._Mass2ResponseParamsModel, type, buffer, offset, size)
        this._Mass2ResponseParamsModel.model.getEnd(fbeBegin)
        return true
      }
      case Mass2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Mass2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Mass2ResponseModel.verify(), 'storage.Mass2Response validation failed!')

        let fbeBegin = this._Mass2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Mass2Response(this._Mass2ResponseModel, type, buffer, offset, size)
        this._Mass2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case MarginUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MarginUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateNotifyModel.verify(), 'storage.MarginUpdateNotify validation failed!')

        let fbeBegin = this._MarginUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_MarginUpdateNotify(this._MarginUpdateNotifyModel, type, buffer, offset, size)
        this._MarginUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllRequestModel.verify(), 'storage.PersonsGetAllRequest validation failed!')

        let fbeBegin = this._PersonsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsGetAllRequest(this._PersonsGetAllRequestModel, type, buffer, offset, size)
        this._PersonsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllResponseModel.verify(), 'storage.PersonsGetAllResponse validation failed!')

        let fbeBegin = this._PersonsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsGetAllResponse(this._PersonsGetAllResponseModel, type, buffer, offset, size)
        this._PersonsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsInsertRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertRequestModel.verify(), 'storage.PersonsInsertRequest validation failed!')

        let fbeBegin = this._PersonsInsertRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsInsertRequest(this._PersonsInsertRequestModel, type, buffer, offset, size)
        this._PersonsInsertRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsInsertResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertResponseModel.verify(), 'storage.PersonsInsertResponse validation failed!')

        let fbeBegin = this._PersonsInsertResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsInsertResponse(this._PersonsInsertResponseModel, type, buffer, offset, size)
        this._PersonsInsertResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateRequestModel.verify(), 'storage.PersonUpdateRequest validation failed!')

        let fbeBegin = this._PersonUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonUpdateRequest(this._PersonUpdateRequestModel, type, buffer, offset, size)
        this._PersonUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateResponseModel.verify(), 'storage.PersonUpdateResponse validation failed!')

        let fbeBegin = this._PersonUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonUpdateResponse(this._PersonUpdateResponseModel, type, buffer, offset, size)
        this._PersonUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsInsert2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsInsert2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsert2RequestModel.verify(), 'storage.PersonsInsert2Request validation failed!')

        let fbeBegin = this._PersonsInsert2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsInsert2Request(this._PersonsInsert2RequestModel, type, buffer, offset, size)
        this._PersonsInsert2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonsInsert2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonsInsert2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsert2ResponseModel.verify(), 'storage.PersonsInsert2Response validation failed!')

        let fbeBegin = this._PersonsInsert2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonsInsert2Response(this._PersonsInsert2ResponseModel, type, buffer, offset, size)
        this._PersonsInsert2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonUpdate2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonUpdate2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdate2RequestModel.verify(), 'storage.PersonUpdate2Request validation failed!')

        let fbeBegin = this._PersonUpdate2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonUpdate2Request(this._PersonUpdate2RequestModel, type, buffer, offset, size)
        this._PersonUpdate2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case PersonUpdate2ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._PersonUpdate2ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdate2ResponseModel.verify(), 'storage.PersonUpdate2Response validation failed!')

        let fbeBegin = this._PersonUpdate2ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_PersonUpdate2Response(this._PersonUpdate2ResponseModel, type, buffer, offset, size)
        this._PersonUpdate2ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LastIdsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LastIdsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LastIdsGetRequestModel.verify(), 'storage.LastIdsGetRequest validation failed!')

        let fbeBegin = this._LastIdsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LastIdsGetRequest(this._LastIdsGetRequestModel, type, buffer, offset, size)
        this._LastIdsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LastIdsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LastIdsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LastIdsGetResponseModel.verify(), 'storage.LastIdsGetResponse validation failed!')

        let fbeBegin = this._LastIdsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LastIdsGetResponse(this._LastIdsGetResponseModel, type, buffer, offset, size)
        this._LastIdsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DailySnapshotRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DailySnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotRequestModel.verify(), 'storage.DailySnapshotRequest validation failed!')

        let fbeBegin = this._DailySnapshotRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DailySnapshotRequest(this._DailySnapshotRequestModel, type, buffer, offset, size)
        this._DailySnapshotRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DailySnapshotResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DailySnapshotResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotResponseModel.verify(), 'storage.DailySnapshotResponse validation failed!')

        let fbeBegin = this._DailySnapshotResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DailySnapshotResponse(this._DailySnapshotResponseModel, type, buffer, offset, size)
        this._DailySnapshotResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case BillingStatisticsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BillingStatisticsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BillingStatisticsGetRequestModel.verify(), 'storage.BillingStatisticsGetRequest validation failed!')

        let fbeBegin = this._BillingStatisticsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BillingStatisticsGetRequest(this._BillingStatisticsGetRequestModel, type, buffer, offset, size)
        this._BillingStatisticsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case BillingStatisticsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BillingStatisticsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BillingStatisticsGetResponseModel.verify(), 'storage.BillingStatisticsGetResponse validation failed!')

        let fbeBegin = this._BillingStatisticsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BillingStatisticsGetResponse(this._BillingStatisticsGetResponseModel, type, buffer, offset, size)
        this._BillingStatisticsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.innerProxy != null) && this.innerProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding storage client
 */
class Client extends fbe.Client {
  /**
   * Initialize storage client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._innerClient = new inner.Client(this.sendBuffer, this.receiveBuffer)
    this._StorageVersionRequestSenderModel = new StorageVersionRequestModel(this.sendBuffer)
    this._StorageVersionRequestReceiverValue = new StorageVersionRequest()
    this._StorageVersionRequestReceiverModel = new StorageVersionRequestModel()
    this._StorageVersionResponseSenderModel = new StorageVersionResponseModel(this.sendBuffer)
    this._StorageVersionResponseReceiverValue = new StorageVersionResponse()
    this._StorageVersionResponseReceiverModel = new StorageVersionResponseModel()
    this._StorageUpdateNotifySenderModel = new StorageUpdateNotifyModel(this.sendBuffer)
    this._StorageUpdateNotifyReceiverValue = new StorageUpdateNotify()
    this._StorageUpdateNotifyReceiverModel = new StorageUpdateNotifyModel()
    this._AccountInsertRequestSenderModel = new AccountInsertRequestModel(this.sendBuffer)
    this._AccountInsertRequestReceiverValue = new AccountInsertRequest()
    this._AccountInsertRequestReceiverModel = new AccountInsertRequestModel()
    this._AccountInsertResponseSenderModel = new AccountInsertResponseModel(this.sendBuffer)
    this._AccountInsertResponseReceiverValue = new AccountInsertResponse()
    this._AccountInsertResponseReceiverModel = new AccountInsertResponseModel()
    this._AccountSocialTradingMasterInsertRequestSenderModel = new AccountSocialTradingMasterInsertRequestModel(this.sendBuffer)
    this._AccountSocialTradingMasterInsertRequestReceiverValue = new AccountSocialTradingMasterInsertRequest()
    this._AccountSocialTradingMasterInsertRequestReceiverModel = new AccountSocialTradingMasterInsertRequestModel()
    this._AccountSocialTradingMasterInsertResponseSenderModel = new AccountSocialTradingMasterInsertResponseModel(this.sendBuffer)
    this._AccountSocialTradingMasterInsertResponseReceiverValue = new AccountSocialTradingMasterInsertResponse()
    this._AccountSocialTradingMasterInsertResponseReceiverModel = new AccountSocialTradingMasterInsertResponseModel()
    this._AccountSocialTradingInvestorInsertRequestSenderModel = new AccountSocialTradingInvestorInsertRequestModel(this.sendBuffer)
    this._AccountSocialTradingInvestorInsertRequestReceiverValue = new AccountSocialTradingInvestorInsertRequest()
    this._AccountSocialTradingInvestorInsertRequestReceiverModel = new AccountSocialTradingInvestorInsertRequestModel()
    this._AccountSocialTradingInvestorInsertResponseSenderModel = new AccountSocialTradingInvestorInsertResponseModel(this.sendBuffer)
    this._AccountSocialTradingInvestorInsertResponseReceiverValue = new AccountSocialTradingInvestorInsertResponse()
    this._AccountSocialTradingInvestorInsertResponseReceiverModel = new AccountSocialTradingInvestorInsertResponseModel()
    this._AccountUpdateRequestSenderModel = new AccountUpdateRequestModel(this.sendBuffer)
    this._AccountUpdateRequestReceiverValue = new AccountUpdateRequest()
    this._AccountUpdateRequestReceiverModel = new AccountUpdateRequestModel()
    this._AccountUpdateResponseSenderModel = new AccountUpdateResponseModel(this.sendBuffer)
    this._AccountUpdateResponseReceiverValue = new AccountUpdateResponse()
    this._AccountUpdateResponseReceiverModel = new AccountUpdateResponseModel()
    this._AccountDeleteRequestSenderModel = new AccountDeleteRequestModel(this.sendBuffer)
    this._AccountDeleteRequestReceiverValue = new AccountDeleteRequest()
    this._AccountDeleteRequestReceiverModel = new AccountDeleteRequestModel()
    this._AccountDeleteResponseSenderModel = new AccountDeleteResponseModel(this.sendBuffer)
    this._AccountDeleteResponseReceiverValue = new AccountDeleteResponse()
    this._AccountDeleteResponseReceiverModel = new AccountDeleteResponseModel()
    this._MarginUpdateRequestSenderModel = new MarginUpdateRequestModel(this.sendBuffer)
    this._MarginUpdateRequestReceiverValue = new MarginUpdateRequest()
    this._MarginUpdateRequestReceiverModel = new MarginUpdateRequestModel()
    this._MarginUpdateResponseSenderModel = new MarginUpdateResponseModel(this.sendBuffer)
    this._MarginUpdateResponseReceiverValue = new MarginUpdateResponse()
    this._MarginUpdateResponseReceiverModel = new MarginUpdateResponseModel()
    this._AssetInsertRequestSenderModel = new AssetInsertRequestModel(this.sendBuffer)
    this._AssetInsertRequestReceiverValue = new AssetInsertRequest()
    this._AssetInsertRequestReceiverModel = new AssetInsertRequestModel()
    this._AssetInsertResponseSenderModel = new AssetInsertResponseModel(this.sendBuffer)
    this._AssetInsertResponseReceiverValue = new AssetInsertResponse()
    this._AssetInsertResponseReceiverModel = new AssetInsertResponseModel()
    this._AssetUpdateRequestSenderModel = new AssetUpdateRequestModel(this.sendBuffer)
    this._AssetUpdateRequestReceiverValue = new AssetUpdateRequest()
    this._AssetUpdateRequestReceiverModel = new AssetUpdateRequestModel()
    this._AssetUpdateResponseSenderModel = new AssetUpdateResponseModel(this.sendBuffer)
    this._AssetUpdateResponseReceiverValue = new AssetUpdateResponse()
    this._AssetUpdateResponseReceiverModel = new AssetUpdateResponseModel()
    this._AssetDeleteRequestSenderModel = new AssetDeleteRequestModel(this.sendBuffer)
    this._AssetDeleteRequestReceiverValue = new AssetDeleteRequest()
    this._AssetDeleteRequestReceiverModel = new AssetDeleteRequestModel()
    this._AssetDeleteResponseSenderModel = new AssetDeleteResponseModel(this.sendBuffer)
    this._AssetDeleteResponseReceiverValue = new AssetDeleteResponse()
    this._AssetDeleteResponseReceiverModel = new AssetDeleteResponseModel()
    this._OrderInsertRequestSenderModel = new OrderInsertRequestModel(this.sendBuffer)
    this._OrderInsertRequestReceiverValue = new OrderInsertRequest()
    this._OrderInsertRequestReceiverModel = new OrderInsertRequestModel()
    this._OrderInsertResponseSenderModel = new OrderInsertResponseModel(this.sendBuffer)
    this._OrderInsertResponseReceiverValue = new OrderInsertResponse()
    this._OrderInsertResponseReceiverModel = new OrderInsertResponseModel()
    this._OrderUpdateRequestSenderModel = new OrderUpdateRequestModel(this.sendBuffer)
    this._OrderUpdateRequestReceiverValue = new OrderUpdateRequest()
    this._OrderUpdateRequestReceiverModel = new OrderUpdateRequestModel()
    this._OrderUpdateResponseSenderModel = new OrderUpdateResponseModel(this.sendBuffer)
    this._OrderUpdateResponseReceiverValue = new OrderUpdateResponse()
    this._OrderUpdateResponseReceiverModel = new OrderUpdateResponseModel()
    this._OrderDeleteRequestSenderModel = new OrderDeleteRequestModel(this.sendBuffer)
    this._OrderDeleteRequestReceiverValue = new OrderDeleteRequest()
    this._OrderDeleteRequestReceiverModel = new OrderDeleteRequestModel()
    this._OrderDeleteResponseSenderModel = new OrderDeleteResponseModel(this.sendBuffer)
    this._OrderDeleteResponseReceiverValue = new OrderDeleteResponse()
    this._OrderDeleteResponseReceiverModel = new OrderDeleteResponseModel()
    this._OrderDeleteAllRequestSenderModel = new OrderDeleteAllRequestModel(this.sendBuffer)
    this._OrderDeleteAllRequestReceiverValue = new OrderDeleteAllRequest()
    this._OrderDeleteAllRequestReceiverModel = new OrderDeleteAllRequestModel()
    this._OrderDeleteAllResponseSenderModel = new OrderDeleteAllResponseModel(this.sendBuffer)
    this._OrderDeleteAllResponseReceiverValue = new OrderDeleteAllResponse()
    this._OrderDeleteAllResponseReceiverModel = new OrderDeleteAllResponseModel()
    this._PositionInsertRequestSenderModel = new PositionInsertRequestModel(this.sendBuffer)
    this._PositionInsertRequestReceiverValue = new PositionInsertRequest()
    this._PositionInsertRequestReceiverModel = new PositionInsertRequestModel()
    this._PositionInsertResponseSenderModel = new PositionInsertResponseModel(this.sendBuffer)
    this._PositionInsertResponseReceiverValue = new PositionInsertResponse()
    this._PositionInsertResponseReceiverModel = new PositionInsertResponseModel()
    this._PositionUpdateRequestSenderModel = new PositionUpdateRequestModel(this.sendBuffer)
    this._PositionUpdateRequestReceiverValue = new PositionUpdateRequest()
    this._PositionUpdateRequestReceiverModel = new PositionUpdateRequestModel()
    this._PositionUpdateResponseSenderModel = new PositionUpdateResponseModel(this.sendBuffer)
    this._PositionUpdateResponseReceiverValue = new PositionUpdateResponse()
    this._PositionUpdateResponseReceiverModel = new PositionUpdateResponseModel()
    this._PositionDeleteRequestSenderModel = new PositionDeleteRequestModel(this.sendBuffer)
    this._PositionDeleteRequestReceiverValue = new PositionDeleteRequest()
    this._PositionDeleteRequestReceiverModel = new PositionDeleteRequestModel()
    this._PositionDeleteResponseSenderModel = new PositionDeleteResponseModel(this.sendBuffer)
    this._PositionDeleteResponseReceiverValue = new PositionDeleteResponse()
    this._PositionDeleteResponseReceiverModel = new PositionDeleteResponseModel()
    this._TradeHistoryRequestSenderModel = new TradeHistoryRequestModel(this.sendBuffer)
    this._TradeHistoryRequestReceiverValue = new TradeHistoryRequest()
    this._TradeHistoryRequestReceiverModel = new TradeHistoryRequestModel()
    this._TradeHistoryResponseSenderModel = new TradeHistoryResponseModel(this.sendBuffer)
    this._TradeHistoryResponseReceiverValue = new TradeHistoryResponse()
    this._TradeHistoryResponseReceiverModel = new TradeHistoryResponseModel()
    this._TradeEventsGetRequestSenderModel = new TradeEventsGetRequestModel(this.sendBuffer)
    this._TradeEventsGetRequestReceiverValue = new TradeEventsGetRequest()
    this._TradeEventsGetRequestReceiverModel = new TradeEventsGetRequestModel()
    this._TradeEventsGetResponseSenderModel = new TradeEventsGetResponseModel(this.sendBuffer)
    this._TradeEventsGetResponseReceiverValue = new TradeEventsGetResponse()
    this._TradeEventsGetResponseReceiverModel = new TradeEventsGetResponseModel()
    this._TradeEventsNotifySenderModel = new TradeEventsNotifyModel(this.sendBuffer)
    this._TradeEventsNotifyReceiverValue = new TradeEventsNotify()
    this._TradeEventsNotifyReceiverModel = new TradeEventsNotifyModel()
    this._TradeEventsInsertRequestSenderModel = new TradeEventsInsertRequestModel(this.sendBuffer)
    this._TradeEventsInsertRequestReceiverValue = new TradeEventsInsertRequest()
    this._TradeEventsInsertRequestReceiverModel = new TradeEventsInsertRequestModel()
    this._TradeEventsInsertResponseSenderModel = new TradeEventsInsertResponseModel(this.sendBuffer)
    this._TradeEventsInsertResponseReceiverValue = new TradeEventsInsertResponse()
    this._TradeEventsInsertResponseReceiverModel = new TradeEventsInsertResponseModel()
    this._ProfitClosedGetRequestSenderModel = new ProfitClosedGetRequestModel(this.sendBuffer)
    this._ProfitClosedGetRequestReceiverValue = new ProfitClosedGetRequest()
    this._ProfitClosedGetRequestReceiverModel = new ProfitClosedGetRequestModel()
    this._ProfitClosedGetResponseSenderModel = new ProfitClosedGetResponseModel(this.sendBuffer)
    this._ProfitClosedGetResponseReceiverValue = new ProfitClosedGetResponse()
    this._ProfitClosedGetResponseReceiverModel = new ProfitClosedGetResponseModel()
    this._OrdersClosedSummariesGetRequestSenderModel = new OrdersClosedSummariesGetRequestModel(this.sendBuffer)
    this._OrdersClosedSummariesGetRequestReceiverValue = new OrdersClosedSummariesGetRequest()
    this._OrdersClosedSummariesGetRequestReceiverModel = new OrdersClosedSummariesGetRequestModel()
    this._OrdersClosedSummariesGetResponseSenderModel = new OrdersClosedSummariesGetResponseModel(this.sendBuffer)
    this._OrdersClosedSummariesGetResponseReceiverValue = new OrdersClosedSummariesGetResponse()
    this._OrdersClosedSummariesGetResponseReceiverModel = new OrdersClosedSummariesGetResponseModel()
    this._OrderInsert2RequestSenderModel = new OrderInsert2RequestModel(this.sendBuffer)
    this._OrderInsert2RequestReceiverValue = new OrderInsert2Request()
    this._OrderInsert2RequestReceiverModel = new OrderInsert2RequestModel()
    this._OrderInsert2ResponseSenderModel = new OrderInsert2ResponseModel(this.sendBuffer)
    this._OrderInsert2ResponseReceiverValue = new OrderInsert2Response()
    this._OrderInsert2ResponseReceiverModel = new OrderInsert2ResponseModel()
    this._OrderUpdate2RequestSenderModel = new OrderUpdate2RequestModel(this.sendBuffer)
    this._OrderUpdate2RequestReceiverValue = new OrderUpdate2Request()
    this._OrderUpdate2RequestReceiverModel = new OrderUpdate2RequestModel()
    this._OrderUpdate2ResponseSenderModel = new OrderUpdate2ResponseModel(this.sendBuffer)
    this._OrderUpdate2ResponseReceiverValue = new OrderUpdate2Response()
    this._OrderUpdate2ResponseReceiverModel = new OrderUpdate2ResponseModel()
    this._OrderDelete2RequestSenderModel = new OrderDelete2RequestModel(this.sendBuffer)
    this._OrderDelete2RequestReceiverValue = new OrderDelete2Request()
    this._OrderDelete2RequestReceiverModel = new OrderDelete2RequestModel()
    this._OrderDelete2ResponseSenderModel = new OrderDelete2ResponseModel(this.sendBuffer)
    this._OrderDelete2ResponseReceiverValue = new OrderDelete2Response()
    this._OrderDelete2ResponseReceiverModel = new OrderDelete2ResponseModel()
    this._AccountInsert2RequestSenderModel = new AccountInsert2RequestModel(this.sendBuffer)
    this._AccountInsert2RequestReceiverValue = new AccountInsert2Request()
    this._AccountInsert2RequestReceiverModel = new AccountInsert2RequestModel()
    this._AccountInsert2ResponseSenderModel = new AccountInsert2ResponseModel(this.sendBuffer)
    this._AccountInsert2ResponseReceiverValue = new AccountInsert2Response()
    this._AccountInsert2ResponseReceiverModel = new AccountInsert2ResponseModel()
    this._AccountSocialTradingMasterInsert2RequestSenderModel = new AccountSocialTradingMasterInsert2RequestModel(this.sendBuffer)
    this._AccountSocialTradingMasterInsert2RequestReceiverValue = new AccountSocialTradingMasterInsert2Request()
    this._AccountSocialTradingMasterInsert2RequestReceiverModel = new AccountSocialTradingMasterInsert2RequestModel()
    this._AccountSocialTradingMasterInsert2ResponseSenderModel = new AccountSocialTradingMasterInsert2ResponseModel(this.sendBuffer)
    this._AccountSocialTradingMasterInsert2ResponseReceiverValue = new AccountSocialTradingMasterInsert2Response()
    this._AccountSocialTradingMasterInsert2ResponseReceiverModel = new AccountSocialTradingMasterInsert2ResponseModel()
    this._AccountSocialTradingInvestorInsert2RequestSenderModel = new AccountSocialTradingInvestorInsert2RequestModel(this.sendBuffer)
    this._AccountSocialTradingInvestorInsert2RequestReceiverValue = new AccountSocialTradingInvestorInsert2Request()
    this._AccountSocialTradingInvestorInsert2RequestReceiverModel = new AccountSocialTradingInvestorInsert2RequestModel()
    this._AccountSocialTradingInvestorInsert2ResponseSenderModel = new AccountSocialTradingInvestorInsert2ResponseModel(this.sendBuffer)
    this._AccountSocialTradingInvestorInsert2ResponseReceiverValue = new AccountSocialTradingInvestorInsert2Response()
    this._AccountSocialTradingInvestorInsert2ResponseReceiverModel = new AccountSocialTradingInvestorInsert2ResponseModel()
    this._AccountDelete2RequestSenderModel = new AccountDelete2RequestModel(this.sendBuffer)
    this._AccountDelete2RequestReceiverValue = new AccountDelete2Request()
    this._AccountDelete2RequestReceiverModel = new AccountDelete2RequestModel()
    this._AccountDelete2ResponseSenderModel = new AccountDelete2ResponseModel(this.sendBuffer)
    this._AccountDelete2ResponseReceiverValue = new AccountDelete2Response()
    this._AccountDelete2ResponseReceiverModel = new AccountDelete2ResponseModel()
    this._AssetInsert2RequestSenderModel = new AssetInsert2RequestModel(this.sendBuffer)
    this._AssetInsert2RequestReceiverValue = new AssetInsert2Request()
    this._AssetInsert2RequestReceiverModel = new AssetInsert2RequestModel()
    this._AssetInsert2ResponseSenderModel = new AssetInsert2ResponseModel(this.sendBuffer)
    this._AssetInsert2ResponseReceiverValue = new AssetInsert2Response()
    this._AssetInsert2ResponseReceiverModel = new AssetInsert2ResponseModel()
    this._AssetUpdate2RequestSenderModel = new AssetUpdate2RequestModel(this.sendBuffer)
    this._AssetUpdate2RequestReceiverValue = new AssetUpdate2Request()
    this._AssetUpdate2RequestReceiverModel = new AssetUpdate2RequestModel()
    this._AssetUpdate2ResponseSenderModel = new AssetUpdate2ResponseModel(this.sendBuffer)
    this._AssetUpdate2ResponseReceiverValue = new AssetUpdate2Response()
    this._AssetUpdate2ResponseReceiverModel = new AssetUpdate2ResponseModel()
    this._AssetDelete2RequestSenderModel = new AssetDelete2RequestModel(this.sendBuffer)
    this._AssetDelete2RequestReceiverValue = new AssetDelete2Request()
    this._AssetDelete2RequestReceiverModel = new AssetDelete2RequestModel()
    this._AssetDelete2ResponseSenderModel = new AssetDelete2ResponseModel(this.sendBuffer)
    this._AssetDelete2ResponseReceiverValue = new AssetDelete2Response()
    this._AssetDelete2ResponseReceiverModel = new AssetDelete2ResponseModel()
    this._PositionInsert2RequestSenderModel = new PositionInsert2RequestModel(this.sendBuffer)
    this._PositionInsert2RequestReceiverValue = new PositionInsert2Request()
    this._PositionInsert2RequestReceiverModel = new PositionInsert2RequestModel()
    this._PositionInsert2ResponseSenderModel = new PositionInsert2ResponseModel(this.sendBuffer)
    this._PositionInsert2ResponseReceiverValue = new PositionInsert2Response()
    this._PositionInsert2ResponseReceiverModel = new PositionInsert2ResponseModel()
    this._PositionUpdate2RequestSenderModel = new PositionUpdate2RequestModel(this.sendBuffer)
    this._PositionUpdate2RequestReceiverValue = new PositionUpdate2Request()
    this._PositionUpdate2RequestReceiverModel = new PositionUpdate2RequestModel()
    this._PositionUpdate2ResponseSenderModel = new PositionUpdate2ResponseModel(this.sendBuffer)
    this._PositionUpdate2ResponseReceiverValue = new PositionUpdate2Response()
    this._PositionUpdate2ResponseReceiverModel = new PositionUpdate2ResponseModel()
    this._PositionDelete2RequestSenderModel = new PositionDelete2RequestModel(this.sendBuffer)
    this._PositionDelete2RequestReceiverValue = new PositionDelete2Request()
    this._PositionDelete2RequestReceiverModel = new PositionDelete2RequestModel()
    this._PositionDelete2ResponseSenderModel = new PositionDelete2ResponseModel(this.sendBuffer)
    this._PositionDelete2ResponseReceiverValue = new PositionDelete2Response()
    this._PositionDelete2ResponseReceiverModel = new PositionDelete2ResponseModel()
    this._Deprecated001SenderModel = new Deprecated001Model(this.sendBuffer)
    this._Deprecated001ReceiverValue = new Deprecated001()
    this._Deprecated001ReceiverModel = new Deprecated001Model()
    this._Deprecated002SenderModel = new Deprecated002Model(this.sendBuffer)
    this._Deprecated002ReceiverValue = new Deprecated002()
    this._Deprecated002ReceiverModel = new Deprecated002Model()
    this._Deprecated003SenderModel = new Deprecated003Model(this.sendBuffer)
    this._Deprecated003ReceiverValue = new Deprecated003()
    this._Deprecated003ReceiverModel = new Deprecated003Model()
    this._Deprecated004SenderModel = new Deprecated004Model(this.sendBuffer)
    this._Deprecated004ReceiverValue = new Deprecated004()
    this._Deprecated004ReceiverModel = new Deprecated004Model()
    this._Deprecated005SenderModel = new Deprecated005Model(this.sendBuffer)
    this._Deprecated005ReceiverValue = new Deprecated005()
    this._Deprecated005ReceiverModel = new Deprecated005Model()
    this._Deprecated006SenderModel = new Deprecated006Model(this.sendBuffer)
    this._Deprecated006ReceiverValue = new Deprecated006()
    this._Deprecated006ReceiverModel = new Deprecated006Model()
    this._TradeEventsInsert2RequestSenderModel = new TradeEventsInsert2RequestModel(this.sendBuffer)
    this._TradeEventsInsert2RequestReceiverValue = new TradeEventsInsert2Request()
    this._TradeEventsInsert2RequestReceiverModel = new TradeEventsInsert2RequestModel()
    this._TradeEventsInsert2ResponseSenderModel = new TradeEventsInsert2ResponseModel(this.sendBuffer)
    this._TradeEventsInsert2ResponseReceiverValue = new TradeEventsInsert2Response()
    this._TradeEventsInsert2ResponseReceiverModel = new TradeEventsInsert2ResponseModel()
    this._MarginUpdate2RequestSenderModel = new MarginUpdate2RequestModel(this.sendBuffer)
    this._MarginUpdate2RequestReceiverValue = new MarginUpdate2Request()
    this._MarginUpdate2RequestReceiverModel = new MarginUpdate2RequestModel()
    this._MarginUpdate2ResponseSenderModel = new MarginUpdate2ResponseModel(this.sendBuffer)
    this._MarginUpdate2ResponseReceiverValue = new MarginUpdate2Response()
    this._MarginUpdate2ResponseReceiverModel = new MarginUpdate2ResponseModel()
    this._AccountUpdate2RequestSenderModel = new AccountUpdate2RequestModel(this.sendBuffer)
    this._AccountUpdate2RequestReceiverValue = new AccountUpdate2Request()
    this._AccountUpdate2RequestReceiverModel = new AccountUpdate2RequestModel()
    this._AccountUpdate2ResponseSenderModel = new AccountUpdate2ResponseModel(this.sendBuffer)
    this._AccountUpdate2ResponseReceiverValue = new AccountUpdate2Response()
    this._AccountUpdate2ResponseReceiverModel = new AccountUpdate2ResponseModel()
    this._DepositMarginRequestSenderModel = new DepositMarginRequestModel(this.sendBuffer)
    this._DepositMarginRequestReceiverValue = new DepositMarginRequest()
    this._DepositMarginRequestReceiverModel = new DepositMarginRequestModel()
    this._DepositMarginResponseSenderModel = new DepositMarginResponseModel(this.sendBuffer)
    this._DepositMarginResponseReceiverValue = new DepositMarginResponse()
    this._DepositMarginResponseReceiverModel = new DepositMarginResponseModel()
    this._DepositMargin2RequestSenderModel = new DepositMargin2RequestModel(this.sendBuffer)
    this._DepositMargin2RequestReceiverValue = new DepositMargin2Request()
    this._DepositMargin2RequestReceiverModel = new DepositMargin2RequestModel()
    this._DepositMargin2ResponseSenderModel = new DepositMargin2ResponseModel(this.sendBuffer)
    this._DepositMargin2ResponseReceiverValue = new DepositMargin2Response()
    this._DepositMargin2ResponseReceiverModel = new DepositMargin2ResponseModel()
    this._StoreClientLoginSuccessRequestSenderModel = new StoreClientLoginSuccessRequestModel(this.sendBuffer)
    this._StoreClientLoginSuccessRequestReceiverValue = new StoreClientLoginSuccessRequest()
    this._StoreClientLoginSuccessRequestReceiverModel = new StoreClientLoginSuccessRequestModel()
    this._StoreClientLoginSuccessResponseSenderModel = new StoreClientLoginSuccessResponseModel(this.sendBuffer)
    this._StoreClientLoginSuccessResponseReceiverValue = new StoreClientLoginSuccessResponse()
    this._StoreClientLoginSuccessResponseReceiverModel = new StoreClientLoginSuccessResponseModel()
    this._ClientLoginInfoGetRequestSenderModel = new ClientLoginInfoGetRequestModel(this.sendBuffer)
    this._ClientLoginInfoGetRequestReceiverValue = new ClientLoginInfoGetRequest()
    this._ClientLoginInfoGetRequestReceiverModel = new ClientLoginInfoGetRequestModel()
    this._ClientLoginInfoGetResponseSenderModel = new ClientLoginInfoGetResponseModel(this.sendBuffer)
    this._ClientLoginInfoGetResponseReceiverValue = new ClientLoginInfoGetResponse()
    this._ClientLoginInfoGetResponseReceiverModel = new ClientLoginInfoGetResponseModel()
    this._ClientLoginInfoNotifySenderModel = new ClientLoginInfoNotifyModel(this.sendBuffer)
    this._ClientLoginInfoNotifyReceiverValue = new ClientLoginInfoNotify()
    this._ClientLoginInfoNotifyReceiverModel = new ClientLoginInfoNotifyModel()
    this._StoreClientLoginSuccess2RequestSenderModel = new StoreClientLoginSuccess2RequestModel(this.sendBuffer)
    this._StoreClientLoginSuccess2RequestReceiverValue = new StoreClientLoginSuccess2Request()
    this._StoreClientLoginSuccess2RequestReceiverModel = new StoreClientLoginSuccess2RequestModel()
    this._StoreClientLoginSuccess2ResponseSenderModel = new StoreClientLoginSuccess2ResponseModel(this.sendBuffer)
    this._StoreClientLoginSuccess2ResponseReceiverValue = new StoreClientLoginSuccess2Response()
    this._StoreClientLoginSuccess2ResponseReceiverModel = new StoreClientLoginSuccess2ResponseModel()
    this._OrderUpdateWithTypeRequestSenderModel = new OrderUpdateWithTypeRequestModel(this.sendBuffer)
    this._OrderUpdateWithTypeRequestReceiverValue = new OrderUpdateWithTypeRequest()
    this._OrderUpdateWithTypeRequestReceiverModel = new OrderUpdateWithTypeRequestModel()
    this._OrderUpdateWithTypeResponseSenderModel = new OrderUpdateWithTypeResponseModel(this.sendBuffer)
    this._OrderUpdateWithTypeResponseReceiverValue = new OrderUpdateWithTypeResponse()
    this._OrderUpdateWithTypeResponseReceiverModel = new OrderUpdateWithTypeResponseModel()
    this._OrderUpdateWithType2RequestSenderModel = new OrderUpdateWithType2RequestModel(this.sendBuffer)
    this._OrderUpdateWithType2RequestReceiverValue = new OrderUpdateWithType2Request()
    this._OrderUpdateWithType2RequestReceiverModel = new OrderUpdateWithType2RequestModel()
    this._OrderUpdateWithType2ResponseSenderModel = new OrderUpdateWithType2ResponseModel(this.sendBuffer)
    this._OrderUpdateWithType2ResponseReceiverValue = new OrderUpdateWithType2Response()
    this._OrderUpdateWithType2ResponseReceiverModel = new OrderUpdateWithType2ResponseModel()
    this._TradeHistoryTimeRangeGetRequestSenderModel = new TradeHistoryTimeRangeGetRequestModel(this.sendBuffer)
    this._TradeHistoryTimeRangeGetRequestReceiverValue = new TradeHistoryTimeRangeGetRequest()
    this._TradeHistoryTimeRangeGetRequestReceiverModel = new TradeHistoryTimeRangeGetRequestModel()
    this._TradeHistoryTimeRangeGetResponseSenderModel = new TradeHistoryTimeRangeGetResponseModel(this.sendBuffer)
    this._TradeHistoryTimeRangeGetResponseReceiverValue = new TradeHistoryTimeRangeGetResponse()
    this._TradeHistoryTimeRangeGetResponseReceiverModel = new TradeHistoryTimeRangeGetResponseModel()
    this._TradeEventsTimeRangeGetRequestSenderModel = new TradeEventsTimeRangeGetRequestModel(this.sendBuffer)
    this._TradeEventsTimeRangeGetRequestReceiverValue = new TradeEventsTimeRangeGetRequest()
    this._TradeEventsTimeRangeGetRequestReceiverModel = new TradeEventsTimeRangeGetRequestModel()
    this._TradeEventsTimeRangeGetResponseSenderModel = new TradeEventsTimeRangeGetResponseModel(this.sendBuffer)
    this._TradeEventsTimeRangeGetResponseReceiverValue = new TradeEventsTimeRangeGetResponse()
    this._TradeEventsTimeRangeGetResponseReceiverModel = new TradeEventsTimeRangeGetResponseModel()
    this._TradeHistoryByIdRequestSenderModel = new TradeHistoryByIdRequestModel(this.sendBuffer)
    this._TradeHistoryByIdRequestReceiverValue = new TradeHistoryByIdRequest()
    this._TradeHistoryByIdRequestReceiverModel = new TradeHistoryByIdRequestModel()
    this._TradeHistoryByIdResponseSenderModel = new TradeHistoryByIdResponseModel(this.sendBuffer)
    this._TradeHistoryByIdResponseReceiverValue = new TradeHistoryByIdResponse()
    this._TradeHistoryByIdResponseReceiverModel = new TradeHistoryByIdResponseModel()
    this._OrdersGetAllRequestSenderModel = new OrdersGetAllRequestModel(this.sendBuffer)
    this._OrdersGetAllRequestReceiverValue = new OrdersGetAllRequest()
    this._OrdersGetAllRequestReceiverModel = new OrdersGetAllRequestModel()
    this._OrdersGetAllResponseSenderModel = new OrdersGetAllResponseModel(this.sendBuffer)
    this._OrdersGetAllResponseReceiverValue = new OrdersGetAllResponse()
    this._OrdersGetAllResponseReceiverModel = new OrdersGetAllResponseModel()
    this._PositionsGetAllRequestSenderModel = new PositionsGetAllRequestModel(this.sendBuffer)
    this._PositionsGetAllRequestReceiverValue = new PositionsGetAllRequest()
    this._PositionsGetAllRequestReceiverModel = new PositionsGetAllRequestModel()
    this._PositionsGetAllResponseSenderModel = new PositionsGetAllResponseModel(this.sendBuffer)
    this._PositionsGetAllResponseReceiverValue = new PositionsGetAllResponse()
    this._PositionsGetAllResponseReceiverModel = new PositionsGetAllResponseModel()
    this._AssetsGetAllRequestSenderModel = new AssetsGetAllRequestModel(this.sendBuffer)
    this._AssetsGetAllRequestReceiverValue = new AssetsGetAllRequest()
    this._AssetsGetAllRequestReceiverModel = new AssetsGetAllRequestModel()
    this._AssetsGetAllResponseSenderModel = new AssetsGetAllResponseModel(this.sendBuffer)
    this._AssetsGetAllResponseReceiverValue = new AssetsGetAllResponse()
    this._AssetsGetAllResponseReceiverModel = new AssetsGetAllResponseModel()
    this._MarginsGetAllRequestSenderModel = new MarginsGetAllRequestModel(this.sendBuffer)
    this._MarginsGetAllRequestReceiverValue = new MarginsGetAllRequest()
    this._MarginsGetAllRequestReceiverModel = new MarginsGetAllRequestModel()
    this._MarginsGetAllResponseSenderModel = new MarginsGetAllResponseModel(this.sendBuffer)
    this._MarginsGetAllResponseReceiverValue = new MarginsGetAllResponse()
    this._MarginsGetAllResponseReceiverModel = new MarginsGetAllResponseModel()
    this._AccountsGetAllRequestSenderModel = new AccountsGetAllRequestModel(this.sendBuffer)
    this._AccountsGetAllRequestReceiverValue = new AccountsGetAllRequest()
    this._AccountsGetAllRequestReceiverModel = new AccountsGetAllRequestModel()
    this._AccountsGetAllResponseSenderModel = new AccountsGetAllResponseModel(this.sendBuffer)
    this._AccountsGetAllResponseReceiverValue = new AccountsGetAllResponse()
    this._AccountsGetAllResponseReceiverModel = new AccountsGetAllResponseModel()
    this._MassRequestSenderModel = new MassRequestModel(this.sendBuffer)
    this._MassRequestReceiverValue = new MassRequest()
    this._MassRequestReceiverModel = new MassRequestModel()
    this._MassResponseSenderModel = new MassResponseModel(this.sendBuffer)
    this._MassResponseReceiverValue = new MassResponse()
    this._MassResponseReceiverModel = new MassResponseModel()
    this._Mass2RequestSenderModel = new Mass2RequestModel(this.sendBuffer)
    this._Mass2RequestReceiverValue = new Mass2Request()
    this._Mass2RequestReceiverModel = new Mass2RequestModel()
    this._Mass2ResponseParamsSenderModel = new Mass2ResponseParamsModel(this.sendBuffer)
    this._Mass2ResponseParamsReceiverValue = new Mass2ResponseParams()
    this._Mass2ResponseParamsReceiverModel = new Mass2ResponseParamsModel()
    this._Mass2ResponseSenderModel = new Mass2ResponseModel(this.sendBuffer)
    this._Mass2ResponseReceiverValue = new Mass2Response()
    this._Mass2ResponseReceiverModel = new Mass2ResponseModel()
    this._MarginUpdateNotifySenderModel = new MarginUpdateNotifyModel(this.sendBuffer)
    this._MarginUpdateNotifyReceiverValue = new MarginUpdateNotify()
    this._MarginUpdateNotifyReceiverModel = new MarginUpdateNotifyModel()
    this._PersonsGetAllRequestSenderModel = new PersonsGetAllRequestModel(this.sendBuffer)
    this._PersonsGetAllRequestReceiverValue = new PersonsGetAllRequest()
    this._PersonsGetAllRequestReceiverModel = new PersonsGetAllRequestModel()
    this._PersonsGetAllResponseSenderModel = new PersonsGetAllResponseModel(this.sendBuffer)
    this._PersonsGetAllResponseReceiverValue = new PersonsGetAllResponse()
    this._PersonsGetAllResponseReceiverModel = new PersonsGetAllResponseModel()
    this._PersonsInsertRequestSenderModel = new PersonsInsertRequestModel(this.sendBuffer)
    this._PersonsInsertRequestReceiverValue = new PersonsInsertRequest()
    this._PersonsInsertRequestReceiverModel = new PersonsInsertRequestModel()
    this._PersonsInsertResponseSenderModel = new PersonsInsertResponseModel(this.sendBuffer)
    this._PersonsInsertResponseReceiverValue = new PersonsInsertResponse()
    this._PersonsInsertResponseReceiverModel = new PersonsInsertResponseModel()
    this._PersonUpdateRequestSenderModel = new PersonUpdateRequestModel(this.sendBuffer)
    this._PersonUpdateRequestReceiverValue = new PersonUpdateRequest()
    this._PersonUpdateRequestReceiverModel = new PersonUpdateRequestModel()
    this._PersonUpdateResponseSenderModel = new PersonUpdateResponseModel(this.sendBuffer)
    this._PersonUpdateResponseReceiverValue = new PersonUpdateResponse()
    this._PersonUpdateResponseReceiverModel = new PersonUpdateResponseModel()
    this._PersonsInsert2RequestSenderModel = new PersonsInsert2RequestModel(this.sendBuffer)
    this._PersonsInsert2RequestReceiverValue = new PersonsInsert2Request()
    this._PersonsInsert2RequestReceiverModel = new PersonsInsert2RequestModel()
    this._PersonsInsert2ResponseSenderModel = new PersonsInsert2ResponseModel(this.sendBuffer)
    this._PersonsInsert2ResponseReceiverValue = new PersonsInsert2Response()
    this._PersonsInsert2ResponseReceiverModel = new PersonsInsert2ResponseModel()
    this._PersonUpdate2RequestSenderModel = new PersonUpdate2RequestModel(this.sendBuffer)
    this._PersonUpdate2RequestReceiverValue = new PersonUpdate2Request()
    this._PersonUpdate2RequestReceiverModel = new PersonUpdate2RequestModel()
    this._PersonUpdate2ResponseSenderModel = new PersonUpdate2ResponseModel(this.sendBuffer)
    this._PersonUpdate2ResponseReceiverValue = new PersonUpdate2Response()
    this._PersonUpdate2ResponseReceiverModel = new PersonUpdate2ResponseModel()
    this._LastIdsGetRequestSenderModel = new LastIdsGetRequestModel(this.sendBuffer)
    this._LastIdsGetRequestReceiverValue = new LastIdsGetRequest()
    this._LastIdsGetRequestReceiverModel = new LastIdsGetRequestModel()
    this._LastIdsGetResponseSenderModel = new LastIdsGetResponseModel(this.sendBuffer)
    this._LastIdsGetResponseReceiverValue = new LastIdsGetResponse()
    this._LastIdsGetResponseReceiverModel = new LastIdsGetResponseModel()
    this._DailySnapshotRequestSenderModel = new DailySnapshotRequestModel(this.sendBuffer)
    this._DailySnapshotRequestReceiverValue = new DailySnapshotRequest()
    this._DailySnapshotRequestReceiverModel = new DailySnapshotRequestModel()
    this._DailySnapshotResponseSenderModel = new DailySnapshotResponseModel(this.sendBuffer)
    this._DailySnapshotResponseReceiverValue = new DailySnapshotResponse()
    this._DailySnapshotResponseReceiverModel = new DailySnapshotResponseModel()
    this._BillingStatisticsGetRequestSenderModel = new BillingStatisticsGetRequestModel(this.sendBuffer)
    this._BillingStatisticsGetRequestReceiverValue = new BillingStatisticsGetRequest()
    this._BillingStatisticsGetRequestReceiverModel = new BillingStatisticsGetRequestModel()
    this._BillingStatisticsGetResponseSenderModel = new BillingStatisticsGetResponseModel(this.sendBuffer)
    this._BillingStatisticsGetResponseReceiverValue = new BillingStatisticsGetResponse()
    this._BillingStatisticsGetResponseReceiverModel = new BillingStatisticsGetResponseModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  /**
   * Get imported inner client
   * @this {!Client}
   * @returns {!inner.Client} inner client
   */
  get innerClient () {
    return this._innerClient
  }

  // Sender models accessors

  /**
   * Get StorageVersionRequest model
   * @this {!Client}
   * @returns {!StorageVersionRequestModel} StorageVersionRequest sender model
   */
  get StorageVersionRequestSenderModel () {
    return this._StorageVersionRequestSenderModel
  }

  /**
   * Get StorageVersionResponse model
   * @this {!Client}
   * @returns {!StorageVersionResponseModel} StorageVersionResponse sender model
   */
  get StorageVersionResponseSenderModel () {
    return this._StorageVersionResponseSenderModel
  }

  /**
   * Get StorageUpdateNotify model
   * @this {!Client}
   * @returns {!StorageUpdateNotifyModel} StorageUpdateNotify sender model
   */
  get StorageUpdateNotifySenderModel () {
    return this._StorageUpdateNotifySenderModel
  }

  /**
   * Get AccountInsertRequest model
   * @this {!Client}
   * @returns {!AccountInsertRequestModel} AccountInsertRequest sender model
   */
  get AccountInsertRequestSenderModel () {
    return this._AccountInsertRequestSenderModel
  }

  /**
   * Get AccountInsertResponse model
   * @this {!Client}
   * @returns {!AccountInsertResponseModel} AccountInsertResponse sender model
   */
  get AccountInsertResponseSenderModel () {
    return this._AccountInsertResponseSenderModel
  }

  /**
   * Get AccountSocialTradingMasterInsertRequest model
   * @this {!Client}
   * @returns {!AccountSocialTradingMasterInsertRequestModel} AccountSocialTradingMasterInsertRequest sender model
   */
  get AccountSocialTradingMasterInsertRequestSenderModel () {
    return this._AccountSocialTradingMasterInsertRequestSenderModel
  }

  /**
   * Get AccountSocialTradingMasterInsertResponse model
   * @this {!Client}
   * @returns {!AccountSocialTradingMasterInsertResponseModel} AccountSocialTradingMasterInsertResponse sender model
   */
  get AccountSocialTradingMasterInsertResponseSenderModel () {
    return this._AccountSocialTradingMasterInsertResponseSenderModel
  }

  /**
   * Get AccountSocialTradingInvestorInsertRequest model
   * @this {!Client}
   * @returns {!AccountSocialTradingInvestorInsertRequestModel} AccountSocialTradingInvestorInsertRequest sender model
   */
  get AccountSocialTradingInvestorInsertRequestSenderModel () {
    return this._AccountSocialTradingInvestorInsertRequestSenderModel
  }

  /**
   * Get AccountSocialTradingInvestorInsertResponse model
   * @this {!Client}
   * @returns {!AccountSocialTradingInvestorInsertResponseModel} AccountSocialTradingInvestorInsertResponse sender model
   */
  get AccountSocialTradingInvestorInsertResponseSenderModel () {
    return this._AccountSocialTradingInvestorInsertResponseSenderModel
  }

  /**
   * Get AccountUpdateRequest model
   * @this {!Client}
   * @returns {!AccountUpdateRequestModel} AccountUpdateRequest sender model
   */
  get AccountUpdateRequestSenderModel () {
    return this._AccountUpdateRequestSenderModel
  }

  /**
   * Get AccountUpdateResponse model
   * @this {!Client}
   * @returns {!AccountUpdateResponseModel} AccountUpdateResponse sender model
   */
  get AccountUpdateResponseSenderModel () {
    return this._AccountUpdateResponseSenderModel
  }

  /**
   * Get AccountDeleteRequest model
   * @this {!Client}
   * @returns {!AccountDeleteRequestModel} AccountDeleteRequest sender model
   */
  get AccountDeleteRequestSenderModel () {
    return this._AccountDeleteRequestSenderModel
  }

  /**
   * Get AccountDeleteResponse model
   * @this {!Client}
   * @returns {!AccountDeleteResponseModel} AccountDeleteResponse sender model
   */
  get AccountDeleteResponseSenderModel () {
    return this._AccountDeleteResponseSenderModel
  }

  /**
   * Get MarginUpdateRequest model
   * @this {!Client}
   * @returns {!MarginUpdateRequestModel} MarginUpdateRequest sender model
   */
  get MarginUpdateRequestSenderModel () {
    return this._MarginUpdateRequestSenderModel
  }

  /**
   * Get MarginUpdateResponse model
   * @this {!Client}
   * @returns {!MarginUpdateResponseModel} MarginUpdateResponse sender model
   */
  get MarginUpdateResponseSenderModel () {
    return this._MarginUpdateResponseSenderModel
  }

  /**
   * Get AssetInsertRequest model
   * @this {!Client}
   * @returns {!AssetInsertRequestModel} AssetInsertRequest sender model
   */
  get AssetInsertRequestSenderModel () {
    return this._AssetInsertRequestSenderModel
  }

  /**
   * Get AssetInsertResponse model
   * @this {!Client}
   * @returns {!AssetInsertResponseModel} AssetInsertResponse sender model
   */
  get AssetInsertResponseSenderModel () {
    return this._AssetInsertResponseSenderModel
  }

  /**
   * Get AssetUpdateRequest model
   * @this {!Client}
   * @returns {!AssetUpdateRequestModel} AssetUpdateRequest sender model
   */
  get AssetUpdateRequestSenderModel () {
    return this._AssetUpdateRequestSenderModel
  }

  /**
   * Get AssetUpdateResponse model
   * @this {!Client}
   * @returns {!AssetUpdateResponseModel} AssetUpdateResponse sender model
   */
  get AssetUpdateResponseSenderModel () {
    return this._AssetUpdateResponseSenderModel
  }

  /**
   * Get AssetDeleteRequest model
   * @this {!Client}
   * @returns {!AssetDeleteRequestModel} AssetDeleteRequest sender model
   */
  get AssetDeleteRequestSenderModel () {
    return this._AssetDeleteRequestSenderModel
  }

  /**
   * Get AssetDeleteResponse model
   * @this {!Client}
   * @returns {!AssetDeleteResponseModel} AssetDeleteResponse sender model
   */
  get AssetDeleteResponseSenderModel () {
    return this._AssetDeleteResponseSenderModel
  }

  /**
   * Get OrderInsertRequest model
   * @this {!Client}
   * @returns {!OrderInsertRequestModel} OrderInsertRequest sender model
   */
  get OrderInsertRequestSenderModel () {
    return this._OrderInsertRequestSenderModel
  }

  /**
   * Get OrderInsertResponse model
   * @this {!Client}
   * @returns {!OrderInsertResponseModel} OrderInsertResponse sender model
   */
  get OrderInsertResponseSenderModel () {
    return this._OrderInsertResponseSenderModel
  }

  /**
   * Get OrderUpdateRequest model
   * @this {!Client}
   * @returns {!OrderUpdateRequestModel} OrderUpdateRequest sender model
   */
  get OrderUpdateRequestSenderModel () {
    return this._OrderUpdateRequestSenderModel
  }

  /**
   * Get OrderUpdateResponse model
   * @this {!Client}
   * @returns {!OrderUpdateResponseModel} OrderUpdateResponse sender model
   */
  get OrderUpdateResponseSenderModel () {
    return this._OrderUpdateResponseSenderModel
  }

  /**
   * Get OrderDeleteRequest model
   * @this {!Client}
   * @returns {!OrderDeleteRequestModel} OrderDeleteRequest sender model
   */
  get OrderDeleteRequestSenderModel () {
    return this._OrderDeleteRequestSenderModel
  }

  /**
   * Get OrderDeleteResponse model
   * @this {!Client}
   * @returns {!OrderDeleteResponseModel} OrderDeleteResponse sender model
   */
  get OrderDeleteResponseSenderModel () {
    return this._OrderDeleteResponseSenderModel
  }

  /**
   * Get OrderDeleteAllRequest model
   * @this {!Client}
   * @returns {!OrderDeleteAllRequestModel} OrderDeleteAllRequest sender model
   */
  get OrderDeleteAllRequestSenderModel () {
    return this._OrderDeleteAllRequestSenderModel
  }

  /**
   * Get OrderDeleteAllResponse model
   * @this {!Client}
   * @returns {!OrderDeleteAllResponseModel} OrderDeleteAllResponse sender model
   */
  get OrderDeleteAllResponseSenderModel () {
    return this._OrderDeleteAllResponseSenderModel
  }

  /**
   * Get PositionInsertRequest model
   * @this {!Client}
   * @returns {!PositionInsertRequestModel} PositionInsertRequest sender model
   */
  get PositionInsertRequestSenderModel () {
    return this._PositionInsertRequestSenderModel
  }

  /**
   * Get PositionInsertResponse model
   * @this {!Client}
   * @returns {!PositionInsertResponseModel} PositionInsertResponse sender model
   */
  get PositionInsertResponseSenderModel () {
    return this._PositionInsertResponseSenderModel
  }

  /**
   * Get PositionUpdateRequest model
   * @this {!Client}
   * @returns {!PositionUpdateRequestModel} PositionUpdateRequest sender model
   */
  get PositionUpdateRequestSenderModel () {
    return this._PositionUpdateRequestSenderModel
  }

  /**
   * Get PositionUpdateResponse model
   * @this {!Client}
   * @returns {!PositionUpdateResponseModel} PositionUpdateResponse sender model
   */
  get PositionUpdateResponseSenderModel () {
    return this._PositionUpdateResponseSenderModel
  }

  /**
   * Get PositionDeleteRequest model
   * @this {!Client}
   * @returns {!PositionDeleteRequestModel} PositionDeleteRequest sender model
   */
  get PositionDeleteRequestSenderModel () {
    return this._PositionDeleteRequestSenderModel
  }

  /**
   * Get PositionDeleteResponse model
   * @this {!Client}
   * @returns {!PositionDeleteResponseModel} PositionDeleteResponse sender model
   */
  get PositionDeleteResponseSenderModel () {
    return this._PositionDeleteResponseSenderModel
  }

  /**
   * Get TradeHistoryRequest model
   * @this {!Client}
   * @returns {!TradeHistoryRequestModel} TradeHistoryRequest sender model
   */
  get TradeHistoryRequestSenderModel () {
    return this._TradeHistoryRequestSenderModel
  }

  /**
   * Get TradeHistoryResponse model
   * @this {!Client}
   * @returns {!TradeHistoryResponseModel} TradeHistoryResponse sender model
   */
  get TradeHistoryResponseSenderModel () {
    return this._TradeHistoryResponseSenderModel
  }

  /**
   * Get TradeEventsGetRequest model
   * @this {!Client}
   * @returns {!TradeEventsGetRequestModel} TradeEventsGetRequest sender model
   */
  get TradeEventsGetRequestSenderModel () {
    return this._TradeEventsGetRequestSenderModel
  }

  /**
   * Get TradeEventsGetResponse model
   * @this {!Client}
   * @returns {!TradeEventsGetResponseModel} TradeEventsGetResponse sender model
   */
  get TradeEventsGetResponseSenderModel () {
    return this._TradeEventsGetResponseSenderModel
  }

  /**
   * Get TradeEventsNotify model
   * @this {!Client}
   * @returns {!TradeEventsNotifyModel} TradeEventsNotify sender model
   */
  get TradeEventsNotifySenderModel () {
    return this._TradeEventsNotifySenderModel
  }

  /**
   * Get TradeEventsInsertRequest model
   * @this {!Client}
   * @returns {!TradeEventsInsertRequestModel} TradeEventsInsertRequest sender model
   */
  get TradeEventsInsertRequestSenderModel () {
    return this._TradeEventsInsertRequestSenderModel
  }

  /**
   * Get TradeEventsInsertResponse model
   * @this {!Client}
   * @returns {!TradeEventsInsertResponseModel} TradeEventsInsertResponse sender model
   */
  get TradeEventsInsertResponseSenderModel () {
    return this._TradeEventsInsertResponseSenderModel
  }

  /**
   * Get ProfitClosedGetRequest model
   * @this {!Client}
   * @returns {!ProfitClosedGetRequestModel} ProfitClosedGetRequest sender model
   */
  get ProfitClosedGetRequestSenderModel () {
    return this._ProfitClosedGetRequestSenderModel
  }

  /**
   * Get ProfitClosedGetResponse model
   * @this {!Client}
   * @returns {!ProfitClosedGetResponseModel} ProfitClosedGetResponse sender model
   */
  get ProfitClosedGetResponseSenderModel () {
    return this._ProfitClosedGetResponseSenderModel
  }

  /**
   * Get OrdersClosedSummariesGetRequest model
   * @this {!Client}
   * @returns {!OrdersClosedSummariesGetRequestModel} OrdersClosedSummariesGetRequest sender model
   */
  get OrdersClosedSummariesGetRequestSenderModel () {
    return this._OrdersClosedSummariesGetRequestSenderModel
  }

  /**
   * Get OrdersClosedSummariesGetResponse model
   * @this {!Client}
   * @returns {!OrdersClosedSummariesGetResponseModel} OrdersClosedSummariesGetResponse sender model
   */
  get OrdersClosedSummariesGetResponseSenderModel () {
    return this._OrdersClosedSummariesGetResponseSenderModel
  }

  /**
   * Get OrderInsert2Request model
   * @this {!Client}
   * @returns {!OrderInsert2RequestModel} OrderInsert2Request sender model
   */
  get OrderInsert2RequestSenderModel () {
    return this._OrderInsert2RequestSenderModel
  }

  /**
   * Get OrderInsert2Response model
   * @this {!Client}
   * @returns {!OrderInsert2ResponseModel} OrderInsert2Response sender model
   */
  get OrderInsert2ResponseSenderModel () {
    return this._OrderInsert2ResponseSenderModel
  }

  /**
   * Get OrderUpdate2Request model
   * @this {!Client}
   * @returns {!OrderUpdate2RequestModel} OrderUpdate2Request sender model
   */
  get OrderUpdate2RequestSenderModel () {
    return this._OrderUpdate2RequestSenderModel
  }

  /**
   * Get OrderUpdate2Response model
   * @this {!Client}
   * @returns {!OrderUpdate2ResponseModel} OrderUpdate2Response sender model
   */
  get OrderUpdate2ResponseSenderModel () {
    return this._OrderUpdate2ResponseSenderModel
  }

  /**
   * Get OrderDelete2Request model
   * @this {!Client}
   * @returns {!OrderDelete2RequestModel} OrderDelete2Request sender model
   */
  get OrderDelete2RequestSenderModel () {
    return this._OrderDelete2RequestSenderModel
  }

  /**
   * Get OrderDelete2Response model
   * @this {!Client}
   * @returns {!OrderDelete2ResponseModel} OrderDelete2Response sender model
   */
  get OrderDelete2ResponseSenderModel () {
    return this._OrderDelete2ResponseSenderModel
  }

  /**
   * Get AccountInsert2Request model
   * @this {!Client}
   * @returns {!AccountInsert2RequestModel} AccountInsert2Request sender model
   */
  get AccountInsert2RequestSenderModel () {
    return this._AccountInsert2RequestSenderModel
  }

  /**
   * Get AccountInsert2Response model
   * @this {!Client}
   * @returns {!AccountInsert2ResponseModel} AccountInsert2Response sender model
   */
  get AccountInsert2ResponseSenderModel () {
    return this._AccountInsert2ResponseSenderModel
  }

  /**
   * Get AccountSocialTradingMasterInsert2Request model
   * @this {!Client}
   * @returns {!AccountSocialTradingMasterInsert2RequestModel} AccountSocialTradingMasterInsert2Request sender model
   */
  get AccountSocialTradingMasterInsert2RequestSenderModel () {
    return this._AccountSocialTradingMasterInsert2RequestSenderModel
  }

  /**
   * Get AccountSocialTradingMasterInsert2Response model
   * @this {!Client}
   * @returns {!AccountSocialTradingMasterInsert2ResponseModel} AccountSocialTradingMasterInsert2Response sender model
   */
  get AccountSocialTradingMasterInsert2ResponseSenderModel () {
    return this._AccountSocialTradingMasterInsert2ResponseSenderModel
  }

  /**
   * Get AccountSocialTradingInvestorInsert2Request model
   * @this {!Client}
   * @returns {!AccountSocialTradingInvestorInsert2RequestModel} AccountSocialTradingInvestorInsert2Request sender model
   */
  get AccountSocialTradingInvestorInsert2RequestSenderModel () {
    return this._AccountSocialTradingInvestorInsert2RequestSenderModel
  }

  /**
   * Get AccountSocialTradingInvestorInsert2Response model
   * @this {!Client}
   * @returns {!AccountSocialTradingInvestorInsert2ResponseModel} AccountSocialTradingInvestorInsert2Response sender model
   */
  get AccountSocialTradingInvestorInsert2ResponseSenderModel () {
    return this._AccountSocialTradingInvestorInsert2ResponseSenderModel
  }

  /**
   * Get AccountDelete2Request model
   * @this {!Client}
   * @returns {!AccountDelete2RequestModel} AccountDelete2Request sender model
   */
  get AccountDelete2RequestSenderModel () {
    return this._AccountDelete2RequestSenderModel
  }

  /**
   * Get AccountDelete2Response model
   * @this {!Client}
   * @returns {!AccountDelete2ResponseModel} AccountDelete2Response sender model
   */
  get AccountDelete2ResponseSenderModel () {
    return this._AccountDelete2ResponseSenderModel
  }

  /**
   * Get AssetInsert2Request model
   * @this {!Client}
   * @returns {!AssetInsert2RequestModel} AssetInsert2Request sender model
   */
  get AssetInsert2RequestSenderModel () {
    return this._AssetInsert2RequestSenderModel
  }

  /**
   * Get AssetInsert2Response model
   * @this {!Client}
   * @returns {!AssetInsert2ResponseModel} AssetInsert2Response sender model
   */
  get AssetInsert2ResponseSenderModel () {
    return this._AssetInsert2ResponseSenderModel
  }

  /**
   * Get AssetUpdate2Request model
   * @this {!Client}
   * @returns {!AssetUpdate2RequestModel} AssetUpdate2Request sender model
   */
  get AssetUpdate2RequestSenderModel () {
    return this._AssetUpdate2RequestSenderModel
  }

  /**
   * Get AssetUpdate2Response model
   * @this {!Client}
   * @returns {!AssetUpdate2ResponseModel} AssetUpdate2Response sender model
   */
  get AssetUpdate2ResponseSenderModel () {
    return this._AssetUpdate2ResponseSenderModel
  }

  /**
   * Get AssetDelete2Request model
   * @this {!Client}
   * @returns {!AssetDelete2RequestModel} AssetDelete2Request sender model
   */
  get AssetDelete2RequestSenderModel () {
    return this._AssetDelete2RequestSenderModel
  }

  /**
   * Get AssetDelete2Response model
   * @this {!Client}
   * @returns {!AssetDelete2ResponseModel} AssetDelete2Response sender model
   */
  get AssetDelete2ResponseSenderModel () {
    return this._AssetDelete2ResponseSenderModel
  }

  /**
   * Get PositionInsert2Request model
   * @this {!Client}
   * @returns {!PositionInsert2RequestModel} PositionInsert2Request sender model
   */
  get PositionInsert2RequestSenderModel () {
    return this._PositionInsert2RequestSenderModel
  }

  /**
   * Get PositionInsert2Response model
   * @this {!Client}
   * @returns {!PositionInsert2ResponseModel} PositionInsert2Response sender model
   */
  get PositionInsert2ResponseSenderModel () {
    return this._PositionInsert2ResponseSenderModel
  }

  /**
   * Get PositionUpdate2Request model
   * @this {!Client}
   * @returns {!PositionUpdate2RequestModel} PositionUpdate2Request sender model
   */
  get PositionUpdate2RequestSenderModel () {
    return this._PositionUpdate2RequestSenderModel
  }

  /**
   * Get PositionUpdate2Response model
   * @this {!Client}
   * @returns {!PositionUpdate2ResponseModel} PositionUpdate2Response sender model
   */
  get PositionUpdate2ResponseSenderModel () {
    return this._PositionUpdate2ResponseSenderModel
  }

  /**
   * Get PositionDelete2Request model
   * @this {!Client}
   * @returns {!PositionDelete2RequestModel} PositionDelete2Request sender model
   */
  get PositionDelete2RequestSenderModel () {
    return this._PositionDelete2RequestSenderModel
  }

  /**
   * Get PositionDelete2Response model
   * @this {!Client}
   * @returns {!PositionDelete2ResponseModel} PositionDelete2Response sender model
   */
  get PositionDelete2ResponseSenderModel () {
    return this._PositionDelete2ResponseSenderModel
  }

  /**
   * Get Deprecated001 model
   * @this {!Client}
   * @returns {!Deprecated001Model} Deprecated001 sender model
   */
  get Deprecated001SenderModel () {
    return this._Deprecated001SenderModel
  }

  /**
   * Get Deprecated002 model
   * @this {!Client}
   * @returns {!Deprecated002Model} Deprecated002 sender model
   */
  get Deprecated002SenderModel () {
    return this._Deprecated002SenderModel
  }

  /**
   * Get Deprecated003 model
   * @this {!Client}
   * @returns {!Deprecated003Model} Deprecated003 sender model
   */
  get Deprecated003SenderModel () {
    return this._Deprecated003SenderModel
  }

  /**
   * Get Deprecated004 model
   * @this {!Client}
   * @returns {!Deprecated004Model} Deprecated004 sender model
   */
  get Deprecated004SenderModel () {
    return this._Deprecated004SenderModel
  }

  /**
   * Get Deprecated005 model
   * @this {!Client}
   * @returns {!Deprecated005Model} Deprecated005 sender model
   */
  get Deprecated005SenderModel () {
    return this._Deprecated005SenderModel
  }

  /**
   * Get Deprecated006 model
   * @this {!Client}
   * @returns {!Deprecated006Model} Deprecated006 sender model
   */
  get Deprecated006SenderModel () {
    return this._Deprecated006SenderModel
  }

  /**
   * Get TradeEventsInsert2Request model
   * @this {!Client}
   * @returns {!TradeEventsInsert2RequestModel} TradeEventsInsert2Request sender model
   */
  get TradeEventsInsert2RequestSenderModel () {
    return this._TradeEventsInsert2RequestSenderModel
  }

  /**
   * Get TradeEventsInsert2Response model
   * @this {!Client}
   * @returns {!TradeEventsInsert2ResponseModel} TradeEventsInsert2Response sender model
   */
  get TradeEventsInsert2ResponseSenderModel () {
    return this._TradeEventsInsert2ResponseSenderModel
  }

  /**
   * Get MarginUpdate2Request model
   * @this {!Client}
   * @returns {!MarginUpdate2RequestModel} MarginUpdate2Request sender model
   */
  get MarginUpdate2RequestSenderModel () {
    return this._MarginUpdate2RequestSenderModel
  }

  /**
   * Get MarginUpdate2Response model
   * @this {!Client}
   * @returns {!MarginUpdate2ResponseModel} MarginUpdate2Response sender model
   */
  get MarginUpdate2ResponseSenderModel () {
    return this._MarginUpdate2ResponseSenderModel
  }

  /**
   * Get AccountUpdate2Request model
   * @this {!Client}
   * @returns {!AccountUpdate2RequestModel} AccountUpdate2Request sender model
   */
  get AccountUpdate2RequestSenderModel () {
    return this._AccountUpdate2RequestSenderModel
  }

  /**
   * Get AccountUpdate2Response model
   * @this {!Client}
   * @returns {!AccountUpdate2ResponseModel} AccountUpdate2Response sender model
   */
  get AccountUpdate2ResponseSenderModel () {
    return this._AccountUpdate2ResponseSenderModel
  }

  /**
   * Get DepositMarginRequest model
   * @this {!Client}
   * @returns {!DepositMarginRequestModel} DepositMarginRequest sender model
   */
  get DepositMarginRequestSenderModel () {
    return this._DepositMarginRequestSenderModel
  }

  /**
   * Get DepositMarginResponse model
   * @this {!Client}
   * @returns {!DepositMarginResponseModel} DepositMarginResponse sender model
   */
  get DepositMarginResponseSenderModel () {
    return this._DepositMarginResponseSenderModel
  }

  /**
   * Get DepositMargin2Request model
   * @this {!Client}
   * @returns {!DepositMargin2RequestModel} DepositMargin2Request sender model
   */
  get DepositMargin2RequestSenderModel () {
    return this._DepositMargin2RequestSenderModel
  }

  /**
   * Get DepositMargin2Response model
   * @this {!Client}
   * @returns {!DepositMargin2ResponseModel} DepositMargin2Response sender model
   */
  get DepositMargin2ResponseSenderModel () {
    return this._DepositMargin2ResponseSenderModel
  }

  /**
   * Get StoreClientLoginSuccessRequest model
   * @this {!Client}
   * @returns {!StoreClientLoginSuccessRequestModel} StoreClientLoginSuccessRequest sender model
   */
  get StoreClientLoginSuccessRequestSenderModel () {
    return this._StoreClientLoginSuccessRequestSenderModel
  }

  /**
   * Get StoreClientLoginSuccessResponse model
   * @this {!Client}
   * @returns {!StoreClientLoginSuccessResponseModel} StoreClientLoginSuccessResponse sender model
   */
  get StoreClientLoginSuccessResponseSenderModel () {
    return this._StoreClientLoginSuccessResponseSenderModel
  }

  /**
   * Get ClientLoginInfoGetRequest model
   * @this {!Client}
   * @returns {!ClientLoginInfoGetRequestModel} ClientLoginInfoGetRequest sender model
   */
  get ClientLoginInfoGetRequestSenderModel () {
    return this._ClientLoginInfoGetRequestSenderModel
  }

  /**
   * Get ClientLoginInfoGetResponse model
   * @this {!Client}
   * @returns {!ClientLoginInfoGetResponseModel} ClientLoginInfoGetResponse sender model
   */
  get ClientLoginInfoGetResponseSenderModel () {
    return this._ClientLoginInfoGetResponseSenderModel
  }

  /**
   * Get ClientLoginInfoNotify model
   * @this {!Client}
   * @returns {!ClientLoginInfoNotifyModel} ClientLoginInfoNotify sender model
   */
  get ClientLoginInfoNotifySenderModel () {
    return this._ClientLoginInfoNotifySenderModel
  }

  /**
   * Get StoreClientLoginSuccess2Request model
   * @this {!Client}
   * @returns {!StoreClientLoginSuccess2RequestModel} StoreClientLoginSuccess2Request sender model
   */
  get StoreClientLoginSuccess2RequestSenderModel () {
    return this._StoreClientLoginSuccess2RequestSenderModel
  }

  /**
   * Get StoreClientLoginSuccess2Response model
   * @this {!Client}
   * @returns {!StoreClientLoginSuccess2ResponseModel} StoreClientLoginSuccess2Response sender model
   */
  get StoreClientLoginSuccess2ResponseSenderModel () {
    return this._StoreClientLoginSuccess2ResponseSenderModel
  }

  /**
   * Get OrderUpdateWithTypeRequest model
   * @this {!Client}
   * @returns {!OrderUpdateWithTypeRequestModel} OrderUpdateWithTypeRequest sender model
   */
  get OrderUpdateWithTypeRequestSenderModel () {
    return this._OrderUpdateWithTypeRequestSenderModel
  }

  /**
   * Get OrderUpdateWithTypeResponse model
   * @this {!Client}
   * @returns {!OrderUpdateWithTypeResponseModel} OrderUpdateWithTypeResponse sender model
   */
  get OrderUpdateWithTypeResponseSenderModel () {
    return this._OrderUpdateWithTypeResponseSenderModel
  }

  /**
   * Get OrderUpdateWithType2Request model
   * @this {!Client}
   * @returns {!OrderUpdateWithType2RequestModel} OrderUpdateWithType2Request sender model
   */
  get OrderUpdateWithType2RequestSenderModel () {
    return this._OrderUpdateWithType2RequestSenderModel
  }

  /**
   * Get OrderUpdateWithType2Response model
   * @this {!Client}
   * @returns {!OrderUpdateWithType2ResponseModel} OrderUpdateWithType2Response sender model
   */
  get OrderUpdateWithType2ResponseSenderModel () {
    return this._OrderUpdateWithType2ResponseSenderModel
  }

  /**
   * Get TradeHistoryTimeRangeGetRequest model
   * @this {!Client}
   * @returns {!TradeHistoryTimeRangeGetRequestModel} TradeHistoryTimeRangeGetRequest sender model
   */
  get TradeHistoryTimeRangeGetRequestSenderModel () {
    return this._TradeHistoryTimeRangeGetRequestSenderModel
  }

  /**
   * Get TradeHistoryTimeRangeGetResponse model
   * @this {!Client}
   * @returns {!TradeHistoryTimeRangeGetResponseModel} TradeHistoryTimeRangeGetResponse sender model
   */
  get TradeHistoryTimeRangeGetResponseSenderModel () {
    return this._TradeHistoryTimeRangeGetResponseSenderModel
  }

  /**
   * Get TradeEventsTimeRangeGetRequest model
   * @this {!Client}
   * @returns {!TradeEventsTimeRangeGetRequestModel} TradeEventsTimeRangeGetRequest sender model
   */
  get TradeEventsTimeRangeGetRequestSenderModel () {
    return this._TradeEventsTimeRangeGetRequestSenderModel
  }

  /**
   * Get TradeEventsTimeRangeGetResponse model
   * @this {!Client}
   * @returns {!TradeEventsTimeRangeGetResponseModel} TradeEventsTimeRangeGetResponse sender model
   */
  get TradeEventsTimeRangeGetResponseSenderModel () {
    return this._TradeEventsTimeRangeGetResponseSenderModel
  }

  /**
   * Get TradeHistoryByIdRequest model
   * @this {!Client}
   * @returns {!TradeHistoryByIdRequestModel} TradeHistoryByIdRequest sender model
   */
  get TradeHistoryByIdRequestSenderModel () {
    return this._TradeHistoryByIdRequestSenderModel
  }

  /**
   * Get TradeHistoryByIdResponse model
   * @this {!Client}
   * @returns {!TradeHistoryByIdResponseModel} TradeHistoryByIdResponse sender model
   */
  get TradeHistoryByIdResponseSenderModel () {
    return this._TradeHistoryByIdResponseSenderModel
  }

  /**
   * Get OrdersGetAllRequest model
   * @this {!Client}
   * @returns {!OrdersGetAllRequestModel} OrdersGetAllRequest sender model
   */
  get OrdersGetAllRequestSenderModel () {
    return this._OrdersGetAllRequestSenderModel
  }

  /**
   * Get OrdersGetAllResponse model
   * @this {!Client}
   * @returns {!OrdersGetAllResponseModel} OrdersGetAllResponse sender model
   */
  get OrdersGetAllResponseSenderModel () {
    return this._OrdersGetAllResponseSenderModel
  }

  /**
   * Get PositionsGetAllRequest model
   * @this {!Client}
   * @returns {!PositionsGetAllRequestModel} PositionsGetAllRequest sender model
   */
  get PositionsGetAllRequestSenderModel () {
    return this._PositionsGetAllRequestSenderModel
  }

  /**
   * Get PositionsGetAllResponse model
   * @this {!Client}
   * @returns {!PositionsGetAllResponseModel} PositionsGetAllResponse sender model
   */
  get PositionsGetAllResponseSenderModel () {
    return this._PositionsGetAllResponseSenderModel
  }

  /**
   * Get AssetsGetAllRequest model
   * @this {!Client}
   * @returns {!AssetsGetAllRequestModel} AssetsGetAllRequest sender model
   */
  get AssetsGetAllRequestSenderModel () {
    return this._AssetsGetAllRequestSenderModel
  }

  /**
   * Get AssetsGetAllResponse model
   * @this {!Client}
   * @returns {!AssetsGetAllResponseModel} AssetsGetAllResponse sender model
   */
  get AssetsGetAllResponseSenderModel () {
    return this._AssetsGetAllResponseSenderModel
  }

  /**
   * Get MarginsGetAllRequest model
   * @this {!Client}
   * @returns {!MarginsGetAllRequestModel} MarginsGetAllRequest sender model
   */
  get MarginsGetAllRequestSenderModel () {
    return this._MarginsGetAllRequestSenderModel
  }

  /**
   * Get MarginsGetAllResponse model
   * @this {!Client}
   * @returns {!MarginsGetAllResponseModel} MarginsGetAllResponse sender model
   */
  get MarginsGetAllResponseSenderModel () {
    return this._MarginsGetAllResponseSenderModel
  }

  /**
   * Get AccountsGetAllRequest model
   * @this {!Client}
   * @returns {!AccountsGetAllRequestModel} AccountsGetAllRequest sender model
   */
  get AccountsGetAllRequestSenderModel () {
    return this._AccountsGetAllRequestSenderModel
  }

  /**
   * Get AccountsGetAllResponse model
   * @this {!Client}
   * @returns {!AccountsGetAllResponseModel} AccountsGetAllResponse sender model
   */
  get AccountsGetAllResponseSenderModel () {
    return this._AccountsGetAllResponseSenderModel
  }

  /**
   * Get MassRequest model
   * @this {!Client}
   * @returns {!MassRequestModel} MassRequest sender model
   */
  get MassRequestSenderModel () {
    return this._MassRequestSenderModel
  }

  /**
   * Get MassResponse model
   * @this {!Client}
   * @returns {!MassResponseModel} MassResponse sender model
   */
  get MassResponseSenderModel () {
    return this._MassResponseSenderModel
  }

  /**
   * Get Mass2Request model
   * @this {!Client}
   * @returns {!Mass2RequestModel} Mass2Request sender model
   */
  get Mass2RequestSenderModel () {
    return this._Mass2RequestSenderModel
  }

  /**
   * Get Mass2ResponseParams model
   * @this {!Client}
   * @returns {!Mass2ResponseParamsModel} Mass2ResponseParams sender model
   */
  get Mass2ResponseParamsSenderModel () {
    return this._Mass2ResponseParamsSenderModel
  }

  /**
   * Get Mass2Response model
   * @this {!Client}
   * @returns {!Mass2ResponseModel} Mass2Response sender model
   */
  get Mass2ResponseSenderModel () {
    return this._Mass2ResponseSenderModel
  }

  /**
   * Get MarginUpdateNotify model
   * @this {!Client}
   * @returns {!MarginUpdateNotifyModel} MarginUpdateNotify sender model
   */
  get MarginUpdateNotifySenderModel () {
    return this._MarginUpdateNotifySenderModel
  }

  /**
   * Get PersonsGetAllRequest model
   * @this {!Client}
   * @returns {!PersonsGetAllRequestModel} PersonsGetAllRequest sender model
   */
  get PersonsGetAllRequestSenderModel () {
    return this._PersonsGetAllRequestSenderModel
  }

  /**
   * Get PersonsGetAllResponse model
   * @this {!Client}
   * @returns {!PersonsGetAllResponseModel} PersonsGetAllResponse sender model
   */
  get PersonsGetAllResponseSenderModel () {
    return this._PersonsGetAllResponseSenderModel
  }

  /**
   * Get PersonsInsertRequest model
   * @this {!Client}
   * @returns {!PersonsInsertRequestModel} PersonsInsertRequest sender model
   */
  get PersonsInsertRequestSenderModel () {
    return this._PersonsInsertRequestSenderModel
  }

  /**
   * Get PersonsInsertResponse model
   * @this {!Client}
   * @returns {!PersonsInsertResponseModel} PersonsInsertResponse sender model
   */
  get PersonsInsertResponseSenderModel () {
    return this._PersonsInsertResponseSenderModel
  }

  /**
   * Get PersonUpdateRequest model
   * @this {!Client}
   * @returns {!PersonUpdateRequestModel} PersonUpdateRequest sender model
   */
  get PersonUpdateRequestSenderModel () {
    return this._PersonUpdateRequestSenderModel
  }

  /**
   * Get PersonUpdateResponse model
   * @this {!Client}
   * @returns {!PersonUpdateResponseModel} PersonUpdateResponse sender model
   */
  get PersonUpdateResponseSenderModel () {
    return this._PersonUpdateResponseSenderModel
  }

  /**
   * Get PersonsInsert2Request model
   * @this {!Client}
   * @returns {!PersonsInsert2RequestModel} PersonsInsert2Request sender model
   */
  get PersonsInsert2RequestSenderModel () {
    return this._PersonsInsert2RequestSenderModel
  }

  /**
   * Get PersonsInsert2Response model
   * @this {!Client}
   * @returns {!PersonsInsert2ResponseModel} PersonsInsert2Response sender model
   */
  get PersonsInsert2ResponseSenderModel () {
    return this._PersonsInsert2ResponseSenderModel
  }

  /**
   * Get PersonUpdate2Request model
   * @this {!Client}
   * @returns {!PersonUpdate2RequestModel} PersonUpdate2Request sender model
   */
  get PersonUpdate2RequestSenderModel () {
    return this._PersonUpdate2RequestSenderModel
  }

  /**
   * Get PersonUpdate2Response model
   * @this {!Client}
   * @returns {!PersonUpdate2ResponseModel} PersonUpdate2Response sender model
   */
  get PersonUpdate2ResponseSenderModel () {
    return this._PersonUpdate2ResponseSenderModel
  }

  /**
   * Get LastIdsGetRequest model
   * @this {!Client}
   * @returns {!LastIdsGetRequestModel} LastIdsGetRequest sender model
   */
  get LastIdsGetRequestSenderModel () {
    return this._LastIdsGetRequestSenderModel
  }

  /**
   * Get LastIdsGetResponse model
   * @this {!Client}
   * @returns {!LastIdsGetResponseModel} LastIdsGetResponse sender model
   */
  get LastIdsGetResponseSenderModel () {
    return this._LastIdsGetResponseSenderModel
  }

  /**
   * Get DailySnapshotRequest model
   * @this {!Client}
   * @returns {!DailySnapshotRequestModel} DailySnapshotRequest sender model
   */
  get DailySnapshotRequestSenderModel () {
    return this._DailySnapshotRequestSenderModel
  }

  /**
   * Get DailySnapshotResponse model
   * @this {!Client}
   * @returns {!DailySnapshotResponseModel} DailySnapshotResponse sender model
   */
  get DailySnapshotResponseSenderModel () {
    return this._DailySnapshotResponseSenderModel
  }

  /**
   * Get BillingStatisticsGetRequest model
   * @this {!Client}
   * @returns {!BillingStatisticsGetRequestModel} BillingStatisticsGetRequest sender model
   */
  get BillingStatisticsGetRequestSenderModel () {
    return this._BillingStatisticsGetRequestSenderModel
  }

  /**
   * Get BillingStatisticsGetResponse model
   * @this {!Client}
   * @returns {!BillingStatisticsGetResponseModel} BillingStatisticsGetResponse sender model
   */
  get BillingStatisticsGetResponseSenderModel () {
    return this._BillingStatisticsGetResponseSenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof StorageVersionRequest) && (value.fbeType === this.StorageVersionRequestSenderModel.fbeType)) {
      return this.send_StorageVersionRequest(value)
    }
    if ((value instanceof StorageVersionResponse) && (value.fbeType === this.StorageVersionResponseSenderModel.fbeType)) {
      return this.send_StorageVersionResponse(value)
    }
    if ((value instanceof StorageUpdateNotify) && (value.fbeType === this.StorageUpdateNotifySenderModel.fbeType)) {
      return this.send_StorageUpdateNotify(value)
    }
    if ((value instanceof AccountInsertRequest) && (value.fbeType === this.AccountInsertRequestSenderModel.fbeType)) {
      return this.send_AccountInsertRequest(value)
    }
    if ((value instanceof AccountInsertResponse) && (value.fbeType === this.AccountInsertResponseSenderModel.fbeType)) {
      return this.send_AccountInsertResponse(value)
    }
    if ((value instanceof AccountSocialTradingMasterInsertRequest) && (value.fbeType === this.AccountSocialTradingMasterInsertRequestSenderModel.fbeType)) {
      return this.send_AccountSocialTradingMasterInsertRequest(value)
    }
    if ((value instanceof AccountSocialTradingMasterInsertResponse) && (value.fbeType === this.AccountSocialTradingMasterInsertResponseSenderModel.fbeType)) {
      return this.send_AccountSocialTradingMasterInsertResponse(value)
    }
    if ((value instanceof AccountSocialTradingInvestorInsertRequest) && (value.fbeType === this.AccountSocialTradingInvestorInsertRequestSenderModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorInsertRequest(value)
    }
    if ((value instanceof AccountSocialTradingInvestorInsertResponse) && (value.fbeType === this.AccountSocialTradingInvestorInsertResponseSenderModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorInsertResponse(value)
    }
    if ((value instanceof AccountUpdateRequest) && (value.fbeType === this.AccountUpdateRequestSenderModel.fbeType)) {
      return this.send_AccountUpdateRequest(value)
    }
    if ((value instanceof AccountUpdateResponse) && (value.fbeType === this.AccountUpdateResponseSenderModel.fbeType)) {
      return this.send_AccountUpdateResponse(value)
    }
    if ((value instanceof AccountDeleteRequest) && (value.fbeType === this.AccountDeleteRequestSenderModel.fbeType)) {
      return this.send_AccountDeleteRequest(value)
    }
    if ((value instanceof AccountDeleteResponse) && (value.fbeType === this.AccountDeleteResponseSenderModel.fbeType)) {
      return this.send_AccountDeleteResponse(value)
    }
    if ((value instanceof MarginUpdateRequest) && (value.fbeType === this.MarginUpdateRequestSenderModel.fbeType)) {
      return this.send_MarginUpdateRequest(value)
    }
    if ((value instanceof MarginUpdateResponse) && (value.fbeType === this.MarginUpdateResponseSenderModel.fbeType)) {
      return this.send_MarginUpdateResponse(value)
    }
    if ((value instanceof AssetInsertRequest) && (value.fbeType === this.AssetInsertRequestSenderModel.fbeType)) {
      return this.send_AssetInsertRequest(value)
    }
    if ((value instanceof AssetInsertResponse) && (value.fbeType === this.AssetInsertResponseSenderModel.fbeType)) {
      return this.send_AssetInsertResponse(value)
    }
    if ((value instanceof AssetUpdateRequest) && (value.fbeType === this.AssetUpdateRequestSenderModel.fbeType)) {
      return this.send_AssetUpdateRequest(value)
    }
    if ((value instanceof AssetUpdateResponse) && (value.fbeType === this.AssetUpdateResponseSenderModel.fbeType)) {
      return this.send_AssetUpdateResponse(value)
    }
    if ((value instanceof AssetDeleteRequest) && (value.fbeType === this.AssetDeleteRequestSenderModel.fbeType)) {
      return this.send_AssetDeleteRequest(value)
    }
    if ((value instanceof AssetDeleteResponse) && (value.fbeType === this.AssetDeleteResponseSenderModel.fbeType)) {
      return this.send_AssetDeleteResponse(value)
    }
    if ((value instanceof OrderInsertRequest) && (value.fbeType === this.OrderInsertRequestSenderModel.fbeType)) {
      return this.send_OrderInsertRequest(value)
    }
    if ((value instanceof OrderInsertResponse) && (value.fbeType === this.OrderInsertResponseSenderModel.fbeType)) {
      return this.send_OrderInsertResponse(value)
    }
    if ((value instanceof OrderUpdateRequest) && (value.fbeType === this.OrderUpdateRequestSenderModel.fbeType)) {
      return this.send_OrderUpdateRequest(value)
    }
    if ((value instanceof OrderUpdateResponse) && (value.fbeType === this.OrderUpdateResponseSenderModel.fbeType)) {
      return this.send_OrderUpdateResponse(value)
    }
    if ((value instanceof OrderDeleteRequest) && (value.fbeType === this.OrderDeleteRequestSenderModel.fbeType)) {
      return this.send_OrderDeleteRequest(value)
    }
    if ((value instanceof OrderDeleteResponse) && (value.fbeType === this.OrderDeleteResponseSenderModel.fbeType)) {
      return this.send_OrderDeleteResponse(value)
    }
    if ((value instanceof OrderDeleteAllRequest) && (value.fbeType === this.OrderDeleteAllRequestSenderModel.fbeType)) {
      return this.send_OrderDeleteAllRequest(value)
    }
    if ((value instanceof OrderDeleteAllResponse) && (value.fbeType === this.OrderDeleteAllResponseSenderModel.fbeType)) {
      return this.send_OrderDeleteAllResponse(value)
    }
    if ((value instanceof PositionInsertRequest) && (value.fbeType === this.PositionInsertRequestSenderModel.fbeType)) {
      return this.send_PositionInsertRequest(value)
    }
    if ((value instanceof PositionInsertResponse) && (value.fbeType === this.PositionInsertResponseSenderModel.fbeType)) {
      return this.send_PositionInsertResponse(value)
    }
    if ((value instanceof PositionUpdateRequest) && (value.fbeType === this.PositionUpdateRequestSenderModel.fbeType)) {
      return this.send_PositionUpdateRequest(value)
    }
    if ((value instanceof PositionUpdateResponse) && (value.fbeType === this.PositionUpdateResponseSenderModel.fbeType)) {
      return this.send_PositionUpdateResponse(value)
    }
    if ((value instanceof PositionDeleteRequest) && (value.fbeType === this.PositionDeleteRequestSenderModel.fbeType)) {
      return this.send_PositionDeleteRequest(value)
    }
    if ((value instanceof PositionDeleteResponse) && (value.fbeType === this.PositionDeleteResponseSenderModel.fbeType)) {
      return this.send_PositionDeleteResponse(value)
    }
    if ((value instanceof TradeHistoryRequest) && (value.fbeType === this.TradeHistoryRequestSenderModel.fbeType)) {
      return this.send_TradeHistoryRequest(value)
    }
    if ((value instanceof TradeHistoryResponse) && (value.fbeType === this.TradeHistoryResponseSenderModel.fbeType)) {
      return this.send_TradeHistoryResponse(value)
    }
    if ((value instanceof TradeEventsGetRequest) && (value.fbeType === this.TradeEventsGetRequestSenderModel.fbeType)) {
      return this.send_TradeEventsGetRequest(value)
    }
    if ((value instanceof TradeEventsGetResponse) && (value.fbeType === this.TradeEventsGetResponseSenderModel.fbeType)) {
      return this.send_TradeEventsGetResponse(value)
    }
    if ((value instanceof TradeEventsNotify) && (value.fbeType === this.TradeEventsNotifySenderModel.fbeType)) {
      return this.send_TradeEventsNotify(value)
    }
    if ((value instanceof TradeEventsInsertRequest) && (value.fbeType === this.TradeEventsInsertRequestSenderModel.fbeType)) {
      return this.send_TradeEventsInsertRequest(value)
    }
    if ((value instanceof TradeEventsInsertResponse) && (value.fbeType === this.TradeEventsInsertResponseSenderModel.fbeType)) {
      return this.send_TradeEventsInsertResponse(value)
    }
    if ((value instanceof ProfitClosedGetRequest) && (value.fbeType === this.ProfitClosedGetRequestSenderModel.fbeType)) {
      return this.send_ProfitClosedGetRequest(value)
    }
    if ((value instanceof ProfitClosedGetResponse) && (value.fbeType === this.ProfitClosedGetResponseSenderModel.fbeType)) {
      return this.send_ProfitClosedGetResponse(value)
    }
    if ((value instanceof OrdersClosedSummariesGetRequest) && (value.fbeType === this.OrdersClosedSummariesGetRequestSenderModel.fbeType)) {
      return this.send_OrdersClosedSummariesGetRequest(value)
    }
    if ((value instanceof OrdersClosedSummariesGetResponse) && (value.fbeType === this.OrdersClosedSummariesGetResponseSenderModel.fbeType)) {
      return this.send_OrdersClosedSummariesGetResponse(value)
    }
    if ((value instanceof OrderInsert2Request) && (value.fbeType === this.OrderInsert2RequestSenderModel.fbeType)) {
      return this.send_OrderInsert2Request(value)
    }
    if ((value instanceof OrderInsert2Response) && (value.fbeType === this.OrderInsert2ResponseSenderModel.fbeType)) {
      return this.send_OrderInsert2Response(value)
    }
    if ((value instanceof OrderUpdate2Request) && (value.fbeType === this.OrderUpdate2RequestSenderModel.fbeType)) {
      return this.send_OrderUpdate2Request(value)
    }
    if ((value instanceof OrderUpdate2Response) && (value.fbeType === this.OrderUpdate2ResponseSenderModel.fbeType)) {
      return this.send_OrderUpdate2Response(value)
    }
    if ((value instanceof OrderDelete2Request) && (value.fbeType === this.OrderDelete2RequestSenderModel.fbeType)) {
      return this.send_OrderDelete2Request(value)
    }
    if ((value instanceof OrderDelete2Response) && (value.fbeType === this.OrderDelete2ResponseSenderModel.fbeType)) {
      return this.send_OrderDelete2Response(value)
    }
    if ((value instanceof AccountInsert2Request) && (value.fbeType === this.AccountInsert2RequestSenderModel.fbeType)) {
      return this.send_AccountInsert2Request(value)
    }
    if ((value instanceof AccountInsert2Response) && (value.fbeType === this.AccountInsert2ResponseSenderModel.fbeType)) {
      return this.send_AccountInsert2Response(value)
    }
    if ((value instanceof AccountSocialTradingMasterInsert2Request) && (value.fbeType === this.AccountSocialTradingMasterInsert2RequestSenderModel.fbeType)) {
      return this.send_AccountSocialTradingMasterInsert2Request(value)
    }
    if ((value instanceof AccountSocialTradingMasterInsert2Response) && (value.fbeType === this.AccountSocialTradingMasterInsert2ResponseSenderModel.fbeType)) {
      return this.send_AccountSocialTradingMasterInsert2Response(value)
    }
    if ((value instanceof AccountSocialTradingInvestorInsert2Request) && (value.fbeType === this.AccountSocialTradingInvestorInsert2RequestSenderModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorInsert2Request(value)
    }
    if ((value instanceof AccountSocialTradingInvestorInsert2Response) && (value.fbeType === this.AccountSocialTradingInvestorInsert2ResponseSenderModel.fbeType)) {
      return this.send_AccountSocialTradingInvestorInsert2Response(value)
    }
    if ((value instanceof AccountDelete2Request) && (value.fbeType === this.AccountDelete2RequestSenderModel.fbeType)) {
      return this.send_AccountDelete2Request(value)
    }
    if ((value instanceof AccountDelete2Response) && (value.fbeType === this.AccountDelete2ResponseSenderModel.fbeType)) {
      return this.send_AccountDelete2Response(value)
    }
    if ((value instanceof AssetInsert2Request) && (value.fbeType === this.AssetInsert2RequestSenderModel.fbeType)) {
      return this.send_AssetInsert2Request(value)
    }
    if ((value instanceof AssetInsert2Response) && (value.fbeType === this.AssetInsert2ResponseSenderModel.fbeType)) {
      return this.send_AssetInsert2Response(value)
    }
    if ((value instanceof AssetUpdate2Request) && (value.fbeType === this.AssetUpdate2RequestSenderModel.fbeType)) {
      return this.send_AssetUpdate2Request(value)
    }
    if ((value instanceof AssetUpdate2Response) && (value.fbeType === this.AssetUpdate2ResponseSenderModel.fbeType)) {
      return this.send_AssetUpdate2Response(value)
    }
    if ((value instanceof AssetDelete2Request) && (value.fbeType === this.AssetDelete2RequestSenderModel.fbeType)) {
      return this.send_AssetDelete2Request(value)
    }
    if ((value instanceof AssetDelete2Response) && (value.fbeType === this.AssetDelete2ResponseSenderModel.fbeType)) {
      return this.send_AssetDelete2Response(value)
    }
    if ((value instanceof PositionInsert2Request) && (value.fbeType === this.PositionInsert2RequestSenderModel.fbeType)) {
      return this.send_PositionInsert2Request(value)
    }
    if ((value instanceof PositionInsert2Response) && (value.fbeType === this.PositionInsert2ResponseSenderModel.fbeType)) {
      return this.send_PositionInsert2Response(value)
    }
    if ((value instanceof PositionUpdate2Request) && (value.fbeType === this.PositionUpdate2RequestSenderModel.fbeType)) {
      return this.send_PositionUpdate2Request(value)
    }
    if ((value instanceof PositionUpdate2Response) && (value.fbeType === this.PositionUpdate2ResponseSenderModel.fbeType)) {
      return this.send_PositionUpdate2Response(value)
    }
    if ((value instanceof PositionDelete2Request) && (value.fbeType === this.PositionDelete2RequestSenderModel.fbeType)) {
      return this.send_PositionDelete2Request(value)
    }
    if ((value instanceof PositionDelete2Response) && (value.fbeType === this.PositionDelete2ResponseSenderModel.fbeType)) {
      return this.send_PositionDelete2Response(value)
    }
    if ((value instanceof Deprecated001) && (value.fbeType === this.Deprecated001SenderModel.fbeType)) {
      return this.send_Deprecated001(value)
    }
    if ((value instanceof Deprecated002) && (value.fbeType === this.Deprecated002SenderModel.fbeType)) {
      return this.send_Deprecated002(value)
    }
    if ((value instanceof Deprecated003) && (value.fbeType === this.Deprecated003SenderModel.fbeType)) {
      return this.send_Deprecated003(value)
    }
    if ((value instanceof Deprecated004) && (value.fbeType === this.Deprecated004SenderModel.fbeType)) {
      return this.send_Deprecated004(value)
    }
    if ((value instanceof Deprecated005) && (value.fbeType === this.Deprecated005SenderModel.fbeType)) {
      return this.send_Deprecated005(value)
    }
    if ((value instanceof Deprecated006) && (value.fbeType === this.Deprecated006SenderModel.fbeType)) {
      return this.send_Deprecated006(value)
    }
    if ((value instanceof TradeEventsInsert2Request) && (value.fbeType === this.TradeEventsInsert2RequestSenderModel.fbeType)) {
      return this.send_TradeEventsInsert2Request(value)
    }
    if ((value instanceof TradeEventsInsert2Response) && (value.fbeType === this.TradeEventsInsert2ResponseSenderModel.fbeType)) {
      return this.send_TradeEventsInsert2Response(value)
    }
    if ((value instanceof MarginUpdate2Request) && (value.fbeType === this.MarginUpdate2RequestSenderModel.fbeType)) {
      return this.send_MarginUpdate2Request(value)
    }
    if ((value instanceof MarginUpdate2Response) && (value.fbeType === this.MarginUpdate2ResponseSenderModel.fbeType)) {
      return this.send_MarginUpdate2Response(value)
    }
    if ((value instanceof AccountUpdate2Request) && (value.fbeType === this.AccountUpdate2RequestSenderModel.fbeType)) {
      return this.send_AccountUpdate2Request(value)
    }
    if ((value instanceof AccountUpdate2Response) && (value.fbeType === this.AccountUpdate2ResponseSenderModel.fbeType)) {
      return this.send_AccountUpdate2Response(value)
    }
    if ((value instanceof DepositMarginRequest) && (value.fbeType === this.DepositMarginRequestSenderModel.fbeType)) {
      return this.send_DepositMarginRequest(value)
    }
    if ((value instanceof DepositMarginResponse) && (value.fbeType === this.DepositMarginResponseSenderModel.fbeType)) {
      return this.send_DepositMarginResponse(value)
    }
    if ((value instanceof DepositMargin2Request) && (value.fbeType === this.DepositMargin2RequestSenderModel.fbeType)) {
      return this.send_DepositMargin2Request(value)
    }
    if ((value instanceof DepositMargin2Response) && (value.fbeType === this.DepositMargin2ResponseSenderModel.fbeType)) {
      return this.send_DepositMargin2Response(value)
    }
    if ((value instanceof StoreClientLoginSuccessRequest) && (value.fbeType === this.StoreClientLoginSuccessRequestSenderModel.fbeType)) {
      return this.send_StoreClientLoginSuccessRequest(value)
    }
    if ((value instanceof StoreClientLoginSuccessResponse) && (value.fbeType === this.StoreClientLoginSuccessResponseSenderModel.fbeType)) {
      return this.send_StoreClientLoginSuccessResponse(value)
    }
    if ((value instanceof ClientLoginInfoGetRequest) && (value.fbeType === this.ClientLoginInfoGetRequestSenderModel.fbeType)) {
      return this.send_ClientLoginInfoGetRequest(value)
    }
    if ((value instanceof ClientLoginInfoGetResponse) && (value.fbeType === this.ClientLoginInfoGetResponseSenderModel.fbeType)) {
      return this.send_ClientLoginInfoGetResponse(value)
    }
    if ((value instanceof ClientLoginInfoNotify) && (value.fbeType === this.ClientLoginInfoNotifySenderModel.fbeType)) {
      return this.send_ClientLoginInfoNotify(value)
    }
    if ((value instanceof StoreClientLoginSuccess2Request) && (value.fbeType === this.StoreClientLoginSuccess2RequestSenderModel.fbeType)) {
      return this.send_StoreClientLoginSuccess2Request(value)
    }
    if ((value instanceof StoreClientLoginSuccess2Response) && (value.fbeType === this.StoreClientLoginSuccess2ResponseSenderModel.fbeType)) {
      return this.send_StoreClientLoginSuccess2Response(value)
    }
    if ((value instanceof OrderUpdateWithTypeRequest) && (value.fbeType === this.OrderUpdateWithTypeRequestSenderModel.fbeType)) {
      return this.send_OrderUpdateWithTypeRequest(value)
    }
    if ((value instanceof OrderUpdateWithTypeResponse) && (value.fbeType === this.OrderUpdateWithTypeResponseSenderModel.fbeType)) {
      return this.send_OrderUpdateWithTypeResponse(value)
    }
    if ((value instanceof OrderUpdateWithType2Request) && (value.fbeType === this.OrderUpdateWithType2RequestSenderModel.fbeType)) {
      return this.send_OrderUpdateWithType2Request(value)
    }
    if ((value instanceof OrderUpdateWithType2Response) && (value.fbeType === this.OrderUpdateWithType2ResponseSenderModel.fbeType)) {
      return this.send_OrderUpdateWithType2Response(value)
    }
    if ((value instanceof TradeHistoryTimeRangeGetRequest) && (value.fbeType === this.TradeHistoryTimeRangeGetRequestSenderModel.fbeType)) {
      return this.send_TradeHistoryTimeRangeGetRequest(value)
    }
    if ((value instanceof TradeHistoryTimeRangeGetResponse) && (value.fbeType === this.TradeHistoryTimeRangeGetResponseSenderModel.fbeType)) {
      return this.send_TradeHistoryTimeRangeGetResponse(value)
    }
    if ((value instanceof TradeEventsTimeRangeGetRequest) && (value.fbeType === this.TradeEventsTimeRangeGetRequestSenderModel.fbeType)) {
      return this.send_TradeEventsTimeRangeGetRequest(value)
    }
    if ((value instanceof TradeEventsTimeRangeGetResponse) && (value.fbeType === this.TradeEventsTimeRangeGetResponseSenderModel.fbeType)) {
      return this.send_TradeEventsTimeRangeGetResponse(value)
    }
    if ((value instanceof TradeHistoryByIdRequest) && (value.fbeType === this.TradeHistoryByIdRequestSenderModel.fbeType)) {
      return this.send_TradeHistoryByIdRequest(value)
    }
    if ((value instanceof TradeHistoryByIdResponse) && (value.fbeType === this.TradeHistoryByIdResponseSenderModel.fbeType)) {
      return this.send_TradeHistoryByIdResponse(value)
    }
    if ((value instanceof OrdersGetAllRequest) && (value.fbeType === this.OrdersGetAllRequestSenderModel.fbeType)) {
      return this.send_OrdersGetAllRequest(value)
    }
    if ((value instanceof OrdersGetAllResponse) && (value.fbeType === this.OrdersGetAllResponseSenderModel.fbeType)) {
      return this.send_OrdersGetAllResponse(value)
    }
    if ((value instanceof PositionsGetAllRequest) && (value.fbeType === this.PositionsGetAllRequestSenderModel.fbeType)) {
      return this.send_PositionsGetAllRequest(value)
    }
    if ((value instanceof PositionsGetAllResponse) && (value.fbeType === this.PositionsGetAllResponseSenderModel.fbeType)) {
      return this.send_PositionsGetAllResponse(value)
    }
    if ((value instanceof AssetsGetAllRequest) && (value.fbeType === this.AssetsGetAllRequestSenderModel.fbeType)) {
      return this.send_AssetsGetAllRequest(value)
    }
    if ((value instanceof AssetsGetAllResponse) && (value.fbeType === this.AssetsGetAllResponseSenderModel.fbeType)) {
      return this.send_AssetsGetAllResponse(value)
    }
    if ((value instanceof MarginsGetAllRequest) && (value.fbeType === this.MarginsGetAllRequestSenderModel.fbeType)) {
      return this.send_MarginsGetAllRequest(value)
    }
    if ((value instanceof MarginsGetAllResponse) && (value.fbeType === this.MarginsGetAllResponseSenderModel.fbeType)) {
      return this.send_MarginsGetAllResponse(value)
    }
    if ((value instanceof AccountsGetAllRequest) && (value.fbeType === this.AccountsGetAllRequestSenderModel.fbeType)) {
      return this.send_AccountsGetAllRequest(value)
    }
    if ((value instanceof AccountsGetAllResponse) && (value.fbeType === this.AccountsGetAllResponseSenderModel.fbeType)) {
      return this.send_AccountsGetAllResponse(value)
    }
    if ((value instanceof MassRequest) && (value.fbeType === this.MassRequestSenderModel.fbeType)) {
      return this.send_MassRequest(value)
    }
    if ((value instanceof MassResponse) && (value.fbeType === this.MassResponseSenderModel.fbeType)) {
      return this.send_MassResponse(value)
    }
    if ((value instanceof Mass2Request) && (value.fbeType === this.Mass2RequestSenderModel.fbeType)) {
      return this.send_Mass2Request(value)
    }
    if ((value instanceof Mass2ResponseParams) && (value.fbeType === this.Mass2ResponseParamsSenderModel.fbeType)) {
      return this.send_Mass2ResponseParams(value)
    }
    if ((value instanceof Mass2Response) && (value.fbeType === this.Mass2ResponseSenderModel.fbeType)) {
      return this.send_Mass2Response(value)
    }
    if ((value instanceof MarginUpdateNotify) && (value.fbeType === this.MarginUpdateNotifySenderModel.fbeType)) {
      return this.send_MarginUpdateNotify(value)
    }
    if ((value instanceof PersonsGetAllRequest) && (value.fbeType === this.PersonsGetAllRequestSenderModel.fbeType)) {
      return this.send_PersonsGetAllRequest(value)
    }
    if ((value instanceof PersonsGetAllResponse) && (value.fbeType === this.PersonsGetAllResponseSenderModel.fbeType)) {
      return this.send_PersonsGetAllResponse(value)
    }
    if ((value instanceof PersonsInsertRequest) && (value.fbeType === this.PersonsInsertRequestSenderModel.fbeType)) {
      return this.send_PersonsInsertRequest(value)
    }
    if ((value instanceof PersonsInsertResponse) && (value.fbeType === this.PersonsInsertResponseSenderModel.fbeType)) {
      return this.send_PersonsInsertResponse(value)
    }
    if ((value instanceof PersonUpdateRequest) && (value.fbeType === this.PersonUpdateRequestSenderModel.fbeType)) {
      return this.send_PersonUpdateRequest(value)
    }
    if ((value instanceof PersonUpdateResponse) && (value.fbeType === this.PersonUpdateResponseSenderModel.fbeType)) {
      return this.send_PersonUpdateResponse(value)
    }
    if ((value instanceof PersonsInsert2Request) && (value.fbeType === this.PersonsInsert2RequestSenderModel.fbeType)) {
      return this.send_PersonsInsert2Request(value)
    }
    if ((value instanceof PersonsInsert2Response) && (value.fbeType === this.PersonsInsert2ResponseSenderModel.fbeType)) {
      return this.send_PersonsInsert2Response(value)
    }
    if ((value instanceof PersonUpdate2Request) && (value.fbeType === this.PersonUpdate2RequestSenderModel.fbeType)) {
      return this.send_PersonUpdate2Request(value)
    }
    if ((value instanceof PersonUpdate2Response) && (value.fbeType === this.PersonUpdate2ResponseSenderModel.fbeType)) {
      return this.send_PersonUpdate2Response(value)
    }
    if ((value instanceof LastIdsGetRequest) && (value.fbeType === this.LastIdsGetRequestSenderModel.fbeType)) {
      return this.send_LastIdsGetRequest(value)
    }
    if ((value instanceof LastIdsGetResponse) && (value.fbeType === this.LastIdsGetResponseSenderModel.fbeType)) {
      return this.send_LastIdsGetResponse(value)
    }
    if ((value instanceof DailySnapshotRequest) && (value.fbeType === this.DailySnapshotRequestSenderModel.fbeType)) {
      return this.send_DailySnapshotRequest(value)
    }
    if ((value instanceof DailySnapshotResponse) && (value.fbeType === this.DailySnapshotResponseSenderModel.fbeType)) {
      return this.send_DailySnapshotResponse(value)
    }
    if ((value instanceof BillingStatisticsGetRequest) && (value.fbeType === this.BillingStatisticsGetRequestSenderModel.fbeType)) {
      return this.send_BillingStatisticsGetRequest(value)
    }
    if ((value instanceof BillingStatisticsGetResponse) && (value.fbeType === this.BillingStatisticsGetResponseSenderModel.fbeType)) {
      return this.send_BillingStatisticsGetResponse(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._innerClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send StorageVersionRequest value
   * @this {!Client}
   * @param {!StorageVersionRequest} value StorageVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_StorageVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StorageVersionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.StorageVersionRequest serialization failed!')
    console.assert(this.StorageVersionRequestSenderModel.verify(), 'storage.StorageVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StorageVersionResponse value
   * @this {!Client}
   * @param {!StorageVersionResponse} value StorageVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_StorageVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StorageVersionResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.StorageVersionResponse serialization failed!')
    console.assert(this.StorageVersionResponseSenderModel.verify(), 'storage.StorageVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StorageUpdateNotify value
   * @this {!Client}
   * @param {!StorageUpdateNotify} value StorageUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_StorageUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StorageUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.StorageUpdateNotify serialization failed!')
    console.assert(this.StorageUpdateNotifySenderModel.verify(), 'storage.StorageUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInsertRequest value
   * @this {!Client}
   * @param {!AccountInsertRequest} value AccountInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInsertRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountInsertRequest serialization failed!')
    console.assert(this.AccountInsertRequestSenderModel.verify(), 'storage.AccountInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInsertResponse value
   * @this {!Client}
   * @param {!AccountInsertResponse} value AccountInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInsertResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountInsertResponse serialization failed!')
    console.assert(this.AccountInsertResponseSenderModel.verify(), 'storage.AccountInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterInsertRequest value
   * @this {!Client}
   * @param {!AccountSocialTradingMasterInsertRequest} value AccountSocialTradingMasterInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterInsertRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingMasterInsertRequest serialization failed!')
    console.assert(this.AccountSocialTradingMasterInsertRequestSenderModel.verify(), 'storage.AccountSocialTradingMasterInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterInsertResponse value
   * @this {!Client}
   * @param {!AccountSocialTradingMasterInsertResponse} value AccountSocialTradingMasterInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterInsertResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingMasterInsertResponse serialization failed!')
    console.assert(this.AccountSocialTradingMasterInsertResponseSenderModel.verify(), 'storage.AccountSocialTradingMasterInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorInsertRequest value
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorInsertRequest} value AccountSocialTradingInvestorInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorInsertRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingInvestorInsertRequest serialization failed!')
    console.assert(this.AccountSocialTradingInvestorInsertRequestSenderModel.verify(), 'storage.AccountSocialTradingInvestorInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorInsertResponse value
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorInsertResponse} value AccountSocialTradingInvestorInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorInsertResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingInvestorInsertResponse serialization failed!')
    console.assert(this.AccountSocialTradingInvestorInsertResponseSenderModel.verify(), 'storage.AccountSocialTradingInvestorInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdateRequest value
   * @this {!Client}
   * @param {!AccountUpdateRequest} value AccountUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountUpdateRequest serialization failed!')
    console.assert(this.AccountUpdateRequestSenderModel.verify(), 'storage.AccountUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdateResponse value
   * @this {!Client}
   * @param {!AccountUpdateResponse} value AccountUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountUpdateResponse serialization failed!')
    console.assert(this.AccountUpdateResponseSenderModel.verify(), 'storage.AccountUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDeleteRequest value
   * @this {!Client}
   * @param {!AccountDeleteRequest} value AccountDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountDeleteRequest serialization failed!')
    console.assert(this.AccountDeleteRequestSenderModel.verify(), 'storage.AccountDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDeleteResponse value
   * @this {!Client}
   * @param {!AccountDeleteResponse} value AccountDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountDeleteResponse serialization failed!')
    console.assert(this.AccountDeleteResponseSenderModel.verify(), 'storage.AccountDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdateRequest value
   * @this {!Client}
   * @param {!MarginUpdateRequest} value MarginUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginUpdateRequest serialization failed!')
    console.assert(this.MarginUpdateRequestSenderModel.verify(), 'storage.MarginUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdateResponse value
   * @this {!Client}
   * @param {!MarginUpdateResponse} value MarginUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginUpdateResponse serialization failed!')
    console.assert(this.MarginUpdateResponseSenderModel.verify(), 'storage.MarginUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInsertRequest value
   * @this {!Client}
   * @param {!AssetInsertRequest} value AssetInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInsertRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetInsertRequest serialization failed!')
    console.assert(this.AssetInsertRequestSenderModel.verify(), 'storage.AssetInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInsertResponse value
   * @this {!Client}
   * @param {!AssetInsertResponse} value AssetInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInsertResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetInsertResponse serialization failed!')
    console.assert(this.AssetInsertResponseSenderModel.verify(), 'storage.AssetInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetUpdateRequest value
   * @this {!Client}
   * @param {!AssetUpdateRequest} value AssetUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetUpdateRequest serialization failed!')
    console.assert(this.AssetUpdateRequestSenderModel.verify(), 'storage.AssetUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetUpdateResponse value
   * @this {!Client}
   * @param {!AssetUpdateResponse} value AssetUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetUpdateResponse serialization failed!')
    console.assert(this.AssetUpdateResponseSenderModel.verify(), 'storage.AssetUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetDeleteRequest value
   * @this {!Client}
   * @param {!AssetDeleteRequest} value AssetDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetDeleteRequest serialization failed!')
    console.assert(this.AssetDeleteRequestSenderModel.verify(), 'storage.AssetDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetDeleteResponse value
   * @this {!Client}
   * @param {!AssetDeleteResponse} value AssetDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetDeleteResponse serialization failed!')
    console.assert(this.AssetDeleteResponseSenderModel.verify(), 'storage.AssetDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInsertRequest value
   * @this {!Client}
   * @param {!OrderInsertRequest} value OrderInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInsertRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderInsertRequest serialization failed!')
    console.assert(this.OrderInsertRequestSenderModel.verify(), 'storage.OrderInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInsertResponse value
   * @this {!Client}
   * @param {!OrderInsertResponse} value OrderInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInsertResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderInsertResponse serialization failed!')
    console.assert(this.OrderInsertResponseSenderModel.verify(), 'storage.OrderInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateRequest value
   * @this {!Client}
   * @param {!OrderUpdateRequest} value OrderUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateRequest serialization failed!')
    console.assert(this.OrderUpdateRequestSenderModel.verify(), 'storage.OrderUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateResponse value
   * @this {!Client}
   * @param {!OrderUpdateResponse} value OrderUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateResponse serialization failed!')
    console.assert(this.OrderUpdateResponseSenderModel.verify(), 'storage.OrderUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDeleteRequest value
   * @this {!Client}
   * @param {!OrderDeleteRequest} value OrderDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDeleteRequest serialization failed!')
    console.assert(this.OrderDeleteRequestSenderModel.verify(), 'storage.OrderDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDeleteResponse value
   * @this {!Client}
   * @param {!OrderDeleteResponse} value OrderDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDeleteResponse serialization failed!')
    console.assert(this.OrderDeleteResponseSenderModel.verify(), 'storage.OrderDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDeleteAllRequest value
   * @this {!Client}
   * @param {!OrderDeleteAllRequest} value OrderDeleteAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDeleteAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDeleteAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDeleteAllRequest serialization failed!')
    console.assert(this.OrderDeleteAllRequestSenderModel.verify(), 'storage.OrderDeleteAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDeleteAllResponse value
   * @this {!Client}
   * @param {!OrderDeleteAllResponse} value OrderDeleteAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDeleteAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDeleteAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDeleteAllResponse serialization failed!')
    console.assert(this.OrderDeleteAllResponseSenderModel.verify(), 'storage.OrderDeleteAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInsertRequest value
   * @this {!Client}
   * @param {!PositionInsertRequest} value PositionInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInsertRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionInsertRequest serialization failed!')
    console.assert(this.PositionInsertRequestSenderModel.verify(), 'storage.PositionInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInsertResponse value
   * @this {!Client}
   * @param {!PositionInsertResponse} value PositionInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInsertResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionInsertResponse serialization failed!')
    console.assert(this.PositionInsertResponseSenderModel.verify(), 'storage.PositionInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionUpdateRequest value
   * @this {!Client}
   * @param {!PositionUpdateRequest} value PositionUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionUpdateRequest serialization failed!')
    console.assert(this.PositionUpdateRequestSenderModel.verify(), 'storage.PositionUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionUpdateResponse value
   * @this {!Client}
   * @param {!PositionUpdateResponse} value PositionUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionUpdateResponse serialization failed!')
    console.assert(this.PositionUpdateResponseSenderModel.verify(), 'storage.PositionUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionDeleteRequest value
   * @this {!Client}
   * @param {!PositionDeleteRequest} value PositionDeleteRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionDeleteRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionDeleteRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionDeleteRequest serialization failed!')
    console.assert(this.PositionDeleteRequestSenderModel.verify(), 'storage.PositionDeleteRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionDeleteResponse value
   * @this {!Client}
   * @param {!PositionDeleteResponse} value PositionDeleteResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionDeleteResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionDeleteResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionDeleteResponse serialization failed!')
    console.assert(this.PositionDeleteResponseSenderModel.verify(), 'storage.PositionDeleteResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryRequest value
   * @this {!Client}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryRequest serialization failed!')
    console.assert(this.TradeHistoryRequestSenderModel.verify(), 'storage.TradeHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryResponse value
   * @this {!Client}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryResponse serialization failed!')
    console.assert(this.TradeHistoryResponseSenderModel.verify(), 'storage.TradeHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetRequest value
   * @this {!Client}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsGetRequest serialization failed!')
    console.assert(this.TradeEventsGetRequestSenderModel.verify(), 'storage.TradeEventsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsGetResponse value
   * @this {!Client}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsGetResponse serialization failed!')
    console.assert(this.TradeEventsGetResponseSenderModel.verify(), 'storage.TradeEventsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsNotify value
   * @this {!Client}
   * @param {!TradeEventsNotify} value TradeEventsNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsNotify serialization failed!')
    console.assert(this.TradeEventsNotifySenderModel.verify(), 'storage.TradeEventsNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsInsertRequest value
   * @this {!Client}
   * @param {!TradeEventsInsertRequest} value TradeEventsInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsInsertRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsInsertRequest serialization failed!')
    console.assert(this.TradeEventsInsertRequestSenderModel.verify(), 'storage.TradeEventsInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsInsertResponse value
   * @this {!Client}
   * @param {!TradeEventsInsertResponse} value TradeEventsInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsInsertResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsInsertResponse serialization failed!')
    console.assert(this.TradeEventsInsertResponseSenderModel.verify(), 'storage.TradeEventsInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetRequest value
   * @this {!Client}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.ProfitClosedGetRequest serialization failed!')
    console.assert(this.ProfitClosedGetRequestSenderModel.verify(), 'storage.ProfitClosedGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ProfitClosedGetResponse value
   * @this {!Client}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ProfitClosedGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ProfitClosedGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.ProfitClosedGetResponse serialization failed!')
    console.assert(this.ProfitClosedGetResponseSenderModel.verify(), 'storage.ProfitClosedGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersClosedSummariesGetRequest value
   * @this {!Client}
   * @param {!OrdersClosedSummariesGetRequest} value OrdersClosedSummariesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersClosedSummariesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersClosedSummariesGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrdersClosedSummariesGetRequest serialization failed!')
    console.assert(this.OrdersClosedSummariesGetRequestSenderModel.verify(), 'storage.OrdersClosedSummariesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersClosedSummariesGetResponse value
   * @this {!Client}
   * @param {!OrdersClosedSummariesGetResponse} value OrdersClosedSummariesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersClosedSummariesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersClosedSummariesGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrdersClosedSummariesGetResponse serialization failed!')
    console.assert(this.OrdersClosedSummariesGetResponseSenderModel.verify(), 'storage.OrdersClosedSummariesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInsert2Request value
   * @this {!Client}
   * @param {!OrderInsert2Request} value OrderInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInsert2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderInsert2Request serialization failed!')
    console.assert(this.OrderInsert2RequestSenderModel.verify(), 'storage.OrderInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderInsert2Response value
   * @this {!Client}
   * @param {!OrderInsert2Response} value OrderInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_OrderInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderInsert2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderInsert2Response serialization failed!')
    console.assert(this.OrderInsert2ResponseSenderModel.verify(), 'storage.OrderInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdate2Request value
   * @this {!Client}
   * @param {!OrderUpdate2Request} value OrderUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdate2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdate2Request serialization failed!')
    console.assert(this.OrderUpdate2RequestSenderModel.verify(), 'storage.OrderUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdate2Response value
   * @this {!Client}
   * @param {!OrderUpdate2Response} value OrderUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdate2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdate2Response serialization failed!')
    console.assert(this.OrderUpdate2ResponseSenderModel.verify(), 'storage.OrderUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDelete2Request value
   * @this {!Client}
   * @param {!OrderDelete2Request} value OrderDelete2Request value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDelete2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDelete2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDelete2Request serialization failed!')
    console.assert(this.OrderDelete2RequestSenderModel.verify(), 'storage.OrderDelete2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderDelete2Response value
   * @this {!Client}
   * @param {!OrderDelete2Response} value OrderDelete2Response value to send
   * @returns {!number} Sent bytes
   */
  send_OrderDelete2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderDelete2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderDelete2Response serialization failed!')
    console.assert(this.OrderDelete2ResponseSenderModel.verify(), 'storage.OrderDelete2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInsert2Request value
   * @this {!Client}
   * @param {!AccountInsert2Request} value AccountInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInsert2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountInsert2Request serialization failed!')
    console.assert(this.AccountInsert2RequestSenderModel.verify(), 'storage.AccountInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountInsert2Response value
   * @this {!Client}
   * @param {!AccountInsert2Response} value AccountInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AccountInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountInsert2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountInsert2Response serialization failed!')
    console.assert(this.AccountInsert2ResponseSenderModel.verify(), 'storage.AccountInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterInsert2Request value
   * @this {!Client}
   * @param {!AccountSocialTradingMasterInsert2Request} value AccountSocialTradingMasterInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterInsert2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingMasterInsert2Request serialization failed!')
    console.assert(this.AccountSocialTradingMasterInsert2RequestSenderModel.verify(), 'storage.AccountSocialTradingMasterInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingMasterInsert2Response value
   * @this {!Client}
   * @param {!AccountSocialTradingMasterInsert2Response} value AccountSocialTradingMasterInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingMasterInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingMasterInsert2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingMasterInsert2Response serialization failed!')
    console.assert(this.AccountSocialTradingMasterInsert2ResponseSenderModel.verify(), 'storage.AccountSocialTradingMasterInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorInsert2Request value
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorInsert2Request} value AccountSocialTradingInvestorInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorInsert2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingInvestorInsert2Request serialization failed!')
    console.assert(this.AccountSocialTradingInvestorInsert2RequestSenderModel.verify(), 'storage.AccountSocialTradingInvestorInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountSocialTradingInvestorInsert2Response value
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorInsert2Response} value AccountSocialTradingInvestorInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AccountSocialTradingInvestorInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountSocialTradingInvestorInsert2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountSocialTradingInvestorInsert2Response serialization failed!')
    console.assert(this.AccountSocialTradingInvestorInsert2ResponseSenderModel.verify(), 'storage.AccountSocialTradingInvestorInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDelete2Request value
   * @this {!Client}
   * @param {!AccountDelete2Request} value AccountDelete2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDelete2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDelete2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountDelete2Request serialization failed!')
    console.assert(this.AccountDelete2RequestSenderModel.verify(), 'storage.AccountDelete2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountDelete2Response value
   * @this {!Client}
   * @param {!AccountDelete2Response} value AccountDelete2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AccountDelete2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountDelete2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountDelete2Response serialization failed!')
    console.assert(this.AccountDelete2ResponseSenderModel.verify(), 'storage.AccountDelete2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInsert2Request value
   * @this {!Client}
   * @param {!AssetInsert2Request} value AssetInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInsert2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetInsert2Request serialization failed!')
    console.assert(this.AssetInsert2RequestSenderModel.verify(), 'storage.AssetInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetInsert2Response value
   * @this {!Client}
   * @param {!AssetInsert2Response} value AssetInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AssetInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetInsert2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetInsert2Response serialization failed!')
    console.assert(this.AssetInsert2ResponseSenderModel.verify(), 'storage.AssetInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetUpdate2Request value
   * @this {!Client}
   * @param {!AssetUpdate2Request} value AssetUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AssetUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetUpdate2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetUpdate2Request serialization failed!')
    console.assert(this.AssetUpdate2RequestSenderModel.verify(), 'storage.AssetUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetUpdate2Response value
   * @this {!Client}
   * @param {!AssetUpdate2Response} value AssetUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AssetUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetUpdate2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetUpdate2Response serialization failed!')
    console.assert(this.AssetUpdate2ResponseSenderModel.verify(), 'storage.AssetUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetDelete2Request value
   * @this {!Client}
   * @param {!AssetDelete2Request} value AssetDelete2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AssetDelete2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetDelete2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetDelete2Request serialization failed!')
    console.assert(this.AssetDelete2RequestSenderModel.verify(), 'storage.AssetDelete2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetDelete2Response value
   * @this {!Client}
   * @param {!AssetDelete2Response} value AssetDelete2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AssetDelete2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetDelete2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetDelete2Response serialization failed!')
    console.assert(this.AssetDelete2ResponseSenderModel.verify(), 'storage.AssetDelete2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInsert2Request value
   * @this {!Client}
   * @param {!PositionInsert2Request} value PositionInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInsert2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionInsert2Request serialization failed!')
    console.assert(this.PositionInsert2RequestSenderModel.verify(), 'storage.PositionInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionInsert2Response value
   * @this {!Client}
   * @param {!PositionInsert2Response} value PositionInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_PositionInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionInsert2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionInsert2Response serialization failed!')
    console.assert(this.PositionInsert2ResponseSenderModel.verify(), 'storage.PositionInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionUpdate2Request value
   * @this {!Client}
   * @param {!PositionUpdate2Request} value PositionUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_PositionUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionUpdate2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionUpdate2Request serialization failed!')
    console.assert(this.PositionUpdate2RequestSenderModel.verify(), 'storage.PositionUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionUpdate2Response value
   * @this {!Client}
   * @param {!PositionUpdate2Response} value PositionUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_PositionUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionUpdate2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionUpdate2Response serialization failed!')
    console.assert(this.PositionUpdate2ResponseSenderModel.verify(), 'storage.PositionUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionDelete2Request value
   * @this {!Client}
   * @param {!PositionDelete2Request} value PositionDelete2Request value to send
   * @returns {!number} Sent bytes
   */
  send_PositionDelete2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionDelete2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionDelete2Request serialization failed!')
    console.assert(this.PositionDelete2RequestSenderModel.verify(), 'storage.PositionDelete2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionDelete2Response value
   * @this {!Client}
   * @param {!PositionDelete2Response} value PositionDelete2Response value to send
   * @returns {!number} Sent bytes
   */
  send_PositionDelete2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionDelete2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionDelete2Response serialization failed!')
    console.assert(this.PositionDelete2ResponseSenderModel.verify(), 'storage.PositionDelete2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated001 value
   * @this {!Client}
   * @param {!Deprecated001} value Deprecated001 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated001 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated001SenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated001 serialization failed!')
    console.assert(this.Deprecated001SenderModel.verify(), 'storage.Deprecated001 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated002 value
   * @this {!Client}
   * @param {!Deprecated002} value Deprecated002 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated002 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated002SenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated002 serialization failed!')
    console.assert(this.Deprecated002SenderModel.verify(), 'storage.Deprecated002 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated003 value
   * @this {!Client}
   * @param {!Deprecated003} value Deprecated003 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated003 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated003SenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated003 serialization failed!')
    console.assert(this.Deprecated003SenderModel.verify(), 'storage.Deprecated003 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated004 value
   * @this {!Client}
   * @param {!Deprecated004} value Deprecated004 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated004 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated004SenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated004 serialization failed!')
    console.assert(this.Deprecated004SenderModel.verify(), 'storage.Deprecated004 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated005 value
   * @this {!Client}
   * @param {!Deprecated005} value Deprecated005 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated005 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated005SenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated005 serialization failed!')
    console.assert(this.Deprecated005SenderModel.verify(), 'storage.Deprecated005 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Deprecated006 value
   * @this {!Client}
   * @param {!Deprecated006} value Deprecated006 value to send
   * @returns {!number} Sent bytes
   */
  send_Deprecated006 (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Deprecated006SenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.Deprecated006 serialization failed!')
    console.assert(this.Deprecated006SenderModel.verify(), 'storage.Deprecated006 validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsInsert2Request value
   * @this {!Client}
   * @param {!TradeEventsInsert2Request} value TradeEventsInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsInsert2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsInsert2Request serialization failed!')
    console.assert(this.TradeEventsInsert2RequestSenderModel.verify(), 'storage.TradeEventsInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsInsert2Response value
   * @this {!Client}
   * @param {!TradeEventsInsert2Response} value TradeEventsInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsInsert2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsInsert2Response serialization failed!')
    console.assert(this.TradeEventsInsert2ResponseSenderModel.verify(), 'storage.TradeEventsInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdate2Request value
   * @this {!Client}
   * @param {!MarginUpdate2Request} value MarginUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdate2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginUpdate2Request serialization failed!')
    console.assert(this.MarginUpdate2RequestSenderModel.verify(), 'storage.MarginUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdate2Response value
   * @this {!Client}
   * @param {!MarginUpdate2Response} value MarginUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdate2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginUpdate2Response serialization failed!')
    console.assert(this.MarginUpdate2ResponseSenderModel.verify(), 'storage.MarginUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdate2Request value
   * @this {!Client}
   * @param {!AccountUpdate2Request} value AccountUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdate2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountUpdate2Request serialization failed!')
    console.assert(this.AccountUpdate2RequestSenderModel.verify(), 'storage.AccountUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountUpdate2Response value
   * @this {!Client}
   * @param {!AccountUpdate2Response} value AccountUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_AccountUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountUpdate2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountUpdate2Response serialization failed!')
    console.assert(this.AccountUpdate2ResponseSenderModel.verify(), 'storage.AccountUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositMarginRequest value
   * @this {!Client}
   * @param {!DepositMarginRequest} value DepositMarginRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DepositMarginRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositMarginRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.DepositMarginRequest serialization failed!')
    console.assert(this.DepositMarginRequestSenderModel.verify(), 'storage.DepositMarginRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositMarginResponse value
   * @this {!Client}
   * @param {!DepositMarginResponse} value DepositMarginResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DepositMarginResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositMarginResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.DepositMarginResponse serialization failed!')
    console.assert(this.DepositMarginResponseSenderModel.verify(), 'storage.DepositMarginResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositMargin2Request value
   * @this {!Client}
   * @param {!DepositMargin2Request} value DepositMargin2Request value to send
   * @returns {!number} Sent bytes
   */
  send_DepositMargin2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositMargin2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.DepositMargin2Request serialization failed!')
    console.assert(this.DepositMargin2RequestSenderModel.verify(), 'storage.DepositMargin2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DepositMargin2Response value
   * @this {!Client}
   * @param {!DepositMargin2Response} value DepositMargin2Response value to send
   * @returns {!number} Sent bytes
   */
  send_DepositMargin2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DepositMargin2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.DepositMargin2Response serialization failed!')
    console.assert(this.DepositMargin2ResponseSenderModel.verify(), 'storage.DepositMargin2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StoreClientLoginSuccessRequest value
   * @this {!Client}
   * @param {!StoreClientLoginSuccessRequest} value StoreClientLoginSuccessRequest value to send
   * @returns {!number} Sent bytes
   */
  send_StoreClientLoginSuccessRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StoreClientLoginSuccessRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.StoreClientLoginSuccessRequest serialization failed!')
    console.assert(this.StoreClientLoginSuccessRequestSenderModel.verify(), 'storage.StoreClientLoginSuccessRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StoreClientLoginSuccessResponse value
   * @this {!Client}
   * @param {!StoreClientLoginSuccessResponse} value StoreClientLoginSuccessResponse value to send
   * @returns {!number} Sent bytes
   */
  send_StoreClientLoginSuccessResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StoreClientLoginSuccessResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.StoreClientLoginSuccessResponse serialization failed!')
    console.assert(this.StoreClientLoginSuccessResponseSenderModel.verify(), 'storage.StoreClientLoginSuccessResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientLoginInfoGetRequest value
   * @this {!Client}
   * @param {!ClientLoginInfoGetRequest} value ClientLoginInfoGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientLoginInfoGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientLoginInfoGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.ClientLoginInfoGetRequest serialization failed!')
    console.assert(this.ClientLoginInfoGetRequestSenderModel.verify(), 'storage.ClientLoginInfoGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientLoginInfoGetResponse value
   * @this {!Client}
   * @param {!ClientLoginInfoGetResponse} value ClientLoginInfoGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientLoginInfoGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientLoginInfoGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.ClientLoginInfoGetResponse serialization failed!')
    console.assert(this.ClientLoginInfoGetResponseSenderModel.verify(), 'storage.ClientLoginInfoGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientLoginInfoNotify value
   * @this {!Client}
   * @param {!ClientLoginInfoNotify} value ClientLoginInfoNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ClientLoginInfoNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientLoginInfoNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.ClientLoginInfoNotify serialization failed!')
    console.assert(this.ClientLoginInfoNotifySenderModel.verify(), 'storage.ClientLoginInfoNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StoreClientLoginSuccess2Request value
   * @this {!Client}
   * @param {!StoreClientLoginSuccess2Request} value StoreClientLoginSuccess2Request value to send
   * @returns {!number} Sent bytes
   */
  send_StoreClientLoginSuccess2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StoreClientLoginSuccess2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.StoreClientLoginSuccess2Request serialization failed!')
    console.assert(this.StoreClientLoginSuccess2RequestSenderModel.verify(), 'storage.StoreClientLoginSuccess2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send StoreClientLoginSuccess2Response value
   * @this {!Client}
   * @param {!StoreClientLoginSuccess2Response} value StoreClientLoginSuccess2Response value to send
   * @returns {!number} Sent bytes
   */
  send_StoreClientLoginSuccess2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.StoreClientLoginSuccess2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.StoreClientLoginSuccess2Response serialization failed!')
    console.assert(this.StoreClientLoginSuccess2ResponseSenderModel.verify(), 'storage.StoreClientLoginSuccess2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithTypeRequest value
   * @this {!Client}
   * @param {!OrderUpdateWithTypeRequest} value OrderUpdateWithTypeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithTypeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithTypeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateWithTypeRequest serialization failed!')
    console.assert(this.OrderUpdateWithTypeRequestSenderModel.verify(), 'storage.OrderUpdateWithTypeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithTypeResponse value
   * @this {!Client}
   * @param {!OrderUpdateWithTypeResponse} value OrderUpdateWithTypeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithTypeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithTypeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateWithTypeResponse serialization failed!')
    console.assert(this.OrderUpdateWithTypeResponseSenderModel.verify(), 'storage.OrderUpdateWithTypeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithType2Request value
   * @this {!Client}
   * @param {!OrderUpdateWithType2Request} value OrderUpdateWithType2Request value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithType2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithType2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateWithType2Request serialization failed!')
    console.assert(this.OrderUpdateWithType2RequestSenderModel.verify(), 'storage.OrderUpdateWithType2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrderUpdateWithType2Response value
   * @this {!Client}
   * @param {!OrderUpdateWithType2Response} value OrderUpdateWithType2Response value to send
   * @returns {!number} Sent bytes
   */
  send_OrderUpdateWithType2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrderUpdateWithType2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrderUpdateWithType2Response serialization failed!')
    console.assert(this.OrderUpdateWithType2ResponseSenderModel.verify(), 'storage.OrderUpdateWithType2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryTimeRangeGetRequest value
   * @this {!Client}
   * @param {!TradeHistoryTimeRangeGetRequest} value TradeHistoryTimeRangeGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryTimeRangeGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryTimeRangeGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryTimeRangeGetRequest serialization failed!')
    console.assert(this.TradeHistoryTimeRangeGetRequestSenderModel.verify(), 'storage.TradeHistoryTimeRangeGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryTimeRangeGetResponse value
   * @this {!Client}
   * @param {!TradeHistoryTimeRangeGetResponse} value TradeHistoryTimeRangeGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryTimeRangeGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryTimeRangeGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryTimeRangeGetResponse serialization failed!')
    console.assert(this.TradeHistoryTimeRangeGetResponseSenderModel.verify(), 'storage.TradeHistoryTimeRangeGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsTimeRangeGetRequest value
   * @this {!Client}
   * @param {!TradeEventsTimeRangeGetRequest} value TradeEventsTimeRangeGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsTimeRangeGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsTimeRangeGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsTimeRangeGetRequest serialization failed!')
    console.assert(this.TradeEventsTimeRangeGetRequestSenderModel.verify(), 'storage.TradeEventsTimeRangeGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeEventsTimeRangeGetResponse value
   * @this {!Client}
   * @param {!TradeEventsTimeRangeGetResponse} value TradeEventsTimeRangeGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeEventsTimeRangeGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeEventsTimeRangeGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeEventsTimeRangeGetResponse serialization failed!')
    console.assert(this.TradeEventsTimeRangeGetResponseSenderModel.verify(), 'storage.TradeEventsTimeRangeGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdRequest value
   * @this {!Client}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryByIdRequest serialization failed!')
    console.assert(this.TradeHistoryByIdRequestSenderModel.verify(), 'storage.TradeHistoryByIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TradeHistoryByIdResponse value
   * @this {!Client}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TradeHistoryByIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TradeHistoryByIdResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.TradeHistoryByIdResponse serialization failed!')
    console.assert(this.TradeHistoryByIdResponseSenderModel.verify(), 'storage.TradeHistoryByIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllRequest value
   * @this {!Client}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrdersGetAllRequest serialization failed!')
    console.assert(this.OrdersGetAllRequestSenderModel.verify(), 'storage.OrdersGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send OrdersGetAllResponse value
   * @this {!Client}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_OrdersGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.OrdersGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.OrdersGetAllResponse serialization failed!')
    console.assert(this.OrdersGetAllResponseSenderModel.verify(), 'storage.OrdersGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllRequest value
   * @this {!Client}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionsGetAllRequest serialization failed!')
    console.assert(this.PositionsGetAllRequestSenderModel.verify(), 'storage.PositionsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PositionsGetAllResponse value
   * @this {!Client}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PositionsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PositionsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PositionsGetAllResponse serialization failed!')
    console.assert(this.PositionsGetAllResponseSenderModel.verify(), 'storage.PositionsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllRequest value
   * @this {!Client}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetsGetAllRequest serialization failed!')
    console.assert(this.AssetsGetAllRequestSenderModel.verify(), 'storage.AssetsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AssetsGetAllResponse value
   * @this {!Client}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AssetsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AssetsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AssetsGetAllResponse serialization failed!')
    console.assert(this.AssetsGetAllResponseSenderModel.verify(), 'storage.AssetsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllRequest value
   * @this {!Client}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginsGetAllRequest serialization failed!')
    console.assert(this.MarginsGetAllRequestSenderModel.verify(), 'storage.MarginsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginsGetAllResponse value
   * @this {!Client}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MarginsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginsGetAllResponse serialization failed!')
    console.assert(this.MarginsGetAllResponseSenderModel.verify(), 'storage.MarginsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetAllRequest value
   * @this {!Client}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountsGetAllRequest serialization failed!')
    console.assert(this.AccountsGetAllRequestSenderModel.verify(), 'storage.AccountsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send AccountsGetAllResponse value
   * @this {!Client}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_AccountsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.AccountsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.AccountsGetAllResponse serialization failed!')
    console.assert(this.AccountsGetAllResponseSenderModel.verify(), 'storage.AccountsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MassRequest value
   * @this {!Client}
   * @param {!MassRequest} value MassRequest value to send
   * @returns {!number} Sent bytes
   */
  send_MassRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MassRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.MassRequest serialization failed!')
    console.assert(this.MassRequestSenderModel.verify(), 'storage.MassRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MassResponse value
   * @this {!Client}
   * @param {!MassResponse} value MassResponse value to send
   * @returns {!number} Sent bytes
   */
  send_MassResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MassResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.MassResponse serialization failed!')
    console.assert(this.MassResponseSenderModel.verify(), 'storage.MassResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Mass2Request value
   * @this {!Client}
   * @param {!Mass2Request} value Mass2Request value to send
   * @returns {!number} Sent bytes
   */
  send_Mass2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Mass2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.Mass2Request serialization failed!')
    console.assert(this.Mass2RequestSenderModel.verify(), 'storage.Mass2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Mass2ResponseParams value
   * @this {!Client}
   * @param {!Mass2ResponseParams} value Mass2ResponseParams value to send
   * @returns {!number} Sent bytes
   */
  send_Mass2ResponseParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Mass2ResponseParamsSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.Mass2ResponseParams serialization failed!')
    console.assert(this.Mass2ResponseParamsSenderModel.verify(), 'storage.Mass2ResponseParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Mass2Response value
   * @this {!Client}
   * @param {!Mass2Response} value Mass2Response value to send
   * @returns {!number} Sent bytes
   */
  send_Mass2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Mass2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.Mass2Response serialization failed!')
    console.assert(this.Mass2ResponseSenderModel.verify(), 'storage.Mass2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send MarginUpdateNotify value
   * @this {!Client}
   * @param {!MarginUpdateNotify} value MarginUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_MarginUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MarginUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.MarginUpdateNotify serialization failed!')
    console.assert(this.MarginUpdateNotifySenderModel.verify(), 'storage.MarginUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllRequest value
   * @this {!Client}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsGetAllRequest serialization failed!')
    console.assert(this.PersonsGetAllRequestSenderModel.verify(), 'storage.PersonsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsGetAllResponse value
   * @this {!Client}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsGetAllResponse serialization failed!')
    console.assert(this.PersonsGetAllResponseSenderModel.verify(), 'storage.PersonsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsertRequest value
   * @this {!Client}
   * @param {!PersonsInsertRequest} value PersonsInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsertRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsInsertRequest serialization failed!')
    console.assert(this.PersonsInsertRequestSenderModel.verify(), 'storage.PersonsInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsertResponse value
   * @this {!Client}
   * @param {!PersonsInsertResponse} value PersonsInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsertResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsInsertResponse serialization failed!')
    console.assert(this.PersonsInsertResponseSenderModel.verify(), 'storage.PersonsInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdateRequest value
   * @this {!Client}
   * @param {!PersonUpdateRequest} value PersonUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonUpdateRequest serialization failed!')
    console.assert(this.PersonUpdateRequestSenderModel.verify(), 'storage.PersonUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdateResponse value
   * @this {!Client}
   * @param {!PersonUpdateResponse} value PersonUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonUpdateResponse serialization failed!')
    console.assert(this.PersonUpdateResponseSenderModel.verify(), 'storage.PersonUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsert2Request value
   * @this {!Client}
   * @param {!PersonsInsert2Request} value PersonsInsert2Request value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsert2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsert2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsInsert2Request serialization failed!')
    console.assert(this.PersonsInsert2RequestSenderModel.verify(), 'storage.PersonsInsert2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonsInsert2Response value
   * @this {!Client}
   * @param {!PersonsInsert2Response} value PersonsInsert2Response value to send
   * @returns {!number} Sent bytes
   */
  send_PersonsInsert2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonsInsert2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonsInsert2Response serialization failed!')
    console.assert(this.PersonsInsert2ResponseSenderModel.verify(), 'storage.PersonsInsert2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdate2Request value
   * @this {!Client}
   * @param {!PersonUpdate2Request} value PersonUpdate2Request value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdate2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdate2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonUpdate2Request serialization failed!')
    console.assert(this.PersonUpdate2RequestSenderModel.verify(), 'storage.PersonUpdate2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send PersonUpdate2Response value
   * @this {!Client}
   * @param {!PersonUpdate2Response} value PersonUpdate2Response value to send
   * @returns {!number} Sent bytes
   */
  send_PersonUpdate2Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.PersonUpdate2ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.PersonUpdate2Response serialization failed!')
    console.assert(this.PersonUpdate2ResponseSenderModel.verify(), 'storage.PersonUpdate2Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LastIdsGetRequest value
   * @this {!Client}
   * @param {!LastIdsGetRequest} value LastIdsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LastIdsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LastIdsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.LastIdsGetRequest serialization failed!')
    console.assert(this.LastIdsGetRequestSenderModel.verify(), 'storage.LastIdsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LastIdsGetResponse value
   * @this {!Client}
   * @param {!LastIdsGetResponse} value LastIdsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LastIdsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LastIdsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.LastIdsGetResponse serialization failed!')
    console.assert(this.LastIdsGetResponseSenderModel.verify(), 'storage.LastIdsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DailySnapshotRequest value
   * @this {!Client}
   * @param {!DailySnapshotRequest} value DailySnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DailySnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DailySnapshotRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.DailySnapshotRequest serialization failed!')
    console.assert(this.DailySnapshotRequestSenderModel.verify(), 'storage.DailySnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DailySnapshotResponse value
   * @this {!Client}
   * @param {!DailySnapshotResponse} value DailySnapshotResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DailySnapshotResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DailySnapshotResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.DailySnapshotResponse serialization failed!')
    console.assert(this.DailySnapshotResponseSenderModel.verify(), 'storage.DailySnapshotResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BillingStatisticsGetRequest value
   * @this {!Client}
   * @param {!BillingStatisticsGetRequest} value BillingStatisticsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BillingStatisticsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BillingStatisticsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.BillingStatisticsGetRequest serialization failed!')
    console.assert(this.BillingStatisticsGetRequestSenderModel.verify(), 'storage.BillingStatisticsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BillingStatisticsGetResponse value
   * @this {!Client}
   * @param {!BillingStatisticsGetResponse} value BillingStatisticsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BillingStatisticsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BillingStatisticsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'storage.BillingStatisticsGetResponse serialization failed!')
    console.assert(this.BillingStatisticsGetResponseSenderModel.verify(), 'storage.BillingStatisticsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'storage.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
    this._innerClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
    this._innerClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * StorageVersionRequest receive handler
   * @this {!Client}
   * @param {!StorageVersionRequest} value StorageVersionRequest received value
   */
  onReceive_StorageVersionRequest (value) {}  // eslint-disable-line

  /**
   * StorageVersionResponse receive handler
   * @this {!Client}
   * @param {!StorageVersionResponse} value StorageVersionResponse received value
   */
  onReceive_StorageVersionResponse (value) {}  // eslint-disable-line

  /**
   * StorageUpdateNotify receive handler
   * @this {!Client}
   * @param {!StorageUpdateNotify} value StorageUpdateNotify received value
   */
  onReceive_StorageUpdateNotify (value) {}  // eslint-disable-line

  /**
   * AccountInsertRequest receive handler
   * @this {!Client}
   * @param {!AccountInsertRequest} value AccountInsertRequest received value
   */
  onReceive_AccountInsertRequest (value) {}  // eslint-disable-line

  /**
   * AccountInsertResponse receive handler
   * @this {!Client}
   * @param {!AccountInsertResponse} value AccountInsertResponse received value
   */
  onReceive_AccountInsertResponse (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsertRequest receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingMasterInsertRequest} value AccountSocialTradingMasterInsertRequest received value
   */
  onReceive_AccountSocialTradingMasterInsertRequest (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsertResponse receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingMasterInsertResponse} value AccountSocialTradingMasterInsertResponse received value
   */
  onReceive_AccountSocialTradingMasterInsertResponse (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsertRequest receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorInsertRequest} value AccountSocialTradingInvestorInsertRequest received value
   */
  onReceive_AccountSocialTradingInvestorInsertRequest (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsertResponse receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorInsertResponse} value AccountSocialTradingInvestorInsertResponse received value
   */
  onReceive_AccountSocialTradingInvestorInsertResponse (value) {}  // eslint-disable-line

  /**
   * AccountUpdateRequest receive handler
   * @this {!Client}
   * @param {!AccountUpdateRequest} value AccountUpdateRequest received value
   */
  onReceive_AccountUpdateRequest (value) {}  // eslint-disable-line

  /**
   * AccountUpdateResponse receive handler
   * @this {!Client}
   * @param {!AccountUpdateResponse} value AccountUpdateResponse received value
   */
  onReceive_AccountUpdateResponse (value) {}  // eslint-disable-line

  /**
   * AccountDeleteRequest receive handler
   * @this {!Client}
   * @param {!AccountDeleteRequest} value AccountDeleteRequest received value
   */
  onReceive_AccountDeleteRequest (value) {}  // eslint-disable-line

  /**
   * AccountDeleteResponse receive handler
   * @this {!Client}
   * @param {!AccountDeleteResponse} value AccountDeleteResponse received value
   */
  onReceive_AccountDeleteResponse (value) {}  // eslint-disable-line

  /**
   * MarginUpdateRequest receive handler
   * @this {!Client}
   * @param {!MarginUpdateRequest} value MarginUpdateRequest received value
   */
  onReceive_MarginUpdateRequest (value) {}  // eslint-disable-line

  /**
   * MarginUpdateResponse receive handler
   * @this {!Client}
   * @param {!MarginUpdateResponse} value MarginUpdateResponse received value
   */
  onReceive_MarginUpdateResponse (value) {}  // eslint-disable-line

  /**
   * AssetInsertRequest receive handler
   * @this {!Client}
   * @param {!AssetInsertRequest} value AssetInsertRequest received value
   */
  onReceive_AssetInsertRequest (value) {}  // eslint-disable-line

  /**
   * AssetInsertResponse receive handler
   * @this {!Client}
   * @param {!AssetInsertResponse} value AssetInsertResponse received value
   */
  onReceive_AssetInsertResponse (value) {}  // eslint-disable-line

  /**
   * AssetUpdateRequest receive handler
   * @this {!Client}
   * @param {!AssetUpdateRequest} value AssetUpdateRequest received value
   */
  onReceive_AssetUpdateRequest (value) {}  // eslint-disable-line

  /**
   * AssetUpdateResponse receive handler
   * @this {!Client}
   * @param {!AssetUpdateResponse} value AssetUpdateResponse received value
   */
  onReceive_AssetUpdateResponse (value) {}  // eslint-disable-line

  /**
   * AssetDeleteRequest receive handler
   * @this {!Client}
   * @param {!AssetDeleteRequest} value AssetDeleteRequest received value
   */
  onReceive_AssetDeleteRequest (value) {}  // eslint-disable-line

  /**
   * AssetDeleteResponse receive handler
   * @this {!Client}
   * @param {!AssetDeleteResponse} value AssetDeleteResponse received value
   */
  onReceive_AssetDeleteResponse (value) {}  // eslint-disable-line

  /**
   * OrderInsertRequest receive handler
   * @this {!Client}
   * @param {!OrderInsertRequest} value OrderInsertRequest received value
   */
  onReceive_OrderInsertRequest (value) {}  // eslint-disable-line

  /**
   * OrderInsertResponse receive handler
   * @this {!Client}
   * @param {!OrderInsertResponse} value OrderInsertResponse received value
   */
  onReceive_OrderInsertResponse (value) {}  // eslint-disable-line

  /**
   * OrderUpdateRequest receive handler
   * @this {!Client}
   * @param {!OrderUpdateRequest} value OrderUpdateRequest received value
   */
  onReceive_OrderUpdateRequest (value) {}  // eslint-disable-line

  /**
   * OrderUpdateResponse receive handler
   * @this {!Client}
   * @param {!OrderUpdateResponse} value OrderUpdateResponse received value
   */
  onReceive_OrderUpdateResponse (value) {}  // eslint-disable-line

  /**
   * OrderDeleteRequest receive handler
   * @this {!Client}
   * @param {!OrderDeleteRequest} value OrderDeleteRequest received value
   */
  onReceive_OrderDeleteRequest (value) {}  // eslint-disable-line

  /**
   * OrderDeleteResponse receive handler
   * @this {!Client}
   * @param {!OrderDeleteResponse} value OrderDeleteResponse received value
   */
  onReceive_OrderDeleteResponse (value) {}  // eslint-disable-line

  /**
   * OrderDeleteAllRequest receive handler
   * @this {!Client}
   * @param {!OrderDeleteAllRequest} value OrderDeleteAllRequest received value
   */
  onReceive_OrderDeleteAllRequest (value) {}  // eslint-disable-line

  /**
   * OrderDeleteAllResponse receive handler
   * @this {!Client}
   * @param {!OrderDeleteAllResponse} value OrderDeleteAllResponse received value
   */
  onReceive_OrderDeleteAllResponse (value) {}  // eslint-disable-line

  /**
   * PositionInsertRequest receive handler
   * @this {!Client}
   * @param {!PositionInsertRequest} value PositionInsertRequest received value
   */
  onReceive_PositionInsertRequest (value) {}  // eslint-disable-line

  /**
   * PositionInsertResponse receive handler
   * @this {!Client}
   * @param {!PositionInsertResponse} value PositionInsertResponse received value
   */
  onReceive_PositionInsertResponse (value) {}  // eslint-disable-line

  /**
   * PositionUpdateRequest receive handler
   * @this {!Client}
   * @param {!PositionUpdateRequest} value PositionUpdateRequest received value
   */
  onReceive_PositionUpdateRequest (value) {}  // eslint-disable-line

  /**
   * PositionUpdateResponse receive handler
   * @this {!Client}
   * @param {!PositionUpdateResponse} value PositionUpdateResponse received value
   */
  onReceive_PositionUpdateResponse (value) {}  // eslint-disable-line

  /**
   * PositionDeleteRequest receive handler
   * @this {!Client}
   * @param {!PositionDeleteRequest} value PositionDeleteRequest received value
   */
  onReceive_PositionDeleteRequest (value) {}  // eslint-disable-line

  /**
   * PositionDeleteResponse receive handler
   * @this {!Client}
   * @param {!PositionDeleteResponse} value PositionDeleteResponse received value
   */
  onReceive_PositionDeleteResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryRequest receive handler
   * @this {!Client}
   * @param {!TradeHistoryRequest} value TradeHistoryRequest received value
   */
  onReceive_TradeHistoryRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryResponse receive handler
   * @this {!Client}
   * @param {!TradeHistoryResponse} value TradeHistoryResponse received value
   */
  onReceive_TradeHistoryResponse (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetRequest receive handler
   * @this {!Client}
   * @param {!TradeEventsGetRequest} value TradeEventsGetRequest received value
   */
  onReceive_TradeEventsGetRequest (value) {}  // eslint-disable-line

  /**
   * TradeEventsGetResponse receive handler
   * @this {!Client}
   * @param {!TradeEventsGetResponse} value TradeEventsGetResponse received value
   */
  onReceive_TradeEventsGetResponse (value) {}  // eslint-disable-line

  /**
   * TradeEventsNotify receive handler
   * @this {!Client}
   * @param {!TradeEventsNotify} value TradeEventsNotify received value
   */
  onReceive_TradeEventsNotify (value) {}  // eslint-disable-line

  /**
   * TradeEventsInsertRequest receive handler
   * @this {!Client}
   * @param {!TradeEventsInsertRequest} value TradeEventsInsertRequest received value
   */
  onReceive_TradeEventsInsertRequest (value) {}  // eslint-disable-line

  /**
   * TradeEventsInsertResponse receive handler
   * @this {!Client}
   * @param {!TradeEventsInsertResponse} value TradeEventsInsertResponse received value
   */
  onReceive_TradeEventsInsertResponse (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetRequest receive handler
   * @this {!Client}
   * @param {!ProfitClosedGetRequest} value ProfitClosedGetRequest received value
   */
  onReceive_ProfitClosedGetRequest (value) {}  // eslint-disable-line

  /**
   * ProfitClosedGetResponse receive handler
   * @this {!Client}
   * @param {!ProfitClosedGetResponse} value ProfitClosedGetResponse received value
   */
  onReceive_ProfitClosedGetResponse (value) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetRequest receive handler
   * @this {!Client}
   * @param {!OrdersClosedSummariesGetRequest} value OrdersClosedSummariesGetRequest received value
   */
  onReceive_OrdersClosedSummariesGetRequest (value) {}  // eslint-disable-line

  /**
   * OrdersClosedSummariesGetResponse receive handler
   * @this {!Client}
   * @param {!OrdersClosedSummariesGetResponse} value OrdersClosedSummariesGetResponse received value
   */
  onReceive_OrdersClosedSummariesGetResponse (value) {}  // eslint-disable-line

  /**
   * OrderInsert2Request receive handler
   * @this {!Client}
   * @param {!OrderInsert2Request} value OrderInsert2Request received value
   */
  onReceive_OrderInsert2Request (value) {}  // eslint-disable-line

  /**
   * OrderInsert2Response receive handler
   * @this {!Client}
   * @param {!OrderInsert2Response} value OrderInsert2Response received value
   */
  onReceive_OrderInsert2Response (value) {}  // eslint-disable-line

  /**
   * OrderUpdate2Request receive handler
   * @this {!Client}
   * @param {!OrderUpdate2Request} value OrderUpdate2Request received value
   */
  onReceive_OrderUpdate2Request (value) {}  // eslint-disable-line

  /**
   * OrderUpdate2Response receive handler
   * @this {!Client}
   * @param {!OrderUpdate2Response} value OrderUpdate2Response received value
   */
  onReceive_OrderUpdate2Response (value) {}  // eslint-disable-line

  /**
   * OrderDelete2Request receive handler
   * @this {!Client}
   * @param {!OrderDelete2Request} value OrderDelete2Request received value
   */
  onReceive_OrderDelete2Request (value) {}  // eslint-disable-line

  /**
   * OrderDelete2Response receive handler
   * @this {!Client}
   * @param {!OrderDelete2Response} value OrderDelete2Response received value
   */
  onReceive_OrderDelete2Response (value) {}  // eslint-disable-line

  /**
   * AccountInsert2Request receive handler
   * @this {!Client}
   * @param {!AccountInsert2Request} value AccountInsert2Request received value
   */
  onReceive_AccountInsert2Request (value) {}  // eslint-disable-line

  /**
   * AccountInsert2Response receive handler
   * @this {!Client}
   * @param {!AccountInsert2Response} value AccountInsert2Response received value
   */
  onReceive_AccountInsert2Response (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsert2Request receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingMasterInsert2Request} value AccountSocialTradingMasterInsert2Request received value
   */
  onReceive_AccountSocialTradingMasterInsert2Request (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingMasterInsert2Response receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingMasterInsert2Response} value AccountSocialTradingMasterInsert2Response received value
   */
  onReceive_AccountSocialTradingMasterInsert2Response (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsert2Request receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorInsert2Request} value AccountSocialTradingInvestorInsert2Request received value
   */
  onReceive_AccountSocialTradingInvestorInsert2Request (value) {}  // eslint-disable-line

  /**
   * AccountSocialTradingInvestorInsert2Response receive handler
   * @this {!Client}
   * @param {!AccountSocialTradingInvestorInsert2Response} value AccountSocialTradingInvestorInsert2Response received value
   */
  onReceive_AccountSocialTradingInvestorInsert2Response (value) {}  // eslint-disable-line

  /**
   * AccountDelete2Request receive handler
   * @this {!Client}
   * @param {!AccountDelete2Request} value AccountDelete2Request received value
   */
  onReceive_AccountDelete2Request (value) {}  // eslint-disable-line

  /**
   * AccountDelete2Response receive handler
   * @this {!Client}
   * @param {!AccountDelete2Response} value AccountDelete2Response received value
   */
  onReceive_AccountDelete2Response (value) {}  // eslint-disable-line

  /**
   * AssetInsert2Request receive handler
   * @this {!Client}
   * @param {!AssetInsert2Request} value AssetInsert2Request received value
   */
  onReceive_AssetInsert2Request (value) {}  // eslint-disable-line

  /**
   * AssetInsert2Response receive handler
   * @this {!Client}
   * @param {!AssetInsert2Response} value AssetInsert2Response received value
   */
  onReceive_AssetInsert2Response (value) {}  // eslint-disable-line

  /**
   * AssetUpdate2Request receive handler
   * @this {!Client}
   * @param {!AssetUpdate2Request} value AssetUpdate2Request received value
   */
  onReceive_AssetUpdate2Request (value) {}  // eslint-disable-line

  /**
   * AssetUpdate2Response receive handler
   * @this {!Client}
   * @param {!AssetUpdate2Response} value AssetUpdate2Response received value
   */
  onReceive_AssetUpdate2Response (value) {}  // eslint-disable-line

  /**
   * AssetDelete2Request receive handler
   * @this {!Client}
   * @param {!AssetDelete2Request} value AssetDelete2Request received value
   */
  onReceive_AssetDelete2Request (value) {}  // eslint-disable-line

  /**
   * AssetDelete2Response receive handler
   * @this {!Client}
   * @param {!AssetDelete2Response} value AssetDelete2Response received value
   */
  onReceive_AssetDelete2Response (value) {}  // eslint-disable-line

  /**
   * PositionInsert2Request receive handler
   * @this {!Client}
   * @param {!PositionInsert2Request} value PositionInsert2Request received value
   */
  onReceive_PositionInsert2Request (value) {}  // eslint-disable-line

  /**
   * PositionInsert2Response receive handler
   * @this {!Client}
   * @param {!PositionInsert2Response} value PositionInsert2Response received value
   */
  onReceive_PositionInsert2Response (value) {}  // eslint-disable-line

  /**
   * PositionUpdate2Request receive handler
   * @this {!Client}
   * @param {!PositionUpdate2Request} value PositionUpdate2Request received value
   */
  onReceive_PositionUpdate2Request (value) {}  // eslint-disable-line

  /**
   * PositionUpdate2Response receive handler
   * @this {!Client}
   * @param {!PositionUpdate2Response} value PositionUpdate2Response received value
   */
  onReceive_PositionUpdate2Response (value) {}  // eslint-disable-line

  /**
   * PositionDelete2Request receive handler
   * @this {!Client}
   * @param {!PositionDelete2Request} value PositionDelete2Request received value
   */
  onReceive_PositionDelete2Request (value) {}  // eslint-disable-line

  /**
   * PositionDelete2Response receive handler
   * @this {!Client}
   * @param {!PositionDelete2Response} value PositionDelete2Response received value
   */
  onReceive_PositionDelete2Response (value) {}  // eslint-disable-line

  /**
   * Deprecated001 receive handler
   * @this {!Client}
   * @param {!Deprecated001} value Deprecated001 received value
   */
  onReceive_Deprecated001 (value) {}  // eslint-disable-line

  /**
   * Deprecated002 receive handler
   * @this {!Client}
   * @param {!Deprecated002} value Deprecated002 received value
   */
  onReceive_Deprecated002 (value) {}  // eslint-disable-line

  /**
   * Deprecated003 receive handler
   * @this {!Client}
   * @param {!Deprecated003} value Deprecated003 received value
   */
  onReceive_Deprecated003 (value) {}  // eslint-disable-line

  /**
   * Deprecated004 receive handler
   * @this {!Client}
   * @param {!Deprecated004} value Deprecated004 received value
   */
  onReceive_Deprecated004 (value) {}  // eslint-disable-line

  /**
   * Deprecated005 receive handler
   * @this {!Client}
   * @param {!Deprecated005} value Deprecated005 received value
   */
  onReceive_Deprecated005 (value) {}  // eslint-disable-line

  /**
   * Deprecated006 receive handler
   * @this {!Client}
   * @param {!Deprecated006} value Deprecated006 received value
   */
  onReceive_Deprecated006 (value) {}  // eslint-disable-line

  /**
   * TradeEventsInsert2Request receive handler
   * @this {!Client}
   * @param {!TradeEventsInsert2Request} value TradeEventsInsert2Request received value
   */
  onReceive_TradeEventsInsert2Request (value) {}  // eslint-disable-line

  /**
   * TradeEventsInsert2Response receive handler
   * @this {!Client}
   * @param {!TradeEventsInsert2Response} value TradeEventsInsert2Response received value
   */
  onReceive_TradeEventsInsert2Response (value) {}  // eslint-disable-line

  /**
   * MarginUpdate2Request receive handler
   * @this {!Client}
   * @param {!MarginUpdate2Request} value MarginUpdate2Request received value
   */
  onReceive_MarginUpdate2Request (value) {}  // eslint-disable-line

  /**
   * MarginUpdate2Response receive handler
   * @this {!Client}
   * @param {!MarginUpdate2Response} value MarginUpdate2Response received value
   */
  onReceive_MarginUpdate2Response (value) {}  // eslint-disable-line

  /**
   * AccountUpdate2Request receive handler
   * @this {!Client}
   * @param {!AccountUpdate2Request} value AccountUpdate2Request received value
   */
  onReceive_AccountUpdate2Request (value) {}  // eslint-disable-line

  /**
   * AccountUpdate2Response receive handler
   * @this {!Client}
   * @param {!AccountUpdate2Response} value AccountUpdate2Response received value
   */
  onReceive_AccountUpdate2Response (value) {}  // eslint-disable-line

  /**
   * DepositMarginRequest receive handler
   * @this {!Client}
   * @param {!DepositMarginRequest} value DepositMarginRequest received value
   */
  onReceive_DepositMarginRequest (value) {}  // eslint-disable-line

  /**
   * DepositMarginResponse receive handler
   * @this {!Client}
   * @param {!DepositMarginResponse} value DepositMarginResponse received value
   */
  onReceive_DepositMarginResponse (value) {}  // eslint-disable-line

  /**
   * DepositMargin2Request receive handler
   * @this {!Client}
   * @param {!DepositMargin2Request} value DepositMargin2Request received value
   */
  onReceive_DepositMargin2Request (value) {}  // eslint-disable-line

  /**
   * DepositMargin2Response receive handler
   * @this {!Client}
   * @param {!DepositMargin2Response} value DepositMargin2Response received value
   */
  onReceive_DepositMargin2Response (value) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccessRequest receive handler
   * @this {!Client}
   * @param {!StoreClientLoginSuccessRequest} value StoreClientLoginSuccessRequest received value
   */
  onReceive_StoreClientLoginSuccessRequest (value) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccessResponse receive handler
   * @this {!Client}
   * @param {!StoreClientLoginSuccessResponse} value StoreClientLoginSuccessResponse received value
   */
  onReceive_StoreClientLoginSuccessResponse (value) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetRequest receive handler
   * @this {!Client}
   * @param {!ClientLoginInfoGetRequest} value ClientLoginInfoGetRequest received value
   */
  onReceive_ClientLoginInfoGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientLoginInfoGetResponse receive handler
   * @this {!Client}
   * @param {!ClientLoginInfoGetResponse} value ClientLoginInfoGetResponse received value
   */
  onReceive_ClientLoginInfoGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientLoginInfoNotify receive handler
   * @this {!Client}
   * @param {!ClientLoginInfoNotify} value ClientLoginInfoNotify received value
   */
  onReceive_ClientLoginInfoNotify (value) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccess2Request receive handler
   * @this {!Client}
   * @param {!StoreClientLoginSuccess2Request} value StoreClientLoginSuccess2Request received value
   */
  onReceive_StoreClientLoginSuccess2Request (value) {}  // eslint-disable-line

  /**
   * StoreClientLoginSuccess2Response receive handler
   * @this {!Client}
   * @param {!StoreClientLoginSuccess2Response} value StoreClientLoginSuccess2Response received value
   */
  onReceive_StoreClientLoginSuccess2Response (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeRequest receive handler
   * @this {!Client}
   * @param {!OrderUpdateWithTypeRequest} value OrderUpdateWithTypeRequest received value
   */
  onReceive_OrderUpdateWithTypeRequest (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithTypeResponse receive handler
   * @this {!Client}
   * @param {!OrderUpdateWithTypeResponse} value OrderUpdateWithTypeResponse received value
   */
  onReceive_OrderUpdateWithTypeResponse (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithType2Request receive handler
   * @this {!Client}
   * @param {!OrderUpdateWithType2Request} value OrderUpdateWithType2Request received value
   */
  onReceive_OrderUpdateWithType2Request (value) {}  // eslint-disable-line

  /**
   * OrderUpdateWithType2Response receive handler
   * @this {!Client}
   * @param {!OrderUpdateWithType2Response} value OrderUpdateWithType2Response received value
   */
  onReceive_OrderUpdateWithType2Response (value) {}  // eslint-disable-line

  /**
   * TradeHistoryTimeRangeGetRequest receive handler
   * @this {!Client}
   * @param {!TradeHistoryTimeRangeGetRequest} value TradeHistoryTimeRangeGetRequest received value
   */
  onReceive_TradeHistoryTimeRangeGetRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryTimeRangeGetResponse receive handler
   * @this {!Client}
   * @param {!TradeHistoryTimeRangeGetResponse} value TradeHistoryTimeRangeGetResponse received value
   */
  onReceive_TradeHistoryTimeRangeGetResponse (value) {}  // eslint-disable-line

  /**
   * TradeEventsTimeRangeGetRequest receive handler
   * @this {!Client}
   * @param {!TradeEventsTimeRangeGetRequest} value TradeEventsTimeRangeGetRequest received value
   */
  onReceive_TradeEventsTimeRangeGetRequest (value) {}  // eslint-disable-line

  /**
   * TradeEventsTimeRangeGetResponse receive handler
   * @this {!Client}
   * @param {!TradeEventsTimeRangeGetResponse} value TradeEventsTimeRangeGetResponse received value
   */
  onReceive_TradeEventsTimeRangeGetResponse (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdRequest receive handler
   * @this {!Client}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest received value
   */
  onReceive_TradeHistoryByIdRequest (value) {}  // eslint-disable-line

  /**
   * TradeHistoryByIdResponse receive handler
   * @this {!Client}
   * @param {!TradeHistoryByIdResponse} value TradeHistoryByIdResponse received value
   */
  onReceive_TradeHistoryByIdResponse (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllRequest receive handler
   * @this {!Client}
   * @param {!OrdersGetAllRequest} value OrdersGetAllRequest received value
   */
  onReceive_OrdersGetAllRequest (value) {}  // eslint-disable-line

  /**
   * OrdersGetAllResponse receive handler
   * @this {!Client}
   * @param {!OrdersGetAllResponse} value OrdersGetAllResponse received value
   */
  onReceive_OrdersGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllRequest receive handler
   * @this {!Client}
   * @param {!PositionsGetAllRequest} value PositionsGetAllRequest received value
   */
  onReceive_PositionsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PositionsGetAllResponse receive handler
   * @this {!Client}
   * @param {!PositionsGetAllResponse} value PositionsGetAllResponse received value
   */
  onReceive_PositionsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllRequest receive handler
   * @this {!Client}
   * @param {!AssetsGetAllRequest} value AssetsGetAllRequest received value
   */
  onReceive_AssetsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AssetsGetAllResponse receive handler
   * @this {!Client}
   * @param {!AssetsGetAllResponse} value AssetsGetAllResponse received value
   */
  onReceive_AssetsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllRequest receive handler
   * @this {!Client}
   * @param {!MarginsGetAllRequest} value MarginsGetAllRequest received value
   */
  onReceive_MarginsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * MarginsGetAllResponse receive handler
   * @this {!Client}
   * @param {!MarginsGetAllResponse} value MarginsGetAllResponse received value
   */
  onReceive_MarginsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllRequest receive handler
   * @this {!Client}
   * @param {!AccountsGetAllRequest} value AccountsGetAllRequest received value
   */
  onReceive_AccountsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * AccountsGetAllResponse receive handler
   * @this {!Client}
   * @param {!AccountsGetAllResponse} value AccountsGetAllResponse received value
   */
  onReceive_AccountsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * MassRequest receive handler
   * @this {!Client}
   * @param {!MassRequest} value MassRequest received value
   */
  onReceive_MassRequest (value) {}  // eslint-disable-line

  /**
   * MassResponse receive handler
   * @this {!Client}
   * @param {!MassResponse} value MassResponse received value
   */
  onReceive_MassResponse (value) {}  // eslint-disable-line

  /**
   * Mass2Request receive handler
   * @this {!Client}
   * @param {!Mass2Request} value Mass2Request received value
   */
  onReceive_Mass2Request (value) {}  // eslint-disable-line

  /**
   * Mass2ResponseParams receive handler
   * @this {!Client}
   * @param {!Mass2ResponseParams} value Mass2ResponseParams received value
   */
  onReceive_Mass2ResponseParams (value) {}  // eslint-disable-line

  /**
   * Mass2Response receive handler
   * @this {!Client}
   * @param {!Mass2Response} value Mass2Response received value
   */
  onReceive_Mass2Response (value) {}  // eslint-disable-line

  /**
   * MarginUpdateNotify receive handler
   * @this {!Client}
   * @param {!MarginUpdateNotify} value MarginUpdateNotify received value
   */
  onReceive_MarginUpdateNotify (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllRequest receive handler
   * @this {!Client}
   * @param {!PersonsGetAllRequest} value PersonsGetAllRequest received value
   */
  onReceive_PersonsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * PersonsGetAllResponse receive handler
   * @this {!Client}
   * @param {!PersonsGetAllResponse} value PersonsGetAllResponse received value
   */
  onReceive_PersonsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * PersonsInsertRequest receive handler
   * @this {!Client}
   * @param {!PersonsInsertRequest} value PersonsInsertRequest received value
   */
  onReceive_PersonsInsertRequest (value) {}  // eslint-disable-line

  /**
   * PersonsInsertResponse receive handler
   * @this {!Client}
   * @param {!PersonsInsertResponse} value PersonsInsertResponse received value
   */
  onReceive_PersonsInsertResponse (value) {}  // eslint-disable-line

  /**
   * PersonUpdateRequest receive handler
   * @this {!Client}
   * @param {!PersonUpdateRequest} value PersonUpdateRequest received value
   */
  onReceive_PersonUpdateRequest (value) {}  // eslint-disable-line

  /**
   * PersonUpdateResponse receive handler
   * @this {!Client}
   * @param {!PersonUpdateResponse} value PersonUpdateResponse received value
   */
  onReceive_PersonUpdateResponse (value) {}  // eslint-disable-line

  /**
   * PersonsInsert2Request receive handler
   * @this {!Client}
   * @param {!PersonsInsert2Request} value PersonsInsert2Request received value
   */
  onReceive_PersonsInsert2Request (value) {}  // eslint-disable-line

  /**
   * PersonsInsert2Response receive handler
   * @this {!Client}
   * @param {!PersonsInsert2Response} value PersonsInsert2Response received value
   */
  onReceive_PersonsInsert2Response (value) {}  // eslint-disable-line

  /**
   * PersonUpdate2Request receive handler
   * @this {!Client}
   * @param {!PersonUpdate2Request} value PersonUpdate2Request received value
   */
  onReceive_PersonUpdate2Request (value) {}  // eslint-disable-line

  /**
   * PersonUpdate2Response receive handler
   * @this {!Client}
   * @param {!PersonUpdate2Response} value PersonUpdate2Response received value
   */
  onReceive_PersonUpdate2Response (value) {}  // eslint-disable-line

  /**
   * LastIdsGetRequest receive handler
   * @this {!Client}
   * @param {!LastIdsGetRequest} value LastIdsGetRequest received value
   */
  onReceive_LastIdsGetRequest (value) {}  // eslint-disable-line

  /**
   * LastIdsGetResponse receive handler
   * @this {!Client}
   * @param {!LastIdsGetResponse} value LastIdsGetResponse received value
   */
  onReceive_LastIdsGetResponse (value) {}  // eslint-disable-line

  /**
   * DailySnapshotRequest receive handler
   * @this {!Client}
   * @param {!DailySnapshotRequest} value DailySnapshotRequest received value
   */
  onReceive_DailySnapshotRequest (value) {}  // eslint-disable-line

  /**
   * DailySnapshotResponse receive handler
   * @this {!Client}
   * @param {!DailySnapshotResponse} value DailySnapshotResponse received value
   */
  onReceive_DailySnapshotResponse (value) {}  // eslint-disable-line

  /**
   * BillingStatisticsGetRequest receive handler
   * @this {!Client}
   * @param {!BillingStatisticsGetRequest} value BillingStatisticsGetRequest received value
   */
  onReceive_BillingStatisticsGetRequest (value) {}  // eslint-disable-line

  /**
   * BillingStatisticsGetResponse receive handler
   * @this {!Client}
   * @param {!BillingStatisticsGetResponse} value BillingStatisticsGetResponse received value
   */
  onReceive_BillingStatisticsGetResponse (value) {}  // eslint-disable-line

  /**
   * storage receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case StorageVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StorageVersionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._StorageVersionRequestReceiverModel.verify(), 'storage.StorageVersionRequest validation failed!')
        let deserialized = this._StorageVersionRequestReceiverModel.deserialize(this._StorageVersionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.StorageVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StorageVersionRequestReceiverValue)
        this.onReceive_StorageVersionRequest(this._StorageVersionRequestReceiverValue)
        return true
      }
      case StorageVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StorageVersionResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._StorageVersionResponseReceiverModel.verify(), 'storage.StorageVersionResponse validation failed!')
        let deserialized = this._StorageVersionResponseReceiverModel.deserialize(this._StorageVersionResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.StorageVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StorageVersionResponseReceiverValue)
        this.onReceive_StorageVersionResponse(this._StorageVersionResponseReceiverValue)
        return true
      }
      case StorageUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StorageUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._StorageUpdateNotifyReceiverModel.verify(), 'storage.StorageUpdateNotify validation failed!')
        let deserialized = this._StorageUpdateNotifyReceiverModel.deserialize(this._StorageUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'storage.StorageUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StorageUpdateNotifyReceiverValue)
        this.onReceive_StorageUpdateNotify(this._StorageUpdateNotifyReceiverValue)
        return true
      }
      case AccountInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInsertRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsertRequestReceiverModel.verify(), 'storage.AccountInsertRequest validation failed!')
        let deserialized = this._AccountInsertRequestReceiverModel.deserialize(this._AccountInsertRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInsertRequestReceiverValue)
        this.onReceive_AccountInsertRequest(this._AccountInsertRequestReceiverValue)
        return true
      }
      case AccountInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInsertResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsertResponseReceiverModel.verify(), 'storage.AccountInsertResponse validation failed!')
        let deserialized = this._AccountInsertResponseReceiverModel.deserialize(this._AccountInsertResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInsertResponseReceiverValue)
        this.onReceive_AccountInsertResponse(this._AccountInsertResponseReceiverValue)
        return true
      }
      case AccountSocialTradingMasterInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterInsertRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsertRequestReceiverModel.verify(), 'storage.AccountSocialTradingMasterInsertRequest validation failed!')
        let deserialized = this._AccountSocialTradingMasterInsertRequestReceiverModel.deserialize(this._AccountSocialTradingMasterInsertRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingMasterInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterInsertRequestReceiverValue)
        this.onReceive_AccountSocialTradingMasterInsertRequest(this._AccountSocialTradingMasterInsertRequestReceiverValue)
        return true
      }
      case AccountSocialTradingMasterInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterInsertResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsertResponseReceiverModel.verify(), 'storage.AccountSocialTradingMasterInsertResponse validation failed!')
        let deserialized = this._AccountSocialTradingMasterInsertResponseReceiverModel.deserialize(this._AccountSocialTradingMasterInsertResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingMasterInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterInsertResponseReceiverValue)
        this.onReceive_AccountSocialTradingMasterInsertResponse(this._AccountSocialTradingMasterInsertResponseReceiverValue)
        return true
      }
      case AccountSocialTradingInvestorInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorInsertRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsertRequestReceiverModel.verify(), 'storage.AccountSocialTradingInvestorInsertRequest validation failed!')
        let deserialized = this._AccountSocialTradingInvestorInsertRequestReceiverModel.deserialize(this._AccountSocialTradingInvestorInsertRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingInvestorInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorInsertRequestReceiverValue)
        this.onReceive_AccountSocialTradingInvestorInsertRequest(this._AccountSocialTradingInvestorInsertRequestReceiverValue)
        return true
      }
      case AccountSocialTradingInvestorInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorInsertResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsertResponseReceiverModel.verify(), 'storage.AccountSocialTradingInvestorInsertResponse validation failed!')
        let deserialized = this._AccountSocialTradingInvestorInsertResponseReceiverModel.deserialize(this._AccountSocialTradingInvestorInsertResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingInvestorInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorInsertResponseReceiverValue)
        this.onReceive_AccountSocialTradingInvestorInsertResponse(this._AccountSocialTradingInvestorInsertResponseReceiverValue)
        return true
      }
      case AccountUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateRequestReceiverModel.verify(), 'storage.AccountUpdateRequest validation failed!')
        let deserialized = this._AccountUpdateRequestReceiverModel.deserialize(this._AccountUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdateRequestReceiverValue)
        this.onReceive_AccountUpdateRequest(this._AccountUpdateRequestReceiverValue)
        return true
      }
      case AccountUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdateResponseReceiverModel.verify(), 'storage.AccountUpdateResponse validation failed!')
        let deserialized = this._AccountUpdateResponseReceiverModel.deserialize(this._AccountUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdateResponseReceiverValue)
        this.onReceive_AccountUpdateResponse(this._AccountUpdateResponseReceiverValue)
        return true
      }
      case AccountDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteRequestReceiverModel.verify(), 'storage.AccountDeleteRequest validation failed!')
        let deserialized = this._AccountDeleteRequestReceiverModel.deserialize(this._AccountDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDeleteRequestReceiverValue)
        this.onReceive_AccountDeleteRequest(this._AccountDeleteRequestReceiverValue)
        return true
      }
      case AccountDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDeleteResponseReceiverModel.verify(), 'storage.AccountDeleteResponse validation failed!')
        let deserialized = this._AccountDeleteResponseReceiverModel.deserialize(this._AccountDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDeleteResponseReceiverValue)
        this.onReceive_AccountDeleteResponse(this._AccountDeleteResponseReceiverValue)
        return true
      }
      case MarginUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateRequestReceiverModel.verify(), 'storage.MarginUpdateRequest validation failed!')
        let deserialized = this._MarginUpdateRequestReceiverModel.deserialize(this._MarginUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.MarginUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdateRequestReceiverValue)
        this.onReceive_MarginUpdateRequest(this._MarginUpdateRequestReceiverValue)
        return true
      }
      case MarginUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateResponseReceiverModel.verify(), 'storage.MarginUpdateResponse validation failed!')
        let deserialized = this._MarginUpdateResponseReceiverModel.deserialize(this._MarginUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.MarginUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdateResponseReceiverValue)
        this.onReceive_MarginUpdateResponse(this._MarginUpdateResponseReceiverValue)
        return true
      }
      case AssetInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInsertRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsertRequestReceiverModel.verify(), 'storage.AssetInsertRequest validation failed!')
        let deserialized = this._AssetInsertRequestReceiverModel.deserialize(this._AssetInsertRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInsertRequestReceiverValue)
        this.onReceive_AssetInsertRequest(this._AssetInsertRequestReceiverValue)
        return true
      }
      case AssetInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInsertResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsertResponseReceiverModel.verify(), 'storage.AssetInsertResponse validation failed!')
        let deserialized = this._AssetInsertResponseReceiverModel.deserialize(this._AssetInsertResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInsertResponseReceiverValue)
        this.onReceive_AssetInsertResponse(this._AssetInsertResponseReceiverValue)
        return true
      }
      case AssetUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdateRequestReceiverModel.verify(), 'storage.AssetUpdateRequest validation failed!')
        let deserialized = this._AssetUpdateRequestReceiverModel.deserialize(this._AssetUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetUpdateRequestReceiverValue)
        this.onReceive_AssetUpdateRequest(this._AssetUpdateRequestReceiverValue)
        return true
      }
      case AssetUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdateResponseReceiverModel.verify(), 'storage.AssetUpdateResponse validation failed!')
        let deserialized = this._AssetUpdateResponseReceiverModel.deserialize(this._AssetUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetUpdateResponseReceiverValue)
        this.onReceive_AssetUpdateResponse(this._AssetUpdateResponseReceiverValue)
        return true
      }
      case AssetDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDeleteRequestReceiverModel.verify(), 'storage.AssetDeleteRequest validation failed!')
        let deserialized = this._AssetDeleteRequestReceiverModel.deserialize(this._AssetDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetDeleteRequestReceiverValue)
        this.onReceive_AssetDeleteRequest(this._AssetDeleteRequestReceiverValue)
        return true
      }
      case AssetDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDeleteResponseReceiverModel.verify(), 'storage.AssetDeleteResponse validation failed!')
        let deserialized = this._AssetDeleteResponseReceiverModel.deserialize(this._AssetDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetDeleteResponseReceiverValue)
        this.onReceive_AssetDeleteResponse(this._AssetDeleteResponseReceiverValue)
        return true
      }
      case OrderInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInsertRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsertRequestReceiverModel.verify(), 'storage.OrderInsertRequest validation failed!')
        let deserialized = this._OrderInsertRequestReceiverModel.deserialize(this._OrderInsertRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInsertRequestReceiverValue)
        this.onReceive_OrderInsertRequest(this._OrderInsertRequestReceiverValue)
        return true
      }
      case OrderInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInsertResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsertResponseReceiverModel.verify(), 'storage.OrderInsertResponse validation failed!')
        let deserialized = this._OrderInsertResponseReceiverModel.deserialize(this._OrderInsertResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInsertResponseReceiverValue)
        this.onReceive_OrderInsertResponse(this._OrderInsertResponseReceiverValue)
        return true
      }
      case OrderUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateRequestReceiverModel.verify(), 'storage.OrderUpdateRequest validation failed!')
        let deserialized = this._OrderUpdateRequestReceiverModel.deserialize(this._OrderUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateRequestReceiverValue)
        this.onReceive_OrderUpdateRequest(this._OrderUpdateRequestReceiverValue)
        return true
      }
      case OrderUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateResponseReceiverModel.verify(), 'storage.OrderUpdateResponse validation failed!')
        let deserialized = this._OrderUpdateResponseReceiverModel.deserialize(this._OrderUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateResponseReceiverValue)
        this.onReceive_OrderUpdateResponse(this._OrderUpdateResponseReceiverValue)
        return true
      }
      case OrderDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteRequestReceiverModel.verify(), 'storage.OrderDeleteRequest validation failed!')
        let deserialized = this._OrderDeleteRequestReceiverModel.deserialize(this._OrderDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDeleteRequestReceiverValue)
        this.onReceive_OrderDeleteRequest(this._OrderDeleteRequestReceiverValue)
        return true
      }
      case OrderDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteResponseReceiverModel.verify(), 'storage.OrderDeleteResponse validation failed!')
        let deserialized = this._OrderDeleteResponseReceiverModel.deserialize(this._OrderDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDeleteResponseReceiverValue)
        this.onReceive_OrderDeleteResponse(this._OrderDeleteResponseReceiverValue)
        return true
      }
      case OrderDeleteAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDeleteAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteAllRequestReceiverModel.verify(), 'storage.OrderDeleteAllRequest validation failed!')
        let deserialized = this._OrderDeleteAllRequestReceiverModel.deserialize(this._OrderDeleteAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderDeleteAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDeleteAllRequestReceiverValue)
        this.onReceive_OrderDeleteAllRequest(this._OrderDeleteAllRequestReceiverValue)
        return true
      }
      case OrderDeleteAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDeleteAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDeleteAllResponseReceiverModel.verify(), 'storage.OrderDeleteAllResponse validation failed!')
        let deserialized = this._OrderDeleteAllResponseReceiverModel.deserialize(this._OrderDeleteAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderDeleteAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDeleteAllResponseReceiverValue)
        this.onReceive_OrderDeleteAllResponse(this._OrderDeleteAllResponseReceiverValue)
        return true
      }
      case PositionInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInsertRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsertRequestReceiverModel.verify(), 'storage.PositionInsertRequest validation failed!')
        let deserialized = this._PositionInsertRequestReceiverModel.deserialize(this._PositionInsertRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInsertRequestReceiverValue)
        this.onReceive_PositionInsertRequest(this._PositionInsertRequestReceiverValue)
        return true
      }
      case PositionInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInsertResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsertResponseReceiverModel.verify(), 'storage.PositionInsertResponse validation failed!')
        let deserialized = this._PositionInsertResponseReceiverModel.deserialize(this._PositionInsertResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInsertResponseReceiverValue)
        this.onReceive_PositionInsertResponse(this._PositionInsertResponseReceiverValue)
        return true
      }
      case PositionUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdateRequestReceiverModel.verify(), 'storage.PositionUpdateRequest validation failed!')
        let deserialized = this._PositionUpdateRequestReceiverModel.deserialize(this._PositionUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionUpdateRequestReceiverValue)
        this.onReceive_PositionUpdateRequest(this._PositionUpdateRequestReceiverValue)
        return true
      }
      case PositionUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdateResponseReceiverModel.verify(), 'storage.PositionUpdateResponse validation failed!')
        let deserialized = this._PositionUpdateResponseReceiverModel.deserialize(this._PositionUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionUpdateResponseReceiverValue)
        this.onReceive_PositionUpdateResponse(this._PositionUpdateResponseReceiverValue)
        return true
      }
      case PositionDeleteRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionDeleteRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDeleteRequestReceiverModel.verify(), 'storage.PositionDeleteRequest validation failed!')
        let deserialized = this._PositionDeleteRequestReceiverModel.deserialize(this._PositionDeleteRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionDeleteRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionDeleteRequestReceiverValue)
        this.onReceive_PositionDeleteRequest(this._PositionDeleteRequestReceiverValue)
        return true
      }
      case PositionDeleteResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionDeleteResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDeleteResponseReceiverModel.verify(), 'storage.PositionDeleteResponse validation failed!')
        let deserialized = this._PositionDeleteResponseReceiverModel.deserialize(this._PositionDeleteResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionDeleteResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionDeleteResponseReceiverValue)
        this.onReceive_PositionDeleteResponse(this._PositionDeleteResponseReceiverValue)
        return true
      }
      case TradeHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryRequestReceiverModel.verify(), 'storage.TradeHistoryRequest validation failed!')
        let deserialized = this._TradeHistoryRequestReceiverModel.deserialize(this._TradeHistoryRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryRequestReceiverValue)
        this.onReceive_TradeHistoryRequest(this._TradeHistoryRequestReceiverValue)
        return true
      }
      case TradeHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryResponseReceiverModel.verify(), 'storage.TradeHistoryResponse validation failed!')
        let deserialized = this._TradeHistoryResponseReceiverModel.deserialize(this._TradeHistoryResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryResponseReceiverValue)
        this.onReceive_TradeHistoryResponse(this._TradeHistoryResponseReceiverValue)
        return true
      }
      case TradeEventsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetRequestReceiverModel.verify(), 'storage.TradeEventsGetRequest validation failed!')
        let deserialized = this._TradeEventsGetRequestReceiverModel.deserialize(this._TradeEventsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetRequestReceiverValue)
        this.onReceive_TradeEventsGetRequest(this._TradeEventsGetRequestReceiverValue)
        return true
      }
      case TradeEventsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsGetResponseReceiverModel.verify(), 'storage.TradeEventsGetResponse validation failed!')
        let deserialized = this._TradeEventsGetResponseReceiverModel.deserialize(this._TradeEventsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsGetResponseReceiverValue)
        this.onReceive_TradeEventsGetResponse(this._TradeEventsGetResponseReceiverValue)
        return true
      }
      case TradeEventsNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsNotifyReceiverModel.verify(), 'storage.TradeEventsNotify validation failed!')
        let deserialized = this._TradeEventsNotifyReceiverModel.deserialize(this._TradeEventsNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsNotifyReceiverValue)
        this.onReceive_TradeEventsNotify(this._TradeEventsNotifyReceiverValue)
        return true
      }
      case TradeEventsInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsInsertRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsertRequestReceiverModel.verify(), 'storage.TradeEventsInsertRequest validation failed!')
        let deserialized = this._TradeEventsInsertRequestReceiverModel.deserialize(this._TradeEventsInsertRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsInsertRequestReceiverValue)
        this.onReceive_TradeEventsInsertRequest(this._TradeEventsInsertRequestReceiverValue)
        return true
      }
      case TradeEventsInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsInsertResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsertResponseReceiverModel.verify(), 'storage.TradeEventsInsertResponse validation failed!')
        let deserialized = this._TradeEventsInsertResponseReceiverModel.deserialize(this._TradeEventsInsertResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsInsertResponseReceiverValue)
        this.onReceive_TradeEventsInsertResponse(this._TradeEventsInsertResponseReceiverValue)
        return true
      }
      case ProfitClosedGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetRequestReceiverModel.verify(), 'storage.ProfitClosedGetRequest validation failed!')
        let deserialized = this._ProfitClosedGetRequestReceiverModel.deserialize(this._ProfitClosedGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.ProfitClosedGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetRequestReceiverValue)
        this.onReceive_ProfitClosedGetRequest(this._ProfitClosedGetRequestReceiverValue)
        return true
      }
      case ProfitClosedGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ProfitClosedGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ProfitClosedGetResponseReceiverModel.verify(), 'storage.ProfitClosedGetResponse validation failed!')
        let deserialized = this._ProfitClosedGetResponseReceiverModel.deserialize(this._ProfitClosedGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.ProfitClosedGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ProfitClosedGetResponseReceiverValue)
        this.onReceive_ProfitClosedGetResponse(this._ProfitClosedGetResponseReceiverValue)
        return true
      }
      case OrdersClosedSummariesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersClosedSummariesGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetRequestReceiverModel.verify(), 'storage.OrdersClosedSummariesGetRequest validation failed!')
        let deserialized = this._OrdersClosedSummariesGetRequestReceiverModel.deserialize(this._OrdersClosedSummariesGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrdersClosedSummariesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersClosedSummariesGetRequestReceiverValue)
        this.onReceive_OrdersClosedSummariesGetRequest(this._OrdersClosedSummariesGetRequestReceiverValue)
        return true
      }
      case OrdersClosedSummariesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersClosedSummariesGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersClosedSummariesGetResponseReceiverModel.verify(), 'storage.OrdersClosedSummariesGetResponse validation failed!')
        let deserialized = this._OrdersClosedSummariesGetResponseReceiverModel.deserialize(this._OrdersClosedSummariesGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrdersClosedSummariesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersClosedSummariesGetResponseReceiverValue)
        this.onReceive_OrdersClosedSummariesGetResponse(this._OrdersClosedSummariesGetResponseReceiverValue)
        return true
      }
      case OrderInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInsert2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsert2RequestReceiverModel.verify(), 'storage.OrderInsert2Request validation failed!')
        let deserialized = this._OrderInsert2RequestReceiverModel.deserialize(this._OrderInsert2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInsert2RequestReceiverValue)
        this.onReceive_OrderInsert2Request(this._OrderInsert2RequestReceiverValue)
        return true
      }
      case OrderInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderInsert2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderInsert2ResponseReceiverModel.verify(), 'storage.OrderInsert2Response validation failed!')
        let deserialized = this._OrderInsert2ResponseReceiverModel.deserialize(this._OrderInsert2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderInsert2ResponseReceiverValue)
        this.onReceive_OrderInsert2Response(this._OrderInsert2ResponseReceiverValue)
        return true
      }
      case OrderUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdate2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdate2RequestReceiverModel.verify(), 'storage.OrderUpdate2Request validation failed!')
        let deserialized = this._OrderUpdate2RequestReceiverModel.deserialize(this._OrderUpdate2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdate2RequestReceiverValue)
        this.onReceive_OrderUpdate2Request(this._OrderUpdate2RequestReceiverValue)
        return true
      }
      case OrderUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdate2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdate2ResponseReceiverModel.verify(), 'storage.OrderUpdate2Response validation failed!')
        let deserialized = this._OrderUpdate2ResponseReceiverModel.deserialize(this._OrderUpdate2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdate2ResponseReceiverValue)
        this.onReceive_OrderUpdate2Response(this._OrderUpdate2ResponseReceiverValue)
        return true
      }
      case OrderDelete2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDelete2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDelete2RequestReceiverModel.verify(), 'storage.OrderDelete2Request validation failed!')
        let deserialized = this._OrderDelete2RequestReceiverModel.deserialize(this._OrderDelete2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderDelete2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDelete2RequestReceiverValue)
        this.onReceive_OrderDelete2Request(this._OrderDelete2RequestReceiverValue)
        return true
      }
      case OrderDelete2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderDelete2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderDelete2ResponseReceiverModel.verify(), 'storage.OrderDelete2Response validation failed!')
        let deserialized = this._OrderDelete2ResponseReceiverModel.deserialize(this._OrderDelete2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderDelete2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderDelete2ResponseReceiverValue)
        this.onReceive_OrderDelete2Response(this._OrderDelete2ResponseReceiverValue)
        return true
      }
      case AccountInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInsert2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsert2RequestReceiverModel.verify(), 'storage.AccountInsert2Request validation failed!')
        let deserialized = this._AccountInsert2RequestReceiverModel.deserialize(this._AccountInsert2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInsert2RequestReceiverValue)
        this.onReceive_AccountInsert2Request(this._AccountInsert2RequestReceiverValue)
        return true
      }
      case AccountInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountInsert2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountInsert2ResponseReceiverModel.verify(), 'storage.AccountInsert2Response validation failed!')
        let deserialized = this._AccountInsert2ResponseReceiverModel.deserialize(this._AccountInsert2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountInsert2ResponseReceiverValue)
        this.onReceive_AccountInsert2Response(this._AccountInsert2ResponseReceiverValue)
        return true
      }
      case AccountSocialTradingMasterInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterInsert2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsert2RequestReceiverModel.verify(), 'storage.AccountSocialTradingMasterInsert2Request validation failed!')
        let deserialized = this._AccountSocialTradingMasterInsert2RequestReceiverModel.deserialize(this._AccountSocialTradingMasterInsert2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingMasterInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterInsert2RequestReceiverValue)
        this.onReceive_AccountSocialTradingMasterInsert2Request(this._AccountSocialTradingMasterInsert2RequestReceiverValue)
        return true
      }
      case AccountSocialTradingMasterInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingMasterInsert2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingMasterInsert2ResponseReceiverModel.verify(), 'storage.AccountSocialTradingMasterInsert2Response validation failed!')
        let deserialized = this._AccountSocialTradingMasterInsert2ResponseReceiverModel.deserialize(this._AccountSocialTradingMasterInsert2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingMasterInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingMasterInsert2ResponseReceiverValue)
        this.onReceive_AccountSocialTradingMasterInsert2Response(this._AccountSocialTradingMasterInsert2ResponseReceiverValue)
        return true
      }
      case AccountSocialTradingInvestorInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorInsert2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsert2RequestReceiverModel.verify(), 'storage.AccountSocialTradingInvestorInsert2Request validation failed!')
        let deserialized = this._AccountSocialTradingInvestorInsert2RequestReceiverModel.deserialize(this._AccountSocialTradingInvestorInsert2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingInvestorInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorInsert2RequestReceiverValue)
        this.onReceive_AccountSocialTradingInvestorInsert2Request(this._AccountSocialTradingInvestorInsert2RequestReceiverValue)
        return true
      }
      case AccountSocialTradingInvestorInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountSocialTradingInvestorInsert2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountSocialTradingInvestorInsert2ResponseReceiverModel.verify(), 'storage.AccountSocialTradingInvestorInsert2Response validation failed!')
        let deserialized = this._AccountSocialTradingInvestorInsert2ResponseReceiverModel.deserialize(this._AccountSocialTradingInvestorInsert2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountSocialTradingInvestorInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountSocialTradingInvestorInsert2ResponseReceiverValue)
        this.onReceive_AccountSocialTradingInvestorInsert2Response(this._AccountSocialTradingInvestorInsert2ResponseReceiverValue)
        return true
      }
      case AccountDelete2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDelete2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDelete2RequestReceiverModel.verify(), 'storage.AccountDelete2Request validation failed!')
        let deserialized = this._AccountDelete2RequestReceiverModel.deserialize(this._AccountDelete2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountDelete2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDelete2RequestReceiverValue)
        this.onReceive_AccountDelete2Request(this._AccountDelete2RequestReceiverValue)
        return true
      }
      case AccountDelete2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountDelete2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountDelete2ResponseReceiverModel.verify(), 'storage.AccountDelete2Response validation failed!')
        let deserialized = this._AccountDelete2ResponseReceiverModel.deserialize(this._AccountDelete2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountDelete2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountDelete2ResponseReceiverValue)
        this.onReceive_AccountDelete2Response(this._AccountDelete2ResponseReceiverValue)
        return true
      }
      case AssetInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInsert2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsert2RequestReceiverModel.verify(), 'storage.AssetInsert2Request validation failed!')
        let deserialized = this._AssetInsert2RequestReceiverModel.deserialize(this._AssetInsert2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInsert2RequestReceiverValue)
        this.onReceive_AssetInsert2Request(this._AssetInsert2RequestReceiverValue)
        return true
      }
      case AssetInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetInsert2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetInsert2ResponseReceiverModel.verify(), 'storage.AssetInsert2Response validation failed!')
        let deserialized = this._AssetInsert2ResponseReceiverModel.deserialize(this._AssetInsert2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetInsert2ResponseReceiverValue)
        this.onReceive_AssetInsert2Response(this._AssetInsert2ResponseReceiverValue)
        return true
      }
      case AssetUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetUpdate2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdate2RequestReceiverModel.verify(), 'storage.AssetUpdate2Request validation failed!')
        let deserialized = this._AssetUpdate2RequestReceiverModel.deserialize(this._AssetUpdate2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetUpdate2RequestReceiverValue)
        this.onReceive_AssetUpdate2Request(this._AssetUpdate2RequestReceiverValue)
        return true
      }
      case AssetUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetUpdate2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetUpdate2ResponseReceiverModel.verify(), 'storage.AssetUpdate2Response validation failed!')
        let deserialized = this._AssetUpdate2ResponseReceiverModel.deserialize(this._AssetUpdate2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetUpdate2ResponseReceiverValue)
        this.onReceive_AssetUpdate2Response(this._AssetUpdate2ResponseReceiverValue)
        return true
      }
      case AssetDelete2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetDelete2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDelete2RequestReceiverModel.verify(), 'storage.AssetDelete2Request validation failed!')
        let deserialized = this._AssetDelete2RequestReceiverModel.deserialize(this._AssetDelete2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetDelete2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetDelete2RequestReceiverValue)
        this.onReceive_AssetDelete2Request(this._AssetDelete2RequestReceiverValue)
        return true
      }
      case AssetDelete2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetDelete2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetDelete2ResponseReceiverModel.verify(), 'storage.AssetDelete2Response validation failed!')
        let deserialized = this._AssetDelete2ResponseReceiverModel.deserialize(this._AssetDelete2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetDelete2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetDelete2ResponseReceiverValue)
        this.onReceive_AssetDelete2Response(this._AssetDelete2ResponseReceiverValue)
        return true
      }
      case PositionInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInsert2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsert2RequestReceiverModel.verify(), 'storage.PositionInsert2Request validation failed!')
        let deserialized = this._PositionInsert2RequestReceiverModel.deserialize(this._PositionInsert2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInsert2RequestReceiverValue)
        this.onReceive_PositionInsert2Request(this._PositionInsert2RequestReceiverValue)
        return true
      }
      case PositionInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionInsert2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionInsert2ResponseReceiverModel.verify(), 'storage.PositionInsert2Response validation failed!')
        let deserialized = this._PositionInsert2ResponseReceiverModel.deserialize(this._PositionInsert2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionInsert2ResponseReceiverValue)
        this.onReceive_PositionInsert2Response(this._PositionInsert2ResponseReceiverValue)
        return true
      }
      case PositionUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionUpdate2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdate2RequestReceiverModel.verify(), 'storage.PositionUpdate2Request validation failed!')
        let deserialized = this._PositionUpdate2RequestReceiverModel.deserialize(this._PositionUpdate2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionUpdate2RequestReceiverValue)
        this.onReceive_PositionUpdate2Request(this._PositionUpdate2RequestReceiverValue)
        return true
      }
      case PositionUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionUpdate2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionUpdate2ResponseReceiverModel.verify(), 'storage.PositionUpdate2Response validation failed!')
        let deserialized = this._PositionUpdate2ResponseReceiverModel.deserialize(this._PositionUpdate2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionUpdate2ResponseReceiverValue)
        this.onReceive_PositionUpdate2Response(this._PositionUpdate2ResponseReceiverValue)
        return true
      }
      case PositionDelete2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionDelete2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDelete2RequestReceiverModel.verify(), 'storage.PositionDelete2Request validation failed!')
        let deserialized = this._PositionDelete2RequestReceiverModel.deserialize(this._PositionDelete2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionDelete2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionDelete2RequestReceiverValue)
        this.onReceive_PositionDelete2Request(this._PositionDelete2RequestReceiverValue)
        return true
      }
      case PositionDelete2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionDelete2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionDelete2ResponseReceiverModel.verify(), 'storage.PositionDelete2Response validation failed!')
        let deserialized = this._PositionDelete2ResponseReceiverModel.deserialize(this._PositionDelete2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionDelete2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionDelete2ResponseReceiverValue)
        this.onReceive_PositionDelete2Response(this._PositionDelete2ResponseReceiverValue)
        return true
      }
      case Deprecated001Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated001ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated001ReceiverModel.verify(), 'storage.Deprecated001 validation failed!')
        let deserialized = this._Deprecated001ReceiverModel.deserialize(this._Deprecated001ReceiverValue)
        console.assert((deserialized.size > 0), 'storage.Deprecated001 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated001ReceiverValue)
        this.onReceive_Deprecated001(this._Deprecated001ReceiverValue)
        return true
      }
      case Deprecated002Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated002ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated002ReceiverModel.verify(), 'storage.Deprecated002 validation failed!')
        let deserialized = this._Deprecated002ReceiverModel.deserialize(this._Deprecated002ReceiverValue)
        console.assert((deserialized.size > 0), 'storage.Deprecated002 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated002ReceiverValue)
        this.onReceive_Deprecated002(this._Deprecated002ReceiverValue)
        return true
      }
      case Deprecated003Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated003ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated003ReceiverModel.verify(), 'storage.Deprecated003 validation failed!')
        let deserialized = this._Deprecated003ReceiverModel.deserialize(this._Deprecated003ReceiverValue)
        console.assert((deserialized.size > 0), 'storage.Deprecated003 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated003ReceiverValue)
        this.onReceive_Deprecated003(this._Deprecated003ReceiverValue)
        return true
      }
      case Deprecated004Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated004ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated004ReceiverModel.verify(), 'storage.Deprecated004 validation failed!')
        let deserialized = this._Deprecated004ReceiverModel.deserialize(this._Deprecated004ReceiverValue)
        console.assert((deserialized.size > 0), 'storage.Deprecated004 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated004ReceiverValue)
        this.onReceive_Deprecated004(this._Deprecated004ReceiverValue)
        return true
      }
      case Deprecated005Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated005ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated005ReceiverModel.verify(), 'storage.Deprecated005 validation failed!')
        let deserialized = this._Deprecated005ReceiverModel.deserialize(this._Deprecated005ReceiverValue)
        console.assert((deserialized.size > 0), 'storage.Deprecated005 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated005ReceiverValue)
        this.onReceive_Deprecated005(this._Deprecated005ReceiverValue)
        return true
      }
      case Deprecated006Model.fbeType: {
        // Deserialize the value from the FBE stream
        this._Deprecated006ReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Deprecated006ReceiverModel.verify(), 'storage.Deprecated006 validation failed!')
        let deserialized = this._Deprecated006ReceiverModel.deserialize(this._Deprecated006ReceiverValue)
        console.assert((deserialized.size > 0), 'storage.Deprecated006 deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Deprecated006ReceiverValue)
        this.onReceive_Deprecated006(this._Deprecated006ReceiverValue)
        return true
      }
      case TradeEventsInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsInsert2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsert2RequestReceiverModel.verify(), 'storage.TradeEventsInsert2Request validation failed!')
        let deserialized = this._TradeEventsInsert2RequestReceiverModel.deserialize(this._TradeEventsInsert2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsInsert2RequestReceiverValue)
        this.onReceive_TradeEventsInsert2Request(this._TradeEventsInsert2RequestReceiverValue)
        return true
      }
      case TradeEventsInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsInsert2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsInsert2ResponseReceiverModel.verify(), 'storage.TradeEventsInsert2Response validation failed!')
        let deserialized = this._TradeEventsInsert2ResponseReceiverModel.deserialize(this._TradeEventsInsert2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsInsert2ResponseReceiverValue)
        this.onReceive_TradeEventsInsert2Response(this._TradeEventsInsert2ResponseReceiverValue)
        return true
      }
      case MarginUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdate2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdate2RequestReceiverModel.verify(), 'storage.MarginUpdate2Request validation failed!')
        let deserialized = this._MarginUpdate2RequestReceiverModel.deserialize(this._MarginUpdate2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.MarginUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdate2RequestReceiverValue)
        this.onReceive_MarginUpdate2Request(this._MarginUpdate2RequestReceiverValue)
        return true
      }
      case MarginUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdate2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdate2ResponseReceiverModel.verify(), 'storage.MarginUpdate2Response validation failed!')
        let deserialized = this._MarginUpdate2ResponseReceiverModel.deserialize(this._MarginUpdate2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.MarginUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdate2ResponseReceiverValue)
        this.onReceive_MarginUpdate2Response(this._MarginUpdate2ResponseReceiverValue)
        return true
      }
      case AccountUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdate2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdate2RequestReceiverModel.verify(), 'storage.AccountUpdate2Request validation failed!')
        let deserialized = this._AccountUpdate2RequestReceiverModel.deserialize(this._AccountUpdate2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdate2RequestReceiverValue)
        this.onReceive_AccountUpdate2Request(this._AccountUpdate2RequestReceiverValue)
        return true
      }
      case AccountUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountUpdate2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountUpdate2ResponseReceiverModel.verify(), 'storage.AccountUpdate2Response validation failed!')
        let deserialized = this._AccountUpdate2ResponseReceiverModel.deserialize(this._AccountUpdate2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountUpdate2ResponseReceiverValue)
        this.onReceive_AccountUpdate2Response(this._AccountUpdate2ResponseReceiverValue)
        return true
      }
      case DepositMarginRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositMarginRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMarginRequestReceiverModel.verify(), 'storage.DepositMarginRequest validation failed!')
        let deserialized = this._DepositMarginRequestReceiverModel.deserialize(this._DepositMarginRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.DepositMarginRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositMarginRequestReceiverValue)
        this.onReceive_DepositMarginRequest(this._DepositMarginRequestReceiverValue)
        return true
      }
      case DepositMarginResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositMarginResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMarginResponseReceiverModel.verify(), 'storage.DepositMarginResponse validation failed!')
        let deserialized = this._DepositMarginResponseReceiverModel.deserialize(this._DepositMarginResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.DepositMarginResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositMarginResponseReceiverValue)
        this.onReceive_DepositMarginResponse(this._DepositMarginResponseReceiverValue)
        return true
      }
      case DepositMargin2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositMargin2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMargin2RequestReceiverModel.verify(), 'storage.DepositMargin2Request validation failed!')
        let deserialized = this._DepositMargin2RequestReceiverModel.deserialize(this._DepositMargin2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.DepositMargin2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositMargin2RequestReceiverValue)
        this.onReceive_DepositMargin2Request(this._DepositMargin2RequestReceiverValue)
        return true
      }
      case DepositMargin2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DepositMargin2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DepositMargin2ResponseReceiverModel.verify(), 'storage.DepositMargin2Response validation failed!')
        let deserialized = this._DepositMargin2ResponseReceiverModel.deserialize(this._DepositMargin2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.DepositMargin2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DepositMargin2ResponseReceiverValue)
        this.onReceive_DepositMargin2Response(this._DepositMargin2ResponseReceiverValue)
        return true
      }
      case StoreClientLoginSuccessRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StoreClientLoginSuccessRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccessRequestReceiverModel.verify(), 'storage.StoreClientLoginSuccessRequest validation failed!')
        let deserialized = this._StoreClientLoginSuccessRequestReceiverModel.deserialize(this._StoreClientLoginSuccessRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.StoreClientLoginSuccessRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StoreClientLoginSuccessRequestReceiverValue)
        this.onReceive_StoreClientLoginSuccessRequest(this._StoreClientLoginSuccessRequestReceiverValue)
        return true
      }
      case StoreClientLoginSuccessResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StoreClientLoginSuccessResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccessResponseReceiverModel.verify(), 'storage.StoreClientLoginSuccessResponse validation failed!')
        let deserialized = this._StoreClientLoginSuccessResponseReceiverModel.deserialize(this._StoreClientLoginSuccessResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.StoreClientLoginSuccessResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StoreClientLoginSuccessResponseReceiverValue)
        this.onReceive_StoreClientLoginSuccessResponse(this._StoreClientLoginSuccessResponseReceiverValue)
        return true
      }
      case ClientLoginInfoGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientLoginInfoGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetRequestReceiverModel.verify(), 'storage.ClientLoginInfoGetRequest validation failed!')
        let deserialized = this._ClientLoginInfoGetRequestReceiverModel.deserialize(this._ClientLoginInfoGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.ClientLoginInfoGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientLoginInfoGetRequestReceiverValue)
        this.onReceive_ClientLoginInfoGetRequest(this._ClientLoginInfoGetRequestReceiverValue)
        return true
      }
      case ClientLoginInfoGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientLoginInfoGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoGetResponseReceiverModel.verify(), 'storage.ClientLoginInfoGetResponse validation failed!')
        let deserialized = this._ClientLoginInfoGetResponseReceiverModel.deserialize(this._ClientLoginInfoGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.ClientLoginInfoGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientLoginInfoGetResponseReceiverValue)
        this.onReceive_ClientLoginInfoGetResponse(this._ClientLoginInfoGetResponseReceiverValue)
        return true
      }
      case ClientLoginInfoNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientLoginInfoNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientLoginInfoNotifyReceiverModel.verify(), 'storage.ClientLoginInfoNotify validation failed!')
        let deserialized = this._ClientLoginInfoNotifyReceiverModel.deserialize(this._ClientLoginInfoNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'storage.ClientLoginInfoNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientLoginInfoNotifyReceiverValue)
        this.onReceive_ClientLoginInfoNotify(this._ClientLoginInfoNotifyReceiverValue)
        return true
      }
      case StoreClientLoginSuccess2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StoreClientLoginSuccess2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccess2RequestReceiverModel.verify(), 'storage.StoreClientLoginSuccess2Request validation failed!')
        let deserialized = this._StoreClientLoginSuccess2RequestReceiverModel.deserialize(this._StoreClientLoginSuccess2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.StoreClientLoginSuccess2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StoreClientLoginSuccess2RequestReceiverValue)
        this.onReceive_StoreClientLoginSuccess2Request(this._StoreClientLoginSuccess2RequestReceiverValue)
        return true
      }
      case StoreClientLoginSuccess2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._StoreClientLoginSuccess2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._StoreClientLoginSuccess2ResponseReceiverModel.verify(), 'storage.StoreClientLoginSuccess2Response validation failed!')
        let deserialized = this._StoreClientLoginSuccess2ResponseReceiverModel.deserialize(this._StoreClientLoginSuccess2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.StoreClientLoginSuccess2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._StoreClientLoginSuccess2ResponseReceiverValue)
        this.onReceive_StoreClientLoginSuccess2Response(this._StoreClientLoginSuccess2ResponseReceiverValue)
        return true
      }
      case OrderUpdateWithTypeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithTypeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeRequestReceiverModel.verify(), 'storage.OrderUpdateWithTypeRequest validation failed!')
        let deserialized = this._OrderUpdateWithTypeRequestReceiverModel.deserialize(this._OrderUpdateWithTypeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateWithTypeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithTypeRequestReceiverValue)
        this.onReceive_OrderUpdateWithTypeRequest(this._OrderUpdateWithTypeRequestReceiverValue)
        return true
      }
      case OrderUpdateWithTypeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithTypeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithTypeResponseReceiverModel.verify(), 'storage.OrderUpdateWithTypeResponse validation failed!')
        let deserialized = this._OrderUpdateWithTypeResponseReceiverModel.deserialize(this._OrderUpdateWithTypeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateWithTypeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithTypeResponseReceiverValue)
        this.onReceive_OrderUpdateWithTypeResponse(this._OrderUpdateWithTypeResponseReceiverValue)
        return true
      }
      case OrderUpdateWithType2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithType2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithType2RequestReceiverModel.verify(), 'storage.OrderUpdateWithType2Request validation failed!')
        let deserialized = this._OrderUpdateWithType2RequestReceiverModel.deserialize(this._OrderUpdateWithType2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateWithType2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithType2RequestReceiverValue)
        this.onReceive_OrderUpdateWithType2Request(this._OrderUpdateWithType2RequestReceiverValue)
        return true
      }
      case OrderUpdateWithType2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrderUpdateWithType2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrderUpdateWithType2ResponseReceiverModel.verify(), 'storage.OrderUpdateWithType2Response validation failed!')
        let deserialized = this._OrderUpdateWithType2ResponseReceiverModel.deserialize(this._OrderUpdateWithType2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrderUpdateWithType2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrderUpdateWithType2ResponseReceiverValue)
        this.onReceive_OrderUpdateWithType2Response(this._OrderUpdateWithType2ResponseReceiverValue)
        return true
      }
      case TradeHistoryTimeRangeGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryTimeRangeGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryTimeRangeGetRequestReceiverModel.verify(), 'storage.TradeHistoryTimeRangeGetRequest validation failed!')
        let deserialized = this._TradeHistoryTimeRangeGetRequestReceiverModel.deserialize(this._TradeHistoryTimeRangeGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryTimeRangeGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryTimeRangeGetRequestReceiverValue)
        this.onReceive_TradeHistoryTimeRangeGetRequest(this._TradeHistoryTimeRangeGetRequestReceiverValue)
        return true
      }
      case TradeHistoryTimeRangeGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryTimeRangeGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryTimeRangeGetResponseReceiverModel.verify(), 'storage.TradeHistoryTimeRangeGetResponse validation failed!')
        let deserialized = this._TradeHistoryTimeRangeGetResponseReceiverModel.deserialize(this._TradeHistoryTimeRangeGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryTimeRangeGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryTimeRangeGetResponseReceiverValue)
        this.onReceive_TradeHistoryTimeRangeGetResponse(this._TradeHistoryTimeRangeGetResponseReceiverValue)
        return true
      }
      case TradeEventsTimeRangeGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsTimeRangeGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsTimeRangeGetRequestReceiverModel.verify(), 'storage.TradeEventsTimeRangeGetRequest validation failed!')
        let deserialized = this._TradeEventsTimeRangeGetRequestReceiverModel.deserialize(this._TradeEventsTimeRangeGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsTimeRangeGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsTimeRangeGetRequestReceiverValue)
        this.onReceive_TradeEventsTimeRangeGetRequest(this._TradeEventsTimeRangeGetRequestReceiverValue)
        return true
      }
      case TradeEventsTimeRangeGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeEventsTimeRangeGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeEventsTimeRangeGetResponseReceiverModel.verify(), 'storage.TradeEventsTimeRangeGetResponse validation failed!')
        let deserialized = this._TradeEventsTimeRangeGetResponseReceiverModel.deserialize(this._TradeEventsTimeRangeGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeEventsTimeRangeGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeEventsTimeRangeGetResponseReceiverValue)
        this.onReceive_TradeEventsTimeRangeGetResponse(this._TradeEventsTimeRangeGetResponseReceiverValue)
        return true
      }
      case TradeHistoryByIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdRequestReceiverModel.verify(), 'storage.TradeHistoryByIdRequest validation failed!')
        let deserialized = this._TradeHistoryByIdRequestReceiverModel.deserialize(this._TradeHistoryByIdRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryByIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdRequestReceiverValue)
        this.onReceive_TradeHistoryByIdRequest(this._TradeHistoryByIdRequestReceiverValue)
        return true
      }
      case TradeHistoryByIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TradeHistoryByIdResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TradeHistoryByIdResponseReceiverModel.verify(), 'storage.TradeHistoryByIdResponse validation failed!')
        let deserialized = this._TradeHistoryByIdResponseReceiverModel.deserialize(this._TradeHistoryByIdResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.TradeHistoryByIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TradeHistoryByIdResponseReceiverValue)
        this.onReceive_TradeHistoryByIdResponse(this._TradeHistoryByIdResponseReceiverValue)
        return true
      }
      case OrdersGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllRequestReceiverModel.verify(), 'storage.OrdersGetAllRequest validation failed!')
        let deserialized = this._OrdersGetAllRequestReceiverModel.deserialize(this._OrdersGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrdersGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllRequestReceiverValue)
        this.onReceive_OrdersGetAllRequest(this._OrdersGetAllRequestReceiverValue)
        return true
      }
      case OrdersGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._OrdersGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._OrdersGetAllResponseReceiverModel.verify(), 'storage.OrdersGetAllResponse validation failed!')
        let deserialized = this._OrdersGetAllResponseReceiverModel.deserialize(this._OrdersGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.OrdersGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._OrdersGetAllResponseReceiverValue)
        this.onReceive_OrdersGetAllResponse(this._OrdersGetAllResponseReceiverValue)
        return true
      }
      case PositionsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllRequestReceiverModel.verify(), 'storage.PositionsGetAllRequest validation failed!')
        let deserialized = this._PositionsGetAllRequestReceiverModel.deserialize(this._PositionsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllRequestReceiverValue)
        this.onReceive_PositionsGetAllRequest(this._PositionsGetAllRequestReceiverValue)
        return true
      }
      case PositionsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PositionsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PositionsGetAllResponseReceiverModel.verify(), 'storage.PositionsGetAllResponse validation failed!')
        let deserialized = this._PositionsGetAllResponseReceiverModel.deserialize(this._PositionsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PositionsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PositionsGetAllResponseReceiverValue)
        this.onReceive_PositionsGetAllResponse(this._PositionsGetAllResponseReceiverValue)
        return true
      }
      case AssetsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllRequestReceiverModel.verify(), 'storage.AssetsGetAllRequest validation failed!')
        let deserialized = this._AssetsGetAllRequestReceiverModel.deserialize(this._AssetsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllRequestReceiverValue)
        this.onReceive_AssetsGetAllRequest(this._AssetsGetAllRequestReceiverValue)
        return true
      }
      case AssetsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AssetsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AssetsGetAllResponseReceiverModel.verify(), 'storage.AssetsGetAllResponse validation failed!')
        let deserialized = this._AssetsGetAllResponseReceiverModel.deserialize(this._AssetsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AssetsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AssetsGetAllResponseReceiverValue)
        this.onReceive_AssetsGetAllResponse(this._AssetsGetAllResponseReceiverValue)
        return true
      }
      case MarginsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllRequestReceiverModel.verify(), 'storage.MarginsGetAllRequest validation failed!')
        let deserialized = this._MarginsGetAllRequestReceiverModel.deserialize(this._MarginsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.MarginsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllRequestReceiverValue)
        this.onReceive_MarginsGetAllRequest(this._MarginsGetAllRequestReceiverValue)
        return true
      }
      case MarginsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginsGetAllResponseReceiverModel.verify(), 'storage.MarginsGetAllResponse validation failed!')
        let deserialized = this._MarginsGetAllResponseReceiverModel.deserialize(this._MarginsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.MarginsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginsGetAllResponseReceiverValue)
        this.onReceive_MarginsGetAllResponse(this._MarginsGetAllResponseReceiverValue)
        return true
      }
      case AccountsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllRequestReceiverModel.verify(), 'storage.AccountsGetAllRequest validation failed!')
        let deserialized = this._AccountsGetAllRequestReceiverModel.deserialize(this._AccountsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllRequestReceiverValue)
        this.onReceive_AccountsGetAllRequest(this._AccountsGetAllRequestReceiverValue)
        return true
      }
      case AccountsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._AccountsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._AccountsGetAllResponseReceiverModel.verify(), 'storage.AccountsGetAllResponse validation failed!')
        let deserialized = this._AccountsGetAllResponseReceiverModel.deserialize(this._AccountsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.AccountsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._AccountsGetAllResponseReceiverValue)
        this.onReceive_AccountsGetAllResponse(this._AccountsGetAllResponseReceiverValue)
        return true
      }
      case MassRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MassRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MassRequestReceiverModel.verify(), 'storage.MassRequest validation failed!')
        let deserialized = this._MassRequestReceiverModel.deserialize(this._MassRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.MassRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MassRequestReceiverValue)
        this.onReceive_MassRequest(this._MassRequestReceiverValue)
        return true
      }
      case MassResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MassResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MassResponseReceiverModel.verify(), 'storage.MassResponse validation failed!')
        let deserialized = this._MassResponseReceiverModel.deserialize(this._MassResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.MassResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MassResponseReceiverValue)
        this.onReceive_MassResponse(this._MassResponseReceiverValue)
        return true
      }
      case Mass2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Mass2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Mass2RequestReceiverModel.verify(), 'storage.Mass2Request validation failed!')
        let deserialized = this._Mass2RequestReceiverModel.deserialize(this._Mass2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.Mass2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Mass2RequestReceiverValue)
        this.onReceive_Mass2Request(this._Mass2RequestReceiverValue)
        return true
      }
      case Mass2ResponseParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Mass2ResponseParamsReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Mass2ResponseParamsReceiverModel.verify(), 'storage.Mass2ResponseParams validation failed!')
        let deserialized = this._Mass2ResponseParamsReceiverModel.deserialize(this._Mass2ResponseParamsReceiverValue)
        console.assert((deserialized.size > 0), 'storage.Mass2ResponseParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Mass2ResponseParamsReceiverValue)
        this.onReceive_Mass2ResponseParams(this._Mass2ResponseParamsReceiverValue)
        return true
      }
      case Mass2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Mass2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Mass2ResponseReceiverModel.verify(), 'storage.Mass2Response validation failed!')
        let deserialized = this._Mass2ResponseReceiverModel.deserialize(this._Mass2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.Mass2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Mass2ResponseReceiverValue)
        this.onReceive_Mass2Response(this._Mass2ResponseReceiverValue)
        return true
      }
      case MarginUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MarginUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MarginUpdateNotifyReceiverModel.verify(), 'storage.MarginUpdateNotify validation failed!')
        let deserialized = this._MarginUpdateNotifyReceiverModel.deserialize(this._MarginUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'storage.MarginUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MarginUpdateNotifyReceiverValue)
        this.onReceive_MarginUpdateNotify(this._MarginUpdateNotifyReceiverValue)
        return true
      }
      case PersonsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllRequestReceiverModel.verify(), 'storage.PersonsGetAllRequest validation failed!')
        let deserialized = this._PersonsGetAllRequestReceiverModel.deserialize(this._PersonsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PersonsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllRequestReceiverValue)
        this.onReceive_PersonsGetAllRequest(this._PersonsGetAllRequestReceiverValue)
        return true
      }
      case PersonsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsGetAllResponseReceiverModel.verify(), 'storage.PersonsGetAllResponse validation failed!')
        let deserialized = this._PersonsGetAllResponseReceiverModel.deserialize(this._PersonsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PersonsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsGetAllResponseReceiverValue)
        this.onReceive_PersonsGetAllResponse(this._PersonsGetAllResponseReceiverValue)
        return true
      }
      case PersonsInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsertRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertRequestReceiverModel.verify(), 'storage.PersonsInsertRequest validation failed!')
        let deserialized = this._PersonsInsertRequestReceiverModel.deserialize(this._PersonsInsertRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PersonsInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsertRequestReceiverValue)
        this.onReceive_PersonsInsertRequest(this._PersonsInsertRequestReceiverValue)
        return true
      }
      case PersonsInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsertResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsertResponseReceiverModel.verify(), 'storage.PersonsInsertResponse validation failed!')
        let deserialized = this._PersonsInsertResponseReceiverModel.deserialize(this._PersonsInsertResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PersonsInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsertResponseReceiverValue)
        this.onReceive_PersonsInsertResponse(this._PersonsInsertResponseReceiverValue)
        return true
      }
      case PersonUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateRequestReceiverModel.verify(), 'storage.PersonUpdateRequest validation failed!')
        let deserialized = this._PersonUpdateRequestReceiverModel.deserialize(this._PersonUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PersonUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdateRequestReceiverValue)
        this.onReceive_PersonUpdateRequest(this._PersonUpdateRequestReceiverValue)
        return true
      }
      case PersonUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdateResponseReceiverModel.verify(), 'storage.PersonUpdateResponse validation failed!')
        let deserialized = this._PersonUpdateResponseReceiverModel.deserialize(this._PersonUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PersonUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdateResponseReceiverValue)
        this.onReceive_PersonUpdateResponse(this._PersonUpdateResponseReceiverValue)
        return true
      }
      case PersonsInsert2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsert2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsert2RequestReceiverModel.verify(), 'storage.PersonsInsert2Request validation failed!')
        let deserialized = this._PersonsInsert2RequestReceiverModel.deserialize(this._PersonsInsert2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PersonsInsert2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsert2RequestReceiverValue)
        this.onReceive_PersonsInsert2Request(this._PersonsInsert2RequestReceiverValue)
        return true
      }
      case PersonsInsert2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonsInsert2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonsInsert2ResponseReceiverModel.verify(), 'storage.PersonsInsert2Response validation failed!')
        let deserialized = this._PersonsInsert2ResponseReceiverModel.deserialize(this._PersonsInsert2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PersonsInsert2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonsInsert2ResponseReceiverValue)
        this.onReceive_PersonsInsert2Response(this._PersonsInsert2ResponseReceiverValue)
        return true
      }
      case PersonUpdate2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdate2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdate2RequestReceiverModel.verify(), 'storage.PersonUpdate2Request validation failed!')
        let deserialized = this._PersonUpdate2RequestReceiverModel.deserialize(this._PersonUpdate2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PersonUpdate2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdate2RequestReceiverValue)
        this.onReceive_PersonUpdate2Request(this._PersonUpdate2RequestReceiverValue)
        return true
      }
      case PersonUpdate2ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._PersonUpdate2ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._PersonUpdate2ResponseReceiverModel.verify(), 'storage.PersonUpdate2Response validation failed!')
        let deserialized = this._PersonUpdate2ResponseReceiverModel.deserialize(this._PersonUpdate2ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.PersonUpdate2Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._PersonUpdate2ResponseReceiverValue)
        this.onReceive_PersonUpdate2Response(this._PersonUpdate2ResponseReceiverValue)
        return true
      }
      case LastIdsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LastIdsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LastIdsGetRequestReceiverModel.verify(), 'storage.LastIdsGetRequest validation failed!')
        let deserialized = this._LastIdsGetRequestReceiverModel.deserialize(this._LastIdsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.LastIdsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LastIdsGetRequestReceiverValue)
        this.onReceive_LastIdsGetRequest(this._LastIdsGetRequestReceiverValue)
        return true
      }
      case LastIdsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LastIdsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LastIdsGetResponseReceiverModel.verify(), 'storage.LastIdsGetResponse validation failed!')
        let deserialized = this._LastIdsGetResponseReceiverModel.deserialize(this._LastIdsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.LastIdsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LastIdsGetResponseReceiverValue)
        this.onReceive_LastIdsGetResponse(this._LastIdsGetResponseReceiverValue)
        return true
      }
      case DailySnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DailySnapshotRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotRequestReceiverModel.verify(), 'storage.DailySnapshotRequest validation failed!')
        let deserialized = this._DailySnapshotRequestReceiverModel.deserialize(this._DailySnapshotRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.DailySnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DailySnapshotRequestReceiverValue)
        this.onReceive_DailySnapshotRequest(this._DailySnapshotRequestReceiverValue)
        return true
      }
      case DailySnapshotResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DailySnapshotResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DailySnapshotResponseReceiverModel.verify(), 'storage.DailySnapshotResponse validation failed!')
        let deserialized = this._DailySnapshotResponseReceiverModel.deserialize(this._DailySnapshotResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.DailySnapshotResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DailySnapshotResponseReceiverValue)
        this.onReceive_DailySnapshotResponse(this._DailySnapshotResponseReceiverValue)
        return true
      }
      case BillingStatisticsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BillingStatisticsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BillingStatisticsGetRequestReceiverModel.verify(), 'storage.BillingStatisticsGetRequest validation failed!')
        let deserialized = this._BillingStatisticsGetRequestReceiverModel.deserialize(this._BillingStatisticsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'storage.BillingStatisticsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BillingStatisticsGetRequestReceiverValue)
        this.onReceive_BillingStatisticsGetRequest(this._BillingStatisticsGetRequestReceiverValue)
        return true
      }
      case BillingStatisticsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BillingStatisticsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BillingStatisticsGetResponseReceiverModel.verify(), 'storage.BillingStatisticsGetResponse validation failed!')
        let deserialized = this._BillingStatisticsGetResponseReceiverModel.deserialize(this._BillingStatisticsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'storage.BillingStatisticsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BillingStatisticsGetResponseReceiverValue)
        this.onReceive_BillingStatisticsGetResponse(this._BillingStatisticsGetResponseReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.innerClient != null) && this.innerClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
    this._innerClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._innerClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._innerClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()
    this._innerClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)
    this._innerClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
