import { TradeHistoryRecord as TradeHistoryRecordApi } from '@api/fbe/core/TradeHistoryRecord';
import { TradeTransactionReason } from '@api/fbe/core/TradeTransactionReason';
import { TradeTransactionType } from '@api/fbe/core/TradeTransactionType';
import { MONEY_MANAGEMENT_TYPES } from '@constants/models';
import { getClassStaticFields } from 'utils';
import { ConvertFbeObjType } from 'utils/models';
import { OrdersClosedSummaryModel } from './OrdersClosedSummary';
import { OrdersClosedSummary } from '@api/fbe/core/OrdersClosedSummary';
import { OrderSide } from '@api/fbe/core/OrderSide';

export type TradeHistoryRecord = ConvertFbeObjType<TradeHistoryRecordApi>;

export enum OrdersTabs {
    positions = 'Positions',
    pendingOrders = 'PendingOrders',
}

export enum TransactionTypesEnum {
    trading = 'Trading',
    deposit = 'Deposit',
    withdrawal = 'Withdrawal',
    credit = 'Credit',
    transfer = 'Transfer',
}

export type TradeHistoryRequestParams = {
    Logins: string[];
    AccountGroupIds: string[];
    OrderId: number | null;
    SymbolIds: string[];
    RequestFrom: Date | null;
    RequestTo: Date | null;
    TransactionTypes: TransactionTypesEnum[];
    ExternalClientIds: string[];
};

export type TradeHistoryAccount = {
    key?: number;
    Login: number | null;
    ActualVersion: number | null;
    AccountGroupId: string | null;
    Name: string;
    Country: string;
    City: string;
    State: string;
    ZipCode: string;
    Address: string;
    Phone: string;
    Email: string;
    Identifier: string;
    Status: string;
    Comment: string;
    Description: string;
    IsEnabled: string;
    IsOTPEnabled: string;
    IsTradeEnabled: string;
    AuthenticationType: number | null;
    PasswordDigest: string;
    InvestorPasswordDigest: string;
    PhonePasswordDigest: string;
    OTPDigest: string;
    AccountingType: number | null;
    MaxPendingOrders: number | null;
    AgentAccount: number | null;
    Created: number | null;
    Modified: number | null;
    Archived: number | null;
    Deleted: number | null;
    Group: string;
    Platform: string;
};

export type TradeHistoryFilter = {
    RequestDirection?: number;
    RequestFrom?: Date;
    RequestTo?: Date;
    Symbols?: number[];
    TransactionTypes?: number[];
    OrderTypes?: number[];
    LastRecordId?: number;
    PageSize?: number;
    AccountLogin?: number;
    ManagerLogin?: number;
    AccountGroup?: number[];
};

export type TradeHistory = {
    key?: number;
    ExternalClientId: string | null;
    RecordId: number | null;
    AccountLogin: number | null;
    ManagerLogin: number | null;
    PlatformName: string | null;
    GroupName: string | null;
    TransactionType: number | null;
    TransactionReason: number | null;
    TransactionStep: number | null;
    TransactionTimestamp: Date | null;
    AccountComment: string | null;
    ManagerComment: string | null;
    BalanceCurrencyName: string | null;
    BalanceMovement: number | null;
    BalanceResult: number | null;
    SrcAssetCurrencyName: string | null;
    SrcAssetMovement: number | null;
    SrcAssetResult: number | null;
    DstAssetCurrencyName: string | null;
    DstAssetMovement: number | null;
    DstAssetResult: number | null;
    OrderId: number | null;
    ParentOrderId: number | null;
    ClientOrderId: string | null;
    ActualVersion: number | null;
    SymbolId: string | null;
    OrderSide: number | null;
    OrderType: number | null;
    OrderInitialType: number | null;
    OrderTimeInForce: number | null;
    OrderStatus: number | null;
    Price: number | null;
    StopPrice: number | null;
    TakeProfit: number | null;
    StopLoss: number | null;
    Amount: number | null;
    ExecutedAmount: number | null;
    RemainingAmount: number | null;
    Expiration: Date | null;
    Created: Date | null;
    Modified: Date | null;
    Archived: Date | null;
    Deleted: Date | null;
    Opened: Date | null;
    Closed: Date | null;
    OpenPrice: number | null;
    ClosePrice: number | null;
    CloseAmount: number | null;
    Profit: number | null;
    ProfitConversionRate: number | null;
    Commission: number | null;
    Swap: number | null;
    // Account: TradeHistoryAccount | null;
    AccountName: string | null;
    OpenMarginRate: number | null;
    CloseMarginRate: number | null;
    OpenMarkup: number | null;
    CloseMarkup: number | null;
    GatewayId: string | null;
    Leverage: number | null;
    PlatformId: string | null;
    MaxLeverage: number | null;
    AccountGroupId: string | null;

    BalanceMovementDeposit: number | null;
    BalanceMovementWithdrawal: number | null;
    BalanceMovementCredit: number | null;
    BalanceMovementTransfer: number | null;

    OrderRequestSourceType: number | null;
    IsCreatedViaQuickTrading: boolean | null;
    IsCreatedViaFastTrader: boolean | null;

    TransactionTradeVolumeUSD: number | null;
};

export const TRADE_TRANSACTION_TYPES = getClassStaticFields(TradeTransactionType);
export const TRADE_TRANSACTION_REASONS = getClassStaticFields(TradeTransactionReason);

export const getCustomOrderSide = (record: TradeHistoryRecordApi): number | null => {
    let customOrderSide = record.OrderSide ? record.OrderSide.valueOf() : null;
    if (record.TransactionType.eq(TradeTransactionType.DEPOSIT)) customOrderSide = MONEY_MANAGEMENT_TYPES.DEPOSIT;
    if (record.TransactionType.eq(TradeTransactionType.CREDIT)) customOrderSide = MONEY_MANAGEMENT_TYPES.CREDIT;
    if (record.TransactionType.eq(TradeTransactionType.TRANSFER)) customOrderSide = MONEY_MANAGEMENT_TYPES.TRANSFER;
    return customOrderSide;
};

export const getCustomProfit = (record: TradeHistoryRecordApi, type: TransactionTypesEnum): number | null => {
    if (record.BalanceMovement === null || record.BalanceMovement === undefined) return null;

    if (
        type === TransactionTypesEnum.deposit &&
        record.TransactionType.eq(TradeTransactionType.DEPOSIT) &&
        record.BalanceMovement >= 0
    ) {
        return record.BalanceMovement;
    }
    if (
        type === TransactionTypesEnum.withdrawal &&
        record.TransactionType.eq(TradeTransactionType.DEPOSIT) &&
        record.BalanceMovement < 0
    ) {
        return record.BalanceMovement;
    }
    if (type === TransactionTypesEnum.credit && record.TransactionType.eq(TradeTransactionType.CREDIT)) {
        return record.BalanceMovement;
    }
    if (type === TransactionTypesEnum.transfer && record.TransactionType.eq(TradeTransactionType.TRANSFER)) {
        return record.BalanceMovement;
    }
    return null;
};

const isTransactionMoneyManagement = (customOrderSide: number | null): boolean => {
    if (customOrderSide === null) return false;
    const moneyManagementValues = Object.keys(MONEY_MANAGEMENT_TYPES)
        .map((key) => +key)
        .filter((v) => !Number.isNaN(Number(v)));
    return moneyManagementValues.includes(customOrderSide);
};

export const convertOrdersClosedSummary = (record: OrdersClosedSummary, key?: number): OrdersClosedSummaryModel => ({
    key,
    RowLabel: record.AccountCurrencyName ? `${record.AccountCurrencyName} Accounts` : '',
    AccountCurrencyName: record.AccountCurrencyName,
    Profit: record.Profit,
    Swap: record.Swap,
    Commission: record.Commission,
    BidConversionPrice: record.BidConversionPrice ?? null,
    AskConversionPrice: record.AskConversionPrice ?? null,
});

const getManagerComment = (record: TradeHistoryRecordApi): string => {
    let managerComment = record.ManagerComment ?? '';
    if (
        (record.OrderSide?.eq(OrderSide.BUY) || record.OrderSide?.eq(OrderSide.SELL)) &&
        TradeTransactionReason.MANAGER_REQUEST.eq(record.TransactionReason)
    ) {
        managerComment = managerComment ? `Canceled by Dealer. ${managerComment}` : 'Canceled by Dealer';
    }
    return managerComment;
};

export const convertTradeRecordToTradeHistoryRecord = (record: TradeHistoryRecordApi): TradeHistory => {
    const customOrderSide = getCustomOrderSide(record);

    const result = {
        RecordId: +record.RecordId.toNumber(),
        AccountLogin: record.AccountLogin ? +record.AccountLogin.toNumber() : null,
        ManagerLogin: record.ManagerLogin ? +record.ManagerLogin.toNumber() : null,
        SymbolId: record.SymbolId ? record.SymbolId.toString() : null,
        PlatformName: record.PlatformName ?? null,
        GroupName: record.GroupName ?? null,
        TransactionType: record.TransactionType.valueOf(),
        TransactionReason: record.TransactionReason.valueOf(),
        TransactionStep: record.TransactionStep,
        TransactionTimestamp: record.TransactionTimestamp,
        AccountComment: record.AccountComment ?? '',
        ManagerComment: getManagerComment(record),
        BalanceCurrencyName: record.BalanceCurrencyName ?? '',
        BalanceMovement: record.BalanceMovement ?? null,
        BalanceResult: record.BalanceResult ?? null,
        SrcAssetCurrencyName: record.SrcAssetCurrencyName ?? null,
        SrcAssetMovement: record.SrcAssetMovement ?? null,
        SrcAssetResult: record.SrcAssetResult ?? null,
        DstAssetCurrencyName: record.DstAssetCurrencyName ?? null,
        DstAssetMovement: record.DstAssetMovement ?? null,
        DstAssetResult: record.DstAssetResult ?? null,
        OrderId: record.OrderId ? +record.OrderId.toNumber() : null,
        ParentOrderId: record.ParentOrderId ? +record.ParentOrderId.toNumber() : null,
        ClientOrderId: record.ClientOrderId ?? null,
        ActualVersion: record.ActualVersion ? +record.ActualVersion.toNumber() : null,
        OrderSide: customOrderSide,
        OrderType: record.OrderType ? record.OrderType.valueOf() : null,
        OrderInitialType: record.OrderInitialType ? record.OrderInitialType.valueOf() : null,
        OrderTimeInForce: record.OrderTimeInForce ? record.OrderTimeInForce.valueOf() : null,
        OrderStatus: record.OrderStatus ? record.OrderStatus.valueOf() : null,
        Price: record.Price ?? null,
        StopPrice: record.StopPrice ?? null,
        TakeProfit: record.TakeProfit ?? null,
        StopLoss: record.StopLoss ?? null,
        Amount: record.Amount ?? null,
        ExecutedAmount: record.ExecutedAmount ?? null,
        RemainingAmount: record.RemainingAmount ?? null,
        Expiration: record.Expiration ?? null,
        Created: record.Created ?? (isTransactionMoneyManagement(customOrderSide) ? record.TransactionTimestamp : null),
        Modified: record.Modified ?? null,
        Archived: record.Archived ?? null,
        Deleted: record.Deleted ?? null,
        Opened: record.Opened ?? null,
        Closed: record.Closed ?? null,

        OpenPrice: record.OpenPrice ?? null,
        ClosePrice: record.ClosePrice ?? null,
        CloseAmount: record.CloseAmount ?? null,
        Profit: record.Profit ?? null,
        ProfitConversionRate: record.ProfitConversionRate ?? null,
        Commission: record.Commission ?? null,
        Swap: record.Swap ?? null,
        AccountName: record.Account ? record.Account.Name : null,
        OpenMarginRate: record.OpenMarginRate ?? null,
        CloseMarginRate: record.CloseMarginRate ?? null,
        OpenMarkup: record.OpenMarkup ? +record.OpenMarkup.toNumber() : null,
        CloseMarkup: record.CloseMarkup ? +record.CloseMarkup.toNumber() : null,
        GatewayId: record.GatewayId ? record.GatewayId.toString() : null,
        Leverage: record.Leverage ?? null,
        PlatformId: record.PlatformId.toString(),
        MaxLeverage: record.MaxLeverage ?? null,
        AccountGroupId: record.AccountGroupId ? record.AccountGroupId.toString() : null,

        ExternalClientId: record.ExternalClientId ?? '',
        BalanceMovementDeposit: getCustomProfit(record, TransactionTypesEnum.deposit),
        BalanceMovementWithdrawal: getCustomProfit(record, TransactionTypesEnum.withdrawal),
        BalanceMovementCredit: getCustomProfit(record, TransactionTypesEnum.credit),
        BalanceMovementTransfer: getCustomProfit(record, TransactionTypesEnum.transfer),

        OrderRequestSourceType:
            record.OrderRequestSourceType !== null && record.OrderRequestSourceType !== undefined
                ? record.OrderRequestSourceType.valueOf()
                : null,
        IsCreatedViaQuickTrading: record.IsCreatedViaQuickTrading ?? null,
        IsCreatedViaFastTrader: record.IsCreatedViaFastTrader ?? null,
        TransactionTradeVolumeUSD: record.TransactionTradeVolumeUSD ?? null,
    };

    return result;
};
